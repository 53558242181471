<div
  #actionsPanel
  [appOverlayPanel]="selectionOptionsPanel"
  [overlayPanelData]="itemActions"
  [overlayPanelEnabled]="itemActions.length > 0"
  [overlayPanelOptions]="{ target: 'body', closeOnScroll: true }"
  (overlayPanelStatus)="opened.emit($event.opened)"
  class="actions-toggle"
>
  <span class="dot"></span>
  <span class="dot"></span>
  <span class="dot"></span>
</div>

<ng-template
  #selectionOptionsPanel
  let-data
>
  <ul class="actions-list">
    <li
      *ngFor="let action of data"
      class="action-list-item"
    >
      <button
        (click)="performAction(action)"
        class="action-button"
      > 
        <i
          [ngClass]="action.icon"
          class="action-icon theme-color"
        ></i>
        <span class="action-label">{{ action.label | translate }}</span>
      </button>
    </li>
  </ul>
</ng-template>

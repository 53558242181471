import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TemplateDirective } from '@proget-shared/_common';
import { ObjectHelper } from '@proget-shared/helper';
import { TranslateService } from '@proget-shared/translate';
import { OverlayPanelDirective, OverlayPanelService, OverlayPanelStatus } from '@proget-shared/ui/overlay-panel';
import { debounceTime, delay, fromEvent, map, merge, NEVER, noop, startWith, Subscription, switchMap, tap } from 'rxjs';

import { DropdownItem } from './dropdown-item.type';
import { EllipsisEnabledDirective } from './ellipsis-enabled.directive';
import { OptionItem } from './options-group/option-item.model';
import { OptionsGroup } from './options-group.model';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownComponent),
    multi: true,
  }],
})
export class DropdownComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  public readonly autocompleteForm = new FormControl('');

  @Input()
  public labelKey: string | string[];
  @Input()
  public valueKey: string | string[];
  @Input()
  public multiple = false;
  @Input()
  public translate = true;
  @Input()
  public autoSort = false;
  @Input()
  public showClear = false;
  @Input()
  public showHeader = true;
  @Input()
  public filter = false;
  @Input()
  public showToggleAll = false;
  @Input()
  public showToggleGroup = false;
  @Input()
  public maxSelectedLabels = 5;
  @Input()
  public autocomplete = false;
  @Input()
  public target = 'body';
  @Input()
  public closeOnScroll = true;
  @Input()
  public matchPanelWidth = true;
  @Input()
  public readonly = false;
  @Input()
  public panelReferer: HTMLElement;
  @Input()
  public panelClass = '';
  @Input()
  public maxVisibleItems = 10;
  @Output()
  public onChange = new EventEmitter<{ value: any }>();
  @Output()
  public autocompleteInput = new EventEmitter<{ query: string }>();
  public panelOpened = false;
  public optionTemplate: TemplateRef<any> | undefined;
  public groupLabelTemplate: TemplateRef<any> | undefined;
  public selectionLabel = '';
  public selectedItems: OptionItem[] = [];
  public requestPending = false;

  private readonly subscription = new Subscription();

  @ContentChildren(TemplateDirective)
  private templates;
  @ViewChild('panel', { read: OverlayPanelDirective })
  private panel: OverlayPanelDirective;
  @ViewChild('panel', { read: ElementRef })
  private panelElement: ElementRef;
  @ViewChild('autocompleteInput', { read: ElementRef })
  private autocompleteInputElement: ElementRef<HTMLInputElement>;
  @ViewChild('defaultItemLabel', { read: TemplateRef })
  private defaultItemLabel: TemplateRef<any>;
  @ViewChild('defaultGroupLabel', { read: TemplateRef })
  private defaultGroupLabel: TemplateRef<any>;
  private outputString = '';
  private _disabled = false;
  private _tabindex: number | undefined = 0;
  private _options = OptionItem.fromArray([]);
  private _optionsSnapshot = '';
  private _suggestions = OptionItem.fromArray([]);
  private optionLabelEllipsis = true;
  private groupLabelEllipsis = true;
  private pickedSuggestions: OptionItem[] = [];
  private autocompleteOptions = OptionItem.fromArray([]);
  private selectedValues: any[] = [];
  private modelChanged: (value: any) => void = noop;
  private modelTouched: () => void = noop;
  private message = { placeholder: '', autocompletePlaceholder: '', empty: '', emptyFilter: '' };
  private defaultMessage = { placeholder: '', autocompletePlaceholder: '', empty: '', emptyFilter: '' };

  constructor(
    private translateService: TranslateService,
    private overlayPanelService: OverlayPanelService,
    private elementRef: ElementRef<HTMLElement>
  ) {
    this.subscription.add(translateService.lang$.subscribe({
      next: () => {
        this.defaultMessage.autocompletePlaceholder = translateService.instant('proget_shared.dropdown.autocomplete_placeholder');
        this.defaultMessage.empty = translateService.instant('proget_shared.dropdown.empty_records');
        this.defaultMessage.emptyFilter = translateService.instant('proget_shared.dropdown.empty_results');

        this.updateSelectedItems();
      },
    }));

    this.subscription.add(this.autocompleteForm.valueChanges
      .pipe(
        tap({
          next: (query) => {
            this.requestPending = !!query;

            if (!query) {
              this.updateAutocompleteOptions();
            }
          },
        }),
        debounceTime(500)
      )
      .subscribe({
        next: (query) => {
          this.requestPending = !!query;

          if (query) {
            this.autocompleteInput.emit({ query });
          } else {
            this._suggestions = OptionItem.fromArray([]);
          }
        },
      })
    );
  }

  writeValue(inputValue: any): void {
    const valuesArray = this.multiple
      ? Array.isArray(inputValue) ? inputValue.slice() : []
      : [inputValue];

    this.updateSelectedItems(valuesArray);

    this.outputString = JSON.stringify(inputValue);
  }

  registerOnChange(fn: (value: any) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;

    if (this.disabled) {
      this.elementRef.nativeElement.blur();
    }
  }

  ngAfterViewInit(): void {
    this.updateTemplates();

    this.subscription.add(
      merge(
        fromEvent<FocusEvent>(window, 'focus').pipe(map(() => true)),
        fromEvent<FocusEvent>(window, 'blur').pipe(map(() => false))
      )
        .pipe(
          delay(0), // Prevent reacting to the element focus event on window focus
          startWith(true),
          switchMap((isWindowActive) => (isWindowActive
            ? fromEvent<FocusEvent>(this.panelElement.nativeElement, 'focus')
            : NEVER)
          )
        )
        .subscribe((event) => {
          if (this.autocomplete && !this.panel.isDescendant(event.relatedTarget as Element)) {
            this.panel.open(this.panelReferer);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set autocompletePlaceholder(value: string) {
    this.message.autocompletePlaceholder = value;
  }

  @Input()
  public set placeholder(value: string) {
    this.message.placeholder = value;
  }

  public get placeholder(): string {
    return this.autocompleteInputVisibility
      ? this.message.autocompletePlaceholder || this.defaultMessage.autocompletePlaceholder
      : this.message.placeholder || this.defaultMessage.placeholder;
  }

  @Input()
  public set emptyMessage(value: string) {
    this.message.empty = value;
  }

  public get emptyMessage(): string {
    if (!this.autocomplete) {
      return this.message.empty || this.defaultMessage.empty;
    }

    if (this.requestPending) {
      return '';
    }

    return this.autocompleteForm.value ? this.emptyFilterMessage : '';
  }

  @Input()
  public set emptyFilterMessage(value: string) {
    this.message.emptyFilter = value;
  }

  public get emptyFilterMessage(): string {
    return this.message.emptyFilter || this.defaultMessage.emptyFilter;
  }

  @Input()
  public set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  public set tabindex(index: number | undefined) {
    this._tabindex = index;
    this.elementRef.nativeElement.removeAttribute('tabindex');
  }

  public get tabindex(): number | undefined {
    return this.disabled ? void 0 : this._tabindex;
  }

  public get panelData(): any {
    return {
      autoSort: this.autoSort,
      emptyFilterMessage: this.emptyFilterMessage,
      emptyMessage: this.emptyMessage,
      filter: this.filter,
      multiple: this.multiple,
      optionsGroup: this.panelOptionsGroup,
      optionTemplate: this.optionTemplate || this.defaultItemLabel,
      groupLabelTemplate: this.groupLabelTemplate || this.defaultGroupLabel,
      pending: this.requestPending,
      selectedItems: this.selectedItems,
      showHeader: this.showHeader,
      showToggleAll: this.showToggleAll,
      showToggleGroup: this.showToggleGroup,
      translate: this.translate,
      valueKey: this.valueKey,
      groupLabelEllipsis: this.groupLabelEllipsis,
      optionLabelEllipsis: this.optionLabelEllipsis,
      maxVisibleItems: this.maxVisibleItems,
    };
  }

  @Input()
  public set suggestions(value: (DropdownItem | OptionsGroup)[]) {
    this._suggestions = OptionItem.fromArray(value);
    this.requestPending = false;
  }

  @Input()
  public set options(options: (DropdownItem | OptionsGroup)[]) {
    const parsedOptions = OptionItem.fromArray(options);
    const parsedOptionsSnapshot = JSON.stringify(parsedOptions);

    if (this._optionsSnapshot === parsedOptionsSnapshot) {
      return;
    }

    this._options = parsedOptions;
    this._optionsSnapshot = parsedOptionsSnapshot;

    // incoming options must include all selected items
    this.pickedSuggestions = [];
    this.updateSelectedItems();
  }

  public get panelOptionsGroup(): OptionItem {
    if (this.autocomplete) {
      return this.autocompleteForm.value ? this._suggestions : this.autocompleteOptions;
    }

    return this._options;
  }

  public get isGroupSelected(): boolean {
    return this.multiple && this.selectedItems.length > this.maxSelectedLabels;
  }

  public get clearButtonVisibility(): boolean {
    if (this.autocompleteInputVisibility) {
      return false;
    }

    return this.showClear && this.isAnySelected();
  }

  public get placeholderVisibility(): boolean {
    if (this.autocompleteInputVisibility) {
      return !this.autocompleteForm.value;
    }

    return !this.isAnySelected();
  }

  public get autocompleteInputVisibility(): boolean {
    return this.autocomplete && this.panelOpened;
  }

  public get panelHidden(): boolean {
    if (!this.autocomplete) {
      return false;
    }

    return this.panelData.optionsGroup.flatten.length === 0 &&
      this.autocompleteForm.value === '';
  }

  public get emptyAutocomplete(): boolean {
    return this.autocomplete && this.autocompleteOptions.flatten.length === 0;
  }

  public openPanel(): void {
    if (!this.autocomplete) {
      this.panel.open(this.panelReferer);
    }

    this.panelElement.nativeElement.focus({ preventScroll: true });
  }

  public closePanel(): void {
    this.panel.close();
  }

  public panelStatusChange({ opened }: OverlayPanelStatus): void {
    if (opened) {
      this.updateTemplates();
    }

    if (!opened) {
      setTimeout(() => {
        if (this.overlayPanelService.isAnyActive()) {
          return;
        }

        if (!this.autocomplete && document.activeElement === document.body) {
          this.elementRef.nativeElement.focus({ preventScroll: true });
        }
      }, 0);

      this.modelTouched();
    }

    if (opened && this.autocomplete) {
      setTimeout(() => {
        this.autocompleteInputElement.nativeElement.focus({ preventScroll: true });
      }, 0);
    }

    this.panelOpened = opened;
  }

  public translationRequired(item: DropdownItem): boolean {
    return item.hasOwnProperty('translate') ? item.translate : this.translate;
  }

  public isAnySelected(): boolean {
    return this.selectedItems.length > 0;
  }

  public clear(): void {
    this.clearAutocomplete();
    this.emitSelectedItems([]);
  }

  public emitSelectedItems(items: OptionItem[]): void {
    if (this.readonly || this.disabled) {
      return;
    }

    this.pickedSuggestions = this.pickedSuggestions
      .filter((suggestion) => this.autocompleteOptions.flatten.indexOf(suggestion) !== -1 || items.indexOf(suggestion) !== -1)
      .concat(this._suggestions.flatten.filter((suggestion) => items.indexOf(suggestion) !== -1))
      .filter((suggestion, index, all) => all.indexOf(suggestion) === index);

    const knownItems = this.autocomplete
      ? items.slice()
      : items.filter((item) => this.panelOptionsGroup.flatten.indexOf(item) !== -1);

    const uniqueValues = knownItems
      .map((item) => this.getItemValue(item))
      .map((value) => ({ value, stringified: JSON.stringify(value) }))
      .filter((value, index, allItems) => allItems.findIndex((item) => value.stringified === item.stringified) === index)
      .map((valueWithString) => valueWithString.value);

    const output = this.multiple
      ? uniqueValues
      : uniqueValues.length ? uniqueValues[0] : null;

    this.selectedItems = knownItems;
    this.updateAutocompleteOptions();

    const newOutputString = JSON.stringify(output);

    if (newOutputString === this.outputString) {
      return;
    }

    this.outputString = newOutputString;
    this.selectedValues = this.multiple ? output : [output];

    this.modelTouched();
    this.modelChanged(output);
    this.onChange.emit({ value: output });
  }

  public stopKeysEventPropagation(event: KeyboardEvent): void {
    if (event.code === 'Escape' && this.autocomplete) {
      this.autocompleteForm.value
        ? this.clearAutocomplete()
        : this.closePanel();
    }

    if (
      event.code !== 'ArrowDown' &&
      event.code !== 'ArrowUp' &&
      event.code !== 'Enter' &&
      event.code !== 'Tab'
    ) {
      event.stopPropagation();
    }
  }

  public getItemLabel(item: any): string {
    return ObjectHelper.getValue(item, this.labelKey || 'label', '') || '';
  }

  private clearAutocomplete(): void {
    this.autocompleteForm.setValue('', { emitEvent: false });
    this.requestPending = false;
    this._suggestions = OptionItem.fromArray([]);
    this.updateAutocompleteOptions();
  }

  private getItemValue(item: OptionItem): any {
    if (this.valueKey) {
      return ObjectHelper.getValue(item.source, this.valueKey, null);
    }

    return item.source.value === void 0 ? item.source : item.source.value;
  }

  private updateAutocompleteOptions(): void {
    this.autocompleteOptions = OptionItem.fromArray(this.selectedItems.map((item) => item.getRaw()));
  }

  private updateSelectedItems(values: any[] = this.selectedValues): void {
    this.selectedValues = values;

    const valuesWithStrings = values
      .map((value) => ({ value, stringified: JSON.stringify(value) }))
      // remove duplications
      .filter((valueWithString, index, allItems) => allItems
        .findIndex((item) => valueWithString.stringified === item.stringified) === index
      );
    const unmatchedValues = valuesWithStrings.map((item) => item.value);
    const matchingOptions = this._options.flatten
      .concat(this.pickedSuggestions)
      .filter((option) => {
        const stringOptionValue = JSON.stringify(this.getItemValue(option));
        const foundItem = valuesWithStrings.find((item) => item.stringified === stringOptionValue);

        if (foundItem) {
          const foundItemValue = foundItem.value;
          const unmatchedIndex = unmatchedValues.indexOf(foundItemValue);
          const deleteCount = unmatchedIndex === -1 ? 0 : 1;

          unmatchedValues.splice(unmatchedValues.indexOf(foundItemValue), deleteCount);
        }

        return !!foundItem;
      });

    const unmatchedOptions = unmatchedValues
      .filter((value) => value !== null && value !== undefined && value !== '')
      .map((value) => {
        const source = {};

        ObjectHelper.setValue(
          source,
          this.labelKey ?? 'label',
          `<${this.translateService.instant('proget_shared.dropdown.unknown_option')}>`
        );
        ObjectHelper.setValue(source, this.valueKey ?? 'value', value);

        return new OptionItem(source, -1);
      });

    this.selectedItems = matchingOptions.concat(unmatchedOptions);
    this.updateAutocompleteOptions();
  }

  private updateTemplates(): void {
    const optionTemplate = this.templates.find((item) => item.appTemplate === 'option');
    const groupLabelTemplate = this.templates.find((item) => item.appTemplate === 'groupLabel');

    this.optionLabelEllipsis = optionTemplate?.inject(EllipsisEnabledDirective)?.appEllipsisEnabled ?? true;
    this.optionTemplate = optionTemplate?.templateRef;
    this.groupLabelEllipsis = groupLabelTemplate?.inject(EllipsisEnabledDirective)?.appEllipsisEnabled ?? true;
    this.groupLabelTemplate = groupLabelTemplate?.templateRef;
  }
}

export class StringHelper {
  public static trimLeft(input: string, char: string | string[] = ' '): string {
    return StringHelper.trimLeftChars(input, StringHelper.findChars(char));
  }

  public static trimRight(input: string, char: string | string[] = ' '): string {
    return StringHelper.trimRightChars(input, StringHelper.findChars(char));
  }

  public static trim(input: string, char: string | string[] = ' '): string {
    const charsArray: string[] = StringHelper.findChars(char);

    return StringHelper.trimLeftChars(StringHelper.trimRightChars(input, charsArray), charsArray);
  }

  public static findChars(char: string | string[]): string[] {
    if (Array.isArray(char)) {
      return char
        .reduce(
          (result, item) => result.concat(
            typeof item === 'string' && item.length === 1 ? item : StringHelper.findChars(item)
          ),
          []
        )
        .filter((item, index, array) => array.indexOf(item) === index)
        .sort();
    }

    return typeof char === 'string' ? char.split('') : [];
  }

  public static addLeadingZero(valueString: string, length = 2): string {
    if (typeof valueString !== 'string') {
      return valueString;
    }

    while (valueString.length < length) {
      valueString = `0${valueString}`;
    }

    return valueString;
  }

  public static escapeRegExp(valueString: string): string {
    const regexp = /[.*+?^${}()|[\]\\]/g;

    return typeof valueString === 'string' ? valueString.replace(regexp, '\\$&') : valueString;
  }

  public static escapeHtml(valueString: string, trim = false): string {
    if (typeof valueString !== 'string') {
      return valueString;
    }

    const escapedString = valueString.replace(
      /[\x26\x0A\x3c\x3e\x22\x27]/g,
      (value: string) => `&#${value.charCodeAt(0)};`
    );

    return trim ? StringHelper.trim(escapedString) : escapedString;
  }

  /*
   * @deprecated Use transformCase instead
   */
  public static camelCaseToSnakeCase(value: string): string {
    return StringHelper.transformCase(value, 'camel', 'snake');
  }

  /*
   * @deprecated Use transformCase instead
   */
  public static camelCaseToKebabCase(value: string): string {
    return StringHelper.transformCase(value, 'camel', 'kebab');
  }

  public static transformCase(value: string, from: 'snake' | 'camel' | 'kebab', to: 'snake' | 'camel' | 'kebab'): string {
    if (from === to) {
      return value;
    }

    const blocks = this.explodeString(value, from);

    switch (to) {
      case 'snake':
        return blocks.join('_').toLocaleLowerCase();
      case 'camel':
        return blocks
          .map((block, index) => (index === 0
            ? block : `${block.substring(0, 1).toUpperCase()}${block.substring(1, block.length).toLocaleLowerCase()}`)
          )
          .join('');
      case 'kebab':
        return blocks.join('-').toLocaleLowerCase();
      default:
        return '';
    }
  }

  private static trimLeftChars(input: string, charsArray: string[]): string {
    const firstCharIndex: number = Math.min(
      ...charsArray.map((char) => input.indexOf(char)).filter((index) => index !== -1)
    );

    return firstCharIndex === 0 ? StringHelper.trimLeftChars(input.substring(1), charsArray) : input;
  }

  private static trimRightChars(input: string, charsArray: string[]): string {
    const lastCharIndex: number = Math.max(
      ...charsArray.map((char) => input.lastIndexOf(char)).filter((index) => index !== -1)
    );

    return lastCharIndex === input.length - 1
      ? StringHelper.trimRightChars(input.substring(0, input.length - 1), charsArray)
      : input;
  }

  private static explodeString(value: string, format: 'snake' | 'camel' | 'kebab'): string[] {
    switch (format) {
      case 'snake':
        return value.match(/(^_|_(?=_)|[^_]|_$)+(?=_(?!$)|$)/g);
      case 'camel':
        return value.match(/(^|[A-Z])?[^A-Z]+(?=[A-Z]|$)|[A-Z0-9]+(?=[A-Z0-9](?=[^A-Z0-9]|$)|$)/g);
      case 'kebab':
        return value.match(/(^-|-(?=-)|[^-]|-$)+(?=-(?!$)|$)/g);
      default:
        return [];
    }
  }
}

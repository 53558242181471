<div
  [spinner]="selectionPending"
  class="selection-info"
>
  <span class="inline-item">
    <b>{{ 'proget_shared.grid.actions.items_count' | translate }}:&nbsp;</b>
    <span class="selection-counter">{{ itemsCount }}</span>
  </span>
  <div
    #selectionOptionsToggle
    *ngIf="forceMenu || selectionOptionsData.selectAllEnabled else deselectButton"
    [appOverlayPanel]="selectionOptionsPanel"
    [overlayPanelOptions]="{ target: 'body' }"
    [overlayPanelData]="selectionOptionsData"
    class="selection-options-toggle"
  >
    <span class="dot"></span>
    <span class="dot"></span>
    <span class="dot"></span>
  </div>
</div>

<ng-template #deselectButton>
  <div class="inline-item">
    <button
      [disabled]="!selectionOptionsData.deselectEnabled"
      (click)="clearItems()"
      data-qa="deselect-items-button"
      type="button"
      class="proget-clear-button selection-options-button theme-color"
    >
      {{ 'proget_shared.grid.actions.deselect_item' | translate }}
    </button>
  </div>
</ng-template>

<ng-template
  #selectionOptionsPanel
  let-data
>
  <ul class="selection-options-list">
    <li>
      <button
        [disabled]="!data.deselectEnabled"
        (click)="clearItems()"
        data-qa="deselect-items-button"
        type="button"
        class="proget-clear-button selection-options-button theme-color"
      >
        {{ 'proget_shared.grid.actions.deselect_item' | translate }}
      </button>
    </li>
    <li *ngIf="data.selectAllEnabled">
      <button
        (click)="selectAllItems()"
        data-qa="select-all-items-button"
        type="button"
        class="proget-clear-button selection-options-button theme-color"
      >
        {{ 'proget_shared.grid.actions.select_all_pages' | translate }}
      </button>
    </li>
  </ul>
</ng-template>

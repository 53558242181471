import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnDestroy {
  private navigationTimeout: any = null;

  ngOnDestroy(): void {
    if (null !== this.navigationTimeout) {
      clearTimeout(this.navigationTimeout);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateOnly',
})
export class DateOnlyPipe implements PipeTransform {
  transform(value: string | Date): string {
    if (null === value || undefined === value) {
      return '';
    }

    const date: Date = this.createDateInstance(value);

    return this.getDate(date);
  }

  private getDate(date: Date): string {
    return date.toISOString().slice(0, 10);
  }

  private createDateInstance(value: string | Date): Date {
    return value instanceof Date ? value : new Date(value);
  }
}

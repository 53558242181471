import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { QueryParams } from '@proget-shared/_common';
import { QueryParamsService } from '@proget-shared/query-params';
import { BehaviorSubject, combineLatest, map, Observable, of, take } from 'rxjs';

import { GridControlHelper } from '../class/grid-control-helper.class';
import { GridControl } from '../class/grid-control.class';
import { GridDefaults } from '../const/grid-defaults.const';
import { GRID_OPTIONS } from '../grid-options.token';
import { GridOptions } from '../type/grid-options.type';
import { GroupedQueryParams } from '../type/grouped-query-params.type';

import { ParamsStorageService } from './params-storage.service';

@Injectable()
export class GridControlService implements OnDestroy {
  public readonly controlsParams$: Observable<GroupedQueryParams>;

  private readonly queryParamsTriggerSubject = new BehaviorSubject<void>(null);
  private readonly urlMask: string;
  private readonly options: GridOptions;

  private controls: { [id: string]: GridControl } = {};

  constructor(
    @Optional()
    @Inject(GRID_OPTIONS)
      options: GridOptions,
    @Optional()
    private queryParams: QueryParamsService,
    private router: Router,
    private paramsStorage: ParamsStorageService
  ) {
    this.options = Object.assign({}, GridDefaults, options);
    this.controlsParams$ = this.getQueryParamsStream();
    this.urlMask = GridControlHelper.getUrlMask(router.url);
  }

  ngOnDestroy(): void {
    this.router.url.indexOf(this.urlMask) === 0 // new url satisfies urlMask
      ? this.paramsStorage.save(this.urlMask, this.controls)
      : this.paramsStorage.clear(this.urlMask);
  }

  public registerGridControl(id: string, queryParamsBased = true): GridControl {
    const useQueryParams: boolean = this.queryParamsAvailable() && queryParamsBased;
    const savedParams: QueryParams = this.paramsStorage.getParams(this.urlMask, id);
    const gridControl: GridControl = new GridControl(id, this, useQueryParams, this.options, savedParams);

    this.controls[id] = gridControl;
    this.queryParamsTriggerSubject.next(null);

    if (Object.keys(savedParams).length > 0) {
      gridControl.filters$.pipe(take(1)).subscribe({
        next: () => {
          this.writeQueryParams(false);
        },
      });
    }

    return gridControl;
  }

  public readQueryParams(): void {
    this.queryParamsTriggerSubject.next(null);
  }

  public writeQueryParams(emitEvent = true): void {
    if (!this.queryParamsAvailable() || this.getControlsUsingQueryParams().length === 0) {
      return;
    }

    this.queryParams.setParams(this.getQueryParams(), { emitEvent });
  }

  public getQueryParams(): QueryParams {
    const controls: GridControl[] = this.getControlsUsingQueryParams();

    if (1 === controls.length) {
      return controls[0].urlParams;
    }

    const prefixedParams: QueryParams = Object.assign(
      {},
      ...controls.map((control: GridControl) => this.addPrefixParamsKeys(control.urlParams, control.id))
    );

    return prefixedParams;
  }

  public queryParamsAvailable(): boolean {
    return null !== this.queryParams;
  }

  private getQueryParamsStream(): Observable<GroupedQueryParams> {
    if (!this.queryParams) {
      return of({});
    }

    return combineLatest([this.queryParams.params$, this.queryParamsTriggerSubject]).pipe(
      map(([params]: [QueryParams, void]) => this.groupQueryParams(params))
    );
  }

  private getControlsUsingQueryParams(): GridControl[] {
    return Object.keys(this.controls)
      .map((key: string) => this.controls[key])
      .filter((control: GridControl) => control.usingQueryParams);
  }

  private addPrefixParamsKeys(params: QueryParams, prefix: string): QueryParams {
    if (!prefix) {
      return params;
    }

    const prefixedParams: QueryParams = {};

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        prefixedParams[`${prefix}_${key}`] = params[key];
      }
    }

    return prefixedParams;
  }

  private groupQueryParams(params: QueryParams): GroupedQueryParams {
    const controls: GridControl[] = this.getControlsUsingQueryParams();
    const groupedParams: GroupedQueryParams = {};

    if (0 === controls.length) {
      return groupedParams;
    }

    if (1 === controls.length) {
      groupedParams[controls[0].id] = params;

      return groupedParams;
    }

    const controlsIds: string[] = controls.map((control: GridControl) => control.id);
    const prefixTest = new RegExp(`^(${controlsIds.join('|')})_(.+)$`);

    for (const key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }

      const prefixMatch: RegExpMatchArray = key.match(prefixTest);

      if (prefixMatch) {
        groupedParams[prefixMatch[1]] = groupedParams[prefixMatch[1]] || {};
        groupedParams[prefixMatch[1]][prefixMatch[2]] = params[key];
      }
    }

    return groupedParams;
  }
}

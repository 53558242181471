import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'secondsToTime' })
export class SecondsToTimePipe implements PipeTransform {
  private times: { [key: string]: number } = {
    d: 86400,
    h: 3600,
    min: 60,
    s: 1,
  };

  transform(seconds = 0): string {
    let timeString = '';

    for (const key in this.times) {
      if (Math.floor(seconds / this.times[key]) > 0) {
        timeString += `${Math.floor(seconds / this.times[key]).toString()} ${key.toString()} `;
        seconds = seconds - this.times[key] * Math.floor(seconds / this.times[key]);
      }
    }

    return timeString ? timeString.trim() : '0';
  }
}

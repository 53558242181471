<button
  [disabled]="headerControl.disabled || minDateSelected"
  (click)="emitPreviousDate()"
  type="button"
  class="date-navigation"
>&lt;</button>
<div class="margin-space"></div>
<app-dropdown
  *ngIf="level <= SelectionLevel.DAY"
  [formControl]="headerControl.get('day')"
  [matchPanelWidth]="false"
  [options]="dayOptions"
  tabindex="-1"
  class="day-selector date-part-selector"
></app-dropdown>
<app-dropdown
  *ngIf="level <= SelectionLevel.MONTH"
  [formControl]="headerControl.get('month')"
  [matchPanelWidth]="false"
  [options]="monthOptions"
  tabindex="-1"
  class="month-selector date-part-selector"
></app-dropdown>
<app-dropdown
  [formControl]="headerControl.get('year')"
  [matchPanelWidth]="false"
  [options]="yearOptions"
  tabindex="-1"
  class="year-selector date-part-selector"
></app-dropdown>
<div class="margin-space"></div>
<button
  [disabled]="headerControl.disabled || maxDateSelected"
  (click)="emitNextDate()"
  type="button"
  class="date-navigation"
>&gt;</button>

import { StringHelper } from '../string/string.helper';

export class DateHelper {
  /**
   * Returns formatted string date
   *
   * @param date input date
   * @param format output format
   * yyyy - full year
   * yy - shorten year
   * M - month
   * MM - month with leading 0
   * d - day
   * dd - day with leading 0
   * H - hours (24)
   * HH - hours with leading 0 (24)
   * h - hours (12)
   * hh - hours with leading 0 (12)
   * A - AM/PM
   * m - minutes
   * mm - minutes with leading 0
   * s - seconds
   * ss - seconds with leading 0
   * @returns
   */
  public static stringify(date: Date, format = 'yyyy-MM-dd'): string {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return '';
    }

    const finder = /([^yMdHhAms\\]*)([\\]*)(y{4}|y{2}|M{1,2}|d{1,2}|H{1,2}|h{1,2}|m{1,2}|s{1,2}|A)([^yMdHhAms\\]*)/g;
    let matching: RegExpExecArray;
    let output = '';

    // eslint-disable-next-line no-cond-assign
    while (matching = finder.exec(format)) {
      output += matching[1];
      const part = matching[3];

      if (matching[2] !== '') {
        output += part + matching[4];

        continue;
      }

      output += part === 'yyyy'
        ? date.getFullYear().toString()
        : StringHelper.addLeadingZero(this.getDatePart(date, part.charAt(0)), part.length);

      output += matching[4];
    }

    return output;
  }
  /**
   * Returns Date from string
   *
   * @param input input date
   * @param format input date format
   * @returns
   */
  public static parse(input: string, format = 'yyyy-MM-dd'): Date {
    const finder = /([^yMdHhAms\\]*)([\\]*)(y{4}|y{2}|M{1,2}|d{1,2}|H{1,2}|h{1,2}|m{1,2}|s{1,2}|A)([^yMdHhAms\\]*)/g;
    const foundPartTypes = [];
    const outputRaw = {
      year: 0,
      month: 0,
      date: 1,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timeFormat: 24,
    };
    let matching: RegExpExecArray;
    let valueIndex = 0;

    // eslint-disable-next-line no-cond-assign
    while (matching = finder.exec(format)) {
      const escapeCharsCount = matching[2].length;

      if (escapeCharsCount > 0) {
        valueIndex += matching[0].length - escapeCharsCount;

        continue;
      }

      const partType = matching[3].charAt(0);

      if (foundPartTypes.indexOf(partType) !== -1) {
        throw new Error('Unique format parts required');
      }

      valueIndex += matching[1].length;
      const valueEndIndex = matching[4].length
        // find separator
        ? input.indexOf(matching[4], valueIndex)
        // or count matching characters
        : valueIndex + (input.substring(valueIndex).match(/^(\d+|am|pm)/i) ?? [''])[0].length;

      if (valueEndIndex === -1) {
        throw new Error('Missing date parts separator');
      }

      const stringValue = input.substring(valueIndex, valueEndIndex);
      const value = Number(stringValue);

      switch (partType) {
        case 'y':
          outputRaw.year = matching[3].length === 2
            ? value > 40 ? value + 1900 : value + 2000
            : value;
          break;
        case 'M':
          outputRaw.month = value - 1;
          break;
        case 'd':
          outputRaw.date = value;
          break;
        case 'H':
          outputRaw.timeFormat = 24;
          outputRaw.hours = value;
          break;
        case 'h':
          outputRaw.timeFormat = 12;
          outputRaw.hours = value;
          break;
        case 'm':
          outputRaw.minutes = value;
          break;
        case 's':
          outputRaw.seconds = value;
          break;
        case 'A':
          outputRaw.hours += stringValue.toUpperCase() === 'PM' ? 12 : 0;
          break;
        default:
          break;
      }

      valueIndex = valueEndIndex + matching[4].length;
      foundPartTypes.push(partType);
    }

    if (foundPartTypes.indexOf('H') !== -1 && (foundPartTypes.indexOf('h') !== -1 || foundPartTypes.indexOf('A') !== -1)) {
      throw new Error('Mixed time format 24 and 12');
    }

    const hours = outputRaw.timeFormat === 12 && outputRaw.hours % 12 === 0
      ? outputRaw.hours - 12
      : outputRaw.hours;
    const date = new Date(outputRaw.year, outputRaw.month, outputRaw.date, hours, outputRaw.minutes, outputRaw.seconds, 0);
    const test = this.stringify(date, format);

    if (test !== input) {
      throw new Error('Invalid date');
    }

    return date;
  }

  /**
   * Deprecated - Use DateHelper.stringify(date: Date, format?: string)
   */
  public static stringifyDate(date: Date, useTime = false): string {
    return DateHelper.stringify(date, useTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd');
  }

  public static mergeDateTime(date: Date, time: Date): Date {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds(),
      time.getMilliseconds()
    );
  }

  public static getDayStart(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public static getDayEnd(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, -1);
  }

  public static getPreviousMonth(date: Date): Date | null {
    return this.isValidDate(date) ? new Date(date.getFullYear(), date.getMonth() - 1) : null;
  }

  public static getMonth(date: Date): Date | null {
    return this.isValidDate(date) ? new Date(date.getFullYear(), date.getMonth()) : null;
  }

  public static getNextMonth(date: Date): Date | null {
    return this.isValidDate(date) ? new Date(date.getFullYear(), date.getMonth() + 1) : null;
  }

  public static isValidDate(date: any): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  public static sameDates(...dates: (Date | null)[]): boolean {
    if (dates.length === 0) {
      return false;
    }

    const primitives = dates.map((date) => (date instanceof Date ? date.getTime() : date));

    return primitives.findIndex((item) => item !== primitives[0]) === -1;
  }

  public static normalizeDateString(dateString: string, useTime?: boolean): string {
    const dateRegExp = /^(\d{4})-(\d{1,2})-(\d{1,2})(?: (\d{1,2}):(\d{1,2}))?$/;
    const dateMatch: RegExpMatchArray | null = dateString.trim().match(dateRegExp);

    if (null === dateMatch) {
      return '';
    }

    if (useTime === void 0) {
      useTime = dateMatch[4] !== void 0;
    }

    const date: Date = new Date(0);

    date.setFullYear(Number(dateMatch[1]));
    date.setMonth(Number(dateMatch[2]) - 1);
    date.setDate(Number(dateMatch[3]));

    if (useTime) {
      date.setHours(Number(dateMatch[4]) || 0);
      date.setMinutes(Number(dateMatch[5]) || 0);
    }

    return this.stringifyDate(date, useTime);
  }

  private static getDatePart(date: Date, type: string): string {
    switch (type) {
      case 'y':
        const fullYear = date.getFullYear().toString();

        return fullYear.substring(fullYear.length - 2);
      case 'M':
        return (date.getMonth() + 1).toString();
      case 'd':
        return date.getDate().toString();
      case 'H':
        return date.getHours().toString();
      case 'h':
        const hour = date.getHours() % 12;

        return (hour === 0 ? 12 : hour).toString();
      case 'm':
        return date.getMinutes().toString();
      case 's':
        return date.getSeconds().toString();
      case 'A':
        return date.getHours() >= 12 ? 'PM' : 'AM';
      default:
        return '';
    }
  }
}

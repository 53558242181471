import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';

import { SpinnerService } from './spinner.service';

@Injectable()
export class SpinnerHttpInterceptor implements HttpInterceptor {
  constructor(private spinnerService: SpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const spinnerParam: boolean | string = req.params.get('spinner');
    const showSpinner: boolean =
      typeof spinnerParam === 'boolean' ? spinnerParam : !req.params.get('spinner');
    const clearRequest: HttpRequest<any> = req.clone({ params: req.params.delete('spinner') });

    if (!showSpinner || req.method === 'GET') {
      return next.handle(clearRequest);
    }

    this.spinnerService.show();

    return next.handle(clearRequest).pipe(
      finalize((): void => {
        this.spinnerService.hide();
      })
    );
  }
}

import { Inject, Injectable, Optional } from '@angular/core';
import { Validators } from '@angular/forms';
import { DialogService } from '@proget-shared/dialog';
import { TranslatedDialogService, TranslatedDialogOptions } from '@proget-shared/dialog/translated-dialog';
import { ResponseError, ResponseErrorTemplateMapper } from '@proget-shared/helper/response-error';
import { Translation } from '@proget-shared/translate';
import { ToastService } from '@proget-shared/ui/toast';
import {
  catchError,
  EMPTY,
  finalize,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

import { GridActionPrompt } from './enum/grid-action-prompt.enum';
import { GridActionType } from './enum/grid-action-type.enum';
import { BatchWorker } from './interface/batch-worker.interface';
import { DisplayFormErrors } from './interface/display-form-errors.interface';
import { JanusWorker } from './interface/janus-worker.interface';
import { BatchWorkToken } from './token/batch-work.token';
import { JanusWorkToken } from './token/janus-work.token';
import { GridAction } from './type/grid-action.type';

type Id = string;

@Injectable()
export class GridActionsService {
  private pendingPrompt: any = null;

  constructor(
    private dialogService: DialogService,
    private translatedDialogService: TranslatedDialogService,
    private toastService: ToastService,
    @Optional()
    @Inject(BatchWorkToken)
    private batchWorkService: BatchWorker,
    @Optional()
    @Inject(JanusWorkToken)
    private janusWorkService: JanusWorker
  ) {}

  public getActionStream(action: GridAction, actionItems: any[]): Observable<any> {
    // DISPLAY

    if (
      GridActionType.DISPLAY_SINGLE === action.type ||
      GridActionType.DISPLAY_MULTI === action.type
    ) {
      return this.prompt(action, actionItems).pipe(catchError(() => EMPTY));
    }

    // ACTION MULTI

    if (GridActionType.ACTION_MULTI === action.type) {
      return this.prompt(action, actionItems).pipe(
        catchError(() => EMPTY),
        switchMap((extras: any) => action
          .action(actionItems, extras)
          .pipe(
            catchError((error: any) => throwError(() => (action.error?.mapper ? action.error.mapper(error, actionItems) : error))),
            catchError((error: any) => this.handleActionError(action, actionItems, error))
          )
        )
      );
    }

    // ACTION SINGLE

    if (GridActionType.ACTION_SINGLE === action.type) {
      return this.prompt(action, actionItems).pipe(
        catchError(() => EMPTY),
        switchMap((extras: any) => (actionItems.length > 0
          ? action.action(actionItems[0], extras)
            .pipe(
              catchError((error: any) => throwError(() => (action.error?.mapper ? action.error.mapper(error, actionItems[0]) : error)))
            )
          : throwError(null)
        )
          .pipe(
            catchError((error: any) => this.handleActionError(action, actionItems, error))
          )
        )
      );
    }

    // ACTION JANUS

    if (GridActionType.JANUS_JOB === action.type) {
      return this.prompt(action, actionItems).pipe(
        catchError(() => EMPTY),
        switchMap((payload: any) => (this.janusWorkService
          ? this.janusWorkService.process(
            actionItems,
            { id: action.actionId, payload },
            action.translations,
            action.errorsDialog
          )
            .pipe(
              switchMap((result) => (!result.partial && result.error.length > 0 ? throwError(() => result) : of(result)))
            )
          : throwError(() => 'Janus Work service is not provided'))
        )
      );
    }

    // BATCH WORK

    return this.prompt(action, actionItems).pipe(
      catchError(() => EMPTY),
      switchMap(
        (extras: any) => (this.batchWorkService
          ? this.batchWorkService.process(
            actionItems,
            function (item: Id): Observable<any> {
              return action.action.call(this, item, extras).pipe(
                catchError((error: any) => throwError(() => (action.error?.mapper ? action.error.mapper(error, item) : error)))
              );
            },
            action.batchWorkConfiguration,
            action.errorsDialog
          )
            .pipe(
              switchMap((result) => (result.error.length > 0 ? throwError(() => result) : of(result)))
            )
          : throwError(() => 'Batch Work service is not provided'))
      )
    );
  }

  public resolveParams(
    params: ((items: any[]) => { [key: string]: string }) | { [key: string]: string },
    actionItems: any[]
  ): object {
    if (!params) {
      return {};
    }

    if ('function' === typeof params) {
      return params.call(this, actionItems);
    }

    return params;
  }

  public closePendingPrompt(): void {
    if (null !== this.pendingPrompt) {
      this.dialogService.reject(this.pendingPrompt);
    }
  }

  private handleActionError(
    action: GridAction,
    actionItems: any[],
    errorObject: ResponseError
  ): Observable<never> {
    const actionError: any = action.error || {};

    if ('string' === typeof actionError.toast) {
      this.toastService.error(
        {
          key: actionError.toast,
          params: this.resolveParams(actionError.toastParams, actionItems),
        },
        ResponseErrorTemplateMapper.map(errorObject.messages)
      );
    }

    if ('function' === typeof actionError.callback) {
      actionError.callback(errorObject);
    }

    if (null !== this.pendingPrompt && 'function' === typeof this.pendingPrompt.setFormErrors) {
      (this.pendingPrompt as DisplayFormErrors).setFormErrors(errorObject.form || {});
    }

    this.dialogService.unlock(this.pendingPrompt);

    return throwError(() => errorObject);
  }

  private prompt(action: GridAction, actionItems: any[]): Observable<any> {
    this.closePendingPrompt();

    // INSTANT

    if (GridActionPrompt.INSTANT === action.prompt.type) {
      return of(true);
    }

    const options: TranslatedDialogOptions = Object.assign({}, action.prompt.options);

    options.configuration = options.configuration || {};
    options.configuration.iconClass = this.getPromptIconClass(action);

    // INPUT

    if (GridActionPrompt.INPUT === action.prompt.type) {
      const initialValue: string =
        'function' === typeof action.prompt.initialValue
          ? action.prompt.initialValue(actionItems) || ''
          : action.prompt.initialValue || '';

      return this.translatedDialogService.prompt(
        this.getPromptHeader(action, actionItems),
        this.getPromptMessage(action, actionItems),
        initialValue,
        options
      );
    }

    // PHRASE

    if (GridActionPrompt.PHRASE === action.prompt.type) {
      options.configuration.validators = [Validators.required];
      options.escapeMessageHtml = false;
      options.styleClass = 'grid-action-phrase-confirm';

      const phrase = (typeof action.prompt.phrase === 'function'
        ? action.prompt.phrase(action.type === GridActionType.ACTION_SINGLE ? actionItems[0] : actionItems)
        : action.prompt.phrase).trim();

      return this.translatedDialogService.phraseConfirm(
        this.getPromptHeader(action, actionItems),
        this.getPromptMessage(action, actionItems),
        phrase,
        options
      );
    }

    // CUSTOM

    if (GridActionPrompt.CUSTOM === action.prompt.type) {
      options.configuration.items = actionItems;

      const {
        dialog,
        message$,
      }: { dialog: any; message$: Observable<any> } = this.dialogService.custom<any>(
        action.prompt.component,
        { ...options, returnDialogInstance: true }
      );

      this.pendingPrompt = dialog;

      return message$.pipe(
        tap({
          next: () => {
            if ('function' === typeof this.pendingPrompt.clearFormErrors) {
              (this.pendingPrompt as DisplayFormErrors).clearFormErrors();
            }

            this.dialogService.lock(this.pendingPrompt);
          },
        }),
        finalize(() => {
          this.pendingPrompt = null;
        })
      );
    }

    // default (CONFIRM)

    return this.translatedDialogService
      .confirm(
        this.getPromptHeader(action, actionItems),
        this.getPromptMessage(action, actionItems),
        options
      )
      .pipe(switchMap((result: boolean) => (result ? of(result) : throwError(() => false))));
  }

  private getPromptHeader(action: GridAction, actionItems: any[]): Translation | string {
    if (
      GridActionPrompt.CUSTOM === action.prompt.type ||
      GridActionPrompt.INSTANT === action.prompt.type
    ) {
      return { key: 'config_error' };
    }

    if (!action.prompt.header || !action.prompt.headerParams) {
      return action.prompt.header || '';
    }

    return {
      key: action.prompt.header,
      params: this.resolveParams(action.prompt.headerParams, actionItems),
    };
  }

  private getPromptMessage(action: GridAction, actionItems: any[]): Translation | string {
    if (
      GridActionPrompt.CUSTOM === action.prompt.type ||
      GridActionPrompt.INSTANT === action.prompt.type
    ) {
      return '';
    }

    if (!action.prompt.message || !action.prompt.messageParams) {
      return action.prompt.message || '';
    }

    return {
      key: action.prompt.message,
      params: this.resolveParams(action.prompt.messageParams, actionItems),
    };
  }

  private getPromptIconClass(action: GridAction): string {
    if (
      GridActionPrompt.CUSTOM === action.prompt?.type ||
      GridActionPrompt.INSTANT === action.prompt?.type
    ) {
      return '';
    }

    const icon = action.prompt?.iconClass ?? true;

    if (true === icon) {
      return action.icon;
    }

    return 'string' === typeof icon ? icon : '';
  }
}

<app-dropdown
  #dropdown
  [autocomplete]="true"
  [emptyMessage]="config?.emptyMessage || '' | translate"
  [formControl]="formControl"
  [options]="options"
  [panelReferer]="panelReferer"
  [placeholder]="config?.placeholder | translate"
  [showClear]="showClear"
  [suggestions]="suggestions"
  [translate]="false"
  (autocompleteInput)="search($event.query)"
  data-qa="filter-autocomplete"
></app-dropdown>

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, startWith, Subscription } from 'rxjs';

import { DropdownPanelComponent } from '../dropdown-panel/dropdown-panel.component';

import { OptionItem } from './option-item.model';

@Component({
  // eslint-disable-next-line
  selector: 'ul[appOptionsGroup]',
  templateUrl: './options-group.component.html',
  styleUrls: ['./options-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsGroupComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly groupSelection = new FormControl(false);

  private readonly subscription = new Subscription();

  @ViewChild('listContainer', { read: ViewContainerRef })
  private listContainer: ViewContainerRef;
  @ViewChild('listItemTemplate', { read: TemplateRef })
  private listItemTemplate: TemplateRef<any>;
  private groupSubscription = new Subscription();
  private optionsGroup = OptionItem.fromArray([]);
  private activeIndex: number | null = null;
  private _anyChildSelected = false;
  private sortedChildren: OptionItem[];

  constructor(
    private dropdownPanel: DropdownPanelComponent,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.dropdownPanel.activeIndex$
        .subscribe({
          next: (index) => {
            this.activeIndex = index;
            this.cdr.detectChanges();
          },
        })
    );

    this.subscription.add(
      this.dropdownPanel.update$
        .subscribe({
          next: () => {
            this.cdr.detectChanges();
          },
        })
    );
  }

  ngAfterViewInit(): void {
    this.renderChildren();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.groupSubscription.unsubscribe();
  }

  public get children(): OptionItem[] {
    return this.sortedChildren ?? this.appOptionsGroup?.children ?? [];
  }

  @Input()
  public set appOptionsGroup(group: OptionItem) {
    this.groupSubscription.unsubscribe();
    this.groupSubscription = new Subscription();
    this.optionsGroup = group;
    this.sortedChildren = this.dropdownPanel.configuration.autoSort
      ? group.children
        .slice()
        .sort((a, b) => a.getLabelText().localeCompare(b.getLabelText(), void 0, { numeric: true, sensitivity: 'base' }))
      : null;

    this.groupSubscription.add(
      this.groupSelection.valueChanges
        .subscribe((value) => {
          this.dropdownPanel.toggleItem(
            this.appOptionsGroup.flatten
              .filter((item) => item.visible && this.dropdownPanel.isSelected(item) !== value)
          );
        })
    );

    this.groupSubscription.add(
      combineLatest([
        this.dropdownPanel.form.get('selection').valueChanges
          .pipe(startWith(this.dropdownPanel.form.value.selection)),
        this.dropdownPanel.update$,
      ])
        .subscribe({
          next: ([selection]) => {
            const visibleItems = this.appOptionsGroup.flatten.filter((item) => item.visible);

            if (visibleItems.length) {
              this._anyChildSelected = visibleItems.some((item) => selection.includes(item.source));
              this.groupSelection.setValue(visibleItems.every((item) => selection.includes(item.source)), { emitEvent: false });
              this.groupSelection.enable({ emitEvent: false });

              return;
            }

            this._anyChildSelected = false;
            this.groupSelection.setValue(false, { emitEvent: false });
            this.groupSelection.disable({ emitEvent: false });
          },
        })
    );

    this.renderChildren();
  }

  public get appOptionsGroup(): OptionItem {
    return this.optionsGroup;
  }

  public get multiple(): boolean {
    return this.dropdownPanel.configuration.multiple;
  }

  public get showToggleGroup(): boolean {
    return this.dropdownPanel.configuration.showToggleGroup;
  }

  public get optionTemplate(): TemplateRef<any> {
    return this.dropdownPanel.configuration.optionTemplate;
  }

  public get groupLabelTemplate(): TemplateRef<any> {
    return this.dropdownPanel.configuration.groupLabelTemplate;
  }

  public get selectionForm(): FormControl {
    return this.dropdownPanel.form.get('selection') as FormControl;
  }

  public get anyChildSelected(): boolean {
    return this._anyChildSelected;
  }

  public get groupLabelEllipsis(): boolean {
    return this.dropdownPanel.configuration.groupLabelEllipsis;
  }

  public get optionLabelEllipsis(): boolean {
    return this.dropdownPanel.configuration.optionLabelEllipsis;
  }

  public isSelected(item: OptionItem): boolean {
    return this.dropdownPanel.isSelected(item);
  }

  public isHovered(item: OptionItem): boolean {
    return item.globalIndex === this.activeIndex;
  }

  public hoverItem(item: OptionItem): void {
    if (!item.isGroup) {
      this.dropdownPanel.setActiveIndex(item.globalIndex);
    }
  }

  public hoverGroup(item: OptionItem): void {
    this.dropdownPanel.setActiveIndex(item.flatten[0].globalIndex, true);
  }

  public getCounter(item: OptionItem): { children: number; flatten: number } {
    return {
      children: item.children.filter((child) => child.visible).length,
      flatten: item.flatten.filter((child) => child.visible).length,
    };
  }

  protected mergeObjects(object1: any, object2: any): any {
    return { ...object1, ...object2 };
  }

  private renderChildren(): void {
    if (!this.listContainer) {
      return;
    }

    this.listContainer.clear();

    for (let i = 0; i < this.children.length; i++) {
      this.listContainer.createEmbeddedView(this.listItemTemplate, { $implicit: this.children[i], index: i });
    }

    this.cdr.detectChanges();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule as ProgetSharedCommonModule } from '@proget-shared/_common';

import { CheckboxComponent } from './checkbox.component';

@NgModule({
  declarations: [
    CheckboxComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProgetSharedCommonModule,
  ],
  exports: [
    CheckboxComponent,
  ],
})
export class CheckboxModule {}

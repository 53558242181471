import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'default' })
export class DefaultPipe implements PipeTransform {
  transform(input: any, defaultValue?: any): any {
    if (void 0 === defaultValue) {
      return input;
    }

    if (null === input || void 0 === input || '' === input) {
      return defaultValue;
    }

    return input;
  }
}

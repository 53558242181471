import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  bufferTime,
  debounceTime,
  fromEvent,
  map,
  merge,
  Observable,
  ReplaySubject,
  share,
  startWith,
  Subject,
  Subscription,
  switchMap,
  take,
  throttleTime,
} from 'rxjs';

import { OverlayPanelDirective } from '../directive/overlay-panel.directive';
import { ChildrenService } from '../service/children.service';
import { OverlayPanelService } from '../service/overlay-panel.service';
import { OverlayPanelAppearance } from '../type/overlay-panel-appearance.type';

@Component({
  selector: 'app-overlay-panel',
  templateUrl: './overlay-panel.component.html',
  styleUrls: ['./overlay-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ChildrenService }],
})
export class OverlayPanelComponent implements AfterViewInit, OnDestroy {
  public readonly init$: Observable<void>;

  public referer: HTMLElement | MouseEvent | undefined;
  public parentDirective: OverlayPanelDirective;
  public animationDuration = 300;
  public padding = 10;
  public matchWidth = false;
  public scrollContent = true;
  public appearance: OverlayPanelAppearance = 'prefer-below';
  public maxHeight = Infinity;

  private readonly subscription = new Subscription();
  private readonly windowScrollSubject = new Subject<void>();
  private readonly templateSubject = new ReplaySubject<TemplateRef<any>>(1);
  private readonly initSubject = new ReplaySubject<void>(1);
  private readonly updatePositionSubject = new Subject<boolean>();
  private readonly observers: (MutationObserver | ResizeObserver)[] = [];

  private template: TemplateRef<any> | undefined;
  private left = 0;
  private top = 0;
  private bottom = 0;
  private displayAboveReferer = false;
  @ViewChild('container', { static: true, read: ElementRef })
  private containerElementRef: ElementRef<HTMLElement>;
  @ViewChild('scrolledContent', { static: true, read: ElementRef })
  private scrolledContentElementRef: ElementRef<HTMLElement>;
  @ViewChild('fixedContent', { static: true, read: ElementRef })
  private fixedContentElementRef: ElementRef<HTMLElement>;
  @ViewChild('scrollbar', { read: ElementRef })
  private scrollbarElementRef: ElementRef;
  private afterInit = false;
  private _templateData: any;
  private _destroying = false;

  constructor(
    protected readonly injector: Injector,
    protected readonly children: ChildrenService,
    private elementRef: ElementRef,
    private overlayPanelService: OverlayPanelService,
    private cdr: ChangeDetectorRef
  ) {
    this.init$ = this.initSubject.asObservable();

    this.subscription.add(this.windowScrollSubject.pipe(
      debounceTime(100)
    )
      .subscribe(() => {
        this.updatePositionSubject.next(false);
      })
    );

    overlayPanelService.register(this);
    elementRef.nativeElement.setAttribute('tabindex', '-1');
  }

  ngAfterViewInit(): void {
    this.afterInit = true;

    this.subscription.add(
      this.templateSubject
        .pipe(take(1))
        .subscribe({
          next: (template) => {
            this.template = template;
            this.updateWidth();
            this.cdr.detectChanges();

            setTimeout(() => {
              this.initSubject.next();
              this.initSubject.complete();

              this.updatePositionSubject.next(true);
              const appearClass = this.displayAboveReferer ? 'from-bottom' : 'from-top';

              this.containerElementRef.nativeElement.classList.add(appearClass);
            });

            setTimeout(() => {
              this.updatePositionSubject.next(true);
            }, 100);
          },
        })
    );

    const updatePositionBufferTime = 0; // 0 groups events from one event loop
    const updatePosition$ = merge(
      this.updatePositionSubject,
      fromEvent(window, 'resize').pipe(map(() => true))
    ).pipe(
      share()
    );

    this.subscription.add(
      updatePosition$
        .pipe(
        // ignore main events while buffering
          throttleTime(updatePositionBufferTime),
          switchMap((initialParam) => updatePosition$.pipe(
          // switch to buffer starting with initial param
            startWith(initialParam),
            bufferTime(updatePositionBufferTime),
            // take only one buffer (prevent infinite interval)
            take(1),
            // find if any of the updates was forced
            map((updateParams) => updateParams.indexOf(true) !== -1)
          )),
          // force update position on init
          startWith(true)
        )
        .subscribe((forceUpdate) => {
          this.updatePosition(forceUpdate);
        })
    );

    // watch html tag, e.x.: dark theme class is added here, layout arrangement may change
    this.observeParent(document.firstElementChild);
    // watch body tag, e.x.: dialogs add class here, body scroll changes
    this.observeParent(document.body);

    if (this.referer instanceof HTMLElement) {
      this.observeRefererPosition();
    }
  }

  ngOnDestroy(): void {
    this.windowScrollSubject.complete();
    this.templateSubject.complete();
    this.initSubject.complete();
    this.subscription.unsubscribe();
    this.overlayPanelService.unregister(this);
    this.observers.forEach((observer) => {
      observer.disconnect();
    });
  }

  public get containerStyle(): Partial<CSSStyleDeclaration> {
    const animationDurationString = `${this.animationDuration}ms`;

    return Object.assign(
      {
        animationDuration: animationDurationString,
        transitionDuration: animationDurationString,
      } as Partial<CSSStyleDeclaration>,
      this.getOverflowStyle()
    );
  }

  public set destroying(value: boolean) {
    if (value === this.destroying) {
      return;
    }

    this._destroying = value;
    this.cdr.detectChanges();
  }

  public get destroying(): boolean {
    return this._destroying;
  }

  public set templateData(value: any) {
    this._templateData = value;
    this.cdr.detectChanges();
  }

  public get templateData(): any {
    return this._templateData;
  }

  public setTemplate(template: TemplateRef<any>): void {
    this.templateSubject.next(template);
  }

  public updatePosition(forceUpdate: boolean): void {
    if (!this.referer || !this.afterInit) {
      return;
    }

    const element = this.elementRef.nativeElement as HTMLElement;
    const content = this.scrollContent ? this.scrolledContentElementRef.nativeElement : this.fixedContentElementRef.nativeElement;
    const clientHeight = document.body.clientHeight;

    this.updateWidth();

    const refererBounds = this.getRefererBounds(this.referer);
    const margin = 10;

    const spaceBelow = clientHeight - refererBounds.bottom - margin;
    const spaceAbove = refererBounds.top - margin;

    const displayAboveReferer = this.resolveDisplayAbove(spaceBelow, spaceAbove, content.offsetHeight);
    const contentMaxHeight = Math.min(this.maxHeight, displayAboveReferer ? spaceAbove : spaceBelow);

    (content.firstElementChild as HTMLElement).style.maxHeight = `${contentMaxHeight}px`;

    if (this.scrollbarElementRef) {
      this.scrollbarElementRef.nativeElement.firstChild.style.maxHeight = `${contentMaxHeight}px`;
    }

    if (displayAboveReferer !== this.displayAboveReferer) {
      displayAboveReferer
        ? element.classList.add('above-referer')
        : element.classList.remove('above-referer');

      this.calculatePosition(
        this.getRefererBounds(this.referer),
        element,
        displayAboveReferer
      );

      this.displayAboveReferer = displayAboveReferer;

      return;
    }

    if (forceUpdate) {
      this.calculatePosition(refererBounds, element, displayAboveReferer);
    }
  }

  protected getTemplate(): TemplateRef<any> | undefined {
    return this.template;
  }

  @HostListener('mousedown', ['$event'])
  protected stopMousedownPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  @HostListener('window:scroll')
  protected onWindowScroll(): void {
    this.windowScrollSubject.next();
  }

  private getOverflowStyle(): Partial<CSSStyleDeclaration> {
    if (!this.scrollContent) {
      return { overflow: 'hidden' };
    }
  }

  private getRefererBounds(referer: HTMLElement | MouseEvent): DOMRect {
    if (referer instanceof MouseEvent) {
      return new DOMRect(referer.clientX, referer.clientY, 0, 0);
    }

    return referer.getBoundingClientRect();
  }

  private calculatePosition(refererBounds: DOMRect, element: HTMLElement, displayAboveReferer: boolean): void {
    this.bottom = 0;
    this.top = 0;
    element.style.bottom = '0px';
    element.style.top = '0px';

    window.requestAnimationFrame(() => {
      const selfBounds = element.getBoundingClientRect();

      if (selfBounds.width === 0) {
        this.left = 0;
        element.style.left = '0px';

        return;
      }

      const clientHeight = document.body.clientHeight;

      const yCorrection = displayAboveReferer
        ? selfBounds.y + selfBounds.height - (clientHeight - this.bottom)
        : selfBounds.y - this.top;
      const xCorrection = selfBounds.x - this.left;

      this.top = refererBounds.y + refererBounds.height - yCorrection;
      this.bottom = clientHeight - (refererBounds.y - yCorrection);

      this.left = refererBounds.left + selfBounds.width < document.body.clientWidth
        ? refererBounds.x - xCorrection
        : refererBounds.right - selfBounds.width - xCorrection;

      element.style.left = `${this.left}px`;
      element.style.top = `${this.top}px`;
      element.style.bottom = `${this.bottom}px`;
    });
  }

  private updateWidth(): void {
    if (!this.referer || !this.afterInit) {
      return;
    }

    const container = this.containerElementRef.nativeElement ;

    container.style.width = this.matchWidth && !(this.referer instanceof MouseEvent)
      ? `${this.referer.offsetWidth}px`
      : 'auto';
  }

  private observeRefererPosition(): void {
    const parents = this.geRefererParents();
    const observer = new ResizeObserver(() => {
      this.updatePositionSubject.next(true);
    });

    for (const parentElement of parents) {
      observer.observe(parentElement);
    }

    this.observers.push(observer);
  }

  private geRefererParents(): HTMLElement[] {
    if (!(this.referer instanceof HTMLElement)) {
      return [];
    }

    const parents: HTMLElement[] = [];
    let parent = this.referer.parentElement;

    while (parent) {
      parents.push(parent);
      parent = parent.parentElement;
    }

    return parents;
  }

  private observeParent(parentElement: Element): void {
    const observer = new MutationObserver(() => {
      this.updatePositionSubject.next(true);
    });

    observer.observe(parentElement, { attributeFilter: ['class', 'style'], attributes: true });
    this.observers.push(observer);
  }

  private resolveDisplayAbove(spaceBelow: number, spaceAbove: number, contentHeight: number): boolean {
    const minSpaceToForceAppearance = 100;

    if (this.appearance === 'force-above') {
      return spaceAbove >= minSpaceToForceAppearance || spaceAbove >= spaceBelow;
    }

    if (this.appearance === 'force-below') {
      return !(spaceBelow >= minSpaceToForceAppearance || spaceBelow >= spaceAbove);
    }

    const fitsBelow = spaceBelow >= contentHeight;
    const fitsAbove = spaceAbove >= contentHeight;

    if (this.appearance === 'prefer-above') {
      return !fitsBelow || fitsAbove;
    }

    if (this.appearance !== 'prefer-below') {
      console.warn(`Unknown value for overlay panel appearance '${this.appearance}', 'prefer-below' used.`);
    }

    // behavior for prefer-below
    return !fitsBelow && fitsAbove;
  }
}

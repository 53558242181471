<div
  [class.disabled]="form.disabled"
  [formGroup]="form"
  class="input-form"
>
  <div class="time-part">
    <div
      [class.arrow-disabled]="form.disabled"
      [hidden]="hoursDisabled"
      (click)="modifyHours(1)"
      (mousedown)="$event.preventDefault()"
      class="arrow-up"
    >
      <div class="arrow-icon"></div>
    </div>
    <div
      [class.default-zero]="form.value.hours === ''"
      [class.leading-zero]="displayLeadingZero(form.value.hours)"
      [class.invalid]="form.enabled && invalidHours"
      class="input-container"
    >
      <input
        #hoursInput
        (change)="$event.stopPropagation()"
        formControlName="hours"
        maxlength="2"
      >
    </div>
    <div
      [class.arrow-disabled]="form.disabled"
      [hidden]="hoursDisabled"
      (click)="modifyHours(-1)"
      (mousedown)="$event.preventDefault()"
      class="arrow-down"
    >
      <div class="arrow-icon"></div>
    </div>
  </div>
  <span class="separator">:</span>
  <div class="time-part">
    <div
      [class.arrow-disabled]="form.disabled"
      [hidden]="minutesDisabled"
      (click)="modifyMinutes(1)"
      (mousedown)="$event.preventDefault()"
      class="arrow-up"
    >
      <div class="arrow-icon"></div>
    </div>
    <div
      [class.default-zero]="form.value.minutes === ''"
      [class.leading-zero]="displayLeadingZero(form.value.minutes)"
      [class.invalid]="form.enabled && invalidMinutes"
      class="input-container"
    >
      <input
        #minutesInput
        (change)="$event.stopPropagation()"
        formControlName="minutes"
        maxlength="2"
      >
    </div>
    <div
      [class.arrow-disabled]="form.disabled"
      [hidden]="minutesDisabled"
      (click)="modifyMinutes(-1)"
      (mousedown)="$event.preventDefault()"
      class="arrow-down"
    >
      <div class="arrow-icon"></div>
    </div>
  </div>
</div>

import { createComponent, Directive, ElementRef, EnvironmentInjector, Input, OnDestroy } from '@angular/core';
import {
  delay,
  distinctUntilChanged,
  filter,
  interval,
  of,
  skipWhile,
  startWith,
  Subject,
  Subscriber,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { ElementSpinnerComponent } from './element-spinner/element-spinner.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[spinner]',
})
export class SpinnerDirective implements OnDestroy {
  private readonly visibilitySubject = new Subject<boolean>();
  private readonly visibilitySubscription: Subscription;

  private sourceSubscription: Subscription | undefined;
  private spinnerElement: HTMLElement | undefined;

  constructor(
    private environment: EnvironmentInjector,
    private elementRef: ElementRef<HTMLElement>
  ) {
    this.visibilitySubscription = this.visibilitySubject
      .pipe(
        skipWhile((value) => !value),
        distinctUntilChanged(),
        switchMap((visibility, index) => {
          const visibleOnStart = !this.spinnerElement;

          this.spinnerElement = this.getSpinnerElement();

          if (visibility) {
            this.addSpinnerElement(visibleOnStart);
          } else {
            // enable animation for following changes after switchMap overwrite
            this.spinnerElement.classList.add('animated');
            this.spinnerElement.classList.remove('visible');
          }

          return of(visibility).pipe(
            delay(0),
            // fade in added element
            tap({
              next: (value) => {
                if (value) {
                  this.spinnerElement.classList.add('visible');
                }
              },
            }),
            // wait for animation to be finished
            delay(index === 0 ? 0 : 500)
          );
        })
      )
      .subscribe({
        next: (visibility) => {
          // enable animation for following changes
          this.spinnerElement.classList.add('animated');

          if (!visibility) {
            this.removeSpinnerElement();
          }
        },
      });
  }

  ngOnDestroy(): void {
    if (this.sourceSubscription) {
      this.sourceSubscription.unsubscribe();
    }

    this.visibilitySubscription.unsubscribe();
    this.visibilitySubject.complete();
  }

  @Input()
  public set spinner(source: Subscription | boolean) {
    if (this.sourceSubscription) {
      this.sourceSubscription.unsubscribe();
      this.sourceSubscription = void 0;
    }

    if (typeof source === 'boolean') {
      this.visibilitySubject.next(source);

      return;
    }

    if (source instanceof Subscriber) {
      this.visibilitySubject.next(true);

      this.sourceSubscription = interval(100)
        .pipe(
          startWith(0),
          filter(() => source.closed),
          take(1)
        )
        .subscribe({
          next: () => {
            this.visibilitySubject.next(false);
          },
        });

      return;
    }

    this.visibilitySubject.next(false);
  }

  private addSpinnerElement(visibility: boolean): void {
    if (this.spinnerElement.parentNode !== null) {
      return;
    }

    const style = window.getComputedStyle(this.elementRef.nativeElement);

    if (style.position === 'static') {
      this.elementRef.nativeElement.style.position = 'relative';
    }

    if (style.display === 'inline') {
      this.elementRef.nativeElement.style.display = 'inline-block';
    }

    if (visibility) {
      this.spinnerElement.classList.add('visible');
    }

    this.elementRef.nativeElement.appendChild(this.spinnerElement);
  }

  private removeSpinnerElement(): void {
    this.elementRef.nativeElement.style.display = null;
    this.elementRef.nativeElement.style.position = null;

    if (this.spinnerElement.parentNode === null) {
      return;
    }

    this.elementRef.nativeElement.removeChild(this.spinnerElement);
  }

  private getSpinnerElement(): HTMLElement {
    if (this.spinnerElement) {
      return this.spinnerElement;
    }

    return createComponent(ElementSpinnerComponent, { environmentInjector: this.environment }).location.nativeElement;
  }
}

<app-dialog-header
  [header]="header"
  [iconClass]="iconClass"
>
</app-dialog-header>
<app-dialog-content>
  <div
    [innerHTML]="messageHtml"
    [style.width.px]="width"
    class=" dialog-message"
  >
  </div>
</app-dialog-content>
<div class="dialog-footer">
  <button
    [ngClass]="noButtonClass"
    (click)="deny()"
    class="dialog-button dialog-button-cancel"
    type="button"
  >
    {{ noLabel }}
  </button>
  <button
    [ngClass]="yesButtonClass"
    (click)="confirm()"
    class="dialog-button dialog-button-submit"
    type="button"
  >
    {{ yesLabel }}
  </button>
</div>

<div
  #calendarPanel
  [appOverlayPanel]="calendar"
  [class.disabled]="disabled"
  [formGroup]="form.get('input')"
  [overlayPanelEnabled]="!disabled"
  [overlayPanelOptions]="{ target: target, closeOnToggle: false, closeOnScroll: closeOnScroll, referer: panelReferer }"
  (overlayPanelStatus)="calendarStatusChanged($event.opened)"
  class="calendar-input"
>
  <div class="calendar-input-group">
    <label
      *ngIf="calendarService.range"
      (mousedown)="selectedRangeEdge = RangeEdge.FROM"
      [hidden]="form.value.input.date && !labelVisibility"
      class="range-separator"
    >{{ 'proget_shared.calendar.date_range.from' | translate }}</label>
    <label (mousedown)="selectedRangeEdge = RangeEdge.FROM">
      <input
        #dateInput
        [class.editing]="calendarService.range && editingEdge === RangeEdge.FROM"
        [placeholder]="placeholder"
        (blur)="verifyInputDate('date')"
        formControlName="date"
        class="date-input"
      >
      <button
        *ngIf="calendarService.clearEnabled"
        [disabled]="disabled || form.value.input.date === ''"
        (click)="form.get('input.date').setValue('')"
        (mousedown)="$event.stopPropagation()"
        type="button"
        class="clear-input-button"
      >
        <span class="clear-icon"></span>
      </button>
    </label>
  </div>
  <div
    *ngIf="calendarService.range"
    class="calendar-input-group"
  >
    <label
      (mousedown)="selectedRangeEdge = RangeEdge.TO"
      [hidden]="form.value.input.endDate && !labelVisibility"
      class="range-separator"
    >{{ 'proget_shared.calendar.date_range.to' | translate }}</label>
    <label (mousedown)="selectedRangeEdge = RangeEdge.TO">
      <input
        #endDateInput
        [class.editing]="editingEdge === RangeEdge.TO"
        [placeholder]="placeholder"
        (blur)="verifyInputDate('endDate')"
        formControlName="endDate"
        class="date-input"
      >
      <button
        *ngIf="calendarService.clearEnabled"
        [disabled]="disabled || form.value.input.endDate === ''"
        (click)="form.get('input.endDate').setValue('')"
        (mousedown)="$event.stopPropagation()"
        type="button"
        class="clear-input-button"
      >
        <span class="clear-icon"></span>
      </button>
    </label>
  </div>
</div>
<ng-template #calendar>
  <app-inline-calendar
    [dayFullLabelKey]="dayFullLabelKey"
    [dayShortLabelKey]="dayShortLabelKey"
    [firstWeekDay]="firstWeekDay"
    [formControl]="form.get('calendar')"
    [monthLabelKey]="monthLabelKey"
    [selectedRangeEdge]="selectedRangeEdge"
    [view]="calendarView"
    (apply)="closePanel()"
    (edgeChange)="markEditingRangeEdge($event)"
    (modeChange)="calendarView = $event"
    outputType="object"
  >
  </app-inline-calendar>
</ng-template>

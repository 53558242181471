import { Component, HostListener } from '@angular/core';
import { ROUTE } from '@core/const';

import { MenuItem } from '../../model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  protected scrollOffset = 0;
  protected menuItems: MenuItem[] = [
    new MenuItem('tenant', [ROUTE.TENANT.BASE], 'icon-files-icon'),
    new MenuItem('label', [ROUTE.LABEL.BASE], 'icon-labels2-icon'),
    new MenuItem('user', [ROUTE.USER.BASE], 'icon-users-icon'),
    new MenuItem('release', [ROUTE.RELEASE.BASE], 'icon-policy-icon'),
    new MenuItem('audit_log', [ROUTE.AUDIT_LOG.BASE], 'icon-history-icon'),
    new MenuItem('statistics', [ROUTE.STATISTICS.BASE], 'icon-dashboard-icon', [
      new MenuItem('operating_system', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.OPERATING_SYSTEM]),
      new MenuItem('operating_system_version', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.OPERATING_SYSTEM_VERSION]),
      new MenuItem('vendor', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.VENDOR]),
      new MenuItem('model', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.MODEL]),
      new MenuItem('mdm_version', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.MDM_VERSION]),
      new MenuItem('activation', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.ACTIVATION]),
      new MenuItem('policy', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.POLICY]),
      new MenuItem('profile', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.PROFILE]),
      new MenuItem('user', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.USER]),
      new MenuItem('group', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.GROUP]),
      new MenuItem('kiosk', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.KIOSK]),
      new MenuItem('groups_apps', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.GROUPS_APPS]),
      new MenuItem('devices_with_assigned_assets', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.DEVICES_WITH_ASSIGNED_ASSETS]),
      new MenuItem('notifications', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.NOTIFICATIONS]),
      new MenuItem('device_contacts', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.DEVICE_CONTACTS]),
      new MenuItem('devices_with_password_policies_types', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.DEVICES_WITH_PASSWORD_POLICIES_TYPES]),
      new MenuItem('devices_auto_enrollments', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.DEVICES_AUTO_ENROLLMENTS]),
      new MenuItem('default_configurations', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.DEFAULT_CONFIGURATIONS]),
      new MenuItem('smime', [ROUTE.STATISTICS.BASE, ROUTE.STATISTICS.SMIME]),
    ]),
    new MenuItem('zero_touch', [ROUTE.ZERO_TOUCH.BASE], 'icon-devices-icon', [
      new MenuItem('customer', [ROUTE.ZERO_TOUCH.BASE, ROUTE.ZERO_TOUCH.CUSTOMER.BASE]),
      new MenuItem('device', [ROUTE.ZERO_TOUCH.BASE, ROUTE.ZERO_TOUCH.DEVICE.BASE]),
      new MenuItem('report', [ROUTE.ZERO_TOUCH.BASE, ROUTE.ZERO_TOUCH.REPORT]),
      new MenuItem('transaction', [ROUTE.ZERO_TOUCH.BASE, ROUTE.ZERO_TOUCH.TRANSACTION.BASE]),
    ]),
    new MenuItem('knox', [ROUTE.KNOX.BASE], 'icon-devices-icon', [new MenuItem('customer', [ROUTE.KNOX.BASE, ROUTE.KNOX.CUSTOMER.BASE])]),
    new MenuItem('terms_of_use', [ROUTE.TERMS_OF_USE.BASE], 'icon-policy-icon'),
  ];
  @HostListener('scroll', ['$event.target.scrollTop'])
  protected setScrollOffset(offset: number): void {
    this.scrollOffset = offset;
  }
}

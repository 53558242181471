import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

import { DropdownPanelComponent } from '../dropdown-panel/dropdown-panel.component';

import { OptionItem } from './option-item.model';

@Directive({
  selector: '[appOptionItem]',
})
export class OptionItemDirective {
  private item: OptionItem;

  constructor(
    private dropdown: DropdownPanelComponent,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  @Input()
  public set appOptionItem(item: OptionItem) {
    this.item = item;
    item.element = this.elementRef.nativeElement;

    this.updateElementClass();
  }

  public get appOptionItem(): any {
    return this.item;
  }

  @HostBinding('class.selected')
  public get isSelected(): boolean {
    return this.item.isGroup ? false : this.dropdown.isSelected(this.item);
  }

  @HostListener('click')
  public triggerClick(): void {
    if (this.item.isGroup) {
      return;
    }

    this.dropdown.configuration.multiple ? this.dropdown.toggleItem(this.item) : this.dropdown.selectItem(this.item);
  }

  private updateElementClass(): void {
    this.elementRef.nativeElement.classList.remove('group');

    if (this.item.isGroup) {
      this.elementRef.nativeElement.classList.add('group');
    }
  }
}

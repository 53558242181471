import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith, Subscription } from 'rxjs';

import { SELECTION_MODE } from '../../enum/selection-mode.enum';
import { SelectionService } from '../../service/selection.service';

@Component({
  selector: 'app-selection-all-visible-items',
  templateUrl: './selection-all-visible-items.component.html',
  styleUrls: ['./selection-all-visible-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionAllVisibleItemsComponent implements OnInit, OnDestroy {
  protected readonly formControl = new FormControl(false);

  private readonly componentSubscription = new Subscription();

  @Input()
  private customSelection: SelectionService;
  private selection: SelectionService | null = null;
  private _isAnyVisibleSelected = false;

  constructor(
    @Optional()
    private inheritedSelection: SelectionService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.selection = this.resolveSelection();

    if (null === this.selection) {
      return;
    }

    this.componentSubscription.add(
      this.selection.itemSelectionChange$.subscribe({
        next: () => {
          this.formControl.setValue(this.selection.areAllVisibleItemsSelected(), {
            emitEvent: false,
          });
          this.updateAnyVisibleSelected();
        },
      })
    );

    this.componentSubscription.add(
      this.selection.visibleItemsChange$
        .pipe(startWith([]))
        .subscribe({
          next: (visibleItems) => {
            visibleItems.length > 0
              ? this.formControl.enable({ emitEvent: false })
              : this.formControl.disable({ emitEvent: false });

            this.formControl.setValue(this.selection.areAllVisibleItemsSelected(), {
              emitEvent: false,
            });

            this.updateAnyVisibleSelected();
          },
        })
    );

    this.componentSubscription.add(
      this.selection.allSelectedItemsSelectionChange$.subscribe({
        next: () => {
          this.formControl.setValue(this.selection.areAllVisibleItemsSelected(), {
            emitEvent: false,
          });

          this.updateAnyVisibleSelected();
        },
      })
    );

    this.componentSubscription.add(
      this.formControl.valueChanges.subscribe({
        next: (checked) => {
          checked ? this.selection.selectAllVisibleItems() : this.selection.deselectAllVisibleItems();
        },
      })
    );

    this.componentSubscription.add(
      this.selection.pending$.subscribe({
        next: (pending) => {
          pending
            ? this.formControl.disable({ emitEvent: false })
            : this.formControl.enable({ emitEvent: false });
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.componentSubscription.unsubscribe();
  }

  public get isAnyVisibleSelected(): boolean {
    return this._isAnyVisibleSelected;
  }

  public isMultiSelectionMode(): boolean {
    if (null === this.selection) {
      return false;
    }

    return SELECTION_MODE.MULTI === this.selection.getMode();
  }

  private resolveSelection(): SelectionService | null {
    if (this.customSelection instanceof SelectionService) {
      return this.customSelection;
    }

    if (this.inheritedSelection instanceof SelectionService) {
      return this.inheritedSelection;
    }

    return null;
  }

  private updateAnyVisibleSelected(): void {
    const isAnyVisibleSelected = this.selection.isAnyItemSelected(this.selection.getVisibleItems());

    if (isAnyVisibleSelected === this._isAnyVisibleSelected) {
      return;
    }

    this._isAnyVisibleSelected = isAnyVisibleSelected;
    this.cdr.markForCheck();
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateHelper } from '@proget-shared/helper';
import { Subscription, fromEvent } from 'rxjs';

import { CalendarService } from '../../service/calendar.service';

import { Time } from './time.type';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly form = new FormGroup({
    hours: new FormControl<string>('0'),
    minutes: new FormControl<string>('0'),
  });

  @ViewChild('hoursInput')
  protected hoursInputEl: ElementRef<HTMLInputElement>;
  @ViewChild('minutesInput')
  protected minutesInputEl: ElementRef<HTMLInputElement>;
  protected invalidHours = false;
  protected invalidMinutes = false;

  @Output()
  private readonly timeChange = new EventEmitter<Date>();
  private readonly subscription = new Subscription();

  private lastValidTime = { hours: 0, minutes: 0 } as Time;
  private _currentTime: Date;
  private _minDate: Date;
  private _maxDate: Date;
  private _disabled = false;

  constructor(private calendarService: CalendarService) {
    this.subscription.add(
      this.form.valueChanges
        .subscribe({
          next: (value) => {
            const hours = Number(value.hours ?? 0);
            const minutes = Number(value.minutes ?? 0);
            const inputValidity = this.isValidTimePart(hours, 0, 23) && this.isValidTimePart(minutes, 0, 59);

            if (!inputValidity) {
              this.displayTime(this.lastValidTime);

              return;
            }

            this.lastValidTime = { hours, minutes };

            const inputTime = new Date(0, 0, 0, hours, minutes);
            const stepTime = this.calendarService.applyTimeStep(inputTime);

            if (stepTime.getTime() !== inputTime.getTime()) {
              return;
            }

            if (this.validate()) {
              this.timeChange.emit(new Date(0, 0, 0, hours, minutes));
            }
          },
        })
    );
  }

  ngOnInit(): void {
    this.updateInputsDisabled();
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      fromEvent([this.hoursInputEl.nativeElement, this.minutesInputEl.nativeElement], 'blur')
        .subscribe({
          next: () => {
            const lastValidDate = new Date(0, 0, 0, this.lastValidTime.hours, this.lastValidTime.minutes);
            const stepTime = this.calendarService.applyTimeStep(lastValidDate);

            if (lastValidDate.getTime() !== stepTime.getTime()) {
              this.displayTime({ hours: stepTime.getHours(), minutes: stepTime.getMinutes() });
              this.timeChange.emit(stepTime);
            }
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set currentTime(value: Date) {
    const time: Time = DateHelper.isValidDate(value)
      ? { hours: value.getHours(), minutes: value.getMinutes() }
      : { hours: 0, minutes: 0 };

    DateHelper.isValidDate(value) && !this._disabled
      ? this.form.enable({ emitEvent: false })
      : this.form.disable({ emitEvent: false });

    this.displayTime(time);
    this.lastValidTime = time;
    this._currentTime = value;
    this.updateInputsDisabled();
    this.validate();
  }

  public get currentTime(): Date {
    return this._currentTime;
  }

  @Input()
  public set minTime(value: Date) {
    this._minDate = value;

    this.validate();
  }

  public get minTime(): Date {
    return this._minDate;
  }

  @Input()
  public set maxTime(value: Date) {
    this._maxDate = value;

    this.validate();
  }

  public get maxTime(): Date {
    return this._maxDate;
  }

  @Input()
  public set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;
    this._disabled ? this.form.disable({ emitEvent: false }) : this.form.enable({ emitEvent: false });

    this.updateInputsDisabled();
  }

  public updateInputsDisabled(): void {
    this.minutesDisabled
      ? this.form.get('minutes').disable({ emitEvent: false })
      : this.form.get('minutes').enable({ emitEvent: false });
    this.hoursDisabled
      ? this.form.get('hours').disable({ emitEvent: false })
      : this.form.get('hours').enable({ emitEvent: false });
  }

  protected get minutesDisabled(): boolean {
    return this.calendarService.timeStep % 60 === 0;
  }

  protected get hoursDisabled(): boolean {
    return this.calendarService.timeStep >= 60 * 24;
  }

  protected displayLeadingZero(value: any): boolean {
    return (value ?? '').toString().length < 2;
  }

  protected modifyHours(modifier: number): void {
    if (this.form.disabled) {
      return;
    }

    const newHours = Math.max(0, Math.min(23, this.lastValidTime.hours + modifier));

    this.form.patchValue({ hours: newHours.toString() });
  }

  protected modifyMinutes(modifier: number): void {
    if (this.form.disabled) {
      return;
    }

    const step = this.calendarService.timeStep < 5
      ? Math.ceil(5 / this.calendarService.timeStep) * this.calendarService.timeStep
      : this.calendarService.timeStep;

    modifier *= step;

    const hoursMinutes = this.lastValidTime.hours * 60;
    let newMinutes = Math.floor((hoursMinutes + this.lastValidTime.minutes) / modifier) * modifier + modifier - hoursMinutes;

    while (newMinutes < 0) {
      if (this.lastValidTime.hours === 0) {
        this.form.patchValue({ minutes: this.lastValidTime.minutes.toString() });

        return;
      }

      newMinutes += 60;
      this.modifyHours(-1);
    }

    while (newMinutes >= 60) {
      if (this.lastValidTime.hours === 23) {
        this.form.patchValue({ minutes: this.lastValidTime.minutes.toString() });

        return;
      }

      newMinutes -= 60;
      this.modifyHours(1);
    }

    this.form.patchValue({ minutes: newMinutes.toString() });
  }

  private displayTime(time: Time): void {
    const currentValue = this.form.getRawValue();
    const currentHours = Number(currentValue.hours);
    const currentMinutes = Number(currentValue.minutes);

    if (time.hours !== currentHours) {
      this.form.patchValue({ hours: time.hours.toString() }, { emitEvent: false });
    }

    if (time.minutes !== currentMinutes) {
      this.form.patchValue({ minutes: time.minutes.toString() }, { emitEvent: false });
    }
  }

  private validate(): boolean {
    if (this.form.disabled) {
      return false;
    }

    if (!this.currentTime) {
      this.invalidHours = true;
      this.invalidMinutes = true;

      return false;
    }

    const hours = Number(this.form.value.hours ?? 0);
    const minutes = Number(this.form.value.minutes ?? 0);
    const startHoursDate = DateHelper.mergeDateTime(this.currentTime, new Date(0, 0, 0, hours));
    const endHoursDate = DateHelper.mergeDateTime(this.currentTime, new Date(0, 0, 0, hours + 1, 0, 0, -1));
    const minutesDate = DateHelper.mergeDateTime(this.currentTime, new Date(0, 0, 0, hours, minutes));

    this.invalidHours =
      !(this.calendarService.isTimeInRange(startHoursDate) || this.calendarService.isTimeInRange(endHoursDate)) ||
      this.minTime && endHoursDate.getTime() < this.minTime.getTime() ||
      this.maxTime && startHoursDate.getTime() > this.maxTime.getTime();
    this.invalidMinutes =
      !this.calendarService.isTimeInRange(minutesDate) ||
      this.minTime && minutesDate.getTime() < this.minTime.getTime() ||
      this.maxTime && minutesDate.getTime() > this.maxTime.getTime();

    return !this.invalidHours && !this.invalidMinutes;
  }

  private isValidTimePart(value: number, min: number, max: number) {
    return isFinite(value) && value >= min && value <= max;
  }
}

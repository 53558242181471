import {
  Component,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { ConfigurableDialog, DialogService } from '@proget-shared/dialog';

import { PromptConfiguration } from '../../interface/prompt-configuration.interface';

@Component({
  selector: 'app-dialog-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PromptComponent implements ConfigurableDialog {
  public header = '';
  public messageHtml = '';
  public cancelLabel = '';
  public okLabel = '';
  public cancelButtonClass = '';
  public okButtonClass = '';
  public inputLabel = '';
  public type: string | undefined;
  public width: number | undefined;
  public iconClass = '';

  protected readonly promptForm = new FormGroup({ promptInput: new FormControl('') });

  constructor(
    private dialog: DialogService,
    elementRef: ElementRef
  ) {
    elementRef.nativeElement.classList.add('proget-dialog-prompt');
  }

  onConfiguration(configuration: PromptConfiguration): void {
    const input = this.promptForm.get('promptInput');

    if (configuration.hasOwnProperty('header')) {
      this.header = configuration.header;
    }

    if (configuration.hasOwnProperty('message')) {
      this.messageHtml = configuration.message;
    }

    if (configuration.hasOwnProperty('cancelLabel')) {
      this.cancelLabel = configuration.cancelLabel;
    }

    if (configuration.hasOwnProperty('okLabel')) {
      this.okLabel = configuration.okLabel;
    }

    if (configuration.hasOwnProperty('cancelButtonClass') && typeof configuration.cancelButtonClass === 'string') {
      this.cancelButtonClass = configuration.cancelButtonClass;
    }

    if (configuration.hasOwnProperty('okButtonClass') && typeof configuration.okButtonClass === 'string') {
      this.okButtonClass = configuration.okButtonClass;
    }

    if (configuration.hasOwnProperty('inputLabel')) {
      this.inputLabel = configuration.inputLabel;
    }

    if (configuration.hasOwnProperty('type')) {
      this.type = configuration.type;
    }

    if (configuration.hasOwnProperty('initialValue')) {
      input.setValue(configuration.initialValue);
    }

    if (configuration.hasOwnProperty('iconClass') && typeof configuration.iconClass === 'string') {
      this.iconClass = configuration.iconClass;
    }

    this.width = configuration.width;
    input.setValidators(this.prepareValidators(configuration));
  }

  protected cancel(): void {
    this.dialog.reject(this);
  }

  protected submit(): void {
    if (!this.promptForm.valid) {
      this.promptForm.markAllAsTouched();

      return;
    }

    this.dialog.resolve(this, this.promptForm.get('promptInput').value);
  }

  private prepareValidators(configuration: PromptConfiguration): ValidatorFn[] {
    if (
      configuration.hasOwnProperty('validators') &&
      configuration.validators instanceof Array
    ) {
      return configuration.validators
        .map((validator) => (control) => {
          if ('function' === typeof validator) {
            return validator(control);
          }

          if (validator && 'string' === typeof validator.customErrorKey) {
            const originalError = validator.validator(control);
            const errorContent = originalError ? originalError[Object.keys(originalError)[0]] : null;

            return originalError ? { [validator.customErrorKey]: errorContent } : null;
          }

          return null;
        });
    }

    if ('password' === configuration.type) {
      return [Validators.required];
    }

    return [];
  }
}

import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { captureMessage } from '@sentry/core';

@Injectable({
  providedIn: 'root',
})
export class MissingTranslationReporterService implements MissingTranslationHandler {
  private reportedKeys: string[] = [];

  handle(params: MissingTranslationHandlerParams): void {
    if (
      // ignore already reported keys
      this.reportedKeys.indexOf(params.key) !== -1 ||
      // ignore keys with non 'translation key format'
      !/^([a-z0-9_]+\.)+[a-z0-9_]+\.?$/.test(params.key) ||
      // ignore keys with numbers only (ex. ip)
      /^[0-9\.]+$/.test(params.key) ||
      // ignore if translations dictionary is undefined
      !params.translateService.translations ||
      Object.keys(params.translateService.translations).length === 0
    ) {
      return;
    }

    const errorMessage = `Missing translation for '${params.key}' in '${params.translateService.currentLang}' language`;

    if (window.location.hostname === 'localhost') {
      console.error(errorMessage);
    }

    captureMessage(errorMessage);
    this.reportedKeys.push(params.key);
  }
}

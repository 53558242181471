import { ComponentRef, Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, take } from 'rxjs';

import { ToastContainerComponent } from '../component/toast-container/toast-container.component';
import { ToastWrapperComponent } from '../component/toast-wrapper/toast-wrapper.component';

@Injectable({
  providedIn: 'root',
})
export class ToastContainerService {
  public readonly closeAll$: Observable<void>;
  public readonly closeByTag$: Observable<string>;
  public readonly closeByInstance$: Observable<any>;

  private readonly closeAllSubject = new Subject<void>();
  private readonly closeByTagSubject = new Subject<string>();
  private readonly closeByInstanceSubject = new Subject<any>();

  private containerSubject = new ReplaySubject<ToastContainerComponent>(1);

  constructor() {
    this.closeAll$ = this.closeAllSubject.asObservable();
    this.closeByTag$ = this.closeByTagSubject.asObservable();
    this.closeByInstance$ = this.closeByInstanceSubject.asObservable();
  }

  public registerContainer(container: ToastContainerComponent): void {
    this.containerSubject.next(container);
  }

  public display(wrapperRef: ComponentRef<ToastWrapperComponent<any>>): void {
    this.containerSubject.pipe(take(1)).subscribe({
      next: (container) => {
        container.getRef().insert(wrapperRef.hostView);
      },
    });
  }

  public closeAll(): void {
    this.closeAllSubject.next();
  }

  public closeByTag(tag: string): void {
    this.closeByTagSubject.next(tag);
  }

  public closeByInstance(toastComponent: any): void {
    this.closeByInstanceSubject.next(toastComponent);
  }
}

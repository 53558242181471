import { Big } from '@proget-shared/_common';

import { NUMBER_REGEX } from './number-regexp.const';

export class NumberHelper {
  public static plus(first: number | string | Big, second: number | string | Big): number {
    const x = new Big(first);
    const y = new Big(second);

    return +x.plus(y).toString();
  }

  public static minus(first: number | string | Big, second: number | string | Big): number {
    const x = new Big(first);
    const y = new Big(second);

    return +x.minus(y).toString();
  }

  public static isNumber(value: string | number): boolean {
    return NUMBER_REGEX.test(`${value}`);
  }
}

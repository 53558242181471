import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ConfigurableDialog, DialogService } from '@proget-shared/dialog';
import { ObjectHelper, StringHelper } from '@proget-shared/helper';
import { filter, startWith } from 'rxjs';

import { ErrorsDialogConfiguration } from './errors-dialog-configuration.type';

@Component({
  selector: 'app-errors-dialog',
  templateUrl: './errors-dialog.component.html',
  styleUrls: ['./errors-dialog.component.scss'],
})
export class ErrorsDialogComponent implements ConfigurableDialog {
  public readonly searchControl = new FormControl('', Validators.maxLength(255));

  public allErrors: string[] = [];
  public errors: string[] = [];
  public header = '';
  public searchVisibility = false;

  constructor(private dialog: DialogService) { }

  onConfiguration(configuration: ErrorsDialogConfiguration): void {
    this.allErrors = ObjectHelper.findStrings(configuration.error);

    const customHeader = typeof configuration.header === 'string' ? configuration.header.trim() : '';
    const defaultHeader = this.allErrors.length > 1
      ? 'proget_shared.form_control_error.errors_dialog.errors'
      : 'proget_shared.form_control_error.errors_dialog.error';

    this.header = customHeader || defaultHeader;
    this.searchVisibility = this.allErrors.length > 10;

    this.searchControl.valueChanges
      .pipe(
        startWith(this.searchControl.value),
        filter(() => this.searchControl.valid)
      )
      .subscribe({
        next: (query) => {
          this.errors = this.filterErrors(query);
        },
      });
  }

  public close(): void {
    this.dialog.reject(this);
  }

  private filterErrors(query: string): string[] {
    const queryTest = new RegExp(`(${StringHelper.escapeRegExp(query)})`, 'i');

    return this.allErrors.filter((error) => queryTest.test(error));
  }
}

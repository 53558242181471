import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule as ProgetSharedCommon } from '@proget-shared/_common';
import { CheckboxModule } from '@proget-shared/form/checkbox';
import { TranslateModule } from '@proget-shared/translate';
import { EllipsisModule } from '@proget-shared/ui/ellipsis';
import { UiHelperModule } from '@proget-shared/ui/helper';
import { OverlayPanelModule } from '@proget-shared/ui/overlay-panel';
import { ScrollbarModule } from '@proget-shared/ui/scrollbar';
import { SpinnerModule } from '@proget-shared/ui/spinner';

import { DropdownPanelComponent } from './dropdown-panel/dropdown-panel.component';
import { DropdownComponent } from './dropdown.component';
import { EllipsisEnabledDirective } from './ellipsis-enabled.directive';
import { OptionItemDirective } from './options-group/option-item.directive';
import { OptionsGroupComponent } from './options-group/options-group.component';

@NgModule({
  declarations: [
    DropdownComponent,
    DropdownPanelComponent,
    OptionsGroupComponent,
    OptionItemDirective,
    EllipsisEnabledDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProgetSharedCommon,
    EllipsisModule,
    OverlayPanelModule,
    TranslateModule.forChild(),
    CheckboxModule,
    SpinnerModule,
    UiHelperModule,
    ScrollbarModule,
  ],
  exports: [
    ProgetSharedCommon,
    DropdownComponent,
    EllipsisEnabledDirective,
  ],
})
export class DropdownModule { }

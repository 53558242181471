import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@proget-shared/translate';

import { FooterComponent } from './footer.component';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild()],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}

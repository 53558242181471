import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';

export class CustomHttpLoader extends TranslateLoader {
  private readonly knownErrors: number[] = [404];

  constructor(
    private http: HttpClient,
    private prefix = '/assets/i18n/',
    private suffix = '.json'
  ) {
    super();
  }

  getTranslation(lang: string): Observable<any> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
      catchError((error: HttpErrorResponse) => (this.knownErrors.includes(error.status) ? throwError(() => error) : EMPTY))
    );
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/service';
import { catchError, Observable, throwError } from 'rxjs';

import { ROUTE } from '../const';
import { HTTP_STATUS_CODE } from '../enum';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router, private userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(
        (error: any): Observable<any> => {
          if (HTTP_STATUS_CODE.UNAUTHORIZED === error.status) {
            this.userService.remove();
          }

          if (HTTP_STATUS_CODE.FORBIDDEN === error.status) {
            this.router.navigate([ROUTE.BASE]);
          }

          return error?.error ? throwError(() => error.error) : throwError(() => error);
        }
      )
    );
  }
}

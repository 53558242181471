import { PlatformLocation } from '@angular/common';
import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router, UrlTree } from '@angular/router';
import { QueryParams } from '@proget-shared/_common';
import { StringHelper } from '@proget-shared/helper';
import { merge, Observable, Subject, distinctUntilChanged, filter, map, pairwise, shareReplay, startWith, takeUntil } from 'rxjs';

import { DefaultQueryParamsParsersConfig } from './default-query-params-parsers-config.token';
import { QueryParamsParserFn } from './query-params-parser-fn.type';
import { QueryParamsParsersConfig } from './query-params-parsers-config.token';
import { QueryParamsParsers } from './query-params-parsers.type';

@Injectable()
export class QueryParamsService implements OnDestroy {
  public readonly params$: Observable<QueryParams>;

  private readonly stateUrlSubject = new Subject<string>();
  private readonly destroySubject = new Subject<void>();

  private snapshot: QueryParams = {};
  private lastEmitEvent = true;
  private parsers: QueryParamsParsers;

  constructor(
    private router: Router,
    private title: Title,
    private platformLocation: PlatformLocation,
    @Optional()
    @Inject(QueryParamsParsersConfig)
    parsers: QueryParamsParsers = {},
    @Optional()
    @Inject(DefaultQueryParamsParsersConfig)
    defaultParsers: QueryParamsParsers = {}
  ) {
    this.parsers = Object.assign({}, defaultParsers, parsers);

    this.params$ = merge(
      // watch location change when router wasn't triggered
      this.stateUrlSubject,
      // watch NavigationEnd only if NavigationStart appeared before
      router.events.pipe(
        filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd),
        pairwise(),
        filter(([first, second]) => first instanceof NavigationStart && second instanceof NavigationEnd),
        map(([_, end]: [NavigationStart, NavigationEnd]) => this.removeRandomFromUrl(end.url))
      )
    ).pipe(
      startWith(router.url),
      takeUntil(this.destroySubject),
      distinctUntilChanged(),
      map((url) => router.parseUrl(url).queryParams),
      map((params) => {
        let paramsChanged = false;

        const parsedParams: QueryParams = Object.keys(params).reduce((collectedParsedParams, paramName) => {
          const paramValue: string | string[] = params[paramName];
          const parser: QueryParamsParserFn = this.parsers[paramName];

          const parsedParamValue: string | string[] = parser
            ? paramValue instanceof Array
              ? paramValue.map(parser).filter((item) => !!item)
              : parser(paramValue)
            : paramValue;

          if (!paramsChanged && JSON.stringify(paramValue) !== JSON.stringify(parsedParamValue)) {
            paramsChanged = true;
          }

          // ignore falsy values and empty arrays
          if (!(!parsedParamValue || parsedParamValue instanceof Array && parsedParamValue.length === 0)) {
            collectedParsedParams[paramName] = parsedParamValue;
          }

          return collectedParsedParams;
        }, {});

        if (paramsChanged) {
          this.setParams(parsedParams);
        }

        return parsedParams;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      shareReplay(1)
    );

    this.params$.subscribe({
      next: (params) => {
        this.snapshot = Object.assign({}, params);
      },
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
    this.stateUrlSubject.complete();
  }

  public setParams(params: QueryParams, options: { emitEvent?: boolean } = {}): void {
    options = Object.assign({ emitEvent: true }, options);
    this.snapshot = Object.assign({}, params);

    const forceReload = !this.lastEmitEvent;

    this.lastEmitEvent = options.emitEvent;

    // TO-DO https://gitlab.proget.pl/progetmdm/web/-/issues/2116
    if (options.emitEvent) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const additionalParams: QueryParams = forceReload ? { __random: `${Date.now()}` } : {};

      this.router.navigate([], { queryParams: Object.assign({}, params, additionalParams), replaceUrl: true });

      return;
    }

    const urlTree: UrlTree = this.router.createUrlTree([], { queryParams: params });
    const url = `${this.getBaseHref()}${this.router.serializeUrl(urlTree)}`;

    this.stateUrlSubject.next(url);
    window.history.replaceState({ path: url }, this.title.getTitle(), url);
  }

  public setParam(key: string, value: string | string[]): void {
    if (!value) {
      this.clearParam(key);

      return;
    }

    const mergedPrarams: QueryParams = Object.assign({}, this.snapshot);

    mergedPrarams[key] = value;

    this.setParams(mergedPrarams);
  }

  public clearParam(key: string): void {
    const paramsCopy: QueryParams = Object.assign({}, this.snapshot);

    delete paramsCopy[key];

    this.setParams(paramsCopy);
  }

  public clearAllParams(): void {
    this.setParams({});
  }

  public setParser(paramName: string, parser: QueryParamsParserFn): void {
    this.parsers[paramName] = parser;
  }

  private removeRandomFromUrl(url: string): string {
    const urlTree: UrlTree = this.router.parseUrl(url);

    delete urlTree.queryParams.__random;

    const clearUrl = `${this.getBaseHref()}${this.router.serializeUrl(urlTree)}`;

    window.history.replaceState({ path: clearUrl }, this.title.getTitle(), clearUrl);

    return clearUrl;
  }

  private getBaseHref(): string {
    const baseHref: string = this.platformLocation.getBaseHrefFromDOM() || '';

    return StringHelper.trimRight(baseHref, '/');
  }
}

import { DropdownItem } from './dropdown-item.type';

export class OptionsGroup {
  public readonly flatten: DropdownItem[];

  constructor(
    public readonly label: string | any,
    public readonly options: (DropdownItem | OptionsGroup)[]
  ) {
    this.flatten = this.options.reduce<DropdownItem[]>(
      (collected, option) => collected.concat(option instanceof OptionsGroup ? option.flatten : option),
      []
    );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ProgetSharedModule } from '@proget-shared';
import { CalendarModule } from '@proget-shared/form/calendar';
import { DropdownModule } from '@proget-shared/form/dropdown';
import { InputModule } from '@proget-shared/form/input';
import { TranslateModule } from '@proget-shared/translate';
import { AnimationsModule } from '@proget-shared/ui/animations';
import { MenuModule } from '@proget-shared/ui/menu';
import { ScrollbarModule } from '@proget-shared/ui/scrollbar';

import { FilterAutocompleteComponent } from './component/filter-autocomplete/filter-autocomplete.component';
import { FilterDateComponent } from './component/filter-date/filter-date.component';
import { FilterInputComponent } from './component/filter-input/filter-input.component';
import { FilterMultiselectComponent } from './component/filter-multiselect/filter-multiselect.component';
import { FilterRangeDateComponent } from './component/filter-range-date/filter-range-date.component';
import { FilterRangeNumberComponent } from './component/filter-range-number/filter-range-number.component';
import { FilterSelectComponent } from './component/filter-select/filter-select.component';
import { GridFiltersComponent } from './component/grid-filters/grid-filters.component';
import { GridOrderComponent } from './component/grid-order/grid-order.component';
import { GridPaginatorComponent } from './component/grid-paginator/grid-paginator.component';
import { SimpleGridComponent } from './component/simple-grid/simple-grid.component';
import { ClearSavedParamsDirective } from './directive/clear-saved-params.directive';
import { FilterActivatorDirective } from './directive/filter-activator.directive';
import { PagingForDirective } from './directive/paging-for.directive';

@NgModule({
  declarations: [
    GridFiltersComponent,
    GridOrderComponent,
    GridPaginatorComponent,
    FilterInputComponent,
    FilterSelectComponent,
    FilterMultiselectComponent,
    FilterAutocompleteComponent,
    FilterDateComponent,
    FilterRangeNumberComponent,
    FilterRangeDateComponent,
    SimpleGridComponent,
    ClearSavedParamsDirective,
    PagingForDirective,
    FilterActivatorDirective,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    CalendarModule,
    DropdownModule,
    InputModule,
    ProgetSharedModule.forChild(),
    AnimationsModule,
    MenuModule,
    ScrollbarModule,
  ],
  exports: [
    GridFiltersComponent,
    GridOrderComponent,
    GridPaginatorComponent,
    SimpleGridComponent,
    ClearSavedParamsDirective,
    PagingForDirective,
  ],
})
export class GridControlModule {}

<div class="p-grid p-nogutter p-justify-end p-align-center">
  <div class="p-col-fixed">
    <span class="logged-as">
      {{ 'header.logged_as' | translate }}:
      {{ loggedUser?.email }}
    </span>
  </div>
  <div class="p-col-fixed">
    <app-dropdown
      [(ngModel)]="selectedLang"
      [options]="languageOptions"
      [translate]="false"
      (onChange)="changeLang($event.value)"
    ></app-dropdown>
  </div>
  <div class="p-col-fixed">
    <i
      [appTooltip]="'header.logout.tooltip' | translate"
      (click)="logout()"
      class="icon-logout2-icon logout"
    >
    </i>
  </div>
</div>

export class ObjectHelper {
  public static isEmpty(data: any): boolean {
    if (!ObjectHelper.isObject(data)) {
      throw new Error('Incorrect value given');
    }

    return Object.keys(data || {}).length === 0;
  }

  public static isObject(data: any): boolean {
    return data === void 0 || typeof data === 'object';
  }

  public static getValue(data: any, path: string | string[], defaultValue: any): any {
    const arrayPath: string[] = Array.isArray(path) ? path.slice() : path.split('.');

    if (!(data instanceof Object && arrayPath.length > 0 && data[arrayPath[0]] !== undefined)) {
      return defaultValue;
    }

    if (arrayPath.length === 1) {
      return data[arrayPath[0]];
    }

    return ObjectHelper.getValue(data[arrayPath[0]], arrayPath.slice(1), defaultValue);
  }

  public static setValue(data: any, path: string | string[], value: any): any {
    const arrayPath: string[] = Array.isArray(path) ? path.slice() : path.split('.');

    if (!(data instanceof Object && !Array.isArray(data) && arrayPath.length > 0)) {
      throw new Error('can not set value for non-object target');
    }

    ObjectHelper.setNestedValue(data, arrayPath, value);
  }

  public static findStrings(input: any): string[] {
    if (null === input || void 0 === input) {
      return [];
    }

    if (input instanceof Array) {
      return input.reduce((result: string[], item: any) => result.concat(this.findStrings(item)), []);
    }

    if ('object' === typeof input) {
      return Object.keys(input)
        .map((key: string) => input[key])
        .reduce((result: string[], item: any) => result.concat(this.findStrings(item)), []);
    }

    return [input.toString()];
  }

  public static objectToArray(input: any): any[] {
    if (!(input instanceof Object && input)) {
      return [];
    }

    const output = [];
    let index = 0;

    while (input.hasOwnProperty(index)) {
      output.push(input[index]);
      index++;
    }

    return output;
  }

  public static equals(objA: any, objB: any): boolean {
    if (objA === objB) {
      return true;
    }

    if (objA && objB && typeof objA === 'object' && typeof objB === 'object') {
      // Array compare
      const isArrayA = Array.isArray(objA);
      const isArrayB = Array.isArray(objB);

      if (isArrayA !== isArrayB) {
        return false;
      }

      if (isArrayA && isArrayB) {
        if (objA.length !== objB.length) {
          return false;
        }

        return !objA.some((value, index) => !this.equals(value, objB[index]));
      }

      // Date compare
      const isDateA = objA instanceof Date;
      const isDateB = objB instanceof Date;

      if (isDateA !== isDateB) {
        return false;
      }

      if (isDateA && isDateB) {
        return objA.getTime() === objB.getTime();
      }

      // RegExp compare
      const isRegExpA = objA instanceof RegExp;
      const isRegExpB = objB instanceof RegExp;

      if (isRegExpA !== isRegExpB) {
        return false;
      }

      if (isRegExpA && isRegExpB) {
        return objA.toString() === objB.toString();
      }

      // Object compare
      const keysA = Object.keys(objA);

      if (keysA.length !== Object.keys(objB).length) {
        return false;
      }

      if (keysA.some((key) => !Object.prototype.hasOwnProperty.call(objB, key))) {
        return false;
      }

      return keysA.every((key) => this.equals(objA[key], objB[key]));
    }

    // NaN compare
    return objA !== objA && objB !== objB;
  }

  private static setNestedValue(data: any, arrayPath: string[], value: any): any {
    if (!(data[arrayPath[0]] instanceof Object && !Array.isArray(data[arrayPath[0]]))) {
      data[arrayPath[0]] = {};
    }

    if (arrayPath.length === 1) {
      data[arrayPath[0]] = value;

      return;
    }

    ObjectHelper.setNestedValue(data[arrayPath[0]], arrayPath.slice(1), value);
  }
}

import { ObjectHelper } from '@proget-shared/helper';

export class ResponseErrorTemplateMapper {
  public static map(errors: string[]): string {
    if (!(errors instanceof Array)) {
      return '';
    }

    return errors.join('<br>');
  }

  public static mapStrings(input: any, unique = false): string {
    return unique
      ? Array.from(new Set(ObjectHelper.findStrings(input))).join('<br>')
      : ObjectHelper.findStrings(input).join('<br>');
  }
}

import { QueryParams } from '@proget-shared/_common';

export class ObjectComparator {
  public static compare(objectA: QueryParams, objectB: QueryParams): boolean {
    if (!objectA) {
      return !objectB;
    }

    if (!objectB) {
      return !objectA;
    }

    if (Object.keys(objectA).length !== Object.keys(objectB).length) {
      return false;
    }

    for (const key in objectA) {
      if (!objectA.hasOwnProperty(key)) {
        continue;
      }

      if (!ObjectComparator.compareValues(objectA[key], objectB[key])) {
        return false;
      }
    }

    return true;
  }

  private static compareValues(valueA: string | string[], valueB: string | string[]): boolean {
    const normalizedA: string = valueA instanceof Array ? valueA.join(',') : valueA;
    const normalizedB: string = valueB instanceof Array ? valueB.join(',') : valueB;

    return normalizedA === normalizedB;
  }
}

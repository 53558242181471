import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { REQUEST_CANCEL_WHITELIST } from '@proget-shared/_common';
import { StringHelper } from '@proget-shared/helper';
import {
  filter,
  finalize,
  Observable,
  Subject,
  take,
  takeUntil,
} from 'rxjs';

@Injectable()
export class RequestCancelInterceptor implements HttpInterceptor {
  private readonly requests: { [url: string]: Subject<void> } = {};
  private readonly whitelistTest: RegExp;

  private pageInited = false;

  constructor(
    router: Router,
    @Optional()
    @Inject(REQUEST_CANCEL_WHITELIST)
    whitelist: string[]
  ) {
    this.whitelistTest = new RegExp(`(${(whitelist ?? []).map((item) => StringHelper.escapeRegExp(item)).join('|')})`);

    router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      take(1)
    )
      .subscribe({
        next: () => {
          this.pageInited = true;
        },
      });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = this.getBase(req.url);
    const persist = req.params.get('persist') === 'true';
    const reqClone = req.clone({ params: req.params.delete('persist') });

    if (!this.pageInited || persist || req.method !== 'GET' || this.whitelistTest.test(url)) {
      return next.handle(reqClone);
    }

    this.cancelRequest(url);

    const subject = new Subject<void>();

    this.requests[url] = subject;

    return next.handle(reqClone).pipe(
      takeUntil(subject),
      finalize(() => {
        this.cancelRequest(url);
      })
    );
  }

  private cancelRequest(url: string): void {
    if (this.requests.hasOwnProperty(url)) {
      const subject = this.requests[url];

      subject.next();
      subject.complete();

      delete this.requests[url];
    }
  }

  private getBase(url: string): string {
    return url.split('?')[0];
  }
}

import { Directive, Injector, Input, ProviderToken, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTemplate]',
})
export class TemplateDirective {
  @Input()
  public appTemplate: string;

  constructor(
    public templateRef: TemplateRef<any>,
    private injector: Injector
  ) {}

  public inject<T>(token: ProviderToken<T>, notFoundValue: any = null): T {
    return this.injector.get(token, notFoundValue);
  }
}

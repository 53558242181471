<div class="progress-bar-wrapper">
  <div
    [style.width.%]="currentProgress"
    class="progress-bar-progress"
  ></div>
  <div
    [hidden]="!displayProgress"
    class="progress-bar-value"
  >
    {{ currentProgress | number: '1.0-0' }}%
  </div>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@proget-shared/dialog';
import { TranslateService } from '@proget-shared/translate';
import { Subscription } from 'rxjs';

import { ErrorsDialogComponent } from '../errors-dialog/errors-dialog.component';

@Component({
  selector: 'app-errors-list',
  templateUrl: './errors-list.component.html',
  styleUrls: ['./errors-list.component.scss'],
})
export class ErrorsListComponent implements OnInit, OnDestroy {
  @Input()
  public display: 'tooltip' | 'inline' = 'inline';
  @Input()
  public hidden = false;

  protected allErrorsCount = 0;
  protected moreErrorsAvailable = false;
  protected visibleErrors: string[] = [];
  protected tooltipErrors = '';

  private readonly langSubscription: Subscription;

  private _allErrors: string[] = [];
  private _visibleErrorsLimit = 2;
  private _animationEnabled = true;
  private afterInit = false;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {
    this.langSubscription = translateService.lang$.subscribe({
      next: () => {
        this.updateVisibleErrors();
      },
    });
  }

  ngOnInit(): void {
    this.afterInit = true;
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
  }

  @Input()
  public set errors(errors: string | string[]) {
    this._allErrors = Array.from(new Set(Array.isArray(errors) ? errors : [errors]));
    this.allErrorsCount = this.errors.length;
    this.updateVisibleErrors();
  }

  public get errors(): string[] {
    return this._allErrors;
  }

  @Input()
  public set visibleErrorsLimit(limit: number) {
    this._visibleErrorsLimit = limit;
    this.updateVisibleErrors();
  }

  public get visibleErrorsLimit(): number {
    return this._visibleErrorsLimit;
  }

  @Input()
  public set animationEnabled(enabled: boolean) {
    this._animationEnabled = enabled;
  }

  public get animationEnabled(): boolean {
    return this._animationEnabled && this.afterInit;
  }

  public showAllErrors(): void {
    if (this.moreErrorsAvailable) {
      this.dialogService.custom(ErrorsDialogComponent, {
        configuration: {
          error: this.errors,
        },
      });
    }
  }

  private updateVisibleErrors(): void {
    this.moreErrorsAvailable = this.errors.length > this.visibleErrorsLimit;
    this.visibleErrors = this.moreErrorsAvailable
      ? this.errors.slice(0, this.visibleErrorsLimit - 1)
        .concat(this.translateService.instant('proget_shared.form_control_error.show_all', { count: this.allErrorsCount }))
      : this.errors.slice();
    this.tooltipErrors = this.visibleErrors.join('<br>');
  }
}

<div class="dialog-header-container">
  <div
    *ngIf="iconClass"
    class="dialog-header-icon"
  >
    <div
      [ngClass]="iconClass"
      class="dialog-background-icon"
    >
    </div>
    <div
      [ngClass]="iconClass"
      class="dialog-icon"
    >
    </div>
  </div>
  <div class="dialog-header-text">{{ header }}</div>
</div>

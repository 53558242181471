import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, Params, UrlTree } from '@angular/router';

/* USAGE:
provide in core module:
  {
    provide: UrlSerializer,
    useClass: CustomUrlSerializer,
  },
*/

@Injectable()
export class CustomUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const parts = url.split('?');

    parts[0] = parts[0].replace(/\)/g, ''); // break groups in brackets (cause exception in routing)

    const parsed: UrlTree = super.parse(parts.join('?'));

    parsed.queryParams = CustomUrlSerializer.clearArrayBrackets(parsed.queryParams);

    return parsed;
  }

  serialize(tree: UrlTree): string {
    tree.queryParams = CustomUrlSerializer.addArrayBrackets(tree.queryParams);

    return super.serialize(tree);
  }

  public static addArrayBrackets(params: Params): Params {
    const urlParams: Params = {};
    const arrayKeyTest = /^(.+)\[\]$/;

    for (const key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }

      const urlKey: string =
        !arrayKeyTest.test(key) && params[key] instanceof Array ? `${key}[]` : key;

      urlParams[urlKey] = params[key];
    }

    return urlParams;
  }

  public static clearArrayBrackets(params: Params): Params {
    const normalized: Params = {};
    const arrayKeyTest = /^(.+)\[\]$/;

    for (const key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }

      const value: any = params[key];
      const arrayKey: RegExpMatchArray = key.match(arrayKeyTest);

      if (arrayKey) {
        normalized[arrayKey[1]] = value instanceof Array ? value : [value];
        continue;
      }

      normalized[key] = value;
    }

    return normalized;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ScrollbarModule } from '@proget-shared/ui/scrollbar';

import { DialogContainerComponent } from './component/dialog-container/dialog-container.component';
import { DialogContentComponent } from './component/dialog-content/dialog-content.component';
import { DialogFloatingWrapperComponent } from './component/dialog-floating-wrapper/dialog-floating-wrapper.component';
import { DialogHeaderComponent } from './component/dialog-header/dialog-header.component';
import { DialogStaticWrapperComponent } from './component/dialog-static-wrapper/dialog-static-wrapper.component';
import { DragDirective } from './directive/drag.directive';
import { GLOBAL_DIALOG_OPTIONS } from './global-dialog-options.token';
import { GlobalDialogOptions } from './type/global-dialog-options.type';

@NgModule({
  declarations: [
    DialogContainerComponent,
    DialogStaticWrapperComponent,
    DialogFloatingWrapperComponent,
    DialogHeaderComponent,
    DialogContentComponent,
    DragDirective,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    ScrollbarModule,
  ],
  exports: [
    DialogContainerComponent,
    DialogHeaderComponent,
    DialogContentComponent,
  ],
})
export class DialogModule {
  static withOptions(options: Partial<GlobalDialogOptions> = {}): ModuleWithProviders<DialogModule> {
    return {
      ngModule: DialogModule,
      providers: [
        {
          provide: GLOBAL_DIALOG_OPTIONS,
          useValue: options,
        },
      ],
    };
  }
}

<div
  [ngStyle]="horizontalAlignStyles"
  class="image-container"
>
  <img
    *ngIf="src && mainImageValid"
    [alt]="alt ? alt : ''"
    [ngStyle]="verticalAlignStyles"
    [src]="src"
    (error)="mainImageValid = false"
    class="image"
  >
  <img
    *ngIf="alternative && alternativeImageValid"
    [alt]="alt ? alt : ''"
    [ngStyle]="verticalAlignStyles"
    [src]="alternative"
    (error)="alternativeImageValid = false"
    class="image"
  >
</div>

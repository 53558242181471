import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { ApplicationScrollbarDirective } from './application-scrollbar.directive';
import { ScrollbarComponent } from './scrollbar.component';

@NgModule({
  imports: [CommonModule, NgScrollbarModule],
  declarations: [ScrollbarComponent, ApplicationScrollbarDirective],
  exports: [ScrollbarComponent, ApplicationScrollbarDirective],
})
export class ScrollbarModule {}

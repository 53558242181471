export const ROUTE = {
  BASE: '',
  PAGE_NOT_FOUND: '404',
  AUDIT_LOG: {
    BASE: 'audit-log',
  },
  AUTH: {
    BASE: 'login',
  },
  KNOX: {
    BASE: 'knox',
    CUSTOMER: {
      BASE: 'customer',
      LIST: 'list',
    },
  },
  LABEL: {
    BASE: 'label',
    LIST: 'list',
    FORM: 'form',
  },
  RELEASE: {
    BASE: 'release',
    LIST: 'list',
  },
  STATISTICS: {
    BASE: 'statistics',
    OPERATING_SYSTEM: 'operating-system',
    OPERATING_SYSTEM_VERSION: 'operating-system-version',
    VENDOR: 'vendor',
    MODEL: 'model',
    MDM_VERSION: 'mdm-version',
    ACTIVATION: 'activation',
    POLICY: 'policy',
    PROFILE: 'profile',
    USER: 'user',
    GROUP: 'group',
    KIOSK: 'kiosk',
    GROUPS_APPS: 'groups-apps',
    DEVICES_WITH_ASSIGNED_ASSETS: 'devices-with-assigned-assets',
    NOTIFICATIONS: 'notifications',
    DEVICE_CONTACTS: 'devices-contacts',
    DEVICES_WITH_PASSWORD_POLICIES_TYPES: 'devices-with-password-policies-types',
    DEVICES_AUTO_ENROLLMENTS: 'devices-auto-enrollments',
    DEFAULT_CONFIGURATIONS: 'default-configurations',
    SMIME: 'smime',
  },
  TENANT: {
    BASE: 'tenant',
    LIST: 'list',
    AUDIT_LOG: {
      BASE: 'audit-log',
      TENANT: 'tenant',
      DEVICE: 'device',
    },
    SLAVES: 'slaves',
    UPDATE_HISTORY: 'update-history',
  },
  USER: {
    BASE: 'user',
    LIST: 'list',
    FORM: 'form',
  },
  ZERO_TOUCH: {
    BASE: 'zero-touch',
    CUSTOMER: {
      BASE: 'customer',
      LIST: 'list',
      FORM: 'form',
      CLAIM_DEVICE: 'claim-device',
    },
    DEVICE: {
      BASE: 'device',
      LIST: 'list',
    },
    TRANSACTION: {
      BASE: 'transaction',
      LIST: 'list',
    },
    REPORT: 'report',
  },
  TERMS_OF_USE: {
    BASE: 'terms-of-use',
    LIST: 'list',
    FORM: 'form',
  },
};

<app-dialog-header
  [header]="header"
  [iconClass]="iconClass"
>
</app-dialog-header>
<app-dialog-content>
  <form
    [formGroup]="promptForm"
    (ngSubmit)="submit()"
    class="dialog-layout"
  >
    <div [style.width.px]="width">
      <div
        [innerHTML]="messageHtml"
        class="dialog-message"
      >
      </div>
      <app-form-control-label
        [control]="promptForm.get('promptInput')"
        [hidden]="!inputLabel"
      >
        {{ inputLabel }}:
      </app-form-control-label>
      <input
        *ngIf="!type || 'input' === type"
        appInput
        autocomplete="off"
        formControlName="promptInput"
        type="text"
        class="dialog-input"
      >
      <app-password
        *ngIf="'password' === type"
        [optional]="false"
        formControlName="promptInput"
      >
      </app-password>
      <textarea
        *ngIf="'textarea' === type"
        appInput
        formControlName="promptInput"
        rows="10"
        class="dialog-input"
      >
      </textarea>
      <app-input-length-counter
        [control]="promptForm.get('promptInput')"
        class="dialog-input-counter"
      >
      </app-input-length-counter>
      <app-form-control-error [control]="promptForm.get('promptInput')"></app-form-control-error>
    </div>
  </form>
</app-dialog-content>
<div class="dialog-footer">
  <button
    [ngClass]="cancelButtonClass"
    (click)="cancel()"
    class="dialog-button dialog-button-cancel"
    type="button"
  >
    {{ cancelLabel }}
  </button>
  <button
    [ngClass]="okButtonClass"
    [submitButton]="promptForm"
    (click)="submit()"
    class="dialog-button dialog-button-submit"
    type="submit"
  >
    {{ okLabel }}
  </button>
</div>

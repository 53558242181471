import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss'],
})
export class DialogContentComponent {
  @Input()
  public scrollbarViewClass = '';
  @Input()
  public scrollbar = true;

  @HostBinding('class.dialog-content')
  protected readonly dialogContent = true;
}

import { Component } from '@angular/core';
import { LANGUAGES } from '@core/const';
import { UserInterface } from '@core/interface';
import { User } from '@core/model';
import { UserService } from '@core/service';
import { DropdownItem } from '@proget-shared/form/dropdown';
import { TranslateService } from '@proget-shared/translate';
import { filter, noop } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  protected readonly languageOptions: DropdownItem[] = LANGUAGES.map((lang) => ({
    label: lang.toUpperCase(),
    value: lang,
  }));

  protected selectedLang = this.translate.currentLang;
  protected loggedUser: User = null;

  constructor(private userService: UserService, private translate: TranslateService) {
    this.userService.onChange.pipe(filter((user: UserInterface) => user instanceof User)).subscribe({
      next: (user: User) => {
        this.loggedUser = user;
      },
      error: noop,
    });
  }

  protected changeLang(lang: string): void {
    this.translate.selectLang(lang);
  }

  protected logout(): void {
    this.userService.remove();
  }
}

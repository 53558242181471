import { NgModule } from '@angular/core';

import { EllipsisComponent } from './ellipsis.component';

@NgModule({
  declarations: [
    EllipsisComponent,
  ],
  exports: [
    EllipsisComponent,
  ],
})
export class EllipsisModule { }

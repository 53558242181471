import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@proget-shared/dialog';
import { ApiErrorsModule } from '@proget-shared/form/api-errors';
import { InputModule } from '@proget-shared/form/input';
import { TranslateModule } from '@proget-shared/translate';
import { AnimationsModule } from '@proget-shared/ui/animations';
import { TooltipModule } from '@proget-shared/ui/tooltip';

import { ErrorsDialogComponent } from './errors-dialog/errors-dialog.component';
import { ErrorsListComponent } from './errors-list/errors-list.component';
import { FormControlErrorComponent } from './form-control-error/form-control-error.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    DialogModule,
    InputModule,
    TooltipModule,
    AnimationsModule,
    ApiErrorsModule,
  ],
  declarations: [FormControlErrorComponent, ErrorsListComponent, ErrorsDialogComponent],
  exports: [FormControlErrorComponent, ErrorsListComponent, ApiErrorsModule],
})
export class FormControlErrorModule {}

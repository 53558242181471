import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Optional, Output, SkipSelf, ViewChild } from '@angular/core';
import {
  NgScrollbar,
  ScrollbarAppearance,
  ScrollbarTrack,
  ScrollbarPointerEventsMethod,
  ScrollbarPosition,
  ScrollbarVisibility,
} from 'ngx-scrollbar';
import { Observable, Subscription, of } from 'rxjs';

@Component({
  selector: 'app-scrollbar',
  templateUrl: './scrollbar.component.html',
  styleUrls: ['./scrollbar.component.scss'],
})
export class ScrollbarComponent implements AfterViewInit, OnDestroy {
  public horizontalScrolled: Observable<Event> = of(null);
  public verticalScrolled: Observable<Event> = of(null);

  @Input()
  public appearance: ScrollbarAppearance = 'compact';
  @Input()
  public autoHeightDisabled = true;
  @Input()
  public autoWidthDisabled = true;
  @Input()
  public pointerEventsMethod: ScrollbarPointerEventsMethod = 'viewport';
  @Input()
  public position: ScrollbarPosition = 'native';
  @Input()
  public track: ScrollbarTrack = 'vertical';
  @Input()
  public trackClass = '';
  @Input()
  public viewClass = '';
  @Input()
  public visibility: ScrollbarVisibility = 'native';
  @Output()
  public afterInit = new EventEmitter<void>();
  @ViewChild('scrollbar')
  public scrollbar: NgScrollbar;

  private readonly subscription = new Subscription();

  constructor(
    @Optional()
    @SkipSelf()
    public readonly parent: ScrollbarComponent,
    private elementRef: ElementRef
  ) {}

  ngAfterViewInit(): void {
    this.horizontalScrolled = this.scrollbar.horizontalScrolled;
    this.verticalScrolled = this.scrollbar.verticalScrolled;
    this.afterInit.emit();

    this.subscription.add(
      this.scrollbar.scrolled.subscribe(() => {
        this.elementRef.nativeElement.dispatchEvent(new Event('ngx-scroll', { bubbles: false }));
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

export enum Profile {
  PROFILE_AS = 1,
  PROFILE_POPIMAP = 2,
  PROFILE_VPN = 3,
  PROFILE_WIFI = 4,
  PROFILE_CERT_MAPPING = 5,
  PROFILE_SCEP = 6,
  PROFILE_CERT = 7,
  PROFILE_LOCALIZATION = 8,
  PROFILE_APPR = 10,
  PROFILE_CONTACT = 11,
  PROFILE_FILE = 12,
  PROFILE_DEP = 13,
  PROFILE_WALLPAPER = 14,
  PROFILE_BACKUP = 15,
  PROFILE_APN = 16,
  PROFILE_KIOSK = 17,
  PROFILE_CONNECTIVITY = 18,
  PROFILE_MTP = 19,
  PROFILE_GEOFENCING = 20,
}

<app-dialog-header
  [header]="header | translate: { count: allErrors.length }"
  iconClass="icon-dangerous-icon"
>
</app-dialog-header>
<div
  *ngIf="searchVisibility"
  class="dialog-content search-content"
>
  <input
    [formControl]="searchControl"
    [placeholder]="'proget_shared.form_control_error.errors_dialog.search.placeholder' | translate"
    appInput
    class="search-input"
  >
  <app-form-control-error [control]="searchControl"></app-form-control-error>
</div>
<app-dialog-content
  [class.single-error]="allErrors.length < 2"
  class="results-content"
>
  <div class="relative-wrapper">
    <ul class="errors-list all-errors-list">
      <li
        *ngFor="let item of allErrors"
        class="error-item"
      >
        {{ item }}
      </li>
    </ul>
    <div class="visible-content">
      <ul class="errors-list">
        <li
          *ngFor="let item of errors"
          class="error-item"
        >
          {{ item }}
        </li>
      </ul>
      <div
        [hidden]="searchControl.pristine || errors.length > 0"
        class="no-results-info"
      >
        <i>{{ 'proget_shared.form_control_error.errors_dialog.search.no_results' | translate }}</i>
      </div>
    </div>
  </div>
</app-dialog-content>
<div class="dialog-footer">
  <button
    (click)="close()"
    data-qa="user-errors-dialog-close"
    type="button"
    class="proget-button text-only"
  >
    {{ 'button.close.label' | translate }}
  </button>
</div>

import {
  ApplicationRef,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Clipboard } from '@proget-shared/_common';
import { ShortcutService } from '@proget-shared/helper';
import { BehaviorSubject, combineLatest, distinctUntilChanged, fromEvent, map, merge, Subscription, startWith } from 'rxjs';

import { TooltipCloudComponent } from '../component/tooltip-cloud/tooltip-cloud.component';
import { TooltipPosition } from '../const/tooltip-position.enum';
import { TooltipTriggerEvent } from '../const/tooltip-trigger-event.enum';
import { Rect } from '../type/rect.type';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input()
  public appTooltipWidth: string;
  @Input()
  public appTooltipPosition = TooltipPosition.RIGHT;
  @Input()
  public appTooltipClass = '';
  @Input()
  public appTooltipZIndex: string;
  @Input()
  public appTooltipContext = {};
  @Input()
  public closeOnClick = false;

  private readonly subscription = new Subscription();
  private readonly margin = 20;
  private readonly disabledSubject = new BehaviorSubject<boolean>(false);
  private readonly tooltipEventSubject = new BehaviorSubject<string>(TooltipTriggerEvent.HOVER); // default

  private cloudRef: ComponentRef<TooltipCloudComponent>;
  private value: TemplateRef<object> | string[] = [];
  private _disabled = false;
  private keySubscription = new Subscription();

  constructor(
    private applicationRef: ApplicationRef,
    private shortcutService: ShortcutService,
    elementRef: ElementRef<HTMLElement>
  ) {
    const focus$ = merge(...this.getFocusableElements(elementRef.nativeElement).reduce(
      (streams, element) => streams.concat(
        fromEvent(element, 'focus').pipe(map(() => true)),
        fromEvent(element, 'blur').pipe(map(() => false))
      ),
      []
    ));

    const hover$ = merge(
      fromEvent(elementRef.nativeElement, 'mouseenter').pipe(map(() => true)),
      fromEvent(elementRef.nativeElement, 'mouseleave').pipe(map(() => false)),
      fromEvent(elementRef.nativeElement, 'mousedown').pipe(map(() => false))
    );

    this.subscription.add(
      combineLatest([
        this.disabledSubject,
        this.tooltipEventSubject,
        focus$.pipe(startWith(false)),
        hover$.pipe(startWith(false)),
      ])
        .pipe(
          map(([disabled, event, focus, hover]) => {
            if (disabled) {
              return false;
            }

            switch (event) {
              case TooltipTriggerEvent.FOCUS:
                return focus;
              case TooltipTriggerEvent.HOVER:
                return hover;
              default:
                return false;
            }
          }),
          distinctUntilChanged()
        )
        .subscribe({
          next: (active) => {
            active ? this.createCloud(elementRef.nativeElement) : this.destroyCloud();
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.keySubscription.unsubscribe();
    this.disabledSubject.complete();
    this.tooltipEventSubject.complete();
    this.destroyCloud();
  }

  @Input()
  public set appTooltip(value: string | string[] | TemplateRef<object>) {
    this.value = value instanceof TemplateRef
      ? value
      : (Array.isArray(value) ? value : [value])
          .filter((item) => typeof item === 'string')
          .map((item) => item.trim())
          .filter((item) => item !== '');

    if (this.empty) {
      this.destroyCloud();
    }
  }

  @Input()
  public set appTooltipEvent(event: TooltipTriggerEvent) {
    this.tooltipEventSubject.next(event);
  }

  @Input()
  public set appTooltipDisabled(value: boolean) {
    this._disabled = value;
    this.disabledSubject.next(value);
  }

  public get appTooltipDisabled(): boolean {
    return this._disabled;
  }

  public get empty(): boolean {
    return !(this.value instanceof TemplateRef) && this.value.length === 0;
  }

  @HostListener('click')
  protected onClick(): void {
    if (this.closeOnClick) {
      this.destroyCloud();
    }
  }

  private get documentWidth(): number {
    return document.documentElement.clientWidth || document.body.clientWidth;
  }

  private get documentHeight(): number {
    return document.documentElement.clientHeight || document.body.clientHeight;
  }

  private createCloud(triggerElement: HTMLElement): void {
    this.destroyCloud();

    if (this.empty) {
      return;
    }

    const applicationViewContainerRef = this.applicationRef.components[0].injector.get(ViewContainerRef);
    const cloudRef = applicationViewContainerRef.createComponent(
      TooltipCloudComponent,
      { projectableNodes: [[this.buildCloudBody()]] }
    );

    // style
    const { nativeElement }: ElementRef<HTMLElement> = cloudRef.location;

    if (this.appTooltipClass) {
      nativeElement.classList.add(this.appTooltipClass);
    }

    nativeElement.style.zIndex = this.appTooltipZIndex;

    // copy content
    this.keySubscription.unsubscribe();
    this.keySubscription = this.shortcutService.on('c')
      .subscribe({
        next: (event) => {
          event.preventDefault();

          Clipboard.setValue(nativeElement.innerText);
        },
      });

    // size
    cloudRef.instance.width = Number(this.appTooltipWidth);

    // position
    const {
      bottom: triggerBottom,
      left: triggerLeft,
      right: triggerRight,
      top: triggerTop,
    }: Rect = triggerElement.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    const triggerMiddleH = triggerLeft + (triggerRight - triggerLeft) / 2 + scrollLeft;
    const triggerMiddleV = triggerTop + (triggerBottom - triggerTop) / 2 + scrollTop;

    switch (this.appTooltipPosition) {
      case TooltipPosition.TOP:
        cloudRef.instance.setPosition(TooltipPosition.TOP, triggerMiddleH, this.documentHeight - triggerTop - scrollTop);
        break;
      case TooltipPosition.BOTTOM:
        cloudRef.instance.setPosition(TooltipPosition.BOTTOM, triggerMiddleH, triggerBottom + scrollTop);
        break;
      case TooltipPosition.LEFT:
        cloudRef.instance.setPosition(TooltipPosition.LEFT, this.documentWidth - triggerLeft - scrollLeft, triggerMiddleV);
        break;
      default:
        cloudRef.instance.setPosition(TooltipPosition.RIGHT, triggerRight + scrollLeft, triggerMiddleV);
        break;
    }

    if (this.isOutsideWindow(cloudRef)) {
      switch (this.appTooltipPosition) {
        case TooltipPosition.TOP:
          cloudRef.instance.setPosition(TooltipPosition.BOTTOM, triggerMiddleH, triggerBottom + scrollTop);
          break;
        case TooltipPosition.BOTTOM:
          cloudRef.instance.setPosition(TooltipPosition.TOP, triggerMiddleH, this.documentHeight - triggerTop - scrollTop);
          break;
        case TooltipPosition.LEFT:
          cloudRef.instance.setPosition(TooltipPosition.RIGHT, triggerRight, triggerMiddleV);
          break;
        default:
          cloudRef.instance.setPosition(TooltipPosition.LEFT, this.documentWidth - triggerLeft, triggerMiddleV);
          break;
      }
    }

    // save
    this.cloudRef = cloudRef;
  }

  private isOutsideWindow(cloud: ComponentRef<TooltipCloudComponent>): boolean {
    const {
      bottom: cloudBottom,
      top: cloudTop,
      right: cloudRight,
      left: cloudLeft,
    }: Rect = cloud.location.nativeElement.getBoundingClientRect();

    switch (this.appTooltipPosition) {
      case TooltipPosition.TOP:
        return cloudTop < this.margin;
      case TooltipPosition.BOTTOM:
        return cloudBottom - this.documentHeight + this.margin > 0;
      case TooltipPosition.LEFT:
        return cloudLeft < this.margin;
      default:
        return cloudRight - this.documentWidth + this.margin > 0;
    }
  }

  private buildCloudBody(): Node {
    if (this.value instanceof TemplateRef) {
      const root = document.createElement('div');
      const content = this.value.createEmbeddedView(this.appTooltipContext);

      content.detectChanges();
      content.rootNodes.forEach((node) => {
        root.appendChild(node);
      });

      return root;
    }

    if (this.value.length === 0) {
      throw new Error('Empty tooltip value');
    }

    if (this.value.length === 1) {
      return document.createTextNode(this.value[0]);
    }

    const output = document.createElement('ul');

    output.classList.add('string-list');

    for (const item of this.value) {
      const itemElement = document.createElement('li');

      itemElement.appendChild(document.createTextNode(item));
      output.appendChild(itemElement);
    }

    return output;
  }

  private destroyCloud(): void {
    if (!this.cloudRef) {
      return;
    }

    this.keySubscription.unsubscribe();
    this.applicationRef.detachView(this.cloudRef.hostView);
    this.cloudRef.destroy();
    this.cloudRef = null;
  }

  private getFocusableElements(rootElement: HTMLElement): Element[] {
    const focusableTags: string[] = ['INPUT', 'TEXTAREA', 'SELECT'];

    if (-1 !== focusableTags.indexOf(rootElement.tagName)) {
      return [rootElement];
    }

    return focusableTags
      .map((tagName: string) => Array.from(rootElement.getElementsByTagName(tagName)))
      .reduce((result: Element[], current: Element[]) => result.concat(current), []);
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith, Subscription } from 'rxjs';

@Component({
  selector: 'app-input-length-counter',
  templateUrl: './input-length-counter.component.html',
  styleUrls: ['./input-length-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputLengthCounterComponent implements OnDestroy {
  @HostBinding('class.unknown-max-length')
  protected unknownMaxLength = true;

  private readonly unknownLengthString = '-';

  /*
   * @deprecated Use [control] instead
   */
  @Input()
  private count: number | string = '-';
  @Input()
  /*
   * @deprecated Use [control] instead
   */
  private maxLength: number | string = this.unknownLengthString;
  private countSubscription = new Subscription();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.countSubscription.unsubscribe();
  }

  public get counterString(): string {
    return `${this.count || 0} / ${this.maxLength || 0}`;
  }

  @Input()
  public set control(control: FormControl) {
    this.countSubscription.unsubscribe();

    if (!(control instanceof FormControl)) {
      return;
    }

    this.countSubscription = control.valueChanges
      .pipe(startWith(control.value))
      .subscribe({
        next: (value) => {
          this.count = typeof value === 'string' ? value.length : 0;
          this.maxLength = this.readMaxLength(control);
          this.unknownMaxLength = this.maxLength === this.unknownLengthString;
          this.cdr.detectChanges();
        },
      });
  }

  private readMaxLength(control: FormControl): number | string {
    try {
      const fakeFormControl = { value: { length: Infinity } } as FormControl;
      const validationResult = control.validator(fakeFormControl);
      const maxLength = validationResult.maxlength.requiredLength;

      if (!isFinite(maxLength)) {
        return this.unknownLengthString;
      }

      return maxLength;
    } catch {
      return this.unknownLengthString;
    }
  }
}

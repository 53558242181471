import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { debounceTime, delay, filter, fromEvent, of, Subject, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-animated-height',
  templateUrl: './animated-height.component.html',
  styleUrls: ['./animated-height.component.scss'],
})
export class AnimatedHeightComponent implements AfterViewInit, OnDestroy {
  @Input()
  public observeCharacterData = false;
  @Input()
  public delay = 0;

  private readonly heightChangedEvent = 'heightChanged';
  private readonly subscription = new Subscription();
  private readonly heightSubject = new Subject<`${number}px` | 'auto'>();
  private readonly mutationObserver: MutationObserver;
  private readonly resizeObserver: ResizeObserver;

  @ViewChild('content', { read: ElementRef })
  private contentElement: ElementRef<HTMLElement>;
  private updateHandler = this.updateHeight.bind(this);
  private enabled = true;

  constructor(private element: ElementRef) {
    this.mutationObserver = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'src' &&
          mutation.target instanceof Image
        ) {
          mutation.target.onload = this.updateHandler;
        }
      }

      this.updateHeight();
    });

    this.resizeObserver = new ResizeObserver(() => {
      this.updateHeight();
    });

    this.subscription.add(
      fromEvent(window, 'resize').pipe(debounceTime(100))
        .subscribe(() => {
          this.updateHeight();
        })
    );

    this.subscription.add(
      this.heightSubject.pipe(
        switchMap((value) => (this.delay === 0 || value === 'auto' ? of(value) : of(value).pipe(delay(this.delay))))
      )
        .subscribe({
          next: (height) => {
            this.element.nativeElement.style.height = height;
          },
        })
    );
  }

  ngAfterViewInit(): void {
    const config = {
      attributeFilter: ['class', 'src', 'style'],
      attributes: true,
      characterData: this.observeCharacterData,
      childList: true,
      subtree: true,
    };

    this.mutationObserver.observe(this.contentElement.nativeElement, config);
    this.resizeObserver.observe(this.contentElement.nativeElement);
    this.updateHeight();

    this.subscription.add(
      fromEvent(this.contentElement.nativeElement, this.heightChangedEvent)
        .pipe(
          filter((event) => event.target !== this.contentElement.nativeElement)
        )
        .subscribe((event) => {
          event.stopPropagation();

          this.updateHeight();
        })
    );

    this.subscription.add(
      this.heightSubject.pipe(debounceTime(500))
        .subscribe(() => {
          this.contentElement.nativeElement.dispatchEvent(new Event(this.heightChangedEvent, { bubbles: true }));
        })
    );
  }

  ngOnDestroy(): void {
    this.mutationObserver.disconnect();
    this.resizeObserver.disconnect();
    this.subscription.unsubscribe();
    this.heightSubject.complete();
  }

  @Input()
  public set duration(time: string) {
    this.element.nativeElement.style.transitionDuration = time;
    this.setAnimationEnabled(parseFloat(time) > 0);
  }

  private setAnimationEnabled(enabled: boolean): void {
    this.enabled = enabled;

    if (this.enabled) {
      this.element.nativeElement.classList.remove('animation-disabled');
    } else {
      this.element.nativeElement.classList.add('animation-disabled');
    }

    this.updateHeight();
  }

  private updateHeight(): void {
    if (!this.enabled) {
      this.heightSubject.next('auto');

      return;
    }

    if (this.contentElement) {
      const elementAfterHeight = 1;

      this.heightSubject.next(`${this.contentElement.nativeElement.offsetHeight - elementAfterHeight}px`);
    }
  }
}

<div
  #paginatorContainer
  class="paginator-relative"
>
  <div class="extras-row">
    <div class="empty-flex-element"></div>
    <div
      #extras
      [class.extras-mobile]="extrasMobile"
      class="paginator-extra"
    >
      <span
        [hidden]="!itemsCountVisibility"
        class="paginator-items-count"
      >
        <span>{{ 'proget_shared.grid.paginator.total_items' | translate }}:&nbsp;</span>
        <b>{{ getItemsCountString() }}</b>
      </span>
      <span
        [hidden]="2 > limitsOptions.length"
        class="paginator-limit-options"
      >
        <span
          [hidden]="!itemsCountVisibility"
          class="vertical-separator"
        >
        </span>
        <span>{{ 'proget_shared.grid.paginator.page_limit' | translate }}:</span>
        <app-dropdown
          [formControl]="limitFormControl"
          [options]="limitsOptions"
          data-qa="limit-selector-dropdown"
          class="limit-selector"
        >
        </app-dropdown>
      </span>
    </div>
    <div class="empty-flex-element"></div>
    <div
      [style.width.px]="expanderWidth"
      class="right-expander"
    >
    </div>
  </div>
  <div class="paginator-container">
    <div
      #paginator
      class="paginator"
    >
      <button
        [disabled]="1 === currentPage"
        (click)="loadPage(1)"
        data-qa="paginator-first-page-button"
        type="button"
        class="proget-button icon-only arrow-button paginator-button"
      >
        <i class="icon-double-prev-icon"></i>
      </button>
      <button
        [disabled]="1 === currentPage"
        (click)="loadPage(currentPage - 1)"
        data-qa="paginator-previous-page-button"
        type="button"
        class="proget-button icon-only arrow-button paginator-button"
      >
        <i class="icon-prev-icon"></i>
      </button>
      <button
        *ngFor="let page of pagingPagesList"
        [attr.data-qa]="'paginator-page-' + page + '-button'"
        [class.active]="currentPage === page"
        [disabled]="0 === itemsCount"
        (click)="loadPage(page)"
        type="button"
        class="proget-button paginator-button"
      >
        <span>{{ page }}</span>
      </button>
      <button
        [disabled]="maxPage === currentPage"
        (click)="loadPage(currentPage + 1)"
        data-qa="paginator-next-page-button"
        type="button"
        class="proget-button icon-only arrow-button paginator-button"
      >
        <i class="icon-next-icon"></i>
      </button>
      <button
        [disabled]="maxPage === currentPage"
        (click)="loadPage(maxPage)"
        data-qa="paginator-last-page-button"
        type="button"
        class="proget-button icon-only arrow-button paginator-button"
      >
        <i class="icon-double-next-icon"></i>
      </button>
    </div>
  </div>
</div>

export class CalendarDateTime {
  constructor(
    private value: Date,
    public wholeDay: boolean
  ) {}

  public getValue(): Date {
    return this.value;
  }

  public getDate(): Date {
    return new Date(this.value.getFullYear(), this.value.getMonth(), this.value.getDate());
  }

  public getDateDate(): number {
    return this.value.getDate();
  }

  public getDateMonth(): number {
    return this.value.getMonth();
  }

  public getDateFullYear(): number {
    return this.value.getFullYear();
  }

  public getTimeStart(): Date {
    return this.wholeDay
      ? new Date(this.value.getFullYear(), this.value.getMonth(), this.value.getDate())
      : this.value;
  }

  public getTimeEnd(): Date {
    return this.wholeDay
      ? new Date(this.value.getFullYear(), this.value.getMonth(), this.value.getDate() + 1)
      : this.value;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '@proget-shared/helper';

@Pipe({ name: 'transformCase' })
export class TransformCasePipe implements PipeTransform {
  transform(input: any, from: 'snake' | 'camel' | 'kebab', to: 'snake' | 'camel' | 'kebab'): any {
    if (typeof input !== 'string') {
      return input;
    }

    return StringHelper.transformCase(input, from, to);
  }
}

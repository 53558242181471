import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { QueryParams } from '@proget-shared/_common';
import { PagingList } from '@proget-shared/grid/grid-control';
import {
  delay,
  EMPTY,
  finalize,
  merge,
  mergeMap,
  Observable,
  of,
  reduce,
  ReplaySubject,
  startWith,
  Subject,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { SELECTION_MODE } from '../enum/selection-mode.enum';
import { SelectableItem } from '../interface/selectable-item';
import { SELECTION_PAGE_FETCH_LIMIT } from '../selection-page-fetch-limit.token';

type PageFetch = (pageParams: QueryParams) => Observable<PagingList<SelectableItem>>;

@Injectable()
export class SelectionService implements OnDestroy {
  public readonly pending$: Observable<boolean>;

  private readonly selectionSubject = new Subject<SelectableItem[]>();
  private readonly itemSelectionSubject = new Subject<SelectableItem>();
  private readonly visibleItemsSubject = new ReplaySubject<SelectableItem[]>();
  private readonly visibleItemsSelectionSubject = new Subject<SelectableItem[]>();
  private readonly allSelectedItemsSelectionSubject = new Subject<void>();
  private readonly pendingSubject = new ReplaySubject<boolean>();

  private mode: SELECTION_MODE = SELECTION_MODE.MULTI;
  private selectedItems = new Map<number | string, SelectableItem>();
  private visibleItems: SelectableItem[] = [];
  private selectionFetchLimit: number;
  private pageFetchMethod: PageFetch | undefined;
  private pageFetchParams: QueryParams | Observable<QueryParams> = {};
  private pageFetchSubscription = new Subscription();

  constructor(
    @Inject(SELECTION_PAGE_FETCH_LIMIT)
    @Optional()
      selectionFetchLimit: number
  ) {
    this.selectionFetchLimit = typeof selectionFetchLimit === 'number' ? selectionFetchLimit : 1000;
    this.pending$ = this.pendingSubject.pipe(startWith(false));
  }

  ngOnDestroy(): void {
    this.pageFetchSubscription.unsubscribe();

    this.selectionSubject.complete();
    this.itemSelectionSubject.complete();
    this.visibleItemsSubject.complete();
    this.visibleItemsSelectionSubject.complete();
    this.allSelectedItemsSelectionSubject.complete();
    this.pendingSubject.complete();
  }

  public get selectionChange$(): Observable<SelectableItem[]> {
    return this.selectionSubject as Observable<SelectableItem[]>;
  }

  public get itemSelectionChange$(): Observable<SelectableItem> {
    return this.itemSelectionSubject as Observable<SelectableItem>;
  }

  public get visibleItemsChange$(): Observable<SelectableItem[]> {
    return this.visibleItemsSubject as Observable<SelectableItem[]>;
  }

  public get visibleItemsSelectionChange$(): Observable<SelectableItem[]> {
    return this.visibleItemsSelectionSubject as Observable<SelectableItem[]>;
  }

  public get allSelectedItemsSelectionChange$(): Observable<void> {
    return this.allSelectedItemsSelectionSubject as Observable<void>;
  }

  public get selectAllPagesEnabled(): boolean {
    return typeof this.pageFetchMethod === 'function';
  }

  public get itemsCount(): number {
    return this.selectedItems.size;
  }

  public setMode(mode: SELECTION_MODE): void {
    this.mode = mode;
  }

  public getMode(): SELECTION_MODE {
    return this.mode;
  }

  public setVisibleItems(visibleItems: SelectableItem[]): void {
    this.visibleItems = visibleItems.slice();
    this.visibleItemsSubject.next(visibleItems);

    // update items in selection
    for (const item of visibleItems) {
      const itemId = item.getSelectionId();

      if (this.selectedItems.has(itemId)) {
        this.selectedItems.set(itemId, item);
      }
    }
  }

  public getVisibleItems(): SelectableItem[] {
    return this.visibleItems;
  }

  public isItemSelected(item: SelectableItem): boolean {
    return this.selectedItems.has(item.getSelectionId());
  }

  public isItemVisible(item: SelectableItem): boolean {
    return this.visibleItems.some(
      (selectedItem: SelectableItem): boolean => selectedItem.getSelectionId() === item.getSelectionId()
    );
  }

  public areAllVisibleItemsSelected(): boolean {
    if (0 === this.selectedItems.size || 0 === this.visibleItems.length) {
      return false;
    }

    return this.visibleItems.every((visibleItems: SelectableItem) => this.isItemSelected(visibleItems));
  }

  public isAnyItemSelected(items?: SelectableItem[]): boolean {
    return Array.isArray(items)
      ? items.some((item) => this.isItemSelected(item))
      : 0 !== this.selectedItems.size;
  }

  public selectItem(item: SelectableItem, emitSelectionChange = true): void {
    if (typeof item.getSelectionId !== 'function' || this.isItemSelected(item)) {
      return;
    }

    if (this.mode === SELECTION_MODE.SINGLE) {
      this.selectedItems.clear();
    }

    this.selectedItems.set(item.getSelectionId(), item);

    this.itemSelectionSubject.next(item);

    if (emitSelectionChange) {
      this.selectionSubject.next(this.getSelectionArray());
      this.visibleItemsSelectionSubject.next(this.visibleItems);
    }
  }

  public selectItems(items: SelectableItem[]): void {
    for (const visibleItem of items) {
      this.selectItem(visibleItem, false);
    }

    const selectionArray = this.getSelectionArray();

    this.selectionSubject.next(selectionArray);
    this.visibleItemsSelectionSubject.next(selectionArray);
  }

  public selectAllVisibleItems(): void {
    this.selectItems(this.visibleItems);
  }

  public deselectItem(item: SelectableItem, emitSelectionChange = true): void {
    if (typeof item.getSelectionId !== 'function' || !this.isItemSelected(item)) {
      return;
    }

    this.selectedItems.delete(item.getSelectionId());

    this.itemSelectionSubject.next(item);

    if (emitSelectionChange) {
      this.selectionSubject.next(this.getSelectionArray());
      this.visibleItemsSelectionSubject.next(this.visibleItems);
    }
  }

  public deselectItems(items: SelectableItem[]): void {
    for (const visibleItem of items) {
      this.deselectItem(visibleItem, false);
    }

    this.selectionSubject.next(this.getSelectionArray());
    this.visibleItemsSelectionSubject.next(this.visibleItems);
  }

  public deselectAllVisibleItems(): void {
    this.deselectItems(this.visibleItems);
  }

  public deselectAllItems(): void {
    this.selectedItems.clear();
    this.allSelectedItemsSelectionSubject.next();
    this.selectionSubject.next(this.getSelectionArray());
  }

  public setSelectedItems(items: SelectableItem[]): void {
    this.deselectAllItems();

    if (!(items instanceof Array)) {
      return;
    }

    for (const item of items) {
      this.selectItem(item);
    }
  }

  public getSelectedItems(): SelectableItem[] {
    return this.getSelectionArray();
  }

  public selectAllPages(): Observable<void> {
    if (!this.selectAllPagesEnabled) {
      return EMPTY;
    }

    const onPageSubject = new Subject<PagingList<SelectableItem>>();
    const completeSubject = new ReplaySubject<void>(1);

    this.pendingSubject.next(true);

    this.pageFetchSubscription.unsubscribe();
    this.pageFetchSubscription = (
      this.pageFetchParams instanceof Observable
        ? this.pageFetchParams.pipe(take(1))
        : of(this.pageFetchParams)
    )
      .pipe(
        switchMap((params) => this.fetchPage(0, this.selectionFetchLimit, params)
          .pipe(
            switchMap((firstPage) => {
              const limit = firstPage.limit;
              const pagesToLoad = Math.ceil(firstPage.total / firstPage.limit);
              const firstBatchSize = Math.max(0, Math.min(pagesToLoad - 1, 5));
              const firstBatch = Array.from(Array(firstBatchSize).keys())
                .map((index) => this.fetchPage(index + 1, limit, params));

              if (firstBatchSize === 0) {
                onPageSubject.complete();
              }

              const nextPage$ = onPageSubject.pipe(
                mergeMap((page, index) => {
                  const pageToLoad = index + firstBatchSize + 1;
                  const pageOverLimit = pageToLoad * limit >= page.total;

                  if (pageOverLimit) {
                    onPageSubject.complete();
                  }

                  return pageOverLimit ? EMPTY : this.fetchPage(pageToLoad, limit, params);
                })
              );

              return merge([...firstBatch, nextPage$]).pipe(
                mergeMap((pageRequest) => pageRequest),
                delay(0), // wait for nextPage$ to be subscribed ?!
                tap({
                  next: (page) => {
                    onPageSubject.next(page);
                  },
                }),
                reduce((acc, current) => acc.concat(current.items), firstPage.items)
              );
            })
          )),
        finalize(() => {
          this.pendingSubject.next(false);
          completeSubject.next();
          completeSubject.complete();
        })
      )
      .subscribe({
        next: (items) => {
          this.selectItems(items);
        },
      });

    return completeSubject.asObservable();
  }

  public registerPageFetchMethod(method: PageFetch, params: QueryParams | Observable<QueryParams> = {}): void {
    this.pageFetchMethod = method;
    this.pageFetchParams = params;
  }

  public isSelectableItem(item: any): boolean {
    return typeof item.getSelectionId === 'function'; // instanceof SelectableItem
  }

  public verifySelection(allAvailableItems: SelectableItem[]): void {
    const selectedItemsIds = this.selectedItems.keys();
    const selectedItemsLength = this.selectedItems.size;
    const availableItemsIds = allAvailableItems
      .filter((item) => typeof item.getSelectionId !== 'function')
      .map((item) => item.getSelectionId());

    for (const selectedItemId of selectedItemsIds) {
      if (!availableItemsIds.includes(selectedItemId)) {
        this.deselectItem(this.selectedItems.get(selectedItemId), false);
      }
    }

    if (selectedItemsLength !== this.selectedItems.size) {
      this.selectionSubject.next(this.getSelectionArray());
      this.visibleItemsSelectionSubject.next(this.visibleItems);
    }
  }

  private fetchPage(page: number, limit: number, params: QueryParams): Observable<PagingList<SelectableItem>> {
    const queryParams = Object.assign({}, params, { offset: page * limit, limit, persist: 'true' });

    return this.pageFetchMethod
      ? this.pageFetchMethod(queryParams)
      : of(new PagingList([], limit, page * limit, 0));
  }

  private getSelectionArray(): SelectableItem[] {
    return Array.from(this.selectedItems.values());
  }
}

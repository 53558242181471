import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@proget-shared/translate';
import { TooltipDirective, TooltipTriggerEvent } from '@proget-shared/ui/tooltip';
import { filter, fromEvent, map, Subscription } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input',
  providers: [TooltipDirective],
})
export class CapsLockTooltipDirective implements OnDestroy {
  private subscription = new Subscription();
  private inputType = 'password';

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private tooltipDirective: TooltipDirective,
    private translateService: TranslateService
  ) {
    tooltipDirective.appTooltipEvent = TooltipTriggerEvent.FOCUS;
    tooltipDirective.appTooltipDisabled = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set type(inputType: string) {
    this.elementRef.nativeElement.setAttribute('type', inputType);

    if (inputType === 'password') {
      this.watchPassword();
    } else {
      this.subscription.unsubscribe();
      this.tooltipDirective.appTooltipDisabled = true;
    }
  }

  public get type(): string {
    return this.inputType;
  }

  private watchPassword(): void {
    this.subscription.unsubscribe();
    this.subscription = new Subscription();

    this.subscription.add(
      fromEvent<FocusEvent>(this.elementRef.nativeElement, 'focus')
        .subscribe({
          next: () => {
            this.tooltipDirective.appTooltipDisabled = true;
          },
        })
    );

    this.subscription.add(
      fromEvent<KeyboardEvent>(this.elementRef.nativeElement, 'keyup')
        .pipe(
          filter((event) => typeof event.getModifierState === 'function' &&
            (this.isCapsLock(event) || this.isLetter(event))
          ),
          map((event) => (this.isCapsLock(event)
            // can not catch activating CapsLock on mac,
            // so CapsLock key only disables the tooltip that was previously shown
            ? false
            : event.getModifierState('CapsLock'))
          )
        )
        .subscribe({
          next: (active) => {
            if (active) {
              this.tooltipDirective.appTooltip = this.translateService.instant('proget_shared.caps_lock_tooltip.message');
            }

            this.tooltipDirective.appTooltipDisabled = !active;
          },
        })
    );
  }

  private isCapsLock(event: KeyboardEvent): boolean {
    return event.code === 'CapsLock';
  }

  private isLetter(event: KeyboardEvent): boolean {
    return event.key.length === 1 && event.key.toLowerCase() !== event.key.toUpperCase();
  }
}

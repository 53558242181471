import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })
export class JwtDecoder {
  constructor(private jwtHelperService: JwtHelperService) {}

  public decodeToken(token: string): any {
    if (null === token) {
      return {};
    }

    try {
      return this.jwtHelperService.decodeToken(token);
    } catch {
      return {};
    }
  }
}

import { Component, ElementRef, forwardRef, Input, OnDestroy, Optional, Provider, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { filter, map, noop, Subscription, tap } from 'rxjs';

import { FilterActivatorDirective } from '../../directive/filter-activator.directive';
import { Range } from '../../model/range';
import { FilterRangeNumberConfig } from '../../type/filter-config.type';

const FILTER_RANGE_NUMBER_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FilterRangeNumberComponent),
  multi: true,
};

type FormValue = {
  from: string;
  to: string;
};

@Component({
  selector: 'app-filter-range-number',
  templateUrl: './filter-range-number.component.html',
  styleUrls: ['./filter-range-number.component.scss'],
  providers: [FILTER_RANGE_NUMBER_VALUE_ACCESSOR],
})
export class FilterRangeNumberComponent implements OnDestroy, ControlValueAccessor {
  public readonly rangeFormGroup = new FormGroup({
    from: new FormControl(''),
    to: new FormControl(''),
  });

  protected minValueFrom = -Infinity;
  protected maxValueFrom = Infinity;
  protected minValueTo = -Infinity;
  protected maxValueTo = Infinity;

  private readonly subscription = new Subscription();

  @ViewChild('inputFrom')
  private inputFromElement: ElementRef<HTMLInputElement>;
  private modelTouched: () => void = noop;
  private modelChanged: (value: Range) => void = noop;
  private model = new Range();
  private configValue: FilterRangeNumberConfig;
  private configMin = -Infinity;
  private configMax = Infinity;
  private minDiff = 0;

  constructor(@Optional() filterActivator: FilterActivatorDirective) {
    this.subscription.add(
      this.rangeFormGroup.valueChanges
        .pipe(
          map(({ from, to }) => ({
            from: (from ?? '').toString(),
            to: (to ?? '').toString(),
          })),
          filter((formValue) => formValue.from !== this.model.from || formValue.to !== this.model.to),
          tap({
            next: (formValue) => {
              this.model = new Range(formValue.from, formValue.to);
              this.updateMinMax();
            },
          }),
          filter(() => this.rangeFormGroup.valid)
        )
        .subscribe({
          next: (value) => {
            this.emitValue(value);
          },
        })
    );

    this.subscription.add(
      filterActivator?.activate$.subscribe({
        next: () => {
          this.inputFromElement.nativeElement.focus({ preventScroll: true });
        },
      })
    );
  }

  writeValue(value: Range): void {
    const typedValue: Range = value instanceof Range ? value : new Range(); // prevents null

    this.model = typedValue.clone();

    this.rangeFormGroup.setValue({
      from: this.isNumber(typedValue.from) ? typedValue.from : '',
      to: this.isNumber(typedValue.to) ? typedValue.to : '',
    }, { emitEvent: false });

    this.updateMinMax();
  }

  registerOnChange(fn: (value: Range) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.rangeFormGroup.disable({ emitEvent: false }) : this.rangeFormGroup.enable({ emitEvent: false });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  @Input()
  public set config(value: FilterRangeNumberConfig) {
    const min = Number(value?.min);
    const max = Number(value?.max);
    const minDiff = Number(value?.minDiff);

    this.configValue = value;
    this.configMin = isFinite(min) ? min : -Infinity;
    this.configMax = isFinite(max) ? max : Infinity;
    this.minDiff = isFinite(minDiff) ? Math.max(minDiff, 0) : 0;

    this.updateMinMax();
  }

  public get config(): FilterRangeNumberConfig {
    return this.configValue;
  }

  protected updateMinMax(): void {
    const maxValueFrom = this.getMaxValueFrom();
    const minValueTo = this.getMinValueTo();

    this.rangeFormGroup.get('from').setValidators([
      Validators.min(this.configMin),
      Validators.max(maxValueFrom),
    ]);

    this.rangeFormGroup.get('to').setValidators([
      Validators.min(minValueTo),
      Validators.max(this.configMax),
    ]);

    this.rangeFormGroup.get('from').updateValueAndValidity({ emitEvent: false });
    this.rangeFormGroup.get('to').updateValueAndValidity({ emitEvent: false });

    // disable min and/or max if field is not valid
    const fromErrors = this.rangeFormGroup.get('from').errors || {};

    this.minValueFrom = fromErrors.hasOwnProperty('min') ? -Infinity : this.configMin;
    this.maxValueFrom = fromErrors.hasOwnProperty('max') ? Infinity : maxValueFrom;

    const toErrors = this.rangeFormGroup.get('to').errors || {};

    this.minValueTo = toErrors.hasOwnProperty('min') ? -Infinity : minValueTo;
    this.maxValueTo = toErrors.hasOwnProperty('max') ? Infinity : this.configMax;
  }

  private getMaxValueFrom(): number {
    const fieldValue: any = this.rangeFormGroup.get('to').value;
    const fieldMin: number = this.isNumber(fieldValue) ? Number(fieldValue) : Infinity;

    return Math.min(fieldMin - this.minDiff, this.configMax);
  }

  private getMinValueTo(): number {
    const fieldValue: any = this.rangeFormGroup.get('from').value;
    const fieldMax: number = this.isNumber(fieldValue) ? Number(fieldValue) : -Infinity;

    return Math.max(fieldMax + this.minDiff, this.configMin);
  }

  private emitValue(value: FormValue): void {
    this.modelTouched();
    this.modelChanged(new Range(value.from, value.to));
  }

  private isNumber(value: any): boolean {
    return value !== '' && value !== null && isFinite(Number(value));
  }
}

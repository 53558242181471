<app-header
  *appIsUser
  [class.slim-menu]="isSlimMenu"
></app-header>
<div
  [appUserClass]="['is-footer']"
  class="wrapper"
>
  <div
    *appIsUser
    class="sidebar"
  >
    <app-image-contain
      [hidden]="isSlimMenu"
      alt="Proget logo"
      src="assets/images/logo.png"
      class="menu-logo"
    ></app-image-contain>
    <app-menu
      [class.full-menu]="!isSlimMenu"
      [class.slim-menu]="isSlimMenu"
      class="menu-buttons"
    ></app-menu>
    <div
      (click)="toggleSidebar()"
      data-qa="toggle-menu-button"
      class="toggle-menu"
    >
      <i [ngClass]="toggleMenuButton.icon">
      </i>
      <span *ngIf="!isSlimMenu">
        {{ toggleMenuButton.name | translate }}
      </span>
    </div>
  </div>
  <app-scrollbar
    #scrollbar
    (afterInit)="updateScrollbarHeight()"
    appApplicationScrollbar
    viewClass="global-scrollbar"
  >
    <div
      [appUserClass]="['is-sidebar', 'is-header']"
      class="main"
    >
      <router-outlet></router-outlet>
    </div>
    <div
      *appIsUser
      class="footer"
    >
      <app-footer></app-footer>
    </div>
  </app-scrollbar>
</div>
<app-toast-container></app-toast-container>
<app-dialog-container></app-dialog-container>

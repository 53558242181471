import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PermissionService } from '@proget-shared/_local';
import { SelectionService } from '@proget-shared/grid/selection';
import { ToastService } from '@proget-shared/ui/toast';
import {
  combineLatest,
  distinctUntilKeyChanged,
  finalize,
  Subject,
  Subscription,
} from 'rxjs';

import { ActionAppearance } from '../../enum/action-appearance.enum';
import { GridActionType } from '../../enum/grid-action-type.enum';
import { SelectionStrategy } from '../../enum/selection-strategy.enum';
import { GridActionsService } from '../../grid-actions.service';
import { GridAction } from '../../type/grid-action.type';

@Component({
  selector: 'app-grid-actions',
  templateUrl: './grid-actions.component.html',
  styleUrls: ['./grid-actions.component.scss'],
  providers: [GridActionsService],
})
export class GridActionsComponent implements OnDestroy {
  @Input()
  public selectionInfo = true;
  @Input()
  public forceSelectionMenu = false;
  @Input()
  public customSelection: SelectionService;

  protected readonly actionForm: FormControl = new FormControl('');

  protected permittedActions: GridAction[] = [];

  private readonly actionsSubject = new Subject<GridAction[]>();
  private readonly subscription = new Subscription();
  @Input()
  private items: any[];
  @Output()
  private clear = new EventEmitter<any[]>();
  private _visibleActions: GridAction[] = [];

  constructor(
    permissionService: PermissionService,
    private toastService: ToastService,
    private gridActionsService: GridActionsService
  ) {
    this.subscription.add(
      combineLatest([this.actionsSubject, permissionService.permissions$]).subscribe({
        next: ([actions]) => {
          this.permittedActions = actions
            .filter((action) => action.appearance !== ActionAppearance.ITEM_ONLY &&
              permissionService.hasPermissionInstant(action.permission)
            );
          this.updateVisibleActions();
        },
      })
    );

    this.subscription.add(
      this.actionForm.valueChanges.subscribe({
        next: (action) => {
          this.performAction(action);
          this.actionForm.reset('', { emitEvent: false });
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get visibleActions(): GridAction[] {
    return this._visibleActions;
  }

  @Input()
  public set actions(actions: GridAction[]) {
    this.actionsSubject.next(actions);
  }

  public get itemsCount(): number {
    return this.items instanceof Array ? this.items.length : 0;
  }

  protected getQaAttribute(action: GridAction): string {
    return `action-${action.label.replace(/\./g, '-')}`;
  }

  protected isActionEnabled(action: GridAction): boolean {
    if (0 === this.itemsCount) {
      return false;
    }

    if (
      (GridActionType.ACTION_SINGLE === action.type ||
        GridActionType.DISPLAY_SINGLE === action.type) &&
      this.items.length !== 1
    ) {
      return false;
    }

    if ('function' !== typeof action.enabled) {
      return true;
    }

    for (const item of this.items) {
      if (action.enabled(item)) {
        return true;
      }
    }

    return false;
  }

  protected performAction(action: GridAction): void {
    if (0 === this.itemsCount || !this.isActionEnabled(action)) {
      return;
    }

    const actionItems: any[] = this.items.filter(
      (item: any) => !('function' === typeof action.enabled) || action.enabled(item)
    );

    if ('function' === typeof action.start?.callback) {
      action.start.callback();
    }

    this.gridActionsService.getActionStream(action, actionItems)
      .pipe(
        distinctUntilKeyChanged('partial'), // prevent deselecting other partial results if one occured
        finalize(() => {
          const actionFinalize: any = action.finalize || {};

          if ('function' === typeof actionFinalize.callback) {
            actionFinalize.callback();
          }
        })
      )
      .subscribe({
        next: (result) => {
          const defaultSelectionStrategy = action.finalize?.selectionStrategy ?? SelectionStrategy.DESELECT_AFTER_GRID_ACTION;
          const actionSuccess = Object.assign(
            { selectionStrategy: defaultSelectionStrategy },
            action.success || {}
          );

          if ([SelectionStrategy.ALWAYS_DESELECT, SelectionStrategy.DESELECT_AFTER_GRID_ACTION].includes(actionSuccess.selectionStrategy)) {
            this.clear.emit(actionItems);
          }

          if (result?.partial) {
            return;
          }

          if ('string' === typeof actionSuccess.toast) {
            this.toastService.success({
              key: actionSuccess.toast,
              params: this.gridActionsService.resolveParams(actionSuccess.toastParams, actionItems),
            });
          }

          if (actionItems.length < this.itemsCount) {
            this.toastService.info({
              key: 'proget_shared.grid.actions.partial_action_warning',
              params: { performed: actionItems.length, total: this.itemsCount },
            });
          }

          if ('function' === typeof actionSuccess.callback) {
            actionSuccess.callback(result, actionItems);
          }

          this.gridActionsService.closePendingPrompt();
        },
        error: () => {
          const defaultSelectionStrategy = action.finalize?.selectionStrategy ?? SelectionStrategy.KEEP_SELECTION;
          const actionError = Object.assign(
            { selectionStrategy: defaultSelectionStrategy },
            action.error || {}
          );

          if ([SelectionStrategy.ALWAYS_DESELECT, SelectionStrategy.DESELECT_AFTER_GRID_ACTION].includes(actionError.selectionStrategy)) {
            this.clear.emit(actionItems);
          }
        },
      });
  }

  private updateVisibleActions(): void {
    this._visibleActions = this.permittedActions.filter(
      (action: GridAction) => 'function' !== typeof action.visibility || action.visibility()
    );
  }
}

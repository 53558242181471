import { ProviderToken } from '@angular/core';

import { ChildDirective } from '../directive/child.directive';

export class ChildrenService {
  private readonly children: ChildDirective[] = [];

  public register(child: ChildDirective): void {
    this.children.push(child);
  }

  public get<T>(token: ProviderToken<T>): T {
    for (const child of this.children) {
      const matchingInstance = child.inject(token);

      if (matchingInstance) {
        return matchingInstance;
      }
    }

    return null;
  }
}

import { Inject, Injectable } from '@angular/core';

import { StorageWrapper } from '../class/storage-wrapper.class';
import { WindowStorage } from '../type/window-storage.type';
import { WindowStorageToken } from '../window-storage.token';

@Injectable({ providedIn: 'root' })
export class LocalStorageService extends StorageWrapper {
  constructor(@Inject(WindowStorageToken) window: WindowStorage) {
    super(window.localStorage);
  }
}

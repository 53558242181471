import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDateTime',
})
export class UtcDateTimePipe implements PipeTransform {
  transform(value: string | Date): string {
    return null === value || undefined === value
      ? ''
      : this.getDateTime(value instanceof Date ? value : new Date(value));
  }

  private getDateTime(date: Date): string {
    return `${date.toISOString().slice(0, 10)} ${date.toISOString().slice(11, 19)}`;
  }
}

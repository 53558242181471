import { createComponent, EnvironmentInjector, Injectable, Type } from '@angular/core';
import { TranslateService, Translation } from '@proget-shared/translate';
import { delay, filter, forkJoin, merge, NEVER, of, timer, take, tap } from 'rxjs';

import { BasicToastComponent } from '../component/basic-toast/basic-toast.component';
import { ToastWrapperComponent } from '../component/toast-wrapper/toast-wrapper.component';
import { Toast } from '../interface/toast.interface';
import { BasicToastOptions } from '../type/basic-toast-options.type';
import { ToastOptions } from '../type/toast-options.type';

import { ToastContainerService } from './toast-container.service';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(
    private toastContainerService: ToastContainerService,
    private translate: TranslateService,
    private environment: EnvironmentInjector
  ) {}

  public info(
    title: string | Translation,
    details?: string | Translation,
    options?: Partial<BasicToastOptions>
  ): void {
    this.displayBasicToast(
      title,
      details,
      Object.assign(
        { class: 'toast-info' } as BasicToastOptions,
        options
      )
    );
  }

  public success(
    title: string | Translation,
    details?: string | Translation,
    options?: Partial<BasicToastOptions>
  ): void {
    this.displayBasicToast(
      title,
      details,
      Object.assign(
        { class: 'toast-success' } as BasicToastOptions,
        options
      )
    );
  }

  public error(
    title: string | Translation,
    details?: string | Translation,
    options?: Partial<BasicToastOptions>
  ): void {
    this.displayBasicToast(
      title,
      details,
      Object.assign(
        { class: 'toast-error', closable: true, duration: 20000 } as BasicToastOptions,
        options
      )
    );
  }

  public custom<T>(component: Type<T>, options?: Partial<ToastOptions>): T {
    const mergedOptions: ToastOptions = Object.assign(
      {
        class: '',
        closable: true,
        duration: 5000,
        icon: '',
        tags: [],
        unique: false,
      } as ToastOptions,
      options
    );

    const wrapperRef = createComponent<ToastWrapperComponent<T>>(ToastWrapperComponent, { environmentInjector: this.environment });

    this.toastContainerService.display(wrapperRef);

    wrapperRef.instance.closable = mergedOptions.closable;
    wrapperRef.instance.icon = mergedOptions.icon;

    if (mergedOptions.class) {
      wrapperRef.location.nativeElement.classList.add(mergedOptions.class);
    }

    const toastRef = wrapperRef.instance.createToast(component);
    const toast = toastRef.instance as Toast;

    if (typeof toast.setOptions === 'function') {
      toast.setOptions(mergedOptions);
    }

    toastRef.location.nativeElement.classList.add('toast-component');

    if (mergedOptions.unique) {
      this.closeByTag(mergedOptions.tags);
    }

    merge(
      this.toastContainerService.closeAll$,
      this.toastContainerService.closeByTag$.pipe(filter((tag) => mergedOptions.tags.indexOf(tag) !== -1)),
      this.toastContainerService.closeByInstance$.pipe(filter((toastComponent) => toastComponent === toastRef.instance)),
      wrapperRef.instance.close$,
      mergedOptions.duration > 0 ? timer(mergedOptions.duration) : NEVER
    )
      .pipe(
        take(1),
        tap({
          next: () => {
            const wrapperElement = wrapperRef.location.nativeElement as HTMLElement;

            wrapperElement.classList.add('destroyed');
            wrapperElement.style.marginTop = `-${wrapperElement.offsetHeight}px`;
          },
        }),
        delay(700)
      )
      .subscribe({
        next: () => {
          wrapperRef.destroy();
        },
      });

    return toastRef.instance;
  }

  public closeAll(): void {
    this.toastContainerService.closeAll();
  }

  public closeByTag(tags: string | string[]): void {
    const tagsArray = typeof tags === 'string' ? [tags] : tags;

    for (const tag of tagsArray) {
      this.toastContainerService.closeByTag(tag);
    }
  }

  public closeByInstance(toastComponent: any): void {
    this.toastContainerService.closeByInstance(toastComponent);
  }

  private displayBasicToast(
    title: string | Translation,
    details?: string | Translation,
    options?: Partial<BasicToastOptions>
  ): BasicToastComponent {
    const mergedOptions = Object.assign({ translate: true, html: true } as BasicToastOptions, options);
    const toast = this.custom(BasicToastComponent, mergedOptions);
    const linkLabel = mergedOptions.link?.label || '';

    toast.linkRoute = mergedOptions.link?.route;

    forkJoin([
      mergedOptions.translate ? this.translate.getTranslatedText(title) : of(title.toString()),
      mergedOptions.translate ? this.translate.getTranslatedText(details) : of(details.toString()),
      mergedOptions.translate ? this.translate.getTranslatedText(linkLabel) : of(linkLabel.toString()),
    ]).subscribe({
      next: ([translatedTitle, translatedDetails, translatedLinkLabel]) => {
        toast.title = translatedTitle;
        toast.details = translatedDetails;
        toast.linkLabel = translatedLinkLabel;
      },
    });

    return toast;
  }
}

import { Directive, ElementRef, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'button[submitButton]',
})
export class SubmitButtonDirective {
  private abstractControl: AbstractControl;

  constructor(elementRef: ElementRef<HTMLInputElement>) {
    elementRef.nativeElement.addEventListener('click', this.verifyClick.bind(this), true);
  }

  @Input()
  public set submitButton(abstractControl: AbstractControl) {
    this.abstractControl = abstractControl;
  }

  private verifyClick(event: MouseEvent): void {
    this.abstractControl?.markAsUntouched(); // force emit touched event
    this.abstractControl?.markAllAsTouched();

    if (this.abstractControl?.invalid) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  ViewEncapsulation,
} from '@angular/core';
import { ConfigurableDialog, DialogService } from '@proget-shared/dialog';

import { AlertConfiguration } from '../../interface/alert-configuration.interface';

@Component({
  selector: 'app-dialog-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements ConfigurableDialog {
  protected header = '';
  protected messageHtml = '';
  protected okLabel = '';
  protected okButtonClass = '';
  protected width: number | undefined;
  protected iconClass = '';

  constructor(
    private dialog: DialogService,
    private cdr: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    elementRef.nativeElement.classList.add('proget-dialog-alert');
  }

  onConfiguration(configuration: AlertConfiguration): void {
    if (configuration.hasOwnProperty('header')) {
      this.header = configuration.header;
    }

    if (configuration.hasOwnProperty('message')) {
      this.messageHtml = configuration.message;
    }

    if (configuration.hasOwnProperty('okLabel')) {
      this.okLabel = configuration.okLabel;
    }

    if (configuration.hasOwnProperty('okButtonClass') && typeof configuration.okButtonClass === 'string') {
      this.okButtonClass = configuration.okButtonClass;
    }

    if (configuration.hasOwnProperty('iconClass') && typeof configuration.iconClass === 'string') {
      this.iconClass = configuration.iconClass;
    }

    this.width = configuration.width;
    this.cdr.markForCheck();
  }

  @HostListener('window:keydown.enter')
  protected close(): void {
    this.dialog.resolve(this);
  }
}

<app-animated-height
  *ngIf="display === 'inline' else tooltip"
  [duration]="animationEnabled ? '300ms' : '0ms'"
  [delay]="visibleErrors.length > 0 ? 300 : 0"
>
  <ul
    [class.errors-hidden]="hidden"
    [class.shorten-list]="moreErrorsAvailable"
    (click)="showAllErrors()"
    class="errors-list"
  >
    <li
      *ngFor="let error of visibleErrors"
      class="error-item"
    >
      {{ error }}
    </li>
  </ul>
</app-animated-height>
<ng-template #tooltip>
  <i
    (click)="showAllErrors()"
    [appTooltip]="tooltipErrors"
    [hidden]="tooltipErrors === '' || hidden"
    [style.cursor]="moreErrorsAvailable ? 'pointer' : 'default'"
    class="icon-dangerous-icon error-tooltip"
  ></i>
</ng-template>

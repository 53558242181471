import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateHelper } from '@proget-shared/helper';

import { CalendarDateTime } from '../../model/calendar-date-time.model';
import { CalendarRange } from '../../model/calendar-range.model';
import { CalendarService } from '../../service/calendar.service';
import { SelectionLevel } from '../date-selector/selection-level.enum';

import { DayDetails } from './day-details.type';

@Component({
  selector: 'app-month-display',
  templateUrl: './month-display.component.html',
  styleUrls: ['./month-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthDisplayComponent {
  @Input()
  public firstWeekDay = 1;
  @Input()
  public minMonth: Date = null;
  @Input()
  public maxMonth: Date = null;
  @Input()
  public dayShortLabelKey = 'calendar.day_names_short';
  @Input()
  public monthLabelKey = 'calendar.month_names';
  @Input()
  public disabled = false;
  @Output()
  public dayClick = new EventEmitter<{ date: Date }>();
  @Output()
  public dayHover = new EventEmitter<{ date: Date }>();
  @Output()
  public display = new EventEmitter<{ month: Date }>();

  public currentDisplayMonth: Date | null = null;
  public weeks: DayDetails[][] = [];
  public monthIndex = 0;

  protected readonly headerIndexes = Array.from(new Array(7).keys());
  protected readonly SelectionLevel = SelectionLevel;

  private selectionStart: Date | null = null;
  private selectionEnd: Date | null = null;
  private preselectionStart: Date | null = null;
  private preselectionEnd: Date | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private calendarService: CalendarService
  ) {}

  public get preselectionVisibility(): boolean {
    return !!(this.preselectionStart || this.preselectionEnd);
  }

  public selectDay(day: DayDetails): void {
    if (this.disabled || !day?.isSelectable) {
      return;
    }

    this.dayClick.emit({ date: day.date });
  }

  public preselectDay(day: DayDetails): void {
    if (this.disabled) {
      return;
    }

    this.dayHover.emit({ date: day?.isSelectable ? day.date : null });
  }

  public setPreselection(value: CalendarDateTime | CalendarRange): void {
    const { start, end } = this.parseSelectionValue(value);

    this.preselectionStart = start;
    this.preselectionEnd = end;

    this.updateDetails();
  }

  public setSelection(value: CalendarDateTime | CalendarRange): void {
    const { start, end } = this.parseSelectionValue(value);

    this.selectionStart = start;
    this.selectionEnd = end;

    this.updateDetails();
  }

  public displayDate(value: Date): void {
    if (!DateHelper.isValidDate(value)) {
      return;
    }

    const valueMonth = new Date(value.getFullYear(), value.getMonth());

    if (this.currentDisplayMonth && valueMonth.getTime() === this.currentDisplayMonth.getTime()) {
      return;
    }

    this.currentDisplayMonth = valueMonth;
    this.display.emit({ month: this.currentDisplayMonth });

    const monthFirstDate = new Date(value.getFullYear(), value.getMonth());
    const monthDaysCount = new Date(value.getFullYear(), value.getMonth() + 1, -1).getDate() + 1;
    const firstWeekOffset = (monthFirstDate.getDay() + 7 - this.firstWeekDay) % 7;
    const weeksCount = Math.ceil((firstWeekOffset + monthDaysCount) / 7);

    this.monthIndex = value.getMonth();
    this.weeks = Array.from(new Array(weeksCount).keys()).map((weekIndex) => Array.from(new Array(7).keys())
      .map((dayIndex) => new Date(value.getFullYear(), value.getMonth(), weekIndex * 7 + dayIndex - firstWeekOffset + 1))
      .map((date): DayDetails => ({
        date,
        isCurrentMonth: date.getMonth() === value.getMonth(),
        isSelected: false,
        isInPreselection: false,
        isFirstPreselectionDay: false,
        isLastPreselectionDay: false,
        isInSelection: false,
        isFirstSelectionDay: false,
        isLastSelectionDay: false,
        isToday: false,
        isSelectable: this.calendarService.isDayInRange(date),
      }))
      .filter((day) => day.isCurrentMonth)
    );

    this.updateDetails();
  }

  private getSelectionStart(start: Date, end: Date): number {
    return start
      ? DateHelper.getDayStart(start).getTime()
      : end ? -Infinity : Infinity;
  }

  private getSelectionEnd(start: Date, end: Date): number {
    return end
      ? DateHelper.getDayStart(end).getTime()
      : start ? Infinity : -Infinity;
  }

  private updateDetails(): void {
    const todayTimestamp = DateHelper.getDayStart(new Date()).getTime();
    const selectedDaysTimestamps = [this.selectionStart, this.selectionEnd]
      .filter((day) => !!day)
      .map((day) => DateHelper.getDayStart(day).getTime());

    const preselectionStartTimestamp = this.getSelectionStart(this.preselectionStart, this.preselectionEnd);
    const preselectionEndTimestamp = this.getSelectionEnd(this.preselectionStart, this.preselectionEnd);
    const selectionStartTimestamp = this.getSelectionStart(this.selectionStart, this.selectionEnd);
    const selectionEndTimestamp = this.getSelectionEnd(this.selectionStart, this.selectionEnd);

    for (let i = 0; i < this.weeks.length; i++) {
      for (let j = 0; j < this.weeks[i].length; j++) {
        const day = this.weeks[i][j];
        const timestamp = day.date.getTime();

        this.weeks[i][j] = Object.assign(day, {
          isSelected: selectedDaysTimestamps.indexOf(timestamp) !== -1,
          // preselection
          isInPreselection: timestamp >= preselectionStartTimestamp && timestamp <= preselectionEndTimestamp,
          isFirstPreselectionDay: timestamp === preselectionStartTimestamp,
          isLastPreselectionDay: timestamp === preselectionEndTimestamp,
          // selection
          isInSelection: timestamp >= selectionStartTimestamp && timestamp <= selectionEndTimestamp,
          isFirstSelectionDay: timestamp === selectionStartTimestamp,
          isLastSelectionDay: timestamp === selectionEndTimestamp,
          isToday: timestamp === todayTimestamp,
        });
      }
    }

    this.cdr.detectChanges();
  }

  private parseSelectionValue(value: CalendarDateTime | CalendarRange): { start: Date | null; end: Date | null } {
    if (value instanceof CalendarDateTime) {
      return { start: value.getDate(), end: value.getDate() };
    }

    if (value instanceof CalendarRange) {
      return {
        start: value.from?.getDate() ?? null,
        end: value.to?.getDate() ?? null,
      };
    }

    return { start: null, end: null };
  }
}

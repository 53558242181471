import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DialogsCountService {
  private staticDialogsCountSubject = new BehaviorSubject<number>(0);
  private floatingDialogsCountSubject = new BehaviorSubject<number>(0);
  private staticDialogsCount = 0;
  private floatingDialogsCount = 0;

  public get staticDialogsCount$(): Observable<number> {
    return this.staticDialogsCountSubject.pipe(
      tap({
        next: (count) => {
          this.staticDialogsCount = count;
        },
      })
    );
  }

  public get floatingDialogsCount$(): Observable<number> {
    return this.floatingDialogsCountSubject.pipe(
      tap({
        next: (count) => {
          this.floatingDialogsCount = count;
        },
      })
    );
  }

  public get allDialogsCount$(): Observable<number> {
    return combineLatest([
      this.staticDialogsCountSubject,
      this.floatingDialogsCountSubject,
    ]).pipe(
      map(([staticDialogs, floatingDialogs]) => staticDialogs + floatingDialogs)
    );
  }

  public addStaticDialog(): void {
    this.staticDialogsCountSubject.next(this.staticDialogsCount + 1);
  }

  public removeStaticDialog(): void {
    this.staticDialogsCountSubject.next(this.staticDialogsCount - 1);
  }

  public addFloatingDialog(): void {
    this.floatingDialogsCountSubject.next(this.floatingDialogsCount + 1);
  }

  public removeFloatingDialog(): void {
    this.floatingDialogsCountSubject.next(this.floatingDialogsCount - 1);
  }
}

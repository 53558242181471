import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '@core/service';
import { noop, Subscription } from 'rxjs';

@Directive({
  selector: '[appIsUser]',
})
export class IsUserDirective implements OnInit, OnDestroy {
  private isVisible = false;
  private userServiceOnChangeSubscription = new Subscription();

  constructor(
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userServiceOnChangeSubscription = this.userService.onChange.subscribe({
      next: (user) => {
        this.updateVisibility(user.loggedIn);
      },
      error: noop,
    });
  }

  ngOnDestroy(): void {
    this.userServiceOnChangeSubscription.unsubscribe();
  }

  private updateVisibility(loggedIn: boolean): void {
    if (loggedIn === this.isVisible) {
      return;
    }

    loggedIn ? this.viewContainerRef.createEmbeddedView(this.templateRef) : this.viewContainerRef.clear();

    this.isVisible = loggedIn;
  }
}

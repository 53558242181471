import { Component, Optional, Self } from '@angular/core';

import { TooltipDirective } from '../../directive/tooltip.directive';

@Component({
  selector: 'app-tooltip-icon',
  templateUrl: './tooltip-icon.component.html',
  styleUrls: ['./tooltip-icon.component.scss'],
})
export class TooltipIconComponent {
  constructor(@Optional() @Self() private tooltip: TooltipDirective) {}

  public isInactiveTooltip(): boolean {
    return this.tooltip && (this.tooltip.empty || this.tooltip.appTooltipDisabled);
  }
}

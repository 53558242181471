<div
  #panel
  [appOverlayPanel]="dropdownPanel"
  [attr.tabindex]="tabindex"
  [class.empty-autocomplete]="emptyAutocomplete"
  [class.multiselect]="multiple"
  [class.panel-visible]="panelOpened && !panelHidden"
  [class.disabled]="disabled"
  [class.has-clear-button]="clearButtonVisibility"
  [class.input-enabled]="autocompleteInputVisibility"
  [class.readonly]="readonly"
  [overlayPanelClass]="panelHidden ? 'panel-hidden' : ''"
  [overlayPanelData]="panelData"
  [overlayPanelEnabled]="!disabled && !readonly"
  [overlayPanelOptions]="{ target: target, matchWidth: matchPanelWidth, padding: 0, closeOnScroll: closeOnScroll, scrollContent: false, referer: panelReferer }"
  (overlayPanelStatus)="panelStatusChange($event)"
  class="dropdown"
>
  <div class="dropdown-text">
    <input
      #autocompleteInput
      [formControl]="autocompleteForm"
      (keydown)="stopKeysEventPropagation($event)"
      (mousedown)="$event.stopPropagation()"
      autocomplete="new-password"
      tabindex="-1"
      class="autocomplete-input"
    >
    <app-ellipsis
      [hidden]="!placeholderVisibility"
      [matchColor]="true"
      class="placeholder"
    >
      <span>{{ placeholder }}</span>
    </app-ellipsis>
    <app-ellipsis class="selection-label">
      <span
        *ngIf="!isGroupSelected else selectedGroupLabel"
        class="selected-items"
      >
        <span
          *ngFor="let item of selectedItems"
          class="selected-item"
        >
          <ng-container
            [ngTemplateOutlet]="optionTemplate || defaultItemLabel"
            [ngTemplateOutletContext]="{ $implicit: item.source, index: item.index, selected: true }"
          ></ng-container>
        </span>
      </span>
    </app-ellipsis>
  </div>
  <span
    (click)="clear()"
    (focus)="$event.preventDefault()"
    (mousedown)="$event.stopPropagation()"
    tabindex="-1"
    class="clear-button"
  ></span>
  <span class="arrow"></span>
</div>

<ng-template
  #dropdownPanel
  let-data
>
  <app-dropdown-panel
    [class]="panelClass"
    [configuration]="data"
    [optionsGroup]="data.optionsGroup"
    [selectedItems]="data.selectedItems"
    [spinner]="data.pending"
    (close)="closePanel()"
    (selectedItemsChange)="emitSelectedItems($event)"
  ></app-dropdown-panel>
</ng-template>

<ng-template
  #defaultItemLabel
  let-item
>
  <span>{{ translationRequired(item) ? (getItemLabel(item) | translate) : getItemLabel(item) }}</span>
</ng-template>

<ng-template
  #defaultGroupLabel
  let-item
>
  <b>{{ translate ? (item | translate) : item }}</b>
</ng-template>

<ng-template #selectedGroupLabel>
  <span>{{ 'proget_shared.dropdown.selected_items' | translate: { count: this.selectedItems.length } }}</span>
</ng-template>

import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigurableDialog, DialogService } from '@proget-shared/dialog';

import { PhraseConfirmConfiguration } from '../../interface/phrase-confirm-configuration.interface';

@Component({
  selector: 'app-dialog-phrase-confirm',
  templateUrl: './phrase-confirm.component.html',
  styleUrls: ['./phrase-confirm.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'proget-dialog-phrase-confirm',
  },
})
export class PhraseConfirmComponent implements ConfigurableDialog {
  public header = '';
  public phrase = '';
  public messageHtml = '';
  public phraseMessageHtml = '';
  public cancelLabel = '';
  public okLabel = '';
  public cancelButtonClass = '';
  public okButtonClass = '';
  public width: number | undefined;
  public iconClass = '';

  protected readonly promptForm = new FormGroup({ promptInput: new FormControl('', Validators.required) });

  constructor(
    private dialog: DialogService,
    private cdr: ChangeDetectorRef
  ) {}

  onConfiguration(configuration: PhraseConfirmConfiguration): void {
    this.phrase = configuration.phrase;

    this.promptForm.get('promptInput').setValidators((control) => (control.value === this.phrase
      ? null
      : { phrase: { current: control.value, required: this.phrase } })
    );

    if (configuration.hasOwnProperty('header')) {
      this.header = configuration.header;
    }

    if (configuration.hasOwnProperty('message')) {
      this.messageHtml = configuration.message;
    }

    if (configuration.hasOwnProperty('phraseMessage')) {
      this.phraseMessageHtml = configuration.phraseMessage;
    }

    if (configuration.hasOwnProperty('cancelLabel')) {
      this.cancelLabel = configuration.cancelLabel;
    }

    if (configuration.hasOwnProperty('okLabel')) {
      this.okLabel = configuration.okLabel;
    }

    if (configuration.hasOwnProperty('cancelButtonClass') && typeof configuration.cancelButtonClass === 'string') {
      this.cancelButtonClass = configuration.cancelButtonClass;
    }

    if (configuration.hasOwnProperty('okButtonClass') && typeof configuration.okButtonClass === 'string') {
      this.okButtonClass = configuration.okButtonClass;
    }

    if (configuration.hasOwnProperty('iconClass') && typeof configuration.iconClass === 'string') {
      this.iconClass = configuration.iconClass;
    }

    this.width = configuration.width;
    this.cdr.markForCheck();
  }

  protected cancel(): void {
    this.dialog.reject(this);
  }

  protected submit(): void {
    if (!this.promptForm.valid) {
      this.promptForm.markAllAsTouched();

      return;
    }

    this.dialog.resolve(this);
  }
}

<app-dialog-header [header]="headerKey | translate"></app-dialog-header>
<app-dialog-content>
  <div class="p-grid">
    <div class="p-col-12">
      <app-progress-bar [progress]="getPercentageProgress()"></app-progress-bar>
    </div>
    <div class="reports-container p-col-12">
      <ng-container #reports></ng-container>
    </div>
  </div>
</app-dialog-content>
<div class="dialog-footer">
  <div class="button-container">
    <button
      [disabled]="isCloseDisabled()"
      (click)="close()"
      data-qa="work-progress-dialog-close-button"
      type="button"
      class="proget-button text-only"
    >
      {{ 'proget_shared.batch_work.close.label' | translate }}
    </button>
  </div>
</div>

<ng-template
  #reportEntry
  let-report
>
  <div
    data-qa="work-report"
    class="p-grid p-align-center work-report"
  >
    <div class="p-col-4 wrap-text">
      {{ report.id }}
    </div>
    <div class="p-col-4">
      {{ report.action | translate }}
    </div>
    <div
      [hidden]="report.errorObject && errorsDialog"
      [innerHTML]="report.result | translate"
      class="p-col-4 wrap-text"
    >
    </div>
    <div
      [hidden]="!(report.errorObject && errorsDialog)"
      (click)="openErrorDetails(report)"
      class="p-col-4 wrap-text display-errors-trigger"
    >
      {{ 'proget_shared.batch_work.display_errors.label' | translate }}
    </div>
  </div>
</ng-template>

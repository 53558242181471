import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  private binaryUnits: string[] = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB'];
  private decimalUnits: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes: number | string = 0, precision = 2): string {
    const size: number = parseFloat(String(bytes));

    if (!isFinite(size)) {
      return '';
    }

    return size >= 1000
      ? `${this.convertToBinary(size, precision)} (${ this.convertToDecimal(size, precision)})`
      : this.convertToBinary(size, precision);
  }

  private getPrecision(unit: string, precision: number): number {
    return unit === 'B' ? 0 : precision;
  }

  private convertToBinary(size: number, precision: number): string {
    let binaryUnit = 0;

    while (size >= 1024) {
      size /= 1024;
      binaryUnit++;
    }

    return `${size.toFixed(this.getPrecision(this.binaryUnits[binaryUnit], precision))} ${this.binaryUnits[binaryUnit]}`;
  }

  private convertToDecimal(size: number, precision: number): string {
    let decimalUnit = 0;

    while (size >= 1000) {
      size /= 1000;
      decimalUnit++;
    }

    return `${size.toFixed(this.getPrecision(this.decimalUnits[decimalUnit], precision))} ${this.decimalUnits[decimalUnit]}`;
  }
}

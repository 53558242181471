import { AfterViewInit, Directive, ElementRef, Optional, Self } from '@angular/core';
import { ScrollbarComponent } from '@proget-shared/ui/scrollbar';

@Directive({
  selector: '[appApplicationScrollbar]',
})
export class ApplicationScrollbarDirective implements AfterViewInit {
  public contentElement: HTMLElement;

  constructor(
    @Self()
    @Optional()
    public component: ScrollbarComponent,
    private elementRef: ElementRef
  ) {}

  ngAfterViewInit(): void {
    const scrollContentCollection = this.elementRef.nativeElement.getElementsByClassName('ng-scroll-content');

    this.contentElement = scrollContentCollection.item(0);
  }
}

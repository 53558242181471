import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@proget-shared/translate';

import { BasicToastComponent } from './component/basic-toast/basic-toast.component';
import { ToastContainerComponent } from './component/toast-container/toast-container.component';
import { ToastWrapperComponent } from './component/toast-wrapper/toast-wrapper.component';

@NgModule({
  declarations: [
    BasicToastComponent,
    ToastContainerComponent,
    ToastWrapperComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    RouterModule,
  ],
  exports: [
    ToastContainerComponent,
  ],
})
export class ToastModule { }

<b
  [hidden]="!title"
  data-qa="basic-toast-title"
>
  {{ title }}
</b>
<p
  [hidden]="!details"
  data-qa="basic-toast-details"
>
  <ng-container *ngTemplateOutlet="html ? htmlContent : textContent; context: { $implicit: details }"></ng-container>
</p>
<div
  [hidden]="!linkRoute || !linkLabel"
  class="link"
>
  <a
    [routerLink]="linkRoute"
    (click)="close()"
    data-qa="basic-toast-link"
  >
    {{ linkLabel }}
  </a>
</div>

<ng-template
  #htmlContent
  let-content
>
  <span [innerHtml]="content"></span>
</ng-template>

<ng-template
  #textContent
  let-content
>
  {{ content }}
</ng-template>

<div
  *ngIf="getOptions()?.backdrop"
  [class.fading-out]="fadingOut"
  [style.animation-duration.ms]="getOptions()?.animationDuration"
  class="backdrop"
>
</div>
<div
  #container
  [class.fading-out]="fadingOut"
  [style.animation-duration.ms]="getOptions()?.animationDuration"
  [style.height.px]="height"
  [style.left.px]="left"
  [style.top.px]="top"
  [style.width.px]="width"
  class="dialog-container"
>
  <span #placeholder class="container-placeholder"></span>
  <div
    [class.hidden]="!resizable"
    (dragStart)="startResizing($event.x, $event.y, DragHandler.TOP_RIGHT)"
    (drag)="resizeByDrag($event.x, $event.y)"
    (dragEnd)="endResizing()"
    class="drag-handler drag-corner drag-tr"
  ></div>
  <div
    [class.hidden]="!resizable"
    (dragStart)="startResizing($event.x, $event.y, DragHandler.RIGHT)"
    (drag)="resizeByDrag($event.x, $event.y)"
    (dragEnd)="endResizing()"
    class="drag-handler drag-horizontal drag-r"
  ></div>
  <div
    [class.hidden]="!resizable"
    (dragStart)="startResizing($event.x, $event.y, DragHandler.BOTTOM_RIGHT)"
    (drag)="resizeByDrag($event.x, $event.y)"
    (dragEnd)="endResizing()"
    class="drag-handler drag-corner drag-br"
  ></div>
  <div
    [class.hidden]="!resizable"
    (dragStart)="startResizing($event.x, $event.y, DragHandler.BOTTOM)"
    (drag)="resizeByDrag($event.x, $event.y)"
    (dragEnd)="endResizing()"
    class="drag-handler drag-vertical drag-b"
  ></div>
  <div
    [class.hidden]="!resizable"
    (dragStart)="startResizing($event.x, $event.y, DragHandler.BOTTOM_LEFT)"
    (drag)="resizeByDrag($event.x, $event.y)"
    (dragEnd)="endResizing()"
    class="drag-handler drag-corner drag-bl"
  ></div>
  <div
    [class.hidden]="!resizable"
    (dragStart)="startResizing($event.x, $event.y, DragHandler.LEFT)"
    (drag)="resizeByDrag($event.x, $event.y)"
    (dragEnd)="endResizing()"
    class="drag-handler drag-horizontal drag-l"
  ></div>
  <div
    [class.hidden]="!resizable"
    (dragStart)="startResizing($event.x, $event.y, DragHandler.TOP_LEFT)"
    (drag)="resizeByDrag($event.x, $event.y)"
    (dragEnd)="endResizing()"
    class="drag-handler drag-corner drag-tl"
  ></div>
  <div
    [class.hidden]="!resizable"
    (dragStart)="startResizing($event.x, $event.y, DragHandler.TOP)"
    (drag)="resizeByDrag($event.x, $event.y)"
    (dragEnd)="endResizing()"
    class="drag-handler drag-vertical drag-t"
  ></div>
  <div [class.lock-enabled]="locked" class="dialog-lock"></div>
</div>

<div
  *ngIf="getOptions()?.backdrop"
  [class.fading-out]="fadingOut"
  [style.animation-duration.ms]="getOptions()?.animationDuration"
  class="backdrop"
>
</div>
<div class="flex-layout">
  <div
    [class.fading-out]="fadingOut"
    [style.animation-duration.ms]="getOptions()?.animationDuration"
    class="dialog-container"
  >
    <span
      #placeholder
      class="container-placeholder"
    >
    </span>
    <div
      [class.lock-enabled]="locked"
      class="dialog-lock"
    >
    </div>
  </div>
</div>

<div class="inline-filters">
  <app-scrollbar
    #scrollbar
    [autoHeightDisabled]="false"
    track="horizontal"
    viewClass="inline-filters-scrollbar"
  >
    <div class="inline-filters-box">
      <div
        #displayedFiltersContainer
        class="displayed-filters-container"
      >
        <div
          #filter
          *ngFor="let control of displayedFilters.controls"
          [attr.data-qa]="'displayed-filter-' + control.config.name"
          class="displayed-filter"
        >
          <div class="filter-label">
            {{ control.config.label | translate }}:
          </div>
          <div
            [class.clear-button-overlap]="!filtersEditable && (isFilterValueEmpty(control) || hasOwnEmptyButton(control))"
            class="filter-form-control"
          >
            <ng-container *ngTemplateOutlet="filterFormControl; context: { $implicit: control, filterElement: filter }"></ng-container>
          </div>
          <div class="clear-button-container">
            <button
              [hidden]="isFilterValueEmpty(control) || hasOwnEmptyButton(control)"
              (click)="emptyFilterValue(control)"
              data-qa="clear-filter-button"
              class="clear-filter-button proget-clear-button icon-only"
            >
              <i class="empty-value-icon"></i>
            </button>
            <button
              [hidden]="!hasOwnEmptyButton(control) && !isFilterValueEmpty(control) || !filtersEditable"
              (click)="removeFilter(control)"
              data-qa="remove-filter-button"
              class="clear-filter-button proget-clear-button icon-only"
            >
              <i class="icon-delete-icon text-red"></i>
            </button>
          </div>
        </div>
        <div class="add-filter-button">
          <button
            [hidden]="!filtersEditable"
            [appMenu]="availableFilters"
            [disabled]="!availableFilters.length"
            class="proget-button icon-only"
          >
            <i class="icon-add-icon"></i>
          </button>
        </div>
      </div>
    </div>
  </app-scrollbar>
  <div
    [hidden]="!fixedAddButtonVisibility || !filtersEditable"
    class="add-filter-button fixed-add-filter-button"
  >
    <button
      [appMenu]="availableFilters"
      [disabled]="!availableFilters.length"
      class="proget-button icon-only"
    >
      <i class="icon-add-icon"></i>
    </button>
  </div>
</div>
<div class="filter-buttons">
  <div class="filter-button-wrapper">
    <button
      [class.active]="!areFiltersPristine()"
      [disabled]="areFiltersPristine()"
      (click)="resetFilters();"
      data-qa="clear-all-filters-button"
      type="button"
      class="proget-clear-button filter-text-button theme-color"
    >
      {{ 'proget_shared.grid.filters.reset_filters' | translate }}
    </button>
  </div>
</div>

<ng-template
  #filterFormControl
  let-filter
  let-filterElement="filterElement"
>
  <ng-container
    [ngSwitch]="filter.config?.type"
  >
    <app-filter-input
      *ngSwitchCase="GridFilterType.INPUT"
      [appFilterActivator]="filter"
      [config]="filter.config"
      [formControl]="filter"
      class="filter-item"
    >
    </app-filter-input>
    <app-filter-select
      *ngSwitchCase="GridFilterType.SELECT"
      [appFilterActivator]="filter"
      [config]="filter.config"
      [formControl]="filter"
      [panelReferer]="filterElement"
      [showClear]="false"
      class="filter-item"
    >
    </app-filter-select>
    <app-filter-multiselect
      *ngSwitchCase="GridFilterType.MULTISELECT"
      [appFilterActivator]="filter"
      [config]="filter.config"
      [formControl]="filter"
      [panelReferer]="filterElement"
      [showClear]="false"
      class="filter-item"
    >
    </app-filter-multiselect>
    <app-filter-autocomplete
      *ngSwitchCase="GridFilterType.AUTOCOMPLETE"
      [appFilterActivator]="filter"
      [config]="filter.config"
      [formControl]="filter"
      [panelReferer]="filterElement"
      [showClear]="false"
      class="filter-item"
    >
    </app-filter-autocomplete>
    <app-filter-date
      *ngSwitchCase="GridFilterType.DATE"
      [appFilterActivator]="filter"
      [config]="filter.config"
      [formControl]="filter"
      [panelReferer]="filterElement"
      [showClear]="false"
      class="filter-item"
    >
    </app-filter-date>
    <app-filter-range-number
      *ngSwitchCase="GridFilterType.RANGE_NUMBER"
      [appFilterActivator]="filter"
      [config]="filter.config"
      [formControl]="filter"
      class="filter-item filter-range-number"
    >
    </app-filter-range-number>
    <app-filter-range-date
      *ngSwitchCase="GridFilterType.RANGE_DATE"
      [appFilterActivator]="filter"
      [config]="filter.config"
      [formControl]="filter"
      [panelReferer]="filterElement"
      class="filter-item filter-range-date"
    >
    </app-filter-range-date>
  </ng-container>
</ng-template>

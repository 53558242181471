<div
  *ngIf="dateVisibility"
  class="day-header"
>
  <span class="part">{{ currentDate?.getDateDate() | default: '-' }}</span>
  <span class="part">{{ monthKey | translate | default: '-' }}</span>
  <span class="part">{{ currentDate?.getDateFullYear() | default: '-' }}</span>
</div>
<div
  *ngIf="wholeDayEnabled"
  class="whole-day"
>
  <div class="radio-button-group">
    <app-radio-button
      [formControl]="wholeDayControl"
      [label]="wholeDayLabel | translate"
      [name]="wholeDayLabel"
      [value]="true"
      class="whole-day-control"
    >
    </app-radio-button>
    <app-radio-button
      [formControl]="wholeDayControl"
      [label]="'proget_shared.calendar.fixed_time' | translate"
      [name]="wholeDayLabel"
      [value]="false"
      class="whole-day-control"
    >
    </app-radio-button>
  </div>
</div>
<app-time-picker
  [currentTime]="currentDate?.getValue()"
  [hidden]="this.wholeDayEnabled && wholeDayControl.value"
  [disabled]="this.wholeDayEnabled && wholeDayControl.value"
  [maxTime]="maxDate?.getTimeEnd()"
  [minTime]="minDate?.getTimeStart()"
  (timeChange)="emitTime($event)"
  class="time-picker"
>
</app-time-picker>

<app-checkbox
  *ngIf="isMultiSelectionMode()"
  [binary]="true"
  [formControl]="formControl"
></app-checkbox>
<input
  *ngIf="isSingleSelectionMode()"
  [formControl]="formControl"
  [checked]="isItemSelected()"
  [value]="getItem()"
  type="radio"
>

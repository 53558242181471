import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  public permissions$: Observable<string[]> = of([]);

  public hasPermissionInstant(_requestedPermission: string | string[], _permissions: string[] = []): boolean {
    return true;
  }
}

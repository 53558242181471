import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CapsLockTooltipModule } from '@proget-shared/form/caps-lock-tooltip';
import { CheckboxModule } from '@proget-shared/form/checkbox';
import { InputModule } from '@proget-shared/form/input';
import { TranslateModule } from '@proget-shared/translate';

import { AutoFocusDirective } from './auto-focus.directive';
import { PasswordComponent } from './password.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    CheckboxModule,
    InputModule,
    CapsLockTooltipModule,
  ],
  declarations: [PasswordComponent, AutoFocusDirective],
  exports: [PasswordComponent],
})
export class PasswordModule {}

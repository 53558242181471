import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DialogsZIndexService {
  private zIndex = 0;

  public getTopZIndex(): number {
    return this.zIndex;
  }

  public nextZIndex(): number {
    return ++this.zIndex;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogTemplateModule } from '@proget-shared/dialog/dialog-template';
import { TranslateModule } from '@proget-shared/translate';

@NgModule({
  imports: [CommonModule, DialogTemplateModule, TranslateModule.forChild()],
  exports: [DialogTemplateModule],
})
export class TranslatedDialogModule {}

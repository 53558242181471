import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtDecoder } from '@shared/jwt-decoder';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { ROUTE } from '../const';
import { UserInterface } from '../interface';
import { UserMapper } from '../mapper';
import { Guest } from '../model';

import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public onChange: BehaviorSubject<UserInterface> = new BehaviorSubject<UserInterface>(new Guest());

  private user: UserInterface = new Guest();

  constructor(private router: Router, private jwtDecoder: JwtDecoder, private tokenStorageService: TokenStorageService) {}

  public get(): Observable<UserInterface> {
    return of(this.user);
  }

  public set(): void {
    this.user = this.getUserFromToken();
    this.onChange.next(this.user);
  }

  public remove(): void {
    this.tokenStorageService.removeToken();
    this.navigate();
    this.user = new Guest();
    this.onChange.next(this.user);
  }

  private getUserFromToken(): UserInterface {
    const userToken: string = this.tokenStorageService.getToken();
    const decodedToken: any = this.jwtDecoder.decodeToken(userToken);

    return 0 === Object.keys(decodedToken).length ? new Guest() : UserMapper.map(decodedToken);
  }

  private navigate(): void {
    if (this.router.url.includes(ROUTE.AUTH.BASE)) {
      return;
    }

    this.router.navigate([ROUTE.AUTH.BASE], { queryParams: { returnUrl: this.router.url } });
  }
}

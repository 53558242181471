// source: https://github.com/MikeMcl/big.js/blob/v6.2.1/big.js
/* eslint-disable */

export class Big {
  public sign: number;
  public exponent: number;
  public coefficient: number[];

  private static NE = -7;
  private static PE = 21;
  private static NUMERIC = /^-?(\d+(\.\d*)?|\.\d+)(e[+-]?\d+)?$/i;

  constructor(n: number | string | Big) {
    if (n instanceof Big) {
      this.sign = n.sign;
      this.exponent = n.exponent;
      this.coefficient = n.coefficient.slice();
    } else {
      if (typeof n !== 'string' && typeof n !== 'number') {
        throw new TypeError(`Invalid value`);
      }
      n = n === 0 && 1 / n < 0 ? '-0' : String(n);
      this.parse(n);
    }
  }

  public minus(y: number | string | Big): Big {
    let i, j, t, isXLessThanY;
    const self = this;
    let signX = self.sign;
    let signY = (y = new Big(y)).sign;

    if (signX !== signY) {
      y.sign = -signY;
      return self.plus(y);
    }
    const exponentX = self.exponent;
    let coefficientX = self.coefficient.slice();
    let coefficientY = y.coefficient;
    let exponentY = y.exponent;

    if (!coefficientX[0] || !coefficientY[0]) {
      if (coefficientY[0]) {
        y.sign = -signY;
      } else if (coefficientX[0]) {
        y = new Big(self);
      } else {
        y.sign = 1;
      }
      return y;
    }

    if (signX = exponentX - exponentY) {
      if (isXLessThanY = signX < 0) {
        signX = -signX;
        t = coefficientX;
      } else {
        exponentY = exponentX;
        t = coefficientY;
      }
      for (signY = signX; signY--;) {
        t.unshift(0);
      }
    } else {
      j = ((isXLessThanY = coefficientX.length < coefficientY.length) ? coefficientX : coefficientY).length;
      for (signX = signY = 0; signY < j; signY++) {
        if (coefficientX[signY] !== coefficientY[signY]) {
          isXLessThanY = coefficientX[signY] < coefficientY[signY];
          break;
        }
      }
    }

    if (isXLessThanY) {
      t = coefficientX;
      coefficientX = coefficientY;
      coefficientY = t;
      y.sign = -y.sign;
    }

    if ((signY = (j = coefficientY.length) - (i = coefficientX.length)) > 0) {
      for (; signY--;) {
        coefficientX[i++] = 0;
      }
    }

    for (signY = i; j > signX;) {
      if (coefficientX[--j] < coefficientY[j]) {
        for (i = j; i && !coefficientX[--i];) {
          coefficientX[i] = 9;
        }
        --coefficientX[i];
        coefficientX[j] += 10;
      }
      coefficientX[j] -= coefficientY[j];
    }

    while (coefficientX.length && coefficientX[coefficientX.length - 1] === 0) {
      coefficientX.pop();
    }

    while (coefficientX[0] === 0) {
      coefficientX.shift();
      --exponentY;
    }

    if (!coefficientX[0]) {
      y.sign = 1;
      coefficientX = [exponentY = 0];
    }
    y.coefficient = coefficientX;
    y.exponent = exponentY;

    return y;
  }

  public plus(y: number | string | Big): Big {
    let exponentDiff, carry, temp;
    const self = this;
    const signX = self.sign;
    const signY = (y = new Big(y)).sign;

    if (signX !== signY) {
      y.sign = -signY;
      return self.minus(y);
    }
    const exponentX = self.exponent;
    let coefficientX = self.coefficient.slice();
    let exponentY = y.exponent;
    let coefficientY = y.coefficient;

    if (!coefficientX[0] || !coefficientY[0]) {
      if (!coefficientY[0]) {
        if (coefficientX[0]) {
          y = new Big(self);
        } else {
          y.sign = self.sign;
        }
      }
      return y;
    }

    if (exponentDiff = exponentX - exponentY) {
      if (exponentDiff > 0) {
        exponentY = exponentX;
        temp = coefficientY;
      } else {
        exponentDiff = -exponentDiff;
        temp = coefficientX;
      }
      for (; exponentDiff--;) {
        temp.unshift(0);
      }
    }

    if (coefficientX.length - coefficientY.length < 0) {
      temp = coefficientY;
      coefficientY = coefficientX;
      coefficientX = temp;
    }
    exponentDiff = coefficientY.length;

    for (carry = 0; exponentDiff; coefficientX[exponentDiff] %= 10) {
      carry = (coefficientX[--exponentDiff] = coefficientX[exponentDiff] + coefficientY[exponentDiff] + carry) / 10 | 0;
    }

    if (carry) {
      coefficientX.unshift(carry);
      ++exponentY;
    }

    while (coefficientX.length && coefficientX[coefficientX.length - 1] === 0) {
      coefficientX.pop();
    }
    y.coefficient = coefficientX;
    y.exponent = exponentY;

    return y;
  }

  public toString(): string {
    return this.stringify(this.exponent <= Big.NE || this.exponent >= Big.PE, !!this.coefficient[0]);
  }

  private parse(n: string): void {
    let exponent, i, numLength;

    if (!Big.NUMERIC.test(n)) {
      throw new Error(`Invalid number`);
    }
    this.sign = n.charAt(0) === '-' ? (n = n.slice(1), -1) : 1;

    if ((exponent = n.indexOf('.')) > -1) {
      n = n.replace('.', '');
    }

    if ((i = n.search(/e/i)) > 0) {
      if (exponent < 0) {
        exponent = i;
      }
      exponent += +n.slice(i + 1);
      n = n.substring(0, i);
    } else if (exponent < 0) {
      exponent = n.length;
    }
    numLength = n.length;

    for (i = 0; i < numLength && n.charAt(i) === '0';) {
      ++i;
    }

    if (i === numLength) {
      this.coefficient = [this.exponent = 0];
    } else {
      for (; numLength > 0 && n.charAt(--numLength) === '0';) {
        //
      }
      this.exponent = exponent - i - 1;
      this.coefficient = [];

      for (exponent = 0; i <= numLength;) {
        this.coefficient[exponent++] = +n.charAt(i++);
      }
    }
  }

  private stringify(doExponential: boolean, isNonzero: boolean): string {
    let exponent = this.exponent;
    let str = this.coefficient.join('');
    const numLength = str.length;

    if (doExponential) {
      str = str.charAt(0) + (numLength > 1 ? `.${str.slice(1)}` : '') + (exponent < 0 ? 'e' : 'e+') + exponent;
    } else if (exponent < 0) {
      for (; ++exponent;) {
        str = `0${str}`;
      }
      str = `0.${str}`;
    } else if (exponent > 0) {
      if (++exponent > numLength) {
        for (exponent -= numLength; exponent--;) {
          str += '0';
        }
      } else if (exponent < numLength) {
        str = `${str.slice(0, exponent)}.${str.slice(exponent)}`;
      }
    } else if (numLength > 1) {
      str = `${str.charAt(0)}.${str.slice(1)}`;
    }

    return this.sign < 0 && isNonzero ? `-${str}` : str;
  }
}

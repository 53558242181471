import { Component, ComponentRef, OnDestroy, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-toast-wrapper',
  templateUrl: './toast-wrapper.component.html',
  styleUrls: ['./toast-wrapper.component.scss'],
})
export class ToastWrapperComponent<T> implements OnDestroy {
  public readonly close$: Observable<void>;

  public closable = false;
  public icon = '';

  @ViewChild('placeholder', { read: ViewContainerRef, static: true })
  protected placeholder: ViewContainerRef;

  private readonly closeSubject = new Subject<void>();

  constructor() {
    this.close$ = this.closeSubject.asObservable();
  }

  ngOnDestroy(): void {
    this.closeSubject.complete();
  }

  public createToast(component: Type<T>): ComponentRef<T> {
    return this.placeholder.createComponent(component);
  }

  protected close(): void {
    this.closeSubject.next();
  }
}

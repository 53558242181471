export enum DragHandler {
  TOP_RIGHT = 'TOP_RIGHT',
  RIGHT = 'RIGHT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  BOTTOM = 'BOTTOM',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  LEFT = 'LEFT',
  TOP_LEFT = 'TOP_LEFT',
  TOP = 'TOP',
}

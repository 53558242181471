import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-contain',
  templateUrl: './image-contain.component.html',
  styleUrls: ['./image-contain.component.scss'],
})
export class ImageContainComponent {
  @Input()
  public alt: string;

  protected mainImageValid = false;
  protected alternativeImageValid = false;
  protected horizontalAlignStyles = this.getHorizontalAlignStyles('center');
  protected verticalAlignStyles = this.getVerticalAlignStyles('center');

  private _src = '';
  private _alternative = '';

  @Input()
  public set src(source: string) {
    if (source === this.src) {
      return;
    }

    this.mainImageValid = !!source;

    if (!this.mainImageValid) {
      return;
    }

    this._src = source;
  }

  public get src(): string {
    return this._src;
  }

  @Input()
  public set alternative(source: string) {
    if (source === this.alternative) {
      return;
    }

    this.alternativeImageValid = !!source;

    if (!this.alternativeImageValid) {
      return;
    }

    this._alternative = source;
  }

  public get alternative(): string {
    return this._alternative;
  }

  @Input()
  public set alignX(align: 'left' | 'center' | 'right') {
    this.horizontalAlignStyles = this.getHorizontalAlignStyles(align);
  }

  @Input()
  public set alignY(align: 'top' | 'center' | 'bottom') {
    this.verticalAlignStyles = this.getVerticalAlignStyles(align);
  }

  private getHorizontalAlignStyles(align: 'left' | 'center' | 'right'): { [key: string]: string } {
    if (align === 'left') {
      return { 'text-align': 'left' }; // eslint-disable-line
    }

    if (align === 'right') {
      return { 'text-align': 'right' }; // eslint-disable-line
    }

    return { 'text-align': 'center' }; // eslint-disable-line
  }

  private getVerticalAlignStyles(align: 'top' | 'center' | 'bottom'): { [key: string]: string } {
    if (align === 'top') {
      return { 'vertical-align': 'top' }; // eslint-disable-line
    }

    if (align === 'bottom') {
      return { 'vertical-align': 'bottom' }; // eslint-disable-line
    }

    return { 'vertical-align': 'middle' }; // eslint-disable-line
  }
}

import {
  ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, SkipSelf, TemplateRef,
} from '@angular/core';
import { QueryParams } from '@proget-shared/_common';
import { QueryParamsService } from '@proget-shared/query-params';
import { BehaviorSubject, combineLatest, ReplaySubject, Subscription } from 'rxjs';

import { GridControl } from '../../class/grid-control.class';
import { GridFilterType } from '../../enum/grid-filter-type.enum';
import { GridControlService } from '../../service/grid-control.service';
import { FilterConfig } from '../../type/filter-config.type';

@Component({
  selector: 'app-simple-grid',
  templateUrl: './simple-grid.component.html',
  styleUrls: ['./simple-grid.component.scss'],
  providers: [GridControlService, QueryParamsService],
})
export class SimpleGridComponent implements OnInit, OnDestroy {
  public gridControl: GridControl;

  @Input()
  public autoFiltering = false;
  @Input()
  public gridControlName = 'items';
  @Output()
  public filters: EventEmitter<QueryParams> = new EventEmitter();
  @Output()
  public visibleItems: EventEmitter<any[]> = new EventEmitter();
  @ContentChild(TemplateRef)
  public gridContent: TemplateRef<any>;

  protected pageItems: any[] | undefined;

  private readonly allItemsSubject = new ReplaySubject<any[]>(1);
  private readonly refreshSubject = new BehaviorSubject<null>(null);
  private readonly subscription = new Subscription();

  private allFiltersConfig: FilterConfig[] = [];
  private gridLimits: number[];
  private gridQueryParams: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    @SkipSelf()
    @Optional()
    private parentGridControlService: GridControlService,
    private gridControlService: GridControlService
  ) {}

  ngOnInit(): void {
    const service = this.parentGridControlService || this.gridControlService;

    this.gridControl = service.registerGridControl(this.gridControlName, this.gridQueryParams);

    if (this.gridLimits !== void 0) {
      this.gridControl.setLimits(this.gridLimits);
    }

    this.gridControl.setFiltersConfig(this.allFiltersConfig);

    this.subscription.add(
      combineLatest([
        this.allItemsSubject,
        this.gridControl.params$,
        this.refreshSubject,
      ]).subscribe({
        next: ([allItems]) => {
          const filteredItems = this.autoFiltering ? this.filterItems(allItems) : allItems;

          this.gridControl.setItemsCount(filteredItems.length);
          this.pageItems = this.gridControl.sliceCurrentPage(filteredItems);
          this.visibleItems.emit(this.pageItems);

          this.cdr.detectChanges();
        },
      })
    );

    this.subscription.add(
      this.gridControl.filters$.subscribe({
        next: (filters) => {
          this.filters.emit(filters);
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set items(items: any) {
    if (Array.isArray(items)) {
      this.allItemsSubject.next(items);
    }
  }

  @Input()
  public set limits(limits: number[]) {
    if (limits instanceof Array) {
      this.gridLimits = limits;
      this.gridControl?.setLimits(limits);
    }
  }

  @Input()
  public set filtersConfig(config: FilterConfig[]) {
    this.allFiltersConfig = config;
    this.gridControl?.setFiltersConfig(config);

  }

  @Input()
  public set useQueryParams(use: boolean) {
    this.gridQueryParams = use;
    this.gridControl?.useQueryParams(use);
  }

  public refresh(): void {
    this.refreshSubject.next(null);
  }

  protected areFiltersConfigured(filtersConfig: FilterConfig[]): boolean {
    return Array.isArray(filtersConfig) && filtersConfig.length > 0;
  }

  private filterItems(items: any[]): any[] {
    const filters: QueryParams = this.gridControl.getFilters();

    return items.filter((item: any) => Object.keys(filters).every((key: string) => this.testItem(item, key, filters[key])));
  }

  private testItem(item: any, key: string, filterValue: string | string[]): boolean {
    if (!item) {
      return false;
    }

    const itemValue = this.getItemValue(item, key);
    const stringValue = (itemValue ?? '').toString();

    if (filterValue instanceof Array) {
      return itemValue instanceof Array
        ? filterValue.some((valueItem: string) => itemValue.indexOf(valueItem) !== -1)
        : filterValue.some((valueItem: string) => valueItem === stringValue);
    }

    if ('string' === typeof filterValue) {
      return this.isExactMatchFilter(key)
        ? stringValue.toLowerCase() === filterValue.toLowerCase()
        : -1 !== stringValue.toLowerCase().indexOf(filterValue.toLowerCase());
    }

    return false;
  }

  private isExactMatchFilter(filterName: string): boolean {
    const filterConfig: FilterConfig = this.getFilterConfig(filterName);

    if (!filterConfig) {
      return false;
    }

    if (-1 !== [GridFilterType.AUTOCOMPLETE, GridFilterType.DATE, GridFilterType.SELECT].indexOf(filterConfig.type)) {
      return true;
    }

    if (GridFilterType.INPUT === filterConfig.type) {
      return filterConfig.exactMatch;
    }

    return false;
  }

  private getFilterConfig(filterName: string): FilterConfig {
    return this.allFiltersConfig.find((config) => config.name === filterName);
  }

  private getItemValue(item: any, key: string): any {
    return key.split('.').reduce((result: any, keyPart: string): any => result?.[keyPart], item);
  }
}

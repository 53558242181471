import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@proget-shared/translate';

import { MenuItemComponent } from './menu-item.component';

@NgModule({
  imports: [CommonModule, RouterModule, TranslateModule.forChild()],
  declarations: [MenuItemComponent],
  exports: [MenuItemComponent],
})
export class MenuItemModule {}

import { Directive, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { FilterFormControl } from '../class/filter-form-control.class';

@Directive({
  selector: '[appFilterActivator]',
})
export class FilterActivatorDirective implements OnDestroy {
  public readonly activate$: Observable<void>;

  private readonly activateSubject = new Subject<void>();

  private subscription = new Subscription();

  constructor() {
    this.activate$ = this.activateSubject.asObservable();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.activateSubject.complete();
  }

  @Input()
  public set appFilterActivator(control: FilterFormControl) {
    this.subscription.unsubscribe();
    this.subscription = control.activate$.subscribe(() => {
      this.activateSubject.next();
    });
  }
}

import {
  Component,
  ViewContainerRef,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  Inject,
  Optional,
  AfterViewInit,
} from '@angular/core';

import { DialogWrapper } from '../../dialog-wrapper.class';
import { GLOBAL_DIALOG_OPTIONS } from '../../global-dialog-options.token';
import { DialogsZIndexService } from '../../service/dialogs-zindex.service';
import { DialogOptions } from '../../type/dialog-options.type';

@Component({
  selector: 'app-dialog-static-wrapper',
  templateUrl: './dialog-static-wrapper.component.html',
  styleUrls: ['./dialog-static-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogStaticWrapperComponent<T, U> extends DialogWrapper<T, U> implements AfterViewInit {
  @ViewChild('placeholder', { read: ViewContainerRef, static: true })
  protected placeholder: ViewContainerRef;

  constructor(
    dialogsZIndexService: DialogsZIndexService,
    elementRef: ElementRef,
    @Inject(GLOBAL_DIALOG_OPTIONS)
    @Optional()
    providedDialogOptions: Partial<DialogOptions>
  ) {
    super(elementRef, dialogsZIndexService, providedDialogOptions);
    this.setSelfClassName('proget-dialog-static-wrapper');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.options.formAutofocus) {
        this.focusInput();
      }
    }, 0);
  }
}

import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-control-label',
  templateUrl: './form-control-label.component.html',
  styleUrls: ['./form-control-label.component.scss'],
})
export class FormControlLabelComponent {
  @Input()
  public control: FormControl;
}

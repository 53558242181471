import { AbstractControlOptions, AsyncValidatorFn, FormControl, ValidatorFn } from '@angular/forms';

export class PasswordFormControl extends FormControl {
  public readonly enabledControl: FormControl = new FormControl(false);

  private requiredValidator: ValidatorFn | null = null;

  constructor(
    formState?: any,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(formState, validatorOrOpts, asyncValidator);

    this.enabledControl.valueChanges.subscribe({
      next: () => {
        this.updateValidators();
      },
    });
  }

  public updateValidators(): void {
    this.enabledControl.value && this.requiredValidator
      ? this.addValidators(this.requiredValidator)
      : this.removeValidators(this.requiredValidator);

    this.updateValueAndValidity();
  }

  public setRequiredValidator(validator: ValidatorFn): void {
    this.removeValidators(this.requiredValidator);
    this.requiredValidator = validator;
    this.updateValidators();
  }
}

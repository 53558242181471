import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { LANGUAGES } from '@core/const';
import { ApplicationScrollbarDirective } from '@core/directive';
import { ErrorHandlerHttpInterceptor, LanguageHttpInterceptor, MiddlewareErrorHttpInterceptor } from '@core/interceptor';
import { TokenStorageService } from '@core/service';
import { ProgetSharedModule } from '@proget-shared';
import { AuthHttpClientModule, AuthTokenService } from '@proget-shared/auth-http-client';
import { DialogModule } from '@proget-shared/dialog';
import { CalendarModule } from '@proget-shared/form/calendar';
import { BatchWorkService } from '@proget-shared/grid/batch-work';
import { BatchWorkToken } from '@proget-shared/grid/grid-actions';
import { ResponseErrorModule } from '@proget-shared/helper/response-error';
import { CustomUrlSerializer } from '@proget-shared/query-params';
import { WindowStorageToken } from '@proget-shared/storage';
import { TranslateModule } from '@proget-shared/translate';
import { ImageContainModule } from '@proget-shared/ui/image-contain';
import { ScrollbarModule } from '@proget-shared/ui/scrollbar';
import { SpinnerModule } from '@proget-shared/ui/spinner';
import { ToastModule } from '@proget-shared/ui/toast';
import { JwtDecoderModule } from '@shared/jwt-decoder';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorModule } from './error';
import { PermissionModule, PermissionService } from './permission';
import { UiModule, ZIndex } from './ui';

const productionImports: any[] = environment.production ? [ErrorModule] : [];

@NgModule({
  declarations: [AppComponent, ApplicationScrollbarDirective],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthHttpClientModule.forRoot({
      authToken: {
        provide: AuthTokenService,
        useClass: TokenStorageService,
      },
    }),
    TranslateModule.forRoot({ langs: LANGUAGES, storageKey: 'lang' }),
    // Own imports
    ProgetSharedModule.forRoot({
      permissionService: PermissionService,
      betaFeaturesEnabled: true,
    }),
    ...productionImports,
    PermissionModule.forRoot(),
    JwtDecoderModule,
    ResponseErrorModule,
    SpinnerModule.withInterceptor(),
    ToastModule,
    UiModule,
    ImageContainModule,
    DialogModule.withOptions({ zIndex: ZIndex.DIALOG }),
    CalendarModule.withConfig({ firstDayOfWeek: environment.firstDayOfWeek }),
    ScrollbarModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MiddlewareErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: WindowStorageToken,
      useValue: window,
    },
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer,
    },
    {
      provide: BatchWorkToken,
      useClass: BatchWorkService,
    },
    provideHttpClient(),
  ],
})
export class AppModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageContainComponent } from './image-contain.component';

@NgModule({
  declarations: [ImageContainComponent],
  imports: [CommonModule],
  exports: [ImageContainComponent],
})
export class ImageContainModule {}

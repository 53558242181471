import { NgModule } from '@angular/core';
import { TranslateModule } from '@proget-shared/translate';
import { TooltipModule } from '@proget-shared/ui/tooltip';

import { CapsLockTooltipDirective } from './caps-lock-tooltip.directive';

@NgModule({
  imports: [
    TooltipModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    CapsLockTooltipDirective,
  ],
  exports: [
    CapsLockTooltipDirective,
  ],
})
export class CapsLockTooltipModule { }

import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  OnDestroy,
  Renderer2,
} from '@angular/core';

interface Position {
  x: number;
  y: number;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[drag]',
})
export class DragDirective implements OnDestroy {
  @Output()
  public dragStart = new EventEmitter<Position>();
  @Output()
  public drag = new EventEmitter<Position>();
  @Output()
  public dragEnd = new EventEmitter<Position>();

  private readonly dragListener: Function;

  constructor(element: ElementRef, renderer: Renderer2) {
    this.dragListener = renderer.listen(
      element.nativeElement,
      'mousedown',
      (mousedownEvent: MouseEvent) => {
        mousedownEvent.preventDefault();

        this.dragStart.emit({
          x: mousedownEvent.clientX,
          y: mousedownEvent.clientY,
        });

        const moveListener = renderer.listen(
          window,
          'mousemove',
          (mousemoveEvent: MouseEvent) => {
            mousemoveEvent.preventDefault();

            this.drag.next({
              x: mousemoveEvent.clientX,
              y: mousemoveEvent.clientY,
            });
          }
        );

        const upListener = renderer.listen(
          document,
          'mouseup',
          (mouseupEvent: MouseEvent) => {
            moveListener();
            upListener();

            this.dragEnd.emit({
              x: mouseupEvent.clientX,
              y: mouseupEvent.clientY,
            });
          }
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.dragListener();
  }
}

export class Range {
  constructor(public from = '', public to = '') {}

  public isEmpty(): boolean {
    return '' === this.from.trim() && '' === this.to.trim();
  }

  public clone(): Range {
    return new Range(this.from, this.to);
  }
}

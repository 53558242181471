import {
  Injectable,
  ComponentRef,
  Type,
  OnDestroy,
} from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import {
  finalize,
  Subscription,
  map,
  filter,
  Observable,
  from,
  EMPTY,
  switchMap,
} from 'rxjs';

import { DialogContainerComponent } from '../component/dialog-container/dialog-container.component';
import { DialogFloatingWrapperComponent } from '../component/dialog-floating-wrapper/dialog-floating-wrapper.component';
import { DialogStaticWrapperComponent } from '../component/dialog-static-wrapper/dialog-static-wrapper.component';
import { DialogWrapper } from '../dialog-wrapper.class';
import { DialogOptions } from '../type/dialog-options.type';

import { DialogsCountService } from './dialogs-count.service';

@Injectable({ providedIn: 'root' })
export class DialogService implements OnDestroy {
  private activeDialogs: ComponentRef<DialogWrapper>[] = [];
  private communicationSubscription = new Subscription();
  private container: DialogContainerComponent | undefined;

  constructor(
    private dialogsCountService: DialogsCountService,
    router: Router
  ) {
    this.communicationSubscription.add(
      router.events
        .pipe(
          filter((event) => event instanceof ActivationStart),
          switchMap(() => from(this.activeDialogs.map((dialogRef) => dialogRef.instance))
          ),
          filter((wrapper) => !wrapper.getOptions().persist),
          map((wrapper) => wrapper.getDialogBody())
        )
        .subscribe({
          next: (dialogBody) => {
            this.reject(dialogBody);
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.communicationSubscription.unsubscribe();
  }

  custom<T, U = any>(
    dialogComponent: Type<T>,
    options?: Partial<DialogOptions> & { returnDialogInstance?: false }
  ): Observable<U>;
  custom<T, U = any>(
    dialogComponent: Type<T>,
    options?: Partial<DialogOptions> & { returnDialogInstance: true }
  ): { message$: Observable<U>; dialog: T };
  custom<T, U = any>(
    dialogComponent: Type<T>,
    options?: Partial<DialogOptions>
  ): Observable<U> | { message$: Observable<U>; dialog: T };
  public custom<T, U = any>(
    dialogComponent: Type<T>,
    options?: Partial<DialogOptions>
  ): Observable<U> | { message$: Observable<U>; dialog: T } {
    const dialog = this.createDialog<T, U>(dialogComponent, options);

    dialog.instance.isFloating()
      ? this.dialogsCountService.addFloatingDialog()
      : this.dialogsCountService.addStaticDialog();
    this.activeDialogs.push(dialog);
    this.updateZIndex();

    if (options?.returnDialogInstance) {
      return {
        message$: dialog.instance.response$,
        dialog: dialog.instance.getDialogBody(),
      };
    }

    return dialog.instance.response$;
  }

  public emit(dialog: any, message: any): void {
    const activeDialog = this.getActiveDialogInstance(dialog);

    if (null !== activeDialog) {
      activeDialog.instance.emitMessage(message);
    }
  }

  public lock(dialog: any): void {
    const activeDialog = this.getActiveDialogInstance(dialog);

    if (null !== activeDialog) {
      activeDialog.instance.lock();
    }
  }

  public unlock(dialog: any): void {
    const activeDialog = this.getActiveDialogInstance(dialog);

    if (null !== activeDialog) {
      activeDialog.instance.unlock();
    }
  }

  /*
   * @deprecated Use resolve instead
   */
  public close(dialog: any, response?: any): void {
    this.resolve(dialog, response);
  }

  /*
   * @deprecated Use reject instead
   */
  public dismiss(dialog: any): void {
    this.reject(dialog);
  }

  /*
   * close dialog and emit response on stream
   */
  public resolve(dialog: any, response?: any): void {
    this.destroyDialog(dialog).subscribe({
      next: (destroyedDialog) => {
        destroyedDialog.instance.resolveDialog(response);
      },
    });
  }

  /*
   * close dialog with error on stream
   */
  public reject(dialog: any, reason?: any): void {
    this.destroyDialog(dialog).subscribe({
      next: (destroyedDialog) => {
        destroyedDialog.instance.rejectDialog(reason);
      },
    });
  }

  /*
   * close dialog and stream without message
   */
  public kill(dialog: any): void {
    this.destroyDialog(dialog).subscribe({
      next: (destroyedDialog) => {
        destroyedDialog.instance.finalizeDialog();
      },
    });
  }

  /*
   * kill dialogs by tag
   */
  public killDialogsByTag(tag: string): void {
    for (const dialog of this.activeDialogs) {
      const dialogWrapper = dialog.instance;

      if ((dialogWrapper.getOptions().tags || []).indexOf(tag) !== -1) {
        this.kill(dialogWrapper.getDialogBody());
      }
    }
  }

  /*
   * kill all active dialogs
   */
  public killAllDialogs(): void {
    while (this.activeDialogs.length > 0) {
      this.kill(this.activeDialogs[0].instance.getDialogBody());
    }
  }

  /*
   * kill all active dialogs
   */
  public killTopDialog(cancellableOnly: boolean): void {
    const maxLocalZIndex = this.activeDialogs
      .filter((dialog) => !cancellableOnly || dialog.instance.getOptions().cancellable)
      .map((dialog) => Number(dialog.location.nativeElement.style.zIndex))
      .reduce(
        (maxZIndex, currentZIndex) => Math.max(maxZIndex, currentZIndex),
        0
      );

    for (const dialog of this.activeDialogs) {
      if (dialog.instance.zIndex === maxLocalZIndex) {
        this.kill(dialog.instance.getDialogBody());
      }
    }
  }

  /*
   * returns active dialogs
   */
  public getDialogs(): DialogWrapper[] {
    return this.activeDialogs.map((ref) => ref.instance);
  }

  /*
   * register DialogContainerComponent
   */
  public registerContainer(container: DialogContainerComponent): void {
    this.container = container;
  }

  /*
   * set dialog size
   */
  public setDialogSize(dialog: any, width: number, height: number): void {
    this.getActiveDialogInstance(dialog)?.instance.setSize(width, height);
  }

  private destroyDialog(
    dialog: any
  ): Observable<ComponentRef<DialogWrapper>> {
    const activeDialog = this.getActiveDialogInstance(dialog);

    if (null === activeDialog) {
      return EMPTY;
    }

    this.activeDialogs.splice(this.activeDialogs.indexOf(activeDialog), 1);

    activeDialog.instance.isFloating()
      ? this.dialogsCountService.removeFloatingDialog()
      : this.dialogsCountService.removeStaticDialog();

    return activeDialog.instance.fadeOut().pipe(
      map(() => activeDialog),
      finalize(() => {
        activeDialog.destroy();
        this.updateZIndex();
      })
    );
  }

  private getActiveDialogInstance(
    dialog: any
  ): ComponentRef<DialogWrapper> {
    const dialogIndex = this.activeDialogs.findIndex(
      (active) => active.instance.getDialogBody() === dialog
    );

    if (-1 === dialogIndex) {
      return null;
    }

    return this.activeDialogs[dialogIndex];
  }

  private createDialog<T, U>(
    dialogComponent: Type<T>,
    options?: Partial<DialogOptions>
  ): ComponentRef<DialogWrapper<T, U>> {
    const containerRef = this.container.getRef();
    const wrapperRef = containerRef.createComponent<DialogWrapper<T, U>>(options?.floating
      ? DialogFloatingWrapperComponent
      : DialogStaticWrapperComponent
    );

    wrapperRef.instance.createDialogBody(dialogComponent, options);

    return wrapperRef;
  }

  private updateZIndex(): void {
    const maxZIndex = Math.max(...this.activeDialogs.map((dialogRef) => dialogRef.instance.getOptions().zIndex || 0), 0);

    this.container.setZIndex(maxZIndex);
  }
}

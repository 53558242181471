import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@proget-shared/ui/toast';
import { Observable, tap } from 'rxjs';

import { HTTP_STATUS_CODE } from '../enum';

@Injectable({ providedIn: 'root' })
export class MiddlewareErrorHttpInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((request: any): void => {
        if (HTTP_STATUS_CODE.OK === request.status && request.body?.code) {
          this.toastService.error({ key: 'notification.middleware_error.title', params: { code: request.body?.code } });
        }
      })
    );
  }
}

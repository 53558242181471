import { Injectable } from '@angular/core';
import { DateHelper } from '@proget-shared/helper';

import { CalendarType } from '../const/calendar-type.enum';
import { RangeEdge } from '../const/range-edge.enum';
import { CalendarDateTime } from '../model/calendar-date-time.model';
import { CalendarRange } from '../model/calendar-range.model';

@Injectable()
export class CalendarService {
  public dateFormat = 'dd-MM-yyyy';
  public timeFormat = 'HH:mm';
  public type = CalendarType.DATE_ONLY;
  public range = false;
  public clearEnabled = true;
  public timeStep = 1;

  private readonly minAvailableTimestamp = new Date(1900, 0, 1).getTime();
  private readonly maxAvailableTimestamp = new Date(2101, 0, 1).getTime() - 1;

  private minTimestamp = this.minAvailableTimestamp;
  private maxTimestamp = this.maxAvailableTimestamp;

  public get withDate(): boolean {
    return this.type !== CalendarType.TIME_ONLY;
  }

  public get withTime(): boolean {
    return this.type !== CalendarType.DATE_ONLY;
  }

  public get timeRequired(): boolean {
    return this.type === CalendarType.DATE_TIME || this.type === CalendarType.TIME_ONLY;
  }

  public set minDate(date: Date) {
    this.minTimestamp = DateHelper.isValidDate(date)
      ? Math.max(date.getTime(), this.minAvailableTimestamp)
      : this.minAvailableTimestamp;
  }

  public set maxDate(date: Date) {
    this.maxTimestamp = DateHelper.isValidDate(date)
      ? Math.min(date.getTime(), this.maxAvailableTimestamp)
      : this.maxAvailableTimestamp;
  }

  public parseDate(input: any, endDate = false): CalendarDateTime {
    if (input instanceof CalendarDateTime) {
      return input;
    }

    if (DateHelper.isValidDate(input)) {
      return new CalendarDateTime(input, false);
    }

    try {
      const date = DateHelper.parse(input, this.dateFormat);

      return this.isDayInRange(date) && !this.timeRequired
        ? new CalendarDateTime(endDate ? DateHelper.getDayEnd(date) : date, true)
        : null;
    } catch {
      if (this.type === CalendarType.DATE_ONLY) {
        return null;
      }
    }

    try {
      const date = DateHelper.parse(input, `${this.dateFormat} ${this.timeFormat}`);

      return this.isTimeInRange(date) ? new CalendarDateTime(date, false) : null;
    } catch {
      if (this.type !== CalendarType.TIME_ONLY) {
        return null;
      }
    }

    try {
      return new CalendarDateTime(DateHelper.parse(input, `${this.timeFormat}`), false);
    } catch {
      return null;
    }
  }

  public stringifyDate(date: CalendarDateTime): string {
    if (!date) {
      return '';
    }

    const dateValue = date.getValue();

    if (this.type === CalendarType.TIME_ONLY) {
      return DateHelper.stringify(dateValue, this.timeFormat);
    }

    if (this.type === CalendarType.DATE_ONLY || date.wholeDay) {
      return DateHelper.stringify(dateValue, this.dateFormat);
    }

    return DateHelper.stringify(dateValue, `${this.dateFormat} ${this.timeFormat}`);
  }

  public parseInput(input: any): CalendarDateTime | CalendarRange {
    if (!input) {
      return null;
    }

    if (!this.range) {
      return this.parseDate(input);
    }

    if (input instanceof CalendarRange) {
      return input;
    }

    try {
      const from = this.parseDate(input.from);
      const to = this.parseDate(input.to, true);

      if (!from && !to) {
        return null;
      }

      return new CalendarRange(from, to, to === null ? RangeEdge.FROM : RangeEdge.TO);
    } catch {
      return null;
    }
  }

  prepareOutput(output: CalendarRange, type: 'object'): CalendarRange;
  prepareOutput(output: CalendarDateTime, type: 'object'): CalendarDateTime;
  prepareOutput(output: CalendarRange, type: 'string'): { from: string; to: string };
  prepareOutput(output: CalendarDateTime, type: 'string'): string;
  public prepareOutput(output: CalendarRange | CalendarDateTime, type: 'object' | 'string'): any {
    if (type === 'object') {
      return output;
    }

    if (!this.range) {
      try {
        return this.stringifyDate(output as CalendarDateTime);
      } catch {
        return '';
      }
    }

    return output instanceof CalendarRange
      ? {
          from: this.stringifyDate(output.from),
          to: this.stringifyDate(output.to),
        }
      : { from: '', to: '' };
  }

  public isYearInRange(date: Date): boolean {
    const yearBegin = new Date(date.getFullYear(), 0, 1).getTime();
    const yearEnd = new Date(date.getFullYear() + 1, 0, 1, 0, 0, 0, -1).getTime();

    return (
      // min range is in the year
      yearBegin <= this.minTimestamp && this.minTimestamp <= yearEnd ||
      // or max range is in the year
      yearBegin <= this.maxTimestamp && this.maxTimestamp <= yearEnd ||
      // or whole year is in range but range edges are not in year
      this.minTimestamp < yearBegin && yearEnd < this.maxTimestamp
    );
  }

  public isMonthInRange(date: Date): boolean {
    const monthBegin = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
    const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0, -1).getTime();

    return (
      // min range is in the month
      monthBegin <= this.minTimestamp && this.minTimestamp <= monthEnd ||
      // or max range is in the month
      monthBegin <= this.maxTimestamp && this.maxTimestamp <= monthEnd ||
      // or whole month is in range but range edges are not in month
      this.minTimestamp < monthBegin && monthEnd < this.maxTimestamp
    );
  }

  public isDayInRange(date: Date): boolean {
    const dayBegin = DateHelper.getDayStart(date).getTime();
    const dayEnd = DateHelper.getDayEnd(date).getTime();

    return (
      // min range is in the day
      dayBegin <= this.minTimestamp && this.minTimestamp <= dayEnd ||
      // or max range is in the day
      dayBegin <= this.maxTimestamp && this.maxTimestamp <= dayEnd ||
      // or whole day is in range but range edges are not in day
      this.minTimestamp < dayBegin && dayEnd < this.maxTimestamp
    );
  }

  public isTimeInRange(date: Date): boolean {
    return date.getTime() >= this.minTimestamp && date.getTime() <= this.maxTimestamp;
  }

  applyTimeStep(date: Date): Date;
  applyTimeStep(date: CalendarDateTime): CalendarDateTime;
  public applyTimeStep(date: Date | CalendarDateTime): Date | CalendarDateTime {
    if (date instanceof CalendarDateTime) {
      return new CalendarDateTime(this.applyTimeStep(date.getValue()), date.wholeDay);
    }

    if (!DateHelper.isValidDate(date)) {
      return date;
    }

    const dateStart = DateHelper.getDayStart(date);
    const dayTime = date.getTime() - dateStart.getTime();
    const stepInMilliseconds = this.timeStep * 60000;
    const stepTime = Math.floor(dayTime / stepInMilliseconds) * stepInMilliseconds;

    return new Date(dateStart.getTime() + stepTime);
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  Optional,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';

import { DEFAULT_DIALOG_OPTIONS } from '../../const/default-dialog-options.const';
import { DialogWrapper } from '../../dialog-wrapper.class';
import { DragHandler } from '../../enum/drag-handler.enum';
import { GLOBAL_DIALOG_OPTIONS } from '../../global-dialog-options.token';
import { DialogsZIndexService } from '../../service/dialogs-zindex.service';
import { DialogOptions } from '../../type/dialog-options.type';
import { DragConfig } from '../../type/drag-config.type';

@Component({
  selector: 'app-dialog-floating-wrapper',
  templateUrl: './dialog-floating-wrapper.component.html',
  styleUrls: ['./dialog-floating-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogFloatingWrapperComponent<T, U> extends DialogWrapper<T, U> implements AfterViewInit, OnDestroy {
  protected readonly DragHandler = DragHandler;

  @ViewChild('placeholder', { read: ViewContainerRef, static: true })
  protected placeholder: ViewContainerRef;
  protected left = 0;
  protected top = 0;
  protected width: number | undefined;
  protected height: number | undefined;

  private minWidth = DEFAULT_DIALOG_OPTIONS.minWidth;
  private minHeight = DEFAULT_DIALOG_OPTIONS.minHeight;

  @ViewChild('container', { read: ElementRef, static: true })
  private containerRef: ElementRef;
  private dragListener: Function;
  private dragConfig: DragConfig;

  constructor(
    private renderer: Renderer2,
    elementRef: ElementRef,
    dialogsZIndexService: DialogsZIndexService,
    @Inject(GLOBAL_DIALOG_OPTIONS)
    @Optional()
    providedDialogOptions: Partial<DialogOptions>
  ) {
    super(elementRef, dialogsZIndexService, providedDialogOptions, 1000);

    this.setSelfClassName('proget-dialog-floating-wrapper');
  }

  ngAfterViewInit(): void {
    const dialogElement = this.containerRef.nativeElement;

    // center
    setTimeout(() => {
      const documentWidth = this.getDocumentWidth();
      const documentHeight = this.getDocumentHeight();

      dialogElement.classList.add('measure');

      this.minWidth = this.options.minWidth;
      this.minHeight = this.options.minHeight;

      this.width = Math.min(Math.max(this.minWidth, dialogElement.offsetHeight), 0.9 * documentWidth);
      this.height = Math.min(Math.max(this.minHeight, dialogElement.offsetHeight), 0.9 * documentHeight);

      this.left = (documentWidth - this.width) / 2;
      this.top = (documentHeight - this.height) / 2;

      dialogElement.style.minWidth = `${this.minWidth}px`;
      dialogElement.style.minHeight = `${this.minHeight}px`;
      dialogElement.classList.remove('measure');

      if (this.options.formAutofocus) {
        this.focusInput();
      }
    });

    // init drag
    const headerElements = dialogElement.getElementsByClassName('dialog-header');

    if (headerElements.length > 0) {
      this.dragListener = this.renderer.listen(
        headerElements.item(0),
        'mousedown',
        (mousedownEvent: MouseEvent) => {
          mousedownEvent.preventDefault();
          this.bringToFront();

          const startLeft = this.left;
          const startTop = this.top;

          const startMouseX = mousedownEvent.clientX;
          const startMouseY = mousedownEvent.clientY;

          const documentWidth = this.getDocumentWidth();
          const documentHeight = this.getDocumentHeight();

          const moveListener = this.renderer.listen(
            window,
            'mousemove',
            (mousemoveEvent: MouseEvent) => {
              mousemoveEvent.preventDefault();

              const newLeft = startLeft - startMouseX + mousemoveEvent.clientX;

              this.left = Math.max(
                0,
                Math.min(documentWidth - this.width, newLeft)
              );

              const newTop = startTop - startMouseY + mousemoveEvent.clientY;

              this.top = Math.max(
                0,
                Math.min(documentHeight - this.height, newTop)
              );
            }
          );

          const upListener = this.renderer.listen(document, 'mouseup', () => {
            moveListener();
            upListener();
          });
        }
      );
    }

    this.bringToFront();
  }

  ngOnDestroy(): void {
    if ('function' === typeof this.dragListener) {
      this.dragListener();
    }
  }

  public get resizable(): boolean {
    return this.options?.resizable ?? DEFAULT_DIALOG_OPTIONS.resizable;
  }

  @HostBinding('class.with-backdrop')
  public get backdrop(): boolean {
    return this.options?.backdrop ?? DEFAULT_DIALOG_OPTIONS.backdrop;
  }

  public setSize(width: number, height: number): void {
    if (!this.options.resizable) {
      super.setSize(width, height);

      return;
    }

    const documentWidth = this.getDocumentWidth();
    const documentHeight = this.getDocumentHeight();

    this.minWidth = this.options.minWidth;
    this.minHeight = this.options.minHeight;

    this.width = Math.min(Math.max(this.minWidth, width), documentWidth);
    this.height = Math.min(Math.max(this.minHeight, height), documentHeight);

    this.left = (documentWidth - this.width) / 2;
    this.top = (documentHeight - this.height) / 2;
  }

  @HostListener('window: resize')
  protected fitToDocument(): void {
    const documentWidth = this.getDocumentWidth();
    const documentHeight = this.getDocumentHeight();

    this.left = Math.max(
      0,
      this.left - Math.max(0, this.left + this.width - documentWidth)
    );
    this.top = Math.max(
      0,
      this.top - Math.max(0, this.top + this.height - documentHeight)
    );

    this.width = Math.min(this.width, documentWidth);
    this.height = Math.min(this.height, documentHeight);

    if (this.options.resizeToCenter) {
      this.left = (documentWidth - this.width) / 2;
      this.top = (documentHeight - this.height) / 2;
    }
  }

  protected startResizing(x: number, y: number, handler: DragHandler): void {
    this.bringToFront();

    this.dragConfig = {
      handler,
      initialTop: this.top,
      initialLeft: this.left,
      initialWidth: this.width,
      initialHeight: this.height,
      initialMouseX: x,
      initialMouseY: y,
    };
  }

  protected resizeByDrag(x: number, y: number): void {
    if (!this.dragConfig) {
      return;
    }

    const documentWidth = this.getDocumentWidth();
    const documentHeight = this.getDocumentHeight();
    const resizeParam = this.options.resizeToCenter ? 2 : 1;

    if (
      this.dragConfig.handler === DragHandler.TOP_RIGHT ||
      this.dragConfig.handler === DragHandler.RIGHT ||
      this.dragConfig.handler === DragHandler.BOTTOM_RIGHT
    ) {
      this.width = Math.min(
        this.options.resizeToCenter
          ? documentWidth
          : documentWidth - this.dragConfig.initialLeft,
        Math.max(
          this.minWidth,
          this.dragConfig.initialWidth + (x - this.dragConfig.initialMouseX) * resizeParam
        )
      );
    }

    if (
      this.dragConfig.handler === DragHandler.BOTTOM_RIGHT ||
      this.dragConfig.handler === DragHandler.BOTTOM ||
      this.dragConfig.handler === DragHandler.BOTTOM_LEFT
    ) {
      this.height = Math.min(
        this.options.resizeToCenter
          ? documentHeight
          : documentHeight - this.dragConfig.initialTop,
        Math.max(
          this.minHeight,
          this.dragConfig.initialHeight + (y - this.dragConfig.initialMouseY) * resizeParam
        )
      );
    }

    if (
      this.dragConfig.handler === DragHandler.BOTTOM_LEFT ||
      this.dragConfig.handler === DragHandler.LEFT ||
      this.dragConfig.handler === DragHandler.TOP_LEFT
    ) {
      this.left = Math.max(
        0,
        Math.min(
          this.dragConfig.initialLeft +
            this.dragConfig.initialWidth -
            this.minWidth,
          this.dragConfig.initialLeft + x - this.dragConfig.initialMouseX
        )
      );
      this.width = Math.max(
        this.minWidth,
        this.dragConfig.initialWidth + (this.dragConfig.initialLeft - this.left) * resizeParam
      );
    }

    if (
      this.dragConfig.handler === DragHandler.TOP_LEFT ||
      this.dragConfig.handler === DragHandler.TOP ||
      this.dragConfig.handler === DragHandler.TOP_RIGHT
    ) {
      this.top = Math.max(
        0,
        Math.min(
          this.dragConfig.initialTop +
            this.dragConfig.initialHeight -
            this.minHeight,
          this.dragConfig.initialTop + y - this.dragConfig.initialMouseY
        )
      );
      this.height = Math.max(
        this.minHeight,
        this.dragConfig.initialHeight + (this.dragConfig.initialTop - this.top) * resizeParam
      );
    }

    if (this.options.resizeToCenter) {
      this.left = (documentWidth - this.width) / 2;
      this.top = (documentHeight - this.height) / 2;
    }
  }

  protected endResizing(): void {
    this.dragConfig = null;
  }

  private getDocumentWidth(): number {
    return document.documentElement.clientWidth || document.body.clientWidth;
  }

  private getDocumentHeight(): number {
    return document.documentElement.clientHeight || document.body.clientHeight;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateHelper } from '@proget-shared/helper';
import { Subscription } from 'rxjs';

import { CalendarDateTime } from '../../model/calendar-date-time.model';
import { SelectionLevel } from '../date-selector/selection-level.enum';

@Component({
  selector: 'app-day-display',
  templateUrl: './day-display.component.html',
  styleUrls: ['./day-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayDisplayComponent implements OnDestroy {
  @Input()
  public minDate: CalendarDateTime = null;
  @Input()
  public maxDate: CalendarDateTime = null;
  @Input()
  public monthLabelKey = 'calendar.month_names';
  @Input()
  public wholeDayEnabled = false;
  @Input()
  public wholeDayLabel = '';
  @Input()
  public dateVisibility = true;
  @Input()
  public setTimeLabel = '';

  protected readonly SelectionLevel = SelectionLevel;
  protected readonly wholeDayControl = new FormControl(true);

  @Output()
  private readonly edgeChange = new EventEmitter<CalendarDateTime>();
  private readonly subscription = new Subscription();

  private _currentDate: CalendarDateTime = null;

  constructor() {
    this.subscription.add(
      this.wholeDayControl.valueChanges.subscribe({
        next: (value) => {
          this.emitWholeDay(value);
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set currentDate(value: CalendarDateTime) {
    this._currentDate = value;

    if (!value) {
      this.wholeDayControl.disable({ emitEvent: false });
      this.wholeDayControl.setValue(false, { emitEvent: false });

      return;
    }

    this.wholeDayControl.enable({ emitEvent: false });
    this.wholeDayControl.setValue(value.wholeDay, { emitEvent: false });
  }

  public get currentDate(): CalendarDateTime {
    return this._currentDate;
  }

  public get monthKey(): string {
    return this.currentDate ? `${this.monthLabelKey}.${this.currentDate.getDateMonth()}` : '';
  }

  protected emitWholeDay(value: boolean): void {
    if (!this.currentDate) {
      return;
    }

    this.edgeChange.emit(new CalendarDateTime(this.currentDate.getValue(), value));
  }

  protected emitTime(time: Date): void {
    if (!this.currentDate) {
      return;
    }

    const newDateTime = new CalendarDateTime(
      DateHelper.mergeDateTime(this.currentDate.getDate(), time),
      this.currentDate.wholeDay
    );

    this.edgeChange.emit(newDateTime);
  }
}

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from '@proget-shared/form/dropdown';
import { RadioButtonModule } from '@proget-shared/form/radio-button';
import { PipeModule } from '@proget-shared/pipe';
import { TranslateModule } from '@proget-shared/translate';
import { EllipsisModule } from '@proget-shared/ui/ellipsis';
import { OverlayPanelModule } from '@proget-shared/ui/overlay-panel';
import { TooltipModule } from '@proget-shared/ui/tooltip';

import { CalendarConfiguration } from './calendar-configuration.enum';
import { CalendarComponent } from './component/calendar/calendar.component';
import { DateSelectorComponent } from './component/date-selector/date-selector.component';
import { DayDisplayComponent } from './component/day-display/day-display.component';
import { InlineCalendarComponent } from './component/inline-calendar/inline-calendar.component';
import { MonthDisplayComponent } from './component/month-display/month-display.component';
import { TimePickerComponent } from './component/time-picker/time-picker.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    RadioButtonModule,
    DropdownModule,
    EllipsisModule,
    OverlayPanelModule,
    TooltipModule,
    PipeModule,
  ],
  declarations: [
    CalendarComponent,
    DateSelectorComponent,
    DayDisplayComponent,
    InlineCalendarComponent,
    MonthDisplayComponent,
    TimePickerComponent,
  ],
  exports: [
    CalendarComponent,
    InlineCalendarComponent,
  ],
})
export class CalendarModule {
  static withConfig(configuration: { firstDayOfWeek?: number } = {}): ModuleWithProviders<CalendarModule> {
    return {
      ngModule: CalendarModule,
      providers: [
        {
          provide: CalendarConfiguration.FIRST_DAY_OF_WEEK,
          useValue: configuration.firstDayOfWeek ?? 0,
        },
      ],
    };
  }
}

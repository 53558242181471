import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@proget-shared/translate';
import { MenuItem } from '@proget-shared/ui/menu';
import { ScrollbarComponent } from '@proget-shared/ui/scrollbar';
import { Subject, Subscription, debounceTime, map, startWith, switchMap, tap } from 'rxjs';

import { FilterFormControl } from '../../class/filter-form-control.class';
import { FiltersValuesSerializer } from '../../class/filters-values-serializer.class';
import { GridControl } from '../../class/grid-control.class';
import { GridFilterType } from '../../enum/grid-filter-type.enum';
import { Range } from '../../model/range';
import { FilterConfig } from '../../type/filter-config.type';

@Component({
  selector: 'app-grid-filters',
  templateUrl: './grid-filters.component.html',
  styleUrls: ['./grid-filters.component.scss'],
})
export class GridFiltersComponent implements OnInit, OnDestroy {
  protected readonly GridFilterType = GridFilterType;

  protected allFiltersConfig: FilterConfig[] = [];
  protected displayedFilters = new FormArray<FilterFormControl>([]);
  protected availableFilters: (MenuItem & { filter: FilterConfig })[] = [];
  protected fixedAddButtonVisibility = true;

  private readonly subscription = new Subscription();
  private readonly resetSubject = new Subject<void>();
  private readonly valuesSerializer = new FiltersValuesSerializer();

  @ViewChild('displayedFiltersContainer')
  private displayedFiltersContainerEl: ElementRef<HTMLDivElement>;
  @ViewChild('scrollbar')
  private scrollbarComponent: ScrollbarComponent;
  @ViewChild('scrollbar', { read: ElementRef })
  private scrollbarEl: ElementRef<HTMLDivElement>;
  private gridControlSubscription = new Subscription();
  private _gridControl: GridControl = null;

  constructor(
    protected cdr: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.translateService.lang$
        .subscribe(() => {
          this.updateAvailableFilters();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.resetSubject.complete();
    this.gridControlSubscription.unsubscribe();
  }

  @Input()
  public set gridControl(gridControl: GridControl) {
    this.gridControlSubscription.unsubscribe();

    if (!(gridControl instanceof GridControl)) {
      this._gridControl = null;

      return;
    }

    this._gridControl = gridControl;
    this.gridControlSubscription = new Subscription();

    this.gridControlSubscription.add(
      gridControl.filtersConfig$.pipe(
        switchMap((filtersConfig) => this.resetSubject.pipe(
          startWith(null),
          map(() => filtersConfig)
        )),
        tap((filtersConfig) => {
          this.allFiltersConfig = filtersConfig;
          this.valuesSerializer.setFiltersConfig(this.allFiltersConfig);
        }),
        switchMap((filtersConfig) => gridControl.filters$.pipe(
          map((filtersValues, index) => ({ filtersConfig, filtersConfigChanged: index === 0, filtersValues })))
        )
      )
        .subscribe(({ filtersValues, filtersConfig, filtersConfigChanged }) => {
          const parsedValues = this.valuesSerializer.parseValues(filtersValues);

          if (filtersConfigChanged) {
            this.displayDefaultFilters(parsedValues);

            return;
          }

          const displayedFiltersNames = this.getDisplayedFiltersNames();

          for (const displayedFilterName of displayedFiltersNames) {
            if (!parsedValues.hasOwnProperty(displayedFilterName)) {
              this.setFilterValue(displayedFilterName, '');
            }
          }

          Object.keys(parsedValues)
            .map((filterName) => filtersConfig.find(({ name }) => name === filterName))
            .filter((matchingConfig) => matchingConfig)
            .forEach((filterConfig) => {
              displayedFiltersNames.indexOf(filterConfig.name) === -1
                ? this.addFilter(filterConfig, true, parsedValues[filterConfig.name])
                : this.setFilterValue(filterConfig.name, parsedValues[filterConfig.name]);
            });

          this.updateAvailableFilters();
        })
    );

    this.gridControlSubscription.add(
      this.displayedFilters.valueChanges
        .pipe(debounceTime(500))
        .subscribe(() => {
          const filtersValue = this.displayedFilters.controls
            .reduce((result, control) => Object.assign(result, { [control.config.name]: control.value }), {});
          const filtersQueryParams = this.valuesSerializer.flattenValues(filtersValue);

          for (const key in filtersQueryParams) {
            if (!filtersQueryParams.hasOwnProperty(key) || !this.isValidFilterValue(filtersQueryParams[key])) {
              delete filtersQueryParams[key];
            }
          }

          gridControl.setFilters(filtersQueryParams);
        })
    );
  }

  public get gridControl(): GridControl {
    return this._gridControl;
  }

  protected get filtersEditable(): boolean {
    return this.allFiltersConfig.length > 4;
  }

  protected areFiltersPristine(): boolean {
    if (!this.gridControl) {
      return true;
    }

    return Object.keys(this.gridControl.getFilters()).length === 0
      && (
        !this.filtersEditable
        ||
          !this.displayedFilters.controls.find(({ config }) => !config.defaultInlineVisibility)
          && !this.availableFilters.find(({ filter }) => filter.defaultInlineVisibility)

      );
  }

  protected resetFilters(): void {
    this.gridControl?.setFilters({});
    this.resetSubject.next();
  }

  protected setFilterValue(filterName: string, value: string | string[] | Range): void {
    const matchingFilter = this.displayedFilters.controls.find(({ config }) => config.name === filterName);

    if (matchingFilter) {
      matchingFilter.setValue(value, { emitEvent: false });
    }
  }

  protected addFilter(config: FilterConfig, addedManually: boolean, initialValue?: string | string[] | Range): void {
    const filterControl = new FilterFormControl(config, initialValue ?? '');

    this.displayedFilters.push(filterControl);
    this.updateAvailableFilters();

    if (this.displayedFiltersContainerEl && addedManually) {
      this.cdr.detectChanges();
      this.checkFixedButtonVisibility();

      this.scrollbarComponent.scrollbar.scrollTo({ right: 0, duration: 500 }).then(() => {
        if (addedManually) {
          filterControl.activate();
        }
      });
    }
  }

  protected removeAllFilters(): void {
    while (this.displayedFilters.controls.length) {
      this.displayedFilters.removeAt(0, { emitEvent: false });
    }
  }

  protected removeFilter(control: FilterFormControl): void {
    const filterName = control.config?.name;

    for (let i = this.displayedFilters.controls.length - 1; i >= 0; i--) {
      if (this.displayedFilters.at(i).config.name === filterName) {
        this.displayedFilters.removeAt(i);
      }
    }

    this.updateAvailableFilters();
  }

  protected emptyFilterValue(control: FilterFormControl): void {
    if (control.value instanceof Range) {
      control.setValue(new Range());

      return;
    }

    if (control.value instanceof Array) {
      control.setValue([]);

      return;
    }

    control.setValue('');
  }

  protected hasOwnEmptyButton(control: FilterFormControl): boolean {
    return [GridFilterType.RANGE_NUMBER, GridFilterType.RANGE_DATE].includes(control.config?.type);
  }

  protected isFilterValueEmpty({ value }: FilterFormControl): boolean {
    if (value instanceof Range) {
      return value.isEmpty();
    }

    if (value instanceof Array) {
      return value.length === 0;
    }

    if ('string' === typeof value) {
      return !value.trim();
    }

    return !value;
  }

  protected updateAvailableFilters(): void {
    const displayedFiltersNames = this.getDisplayedFiltersNames();

    this.availableFilters = this.allFiltersConfig
      .filter((filterConfig) => displayedFiltersNames.indexOf(filterConfig.name) === -1)
      .map((filterConfig) => ({
        label: this.translateService.instant(filterConfig.label),
        command: () => this.addFilter(filterConfig, true),
        dataQa: `filter-menu-item-${filterConfig.name}`,
        filter: filterConfig,
      }))
      .sort((a, b) => a.label.localeCompare(b.label, void 0, { numeric: true, sensitivity: 'base' }));

    this.cdr.detectChanges();
    this.checkFixedButtonVisibility();
  }

  private displayDefaultFilters(values): void {
    const defaultFilters = this.allFiltersConfig
      .filter(({ defaultInlineVisibility }) => defaultInlineVisibility);
    const defaultFiltersNames = defaultFilters
      .map(({ name }) => name);
    const valueFiltersNames = Object.keys(values);
    const filtersToDisplay = this.filtersEditable
      ? valueFiltersNames.some((valueFilterName) => !defaultFiltersNames.includes(valueFilterName))
        ? this.allFiltersConfig.filter(({ name }) => valueFiltersNames.includes(name))
        : defaultFilters
      : this.allFiltersConfig.slice();

    for (let i = this.displayedFilters.controls.length - 1; i >= 0; i--) {
      const filterControl = this.displayedFilters.at(i);
      const matchingIndex = filtersToDisplay.findIndex((config) => config.name === filterControl.config.name);

      if (matchingIndex !== -1) {
        filterControl.setValue(values[filterControl.config.name], { emitEvent: false });
        filterControl.markAsPristine();
        filtersToDisplay.splice(matchingIndex, 1);
      } else {
        this.displayedFilters.removeAt(i);
      }
    }

    filtersToDisplay.forEach((config) => {
      this.addFilter(config, false, values[config.name]);
    });

    this.displayedFilters.controls.sort((a, b) => this.allFiltersConfig.indexOf(a.config) - this.allFiltersConfig.indexOf(b.config)
    );

    this.updateAvailableFilters();
    this.displayedFilters.markAsPristine();
  }

  private getDisplayedFiltersNames(): string[] {
    return this.displayedFilters.controls.map((filter) => filter.config.name);
  }

  private isValidFilterValue(value: string | string[]): boolean {
    if (value instanceof Array) {
      return value.length > 0;
    }

    return 'string' === typeof value && !!value.trim();
  }

  @HostListener('window: resize')
  private checkFixedButtonVisibility(): void {
    this.fixedAddButtonVisibility =
      this.displayedFiltersContainerEl?.nativeElement.offsetWidth > this.scrollbarEl?.nativeElement.offsetWidth;
  }
}

<div
  (click)="changeOrder()"
  data-qa="change-order-button"
  class="order-container"
>
  <div class="order-label">
    <ng-content></ng-content>
  </div>
  <div class="order-symbol theme-color">
    <span
      [hidden]="isDescOrder()"
      class="order-icon caret caret-up"
    ></span>
    <span
      [hidden]="isAscOrder()"
      class="order-icon caret caret-down"
    ></span>
  </div>
</div>

import { Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogsCountService } from '@proget-shared/dialog';
import { LocalStorageService, UserStorageService } from '@proget-shared/storage';
import { SpinnerService } from '@proget-shared/ui/spinner';
import { distinctUntilChanged, map, noop, Subscription } from 'rxjs';

import { Guest, User } from './core/model';
import { CautionService, UserService } from './core/service';

const SLIM_MENU = 'slimMenu';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class.login')
  protected isLoginClass = true;
  @HostBinding('class.slim-sidebar')
  protected isSlimMenu = false;

  protected toggleMenuButton: { [key: string]: string } = {
    icon: null,
    name: 'menu.item.hide_menu.name',
  };

  private readonly subscription = new Subscription();

  private userServiceOnChangeSubscription = new Subscription();
  @ViewChild('scrollbar', { read: ElementRef })
  private scrollbarEl: ElementRef;

  constructor(
    private cautionService: CautionService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private dialogsCountService: DialogsCountService,
    private userStorageService: UserStorageService,
    spinnerService: SpinnerService
  ) {
    spinnerService.spinnerVisibility$.subscribe({
      next: (visibility) => {
        const loaderElement = document.getElementById('proget-loader');

        if (!loaderElement) {
          return;
        }

        visibility
          ? loaderElement.classList.remove('loader-hidden')
          : loaderElement.classList.add('loader-hidden');
      },
    });

    if (this.localStorageService.get(SLIM_MENU)) {
      this.isSlimMenu = true;
    }

    this.updateLoginClass();
    this.watchDialogs();
    this.toggleButtonIcon();
  }

  ngOnInit(): void {
    this.userService.set();
    this.cautionService.display();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.userServiceOnChangeSubscription.unsubscribe();
  }

  @HostListener('window: resize')
  protected updateScrollbarHeight(): void {
    this.scrollbarEl.nativeElement.firstChild.style.height = document.body.clientHeight;
  }

  protected toggleSidebar(): void {
    this.isSlimMenu = !this.isSlimMenu;
    this.localStorageService.set(SLIM_MENU, this.isSlimMenu);
    this.toggleButtonIcon();
  }

  private updateLoginClass(): void {
    this.userServiceOnChangeSubscription = this.userService.onChange.subscribe({
      next: (user) => {
        this.isLoginClass = user instanceof Guest;
        user && user instanceof User
          ? this.userStorageService.setUserId(window.btoa(user?.email).replace(/.(.)?/g, '$1'))
          : this.userStorageService.clearUserId();
      },
      error: noop,
    });
  }

  private toggleButtonIcon(): void {
    this.toggleMenuButton.icon = this.isSlimMenu ? 'icon-double-next-icon' : 'icon-double-prev-icon';
  }

  private watchDialogs(): void {
    this.subscription.add(
      this.dialogsCountService.staticDialogsCount$
        .pipe(
          map((count) => count > 0),
          distinctUntilChanged()
        )
        .subscribe({
          next: (dialogsVisible) => {
            document.body.classList.remove('dialog-active');

            if (dialogsVisible) {
              document.body.classList.add('dialog-active');
            }
          },
        })
    );
  }
}

import { Injectable } from '@angular/core';
import {
  debounce,
  delay,
  distinctUntilChanged,
  map,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  public spinnerVisibility$: Observable<boolean>;

  private requestsCounterSubject = new ReplaySubject<number>(1);
  private savedVisibilitySubject = new ReplaySubject<boolean>(1);
  private pendingRequestsCount = 0;
  private currentVisibility$: Observable<boolean> = this.requestsCounterSubject.pipe(
    startWith(this.pendingRequestsCount),
    map((pendingRequestsCount) => pendingRequestsCount > 0),
    shareReplay(1)
  );

  constructor() {
    this.spinnerVisibility$ = this.currentVisibility$.pipe(
      withLatestFrom(
        this.savedVisibilitySubject.pipe(delay(300), startWith(this.pendingRequestsCount > 0))
      ),
      debounce(([visibility, lastVisibility]) => of(true).pipe(
        delay(this.getVisibilityDelayTime(visibility, lastVisibility))
      )
      ),
      map(([visibility]) => visibility),
      distinctUntilChanged(),
      tap({
        next: (visibility) => {
          this.savedVisibilitySubject.next(visibility);
        },
      }),
      shareReplay(1)
    );
  }

  public show(): void {
    this.requestsCounterSubject.next(++this.pendingRequestsCount);
  }

  public hide(): void {
    this.requestsCounterSubject.next(--this.pendingRequestsCount);
  }

  public update(): void {
    this.requestsCounterSubject.next(this.pendingRequestsCount);
  }

  private getVisibilityDelayTime(visibility: boolean, lastVisibility: boolean): number {
    // if previously loader was OFF wait 250ms to ignore single short requests
    if (visibility && !lastVisibility) {
      return 250;
    }

    // instant hiding and
    // if previously loader was ON keep visibility instant
    return 0;
  }
}

import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { OverlayPanelDirective, OverlayPanelOptions } from '@proget-shared/ui/overlay-panel';
import { Subscription } from 'rxjs';

import { MenuService } from '../service/menu.service';
import { MenuItem } from '../type/menu-item.type';
import { MenuStatus } from '../type/menu-status.type';

@Directive({
  selector: '[appMenu]',
  providers: [OverlayPanelDirective],
})
export class MenuDirective implements OnDestroy {
  @Output()
  public readonly appMenuStatus = new EventEmitter<MenuStatus>();

  @Input()
  public appMenu: MenuItem[];

  private readonly subscription: Subscription;
  private readonly menuOptions: Partial<OverlayPanelOptions> = { padding: 0, target: 'body' };

  private opened = false;

  constructor(
    private overlayPanel: OverlayPanelDirective,
    menuService: MenuService
  ) {
    overlayPanel.appOverlayPanel = menuService.getTemplate();
    overlayPanel.overlayPanelOptions = this.menuOptions;
    overlayPanel.overlayPanelClass = 'menu-overlay-panel';

    this.subscription = overlayPanel.overlayPanelStatus
      .subscribe((status) => {
        this.opened = status.opened;
        this.appMenuStatus.emit(Object.assign({ menu: this }, status));

        if (status.opened) {
          overlayPanel.overlayPanelData = Array.isArray(this.appMenu)
            ? this.appMenu.map(({ label, command, dataQa }) => ({
              label, command: this.wrapCommand(command), dataQa,
            }))
            : [];
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set appMenuOptions(options: Partial<OverlayPanelOptions>) {
    this.overlayPanel.overlayPanelOptions = Object.assign({}, this.menuOptions, options);
  }

  @Input()
  public set appMenuEnabled(enabled: boolean) {
    this.overlayPanel.overlayPanelEnabled = enabled;
  }

  public toggle(): void {
    this.opened ? this.close() : this.open();
  }

  public open(): void {
    this.overlayPanel.open();
  }

  public close(): void {
    this.overlayPanel.close();
  }

  private wrapCommand(command: Function): Function {
    return () => {
      if (!this.opened) {
        return;
      }

      this.close();

      typeof command === 'function' ? command() : () => void 0;
    };
  }
}

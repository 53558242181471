import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { QueryParams } from '@proget-shared/_common';
import { filter, noop } from 'rxjs';

import { GridControlHelper } from '../class/grid-control-helper.class';
import { GridControl } from '../class/grid-control.class';

@Injectable({
  providedIn: 'root',
})
export class ParamsStorageService {
  private readonly storage: { [urlMask: string]: { [id: string]: QueryParams } } = {};

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((navigationEnd) => navigationEnd instanceof NavigationEnd))
      .subscribe({
        next: (navigationEnd: NavigationEnd) => {
          const slashEndingUrl = /\/$/.test(navigationEnd.urlAfterRedirects)
            ? navigationEnd.urlAfterRedirects
            : `${navigationEnd.urlAfterRedirects}/`;

          for (const urlMask in this.storage) {
            if (slashEndingUrl.indexOf(urlMask) !== 0) {
              this.clear(urlMask);
            }
          }
        },
        error: noop,
      });
  }

  public save(urlMask: string, controls: { [id: string]: GridControl } = {}): void {
    const controlsParams: { [id: string]: QueryParams } = {};

    for (const id in controls) {
      if (!controls.hasOwnProperty(id)) {
        continue;
      }

      controlsParams[id] = controls[id].params;
    }

    this.storage[urlMask] = controlsParams;
  }

  public getParams(urlMask: string, id: string): QueryParams {
    return this.storage[urlMask]?.[id] ?? {};
  }

  public clear(urlMask: string): void {
    delete this.storage[urlMask];
  }

  public clearForUrl(url: string): void {
    this.clear(GridControlHelper.getUrlMask(url));
  }
}

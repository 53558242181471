import { Injectable } from '@angular/core';
import { Observable, filter, fromEvent, share, tap } from 'rxjs';

import { ShortcutOptions } from './shortcut-options.type';

@Injectable({ providedIn: 'root' })
export class ShortcutService {
  private readonly defaultOptions: ShortcutOptions = { ctrl: false, shift: false, target: null, preventDefault: true };
  private readonly keydown$ = fromEvent<KeyboardEvent>(document, 'keydown').pipe(share());

  public on(key: string | string[], options: Partial<ShortcutOptions> = {}): Observable<KeyboardEvent> {
    const { ctrl, shift, target, preventDefault } = Object.assign({}, this.defaultOptions, options);
    const lowerCaseKey = (Array.isArray(key) ? key : [key]).map((item) => item.toLocaleLowerCase());

    return this.keydown$.pipe(
      filter((event) => typeof event?.key === 'string'
        && lowerCaseKey.includes(event.key.toLocaleLowerCase())
        && (ctrl === 'any' || ctrl === (event.ctrlKey || event.metaKey))
        && (shift === 'any' || shift === event.shiftKey)
        && (!target || target.contains(event.target as Element))
      ),
      tap((event) => {
        if (preventDefault) {
          event.preventDefault();
        }
      })
    );
  }
}

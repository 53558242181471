import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipComponent } from './component/tooltip/tooltip.component';
import { TooltipCloudComponent } from './component/tooltip-cloud/tooltip-cloud.component';
import { TooltipIconComponent } from './component/tooltip-icon/tooltip-icon.component';
import { TooltipDirective } from './directive/tooltip.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TooltipComponent, TooltipCloudComponent, TooltipDirective, TooltipIconComponent],
  exports: [TooltipComponent, TooltipDirective, TooltipIconComponent],
})
export class TooltipModule {}

import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { ToastContainerService } from '../../service/toast-container.service';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
})
export class ToastContainerComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  private containerRef: ViewContainerRef;

  constructor(
    private containerService: ToastContainerService
  ) {}

  ngOnInit(): void {
    this.containerService.registerContainer(this);
  }

  public getRef(): ViewContainerRef {
    return this.containerRef;
  }
}

import { Component, ElementRef, forwardRef, Input, OnDestroy, Optional, Provider, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Subscription } from 'rxjs';

import { FilterActivatorDirective } from '../../directive/filter-activator.directive';
import { FilterInputConfig } from '../../type/filter-config.type';

const FILTER_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FilterInputComponent),
  multi: true,
};

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
  providers: [FILTER_INPUT_VALUE_ACCESSOR],
})
export class FilterInputComponent implements OnDestroy, ControlValueAccessor {
  @Input()
  public config: FilterInputConfig;

  protected formControl: FormControl = new FormControl('');

  private readonly subscription = new Subscription();

  @ViewChild('input')
  private inputElement: ElementRef<HTMLInputElement>;
  private modelTouched: () => void = noop;
  private modelChanged: (value: string) => void = noop;

  constructor(@Optional() filterActivator: FilterActivatorDirective) {
    this.subscription.add(
      this.formControl.valueChanges.subscribe({
        next: (value: string) => {
          this.modelChanged(value);
          this.modelTouched();
        },
      })
    );

    this.subscription.add(
      filterActivator?.activate$.subscribe({
        next: () => {
          this.inputElement.nativeElement.focus({ preventScroll: true });
        },
      })
    );
  }

  writeValue(value: string): void {
    setTimeout(() => {
      this.formControl.setValue(value || '');
    });
  }

  registerOnChange(fn: (value: string) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable({ emitEvent: false }) : this.formControl.enable({ emitEvent: false });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  protected trimInsertedText(event: ClipboardEvent): void {
    if (!this.config.hasOwnProperty('trimOnPaste') || this.config.trimOnPaste) {
      event.preventDefault();
      this.formControl.setValue(event.clipboardData.getData('text').trim());
    }
  }
}

import {
  ApplicationRef,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ShortcutService } from '@proget-shared/helper';
import {
  delay,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs';

import { OverlayPanelComponent } from '../component/overlay-panel.component';
import { OverlayPanelTrigger } from '../enum/overlay-panel-trigger.enum';
import { OverlayPanelService } from '../service/overlay-panel.service';
import { OverlayPanelOptions } from '../type/overlay-panel-options.type';
import { OverlayPanelStatus } from '../type/overlay-panel-status.type';

@Directive({
  selector: '[appOverlayPanel]',
})
export class OverlayPanelDirective implements OnDestroy {
  @Output()
  public readonly overlayPanelStatus = new EventEmitter<OverlayPanelStatus>();
  @Output()
  public readonly overlayPanelDestroy = new EventEmitter<void>();

  @Input()
  public appOverlayPanel: TemplateRef<any>;

  private readonly defaultOptions: OverlayPanelOptions = {
    animationDuration: 300,
    matchWidth: false,
    padding: 10,
    referer: null,
    target: 'here',
    closeOtherPanels: true,
    closeOnScroll: false,
    closeOnResize: true,
    closeOnToggle: true,
    scrollContent: true,
    appearance: 'prefer-below',
    maxHeight: Infinity,
  };
  private readonly subscription = new Subscription();
  private readonly openedSubject = new Subject<boolean>();

  private enabled = true;
  private panelClass = '';
  private panel: ComponentRef<OverlayPanelComponent> | null = null;
  private options: Partial<OverlayPanelOptions> = {};
  private destroyTimeout: any;
  private _overlayPanelData: any;
  private eventsSubscription = new Subscription();
  private trigger: OverlayPanelTrigger[] = [OverlayPanelTrigger.LMB];
  private _hasFocus = false;

  constructor(
    private appRef: ApplicationRef,
    private viewRef: ViewContainerRef,
    private elementRef: ElementRef<HTMLElement>,
    private overlayPanelService: OverlayPanelService,
    private shortcutService: ShortcutService
  ) {
    this.subscription.add(
      overlayPanelService.close$.pipe(
        filter((caller) => this.panel && caller !== this.panel.instance)
      )
        .subscribe({
          next: () => {
            this.close();
          },
        })
    );

    this.subscription.add(
      merge(
        // LMB
        fromEvent<MouseEvent>(elementRef.nativeElement, 'mousedown').pipe(
          takeWhile(() => this.trigger.includes(OverlayPanelTrigger.LMB)),
          filter((event) => event.button === 0)
        ),
        // RMB
        fromEvent<MouseEvent>(elementRef.nativeElement, 'contextmenu').pipe(
          takeWhile(() => this.trigger.includes(OverlayPanelTrigger.RMB)),
          tap({
            next: (event) => {
              if (this.enabled) {
                event.preventDefault();
              }
            },
          })
        )
      ).pipe(
        map((event) => ({
          event,
          panelOpened: !!this.panel && !this.panel.instance.destroying,
        })),
        switchMap((eventData) => fromEvent<MouseEvent>(elementRef.nativeElement, 'mouseup').pipe(
          takeUntil(fromEvent(document, 'mouseup')),
          take(1),
          map(() => eventData)
        ))
      )
        .subscribe({
          next: ({ event, panelOpened }) => {
            const trigger = event.button === 0
              ? this.options.referer instanceof HTMLElement ? this.options.referer : this.elementRef.nativeElement
              : event;

            if (panelOpened && !this.options.closeOnToggle) {
              return;
            }

            panelOpened ? this.close() : this.open(trigger);
          },
        })
    );

    this.subscription.add(
      this.openedSubject.pipe(
        distinctUntilChanged()
      )
        .subscribe({
          next: (opened) => {
            opened
              ? this.elementRef.nativeElement.classList.add('overlay-panel-opened')
              : this.elementRef.nativeElement.classList.remove('overlay-panel-opened');

            this.overlayPanelStatus.emit({ opened, panel: opened ? this.panel.instance : null });
          },
        })
    );

    this.subscription.add(
      shortcutService.on('Space', { target: elementRef.nativeElement })
        .subscribe({
          next: () => {
            this.open();
          },
        })
    );

    this.subscription.add(
      fromEvent(elementRef.nativeElement, 'focus').pipe(
        filter(() => this.enabled),
        tap({
          next: () => {
            this._hasFocus = true;
          },
        }),
        switchMap(() => this.onBlur(elementRef.nativeElement))
      )
        .subscribe({
          next: () => {
            this.close();
            this._hasFocus = false;
          },
        })
    );

    this.options = Object.assign({}, this.defaultOptions);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.eventsSubscription.unsubscribe();
    this.openedSubject.complete();
    this.close(true);
    this.overlayPanelStatus.complete();
    this.overlayPanelDestroy.complete();
  }

  @Input()
  public set overlayPanelOptions(options: Partial<OverlayPanelOptions>) {
    this.options = Object.assign({}, this.defaultOptions, options);
    this.updatePanelOptions();
  }

  @Input()
  public set overlayPanelData(data: any) {
    this._overlayPanelData = data;

    if (this.panel) {
      this.panel.instance.templateData = data;
    }
  }

  public get overlayPanelData(): any {
    return this._overlayPanelData;
  }

  @Input()
  public set overlayPanelEnabled(enabled: boolean) {
    if (!enabled) {
      if (this.isDescendant(document.activeElement)) {
        (document.activeElement as HTMLElement).blur();
      }

      this.close();
    }

    enabled
      ? this.elementRef.nativeElement.classList.remove('overlay-panel-disabled')
      : this.elementRef.nativeElement.classList.add('overlay-panel-disabled');

    this.enabled = enabled;
  }

  @Input()
  public set overlayPanelClass(panelClass: string) {
    const previousPanelClass = this.panelClass;

    this.panelClass = panelClass;

    if (!this.panel) {
      return;
    }

    if (previousPanelClass) {
      this.panel.location.nativeElement.classList.remove(previousPanelClass);
    }

    if (panelClass) {
      this.panel.location.nativeElement.classList.add(panelClass);
    }

    this.panel.instance.updatePosition(true);
  }

  @Input()
  public set overlayPanelTrigger(trigger: OverlayPanelTrigger | OverlayPanelTrigger[]) {
    this.trigger = (trigger instanceof Array ? trigger : [trigger])
      .filter((item) => [OverlayPanelTrigger.LMB, OverlayPanelTrigger.RMB].includes(item));
  }

  @HostBinding('class.overlay-panel-focus')
  public get hasFocus(): boolean {
    return this._hasFocus;
  }

  public open(referer: MouseEvent | HTMLElement = this.elementRef.nativeElement): void {
    if (!this.enabled) {
      return;
    }

    if (this.panel) {
      clearTimeout(this.destroyTimeout);
      this.panel.instance.destroying = false;
      this.afterOpen();

      return;
    }

    const componentRef = typeof this.options.target === 'string'
      ? this.options.target === 'body'
        // TODO check
        ? this.appRef.components[0].injector.get(ViewContainerRef).createComponent(OverlayPanelComponent)
        : this.viewRef.createComponent(OverlayPanelComponent)
      : this.options.target.createComponent(OverlayPanelComponent);

    componentRef.instance.setTemplate(this.appOverlayPanel);
    componentRef.instance.templateData = this.overlayPanelData;
    componentRef.instance.referer = referer;
    componentRef.instance.parentDirective = this;

    this.panel = componentRef;
    this.overlayPanelClass = this.panelClass; // update panel style

    this.updatePanelOptions();
    this.afterOpen();
  }

  public close(instant = false): void {
    if (!this.panel) {
      return;
    }

    this.openedSubject.next(false);
    this.eventsSubscription.unsubscribe();

    if (!instant) {
      this.panel.instance.destroying = true;

      clearTimeout(this.destroyTimeout);
      this.destroyTimeout = setTimeout(() => {
        this.close(true);
      }, this.options.animationDuration);

      return;
    }

    if (this.isDescendant(document.activeElement)) {
      // remove focus from the element to be removed
      this.elementRef.nativeElement.focus({ preventScroll: true });
    }

    this.overlayPanelDestroy.emit();
    this.panel.destroy();
    this.panel = null;
  }

  public isDescendant(element: Element): boolean {
    if (!element) {
      return false;
    }

    let currentElement = element;

    while (currentElement instanceof Node) {
      if (
        currentElement === this.panel?.location.nativeElement ||
        currentElement === this.elementRef.nativeElement
      ) {
        return true;
      }

      currentElement = currentElement.parentElement;
    }

    return false;
  }

  private afterOpen(): void {
    this.eventsSubscription.unsubscribe();
    this.eventsSubscription = new Subscription();

    if (!this.getParentPanel() && this.options.closeOtherPanels) {
      this.overlayPanelService.closeOthers(this.panel.instance);
    }

    this.eventsSubscription.add(
      merge(
        this.shortcutService.on('Escape'),
        fromEvent(document, 'mousedown').pipe(
          filter((event) => this.options.closeOnToggle || !this.isDescendant(event.target as HTMLElement))
        )
      )
        .pipe(delay(0))
        .subscribe({
          next: () => {
            this.close();
          },
        })
    );

    this.eventsSubscription.add(
      merge(...this.getParents().map((element) => fromEvent(element, 'scroll')))
        .subscribe({
          next: () => {
            this.options.closeOnScroll
              ? this.close()
              : this.panel?.instance.updatePosition(true);
          },
        })
    );

    if (this.options.closeOnResize) {
      this.eventsSubscription.add(
        fromEvent(window, 'resize')
          .subscribe({
            next: () => {
              this.close();
            },
          })
      );
    }

    this.eventsSubscription.add(
      this.panel.instance.init$.pipe(take(1)).subscribe({
        next: () => {
          this.openedSubject.next(true);
        },
      })
    );
  }

  private getParents(element: Element = this.elementRef.nativeElement, parents: (Element | Document)[] = []): (Element | Document)[] {
    return element.parentElement
      ? this.getParents(element.parentElement, parents.concat(element.parentElement))
      : parents.concat(document);
  }

  private updatePanelOptions(): void {
    if (!this.panel) {
      return;
    }

    this.panel.instance.animationDuration = this.options.animationDuration;
    this.panel.instance.matchWidth = this.options.matchWidth;
    this.panel.instance.padding = this.options.padding;
    this.panel.instance.scrollContent = this.options.scrollContent;
    this.panel.instance.appearance = this.options.appearance;
    this.panel.instance.maxHeight = this.options.maxHeight;
  }

  private onBlur(element: Element): Observable<FocusEvent> {
    return fromEvent<FocusEvent>(element, 'blur').pipe(
      delay(0),
      take(1),
      switchMap((event) => (this.isDescendant(document.activeElement)
        ? this.onBlur(document.activeElement)
        : of(event))
      )
    );
  }

  private getParentPanel(): HTMLElement | null {
    let currentElement = this.elementRef.nativeElement;

    do {
      if (currentElement.tagName.toLocaleLowerCase() === 'app-overlay-panel') {
        return currentElement;
      }

      currentElement = currentElement.parentElement;
    } while (currentElement !== document.body);

    return null;
  }
}

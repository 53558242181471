import { Component, forwardRef, Input, OnDestroy, Optional, Provider, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent, DropdownItem } from '@proget-shared/form/dropdown';
import { noop, Subscription } from 'rxjs';

import { FilterActivatorDirective } from '../../directive/filter-activator.directive';
import { FilterAutocompleteConfig } from '../../type/filter-config.type';

const FILTER_AUTOCOMPLETE_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FilterAutocompleteComponent),
  multi: true,
};

@Component({
  selector: 'app-filter-autocomplete',
  templateUrl: './filter-autocomplete.component.html',
  styleUrls: ['./filter-autocomplete.component.scss'],
  providers: [FILTER_AUTOCOMPLETE_VALUE_ACCESSOR],
})
export class FilterAutocompleteComponent implements OnDestroy, ControlValueAccessor {
  @Input()
  public config: FilterAutocompleteConfig;
  @Input()
  public showClear = true;
  @Input()
  public panelReferer: HTMLElement;

  protected suggestions: DropdownItem[] = [];
  protected formControl: FormControl = new FormControl(null);
  protected options: DropdownItem[] = [];

  private modelTouched: () => void = noop;
  private modelChanged: (value: string) => void = noop;
  private readonly subscription = new Subscription();
  @ViewChild('dropdown')
  private dropdown: DropdownComponent;

  constructor(@Optional() filterActivator: FilterActivatorDirective) {
    this.subscription.add(
      this.formControl.valueChanges.subscribe({
        next: (item) => {
          this.modelChanged(item ? item.value : '');
          this.modelTouched();
        },
      })
    );

    this.subscription.add(
      filterActivator?.activate$.subscribe({
        next: () => {
          this.dropdown.openPanel();
        },
      })
    );
  }

  writeValue(value: string): void {
    if (!this.formControl.value || value !== this.formControl.value.value) {
      const label: string = value ? `(id: ${value})` : '';

      this.formControl.setValue(value ? { label, value } : null);
      this.options = value ? [{ label, value: { label, value } }] : [];
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable({ emitEvent: false }) : this.formControl.enable({ emitEvent: false });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  protected search(query: string): void {
    if (
      !this.config
      || 'function' !== typeof this.config.callback
      || query.length < (isFinite(Number(this.config.queryLength)) ? this.config.queryLength : 3)
    ) {
      this.suggestions = [];

      return;
    }

    this.config.callback(query).subscribe({
      next: (items) => {
        this.suggestions = items.map((item) => ({ label: item.label, value: item }));
      },
      error: () => {
        this.suggestions = [];
      },
    });
  }
}

<div
  [formGroup]="rangeFormGroup"
  class="range-group"
>
  <input
    #inputFrom
    [class.invalid-value]="rangeFormGroup.get('from').invalid"
    [max]="maxValueFrom"
    [min]="minValueFrom"
    [placeholder]="config?.placeholder | translate"
    data-qa="filter-range-from-input"
    formControlName="from"
    appInput
    type="number"
    class="range-input"
  >
  <span class="range-separator">-</span>
  <input
    [class.invalid-value]="rangeFormGroup.get('to').invalid"
    [max]="maxValueTo"
    [min]="minValueTo"
    [placeholder]="config?.placeholder | translate"
    data-qa="filter-range-to-input"
    formControlName="to"
    appInput
    type="number"
    class="range-input"
  >
</div>

import { DialogOptions } from '../type/dialog-options.type';

export const DEFAULT_DIALOG_OPTIONS: DialogOptions = {
  animationDuration: 200,
  cancellable: false,
  configuration: {},
  floating: false,
  formAutofocus: true,
  persist: false,
  returnDialogInstance: false,
  zIndex: 1001,
  backdrop: true,
  styleClass: '',
  tags: [],
  // floating options only:
  resizable: false,
  resizeToCenter: false,
  minHeight: 100,
  minWidth: 200,
};

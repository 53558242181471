export const Activation = {
  ANDROID_ANTI_FRAUD: 'android_anti_fraud',
  ANDROID_MDM: 'android_mdm',
  ANDROID_FOR_WORK_DEVICE_OWNER: 'android_for_work_device_owner',
  ANDROID_FOR_WORK_WORK_PROFILE: 'android_for_work_work_profile',
  KNOX_MDM: 'knox_mdm',
  IOS_MDM: 'ios_mdm',
  MACOS_MDM: 'macos_mdm',
  WINDOWS_MDM: 'windows_mdm',
};

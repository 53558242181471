import { Component, forwardRef, Input, OnDestroy, Optional, Provider, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent, DropdownItem } from '@proget-shared/form/dropdown';
import { BehaviorSubject, noop, Observable, Subscription } from 'rxjs';

import { DEFAULT_SELECT_CONFIG } from '../../const/default-select-config.const';
import { FilterActivatorDirective } from '../../directive/filter-activator.directive';
import { FilterMultiselectConfig } from '../../type/filter-config.type';

const FILTER_MULTISELECT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FilterMultiselectComponent),
  multi: true,
};

@Component({
  selector: 'app-filter-multiselect',
  templateUrl: './filter-multiselect.component.html',
  styleUrls: ['./filter-multiselect.component.scss'],
  providers: [FILTER_MULTISELECT_VALUE_ACCESSOR],
})
export class FilterMultiselectComponent implements OnDestroy, ControlValueAccessor {
  @Input()
  public showClear = true;
  @Input()
  public panelReferer: HTMLElement;

  protected formControl = new FormControl([]);
  protected dropdownOptions$: Observable<DropdownItem[]>;
  protected itemTemplate: TemplateRef<any> = null;
  protected isValid = true;

  private readonly subscription = new Subscription();
  private readonly optionsSubject = new BehaviorSubject<DropdownItem[]>([]);

  private modelTouched: () => void = noop;
  private modelChanged: (value: string[]) => void = noop;
  private _config: FilterMultiselectConfig;
  @ViewChild('dropdown')
  private dropdown: DropdownComponent;

  constructor(@Optional() filterActivator: FilterActivatorDirective) {
    this.subscription.add(
      this.formControl.valueChanges.subscribe({
        next: (value: string[]) => {
          this.modelChanged(value);
          this.modelTouched();
        },
      })
    );

    this.subscription.add(
      this.optionsSubject.subscribe({
        next: () => {
          this.reloadFormValue();
        },
      })
    );

    this.subscription.add(
      filterActivator?.activate$.subscribe({
        next: () => {
          this.dropdown.openPanel();
        },
      })
    );

    this.dropdownOptions$ = this.optionsSubject.asObservable();
  }

  writeValue(value: string[]): void {
    this.setFormValue(value);
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable({ emitEvent: false }) : this.formControl.enable({ emitEvent: false });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  @Input()
  public set config(config: FilterMultiselectConfig) {
    this.itemTemplate = config.template?.dropdownItem || null;
    this.optionsSubject.next(config.options);
    this._config = Object.assign({}, DEFAULT_SELECT_CONFIG, config);
  }

  public get config(): FilterMultiselectConfig {
    return this._config;
  }

  protected getOptionByValue(value: string, options: DropdownItem[]): any {
    const defaultLabel: any = this.itemTemplate ? {} : '';
    const emptyOption: DropdownItem = { label: defaultLabel, value };

    if (!options) {
      return emptyOption;
    }

    const matchingOption: DropdownItem = options.find((option) => option.value === value);

    return matchingOption ? matchingOption : emptyOption;
  }

  private reloadFormValue(): void {
    this.setFormValue(this.formControl.value);
  }

  private setFormValue(values: string[]): void {
    const properValues: string[] = this.extractProperValues(values);

    this.isValid = !values || Array.isArray(values) && properValues.length === values.length;
    this.formControl.setValue(properValues);
  }

  private extractProperValues(values: string[]): string[] {
    if (!Array.isArray(values)) {
      return [];
    }

    if (values.length === 0 || !this.config || !Array.isArray(this.config.options)) {
      return values.slice();
    }

    const optionsValues: string[] = this.config.options.map((option) => option.value);

    return values.filter((value) => optionsValues.indexOf(value) !== -1);
  }
}

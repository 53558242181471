import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
})
export class DialogHeaderComponent {
  @Input()
  public header: string;
  @Input()
  public iconClass = '';

  @HostBinding('class.dialog-header')
  protected readonly dialogHeader = true;
}

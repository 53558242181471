<div
  [hidden]="!headerVisibility"
  class="options-header"
>
  <div
    *ngIf="configuration.multiple && configuration.showToggleAll"
    class="toggle-all-checkbox"
  >
    <app-checkbox
      [binary]="true"
      [formControl]="rootGroup.groupSelection"
      [indeterminate]="rootGroup.anyChildSelected"
      [tabIndex]="-1"
    ></app-checkbox>
  </div>
  <div
    [hidden]="!configuration.filter"
    tabindex="-1"
    class="filter-input"
  >
    <input
      #filterInput
      [formControl]="form.get('filter')"
      (focus)="setActiveIndex(-1)"
      (keydown.arrowDown)="keyNavigation($event, 1)"
      (keydown.arrowUp)="keyNavigation($event, -1)"
    >
  </div>
</div>
<app-scrollbar
  #scrollbar
  [autoWidthDisabled]="false"
  track="vertical"
>
  <ul
    #rootGroup
    [appOptionsGroup]="root"
    [class.multiple]="configuration.multiple"
    (mouseleave)="setActiveIndex(-1)"
    class="root-group"
  ></ul>
</app-scrollbar>
<app-ellipsis
  [hidden]="hasVisibleOptions"
  class="empty-message"
>
  <span>{{ emptyOptionsMessage }}</span>
</app-ellipsis>

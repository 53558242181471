import { Injectable } from '@angular/core';

@Injectable()
export abstract class AuthTokenService {
  /**
   * Returns complete token with Bearer prefix
   */
  abstract getPrefixedToken(): string;
  /**
   * Returns if token is set
   */
  abstract hasToken(): boolean;
}

export class Clipboard {
  public static setValue(value: string): void {
    const copyBox: HTMLTextAreaElement = document.createElement('textarea');

    copyBox.style.position = 'fixed';
    copyBox.style.left = '0';
    copyBox.style.top = '0';
    copyBox.style.opacity = '0';
    copyBox.value = value;

    document.body.appendChild(copyBox);
    copyBox.focus();
    copyBox.select();

    // eslint-disable-next-line deprecation/deprecation
    document.execCommand('copy');
    document.body.removeChild(copyBox);
  }
}

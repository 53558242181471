import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@proget-shared/translate';
import { forkJoin, noop, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CautionService implements OnDestroy {
  private langChangeSubscription = new Subscription();

  constructor(private translate: TranslateService) {}

  ngOnDestroy(): void {
    this.langChangeSubscription.unsubscribe();
  }

  public display(): void {
    this.langChangeSubscription = this.translate.lang$.subscribe({
      next: (): void => {
        this.consoleLogWarnings();
      },
      error: noop,
    });
  }

  private consoleLogWarnings(): void {
    const fontFamily = 'font-family: helvetica;';

    forkJoin([this.translate.get('app.console_warning.title'), this.translate.get('app.console_warning.message')]).subscribe({
      next: ([title, message]) => {
        console.clear();
        console.info(`%c${title}!\n`, `${fontFamily} color: red; font-size: 55px;`);
        console.info(`%c${message}`, `${fontFamily} font-size: 20px;`);
      },
      error: noop,
    });
  }
}

import { ErrorHandler as CoreErrorHandler, Injectable, OnDestroy } from '@angular/core';
import { User } from '@core/model';
import { UserService } from '@core/service';
import { captureException, init, replayIntegration, setUser } from '@sentry/angular';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';

init({
  dsn: environment.sentry.dsn,
  release: environment.version,
  attachStacktrace: true,
  allowUrls: [
    location.host,
  ],
  ignoreErrors: [
    /ResizeObserver loop completed/i,
    'ResizeObserver loop limit exceeded', // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
    'Multiple Sentry Session Replay instances are not supported',
  ],
  beforeSend: (event: any): any => {
    event.logger = 'javascript'; // https://github.com/getsentry/sentry-javascript/issues/1851

    return event;
  },
  ...environment.sentry.replay ? {
    integrations: [
      replayIntegration({
        blockAllMedia: false,
        maskAllInputs: false,
        maskAllText: false,
        mutationBreadcrumbLimit: 2000,
      }),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  } : {},
});

@Injectable({
  providedIn: 'root',
})
export class ErrorHandler implements OnDestroy, CoreErrorHandler {
  private readonly subscription = new Subscription();

  constructor(userService: UserService) {
    this.subscription.add(
      userService.onChange.subscribe({
        next: (user) => {
          setUser({ id: `${user && user instanceof User ? window.btoa(user?.email).replace(/.(.)?/g, '$1') : ''}-${Date.now()}` });
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleError(error: any): any {
    if (/Loading chunk [\S]+ failed/.test(error.message)) {
      window.location.reload();

      return;
    }

    captureException(error?.originalError ? error.originalError : error);

    throw error;
  }
}

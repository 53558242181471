<span
  #shortenText
  [class.expanded]="expanded"
  [class.multiline]="isMultiline"
  class="ellipsis-shorten-text"
>
  <ng-content></ng-content>
</span>
<div
  #fullTextContainer
  class="ellipsis-full-text-container"
>
  <div
    #fullText
    class="ellipsis-full-text"
  ></div>
</div>

export class GridControlHelper {
  public static getUrlMask(url: string): string {
    const ignoredEndings: string[] = ['list'];
    const baseUrl: string = url.split('?')[0];

    // remove "/[ignoredEnding]" at the end and/or "/" sign
    const regexp = new RegExp(`(\/(?:${ignoredEndings.join('|')}))?(\/)?$`);
    const endingMatch: RegExpMatchArray = baseUrl.match(regexp);

    return `${endingMatch ? baseUrl.substring(0, endingMatch.index) : baseUrl}/`;
  }
}

import { Injectable } from '@angular/core';
import { AuthTokenService } from '@proget-shared/auth-http-client';
import { SessionStorageService } from '@proget-shared/storage';

const TOKEN_NAME = 'token';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService implements AuthTokenService {
  constructor(private sessionStorageService: SessionStorageService) {}

  public hasToken(): boolean {
    return null !== this.getToken();
  }

  public setToken(token: string): void {
    this.sessionStorageService.set(TOKEN_NAME, token);
  }

  public getToken(): any {
    return this.sessionStorageService.get(TOKEN_NAME);
  }

  public removeToken(): void {
    this.sessionStorageService.remove(TOKEN_NAME);
  }

  public getPrefixedToken(): string {
    return `Bearer ${this.getToken()}`;
  }

  public getDownloadToken(): string {
    return `?token=${this.getToken()}`;
  }

  public clear(): void {
    this.sessionStorageService.clear();
  }
}

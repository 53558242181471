import { ObjectHelper } from '@proget-shared/helper';

import { ResponseError, ResponseErrorForm } from '../interface';

type Options = {
  omitEmpty?: boolean;
};

export class ResponseErrorFactory {
  public static create(response: any, options: Options = {}): ResponseError {
    if (!response) {
      return {};
    }

    const mergedOptions: Options = Object.assign({ omitEmpty: true }, options);
    const errors: ResponseError = {};

    if (response.hasOwnProperty('code')) {
      errors.code = response.code;
    }

    if (response.hasOwnProperty('errors')) {
      if (response.errors.hasOwnProperty('children')) {
        errors.form = ResponseErrorFactory.mapFormErrors(response.errors.children, mergedOptions);
      }

      if (response.errors.hasOwnProperty('errors') && response.errors.errors.length) {
        errors.messages = response.errors.errors;
      }
    } else if (response.hasOwnProperty('message')) {
      errors.messages = [response.message];
    }

    return errors;
  }

  private static mapFormErrors(errorFormData: any, options: Options): ResponseErrorForm {
    const errors: any = {};

    for (const key in errorFormData) {
      if (!errorFormData.hasOwnProperty(key)) {
        continue;
      }

      if (options.omitEmpty && Array.isArray(errorFormData[key]) && errorFormData[key].length === 0) {
        continue;
      }

      if (errorFormData[key].hasOwnProperty('errors')) {
        errors[key] = Array.from(new Set(ObjectHelper.findStrings(errorFormData[key].errors)));
      }

      if (errorFormData[key].hasOwnProperty('children')) {
        const nestedErrors: ResponseErrorForm = ResponseErrorFactory.mapFormErrors(errorFormData[key].children, options);

        if (!options.omitEmpty || !ObjectHelper.isEmpty(nestedErrors)) {
          errors[key] = nestedErrors;
        }
      }

      if (errorFormData[key].hasOwnProperty('errors') && errorFormData[key].hasOwnProperty('children')) {
        errors[`${key}Errors`] = errorFormData[key].errors;
      }
    }

    return errors;
  }
}

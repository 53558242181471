import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ProgetSharedModule } from '@proget-shared';
import { TranslatedDialogModule } from '@proget-shared/dialog/translated-dialog';
import { SelectionModule } from '@proget-shared/grid/selection';
import { TranslateModule } from '@proget-shared/translate';
import { OverlayPanelModule } from '@proget-shared/ui/overlay-panel';
import { TooltipModule } from '@proget-shared/ui/tooltip';

import { GridActionsComponent } from './component/grid-actions/grid-actions.component';
import { ItemActionsComponent } from './component/item-actions/item-actions.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ProgetSharedModule.forChild(),
    TooltipModule,
    TranslatedDialogModule,
    SelectionModule,
    OverlayPanelModule,
  ],
  declarations: [
    GridActionsComponent,
    ItemActionsComponent,
  ],
  exports: [
    GridActionsComponent,
    ItemActionsComponent,
  ],
})
export class GridActionsModule {}

import { ModuleWithProviders, NgModule } from '@angular/core';

import { PermissionComponentsModule } from './permission-components.module';
import { PermissionService } from './service';

@NgModule({
  imports: [PermissionComponentsModule],
  exports: [PermissionComponentsModule],
})
export class PermissionModule {
  public static forRoot(): ModuleWithProviders<PermissionModule> {
    return {
      ngModule: PermissionModule,
      providers: [PermissionService],
    };
  }

  public static forChild(): ModuleWithProviders<PermissionModule> {
    return {
      ngModule: PermissionModule,
    };
  }
}

import { Component, Input } from '@angular/core';

import { TooltipPosition } from '../../const/tooltip-position.enum';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input()
  public width: number | undefined;
  @Input()
  public position = TooltipPosition.RIGHT;
  @Input()
  public zIndex: string;
  @Input()
  public disabled = false;
}

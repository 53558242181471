<app-tooltip-icon
  [appTooltip]="cloudContent"
  [appTooltipDisabled]="disabled"
  [appTooltipPosition]="position"
  [appTooltipWidth]="width"
  [appTooltipZIndex]="zIndex"
  class="proget-tooltip-trigger"
></app-tooltip-icon>
<ng-template #cloudContent>
  <ng-content></ng-content>
</ng-template>

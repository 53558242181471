import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appRequiredClass]',
})
export class RequiredClassDirective implements OnInit, OnDestroy {
  private _appRequiredClass: boolean | AbstractControl = null;

  private subscription: Subscription;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.updateElementClass();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  @Input()
  public set appRequiredClass(appRequiredClass: boolean | AbstractControl) {
    this._appRequiredClass = appRequiredClass;
    this.subscription?.unsubscribe();

    if (appRequiredClass instanceof AbstractControl) {
      this.subscription = appRequiredClass.statusChanges.subscribe(() => {
        this.updateElementClass();
      });
    } else {
      this.updateElementClass();
    }
  }

  public get appRequiredClass(): boolean | AbstractControl {
    return this._appRequiredClass;
  }

  private updateElementClass(): void {
    const classList: DOMTokenList = this.elementRef.nativeElement.classList;

    this.hasRequiredClass() ? classList.add('required') : classList.remove('required');
  }

  private hasRequiredClass(): boolean {
    if (typeof this.appRequiredClass === 'boolean') {
      return this.appRequiredClass;
    }

    if (!this.appRequiredClass) {
      return false;
    }

    return this.appRequiredClass.enabled && this.hasRequiredValidator(this.appRequiredClass);
  }

  private hasRequiredValidator(control: AbstractControl): boolean {
    const testControl: AbstractControl = { value: null } as AbstractControl;
    const nullValidationResult: any = control.validator ? control.validator(testControl) : null;

    return !!nullValidationResult?.required;
  }
}

<div
  [class.disabled]="disabled"
  class="container"
>
  <div
    *ngIf="calendarService.type === CalendarType.TIME_OPTIONAL || calendarService.type === CalendarType.DATE_TIME"
    class="view-selector"
  >
    <button
      [class.active]="view === CalendarView.DATE"
      (click)="this.tabsAnimation = true; view = CalendarView.DATE"
      class="view-button proget-button primary-button icon-only"
    >
      <i class="icon date"></i>
    </button>
    <button
      [disabled]="!timeViewEnabled"
      [class.active]="view === CalendarView.TIME"
      (click)="this.tabsAnimation = true; view = CalendarView.TIME"
      type="button"
      class="view-button proget-button primary-button icon-only"
    >
      <i class="icon time"></i>
    </button>
  </div>
  <div
    [class.animated]="tabsAnimation"
    class="tab-container"
  >
    <div
      *ngIf="calendarService.type !== CalendarType.TIME_ONLY"
      [class.active]="view === CalendarView.DATE"
      class="tab"
    >
      <app-month-display
        #monthDisplay
        [dayShortLabelKey]="dayShortLabelKey"
        [disabled]="disabled"
        [firstWeekDay]="firstWeekDay"
        [maxMonth]="rightMonthDisplay?.currentDisplayMonth"
        [monthLabelKey]="monthLabelKey"
        (dayClick)="selectDate($event.date)"
        (dayHover)="preselectDate($event.date)"
        (display)="monthDisplayChanged($event.month, monthDisplay)"
        class="calendar-column column"
      >
      </app-month-display>
      <ng-container *ngIf="calendarService.range">
        <div class="separator">
          <div
            *ngIf="monthsBetweenCount > 0"
            [appTooltip]="'proget_shared.calendar.months_between' | translate: { count: monthsBetweenCount }"
            appTooltipPosition="bottom"
            class="months-between"
          >
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
        <app-month-display
          #rightMonthDisplay
          [dayShortLabelKey]="dayShortLabelKey"
          [disabled]="disabled"
          [firstWeekDay]="firstWeekDay"
          [minMonth]="monthDisplay.currentDisplayMonth"
          [monthLabelKey]="monthLabelKey"
          (dayClick)="selectDate($event.date)"
          (dayHover)="preselectDate($event.date)"
          (display)="monthDisplayChanged($event.month, rightMonthDisplay)"
          class="calendar-column column"
        >
        </app-month-display>
      </ng-container>
    </div>
    <div
      *ngIf="calendarService.type !== CalendarType.DATE_ONLY"
      [class.active]="view === CalendarView.TIME"
      [class.footer-button-padding]="isStandalone && calendarService.clearEnabled"
      class="tab"
    >
      <div
        *ngIf="calendarService.range && isStandalone && calendarService.type === CalendarType.TIME_ONLY"
        class="time-range-label"
      >
        {{ 'proget_shared.calendar.date_range.from' | translate }}
      </div>
      <div
        (mouseenter)="emitSelectionEdge(RangeEdge.FROM)"
        class="column"
      >
        <app-day-display
          [class.content-hidden]="!selectionDate"
          [currentDate]="selectionDate"
          [dateVisibility]="calendarService.withDate"
          [monthLabelKey]="monthLabelKey"
          [maxDate]="selectionDateEnd"
          [wholeDayEnabled]="!calendarService.timeRequired"
          [wholeDayLabel]="calendarService.range ? 'proget_shared.calendar.day_start' : 'proget_shared.calendar.whole_day'"
          (edgeChange)="selectTime($event, RangeEdge.FROM)"
        >
        </app-day-display>
        <button
          *ngIf="!selectionDate && calendarService.type === CalendarType.TIME_ONLY"
          (click)="selectInitialTime(RangeEdge.FROM)"
          type="button"
          class="proget-button set-time-button"
        >
          {{ 'proget_shared.calendar.button.set_time' | translate }}
        </button>
        <button
          *ngIf="isStandalone && calendarService.type === CalendarType.TIME_ONLY"
          [class.content-hidden]="!selectionDate"
          (click)="selectTime(null, RangeEdge.FROM)"
          type="button"
          class="proget-button clear-buton"
        >
          {{ 'proget_shared.calendar.button.clear' | translate }}
        </button>
      </div>
      <ng-container *ngIf="calendarService.range">
        <div
          *ngIf="isStandalone && calendarService.type === CalendarType.TIME_ONLY"
          class="time-range-label"
        >
          {{ 'proget_shared.calendar.date_range.to' | translate }}
        </div>
        <div
          (mouseenter)="emitSelectionEdge(RangeEdge.TO)"
          [class.border-left]="!isStandalone && calendarService.type === CalendarType.TIME_ONLY"
          class="column"
        >
          <app-day-display
            [class.content-hidden]="!selectionDateEnd"
            [currentDate]="selectionDateEnd"
            [dateVisibility]="calendarService.withDate"
            [monthLabelKey]="monthLabelKey"
            [minDate]="selectionDate"
            [wholeDayEnabled]="!calendarService.timeRequired"
            (edgeChange)="selectTime($event, RangeEdge.TO)"
            wholeDayLabel="proget_shared.calendar.day_end"
          >
          </app-day-display>
          <button
            *ngIf="!selectionDateEnd && calendarService.type === CalendarType.TIME_ONLY"
            (click)="selectInitialTime(RangeEdge.TO)"
            type="button"
            class="proget-button set-time-button"
          >
            {{ 'proget_shared.calendar.button.set_time' | translate }}
          </button>
          <button
            *ngIf="isStandalone && calendarService.type === CalendarType.TIME_ONLY"
            [class.content-hidden]="!selectionDateEnd"
            (click)="selectTime(null, RangeEdge.TO)"
            type="button"
            class="proget-button clear-buton"
          >
            {{ 'proget_shared.calendar.button.clear' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div
  *ngIf="!autoApply"
  class="summary-container"
>
  <button
    (click)="apply.emit()"
    type="button"
    class="proget-button primary-button"
  >
    {{ 'proget_shared.calendar.button.apply' | translate }}
  </button>
</div>

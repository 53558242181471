<ng-scrollbar
  #scrollbar
  [appearance]="appearance"
  [autoHeightDisabled]="autoHeightDisabled"
  [autoWidthDisabled]="autoWidthDisabled"
  [pointerEventsMethod]="pointerEventsMethod"
  [position]="position"
  [track]="track"
  [trackClass]="trackClass"
  [viewClass]="viewClass"
  [visibility]="visibility"
>
  <ng-content></ng-content>
</ng-scrollbar>

import { Injectable } from '@angular/core';
import { Subject, distinctUntilKeyChanged, fromEvent, share } from 'rxjs';

import { EllipsisComponent } from './ellipsis.component';

@Injectable({ providedIn: 'root' })
export class EllipsisService {
  public readonly activeEllipsis$;
  public readonly mouseenter$ = fromEvent(document.body, 'mousemove').pipe(
    distinctUntilKeyChanged('target'),
    share()
  );

  private readonly activeEllipsisSubject = new Subject<EllipsisComponent>();

  constructor() {
    this.activeEllipsis$ = this.activeEllipsisSubject.asObservable();
  }

  public setActiveEllipsis(ellipsis: EllipsisComponent): void {
    this.activeEllipsisSubject.next(ellipsis);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ApiErrorsDirective } from './api-errors.directive';
import { RelatedControlsDirective } from './related-controls.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ApiErrorsDirective,
    RelatedControlsDirective,
  ],
  exports: [
    ApiErrorsDirective,
    RelatedControlsDirective,
  ],
})
export class ApiErrorsModule { }

import { ComponentRef, createComponent, EnvironmentInjector, Injectable, TemplateRef } from '@angular/core';

import { MenuTemplateComponent } from '../component/menu-template.component';

@Injectable({ providedIn: 'root' })
export class MenuService {
  private menuTemplateComponent: ComponentRef<MenuTemplateComponent>;

  constructor(environmentInjector: EnvironmentInjector) {
    this.menuTemplateComponent = createComponent(MenuTemplateComponent, { environmentInjector });
    this.menuTemplateComponent.changeDetectorRef.detectChanges();
  }

  public getTemplate(): TemplateRef<any> {
    return this.menuTemplateComponent.instance.template;
  }
}

import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { RadioButtonComponent } from './radio-button.component';

@NgModule({
  imports: [ReactiveFormsModule],
  declarations: [RadioButtonComponent],
  exports: [RadioButtonComponent],
})
export class RadioButtonModule {}

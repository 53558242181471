import { AfterViewInit, Component, EventEmitter, forwardRef, Injector, Input, Optional, Output, SkipSelf, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateHelper } from '@proget-shared/helper';
import { OverlayPanelComponent } from '@proget-shared/ui/overlay-panel';
import { noop } from 'rxjs';

import { CalendarType } from '../../const/calendar-type.enum';
import { CalendarView } from '../../const/calendar-view.enum';
import { RangeEdge } from '../../const/range-edge.enum';
import { CalendarDateTime } from '../../model/calendar-date-time.model';
import { CalendarRange } from '../../model/calendar-range.model';
import { CalendarService } from '../../service/calendar.service';
import { SelectionLevel } from '../date-selector/selection-level.enum';
import { MonthDisplayComponent } from '../month-display/month-display.component';

@Component({
  selector: 'app-inline-calendar',
  templateUrl: './inline-calendar.component.html',
  styleUrls: ['./inline-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InlineCalendarComponent),
      multi: true,
    },
    {
      provide: CalendarService,
      useFactory: (parentInjector: Injector, parentService?: CalendarService) => {
        if (parentService) {
          return parentService;
        }

        const injector = Injector.create({
          providers: [{ provide: CalendarService }],
          parent: parentInjector,
        });

        return injector.get(CalendarService);
      },
      deps: [Injector, [new Optional(), new SkipSelf(), CalendarService]],
    },
  ],
})
export class InlineCalendarComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  public firstWeekDay = 1;
  @Input()
  public dayShortLabelKey = 'calendar.day_names_short';
  @Input()
  public dayFullLabelKey = 'calendar.day_names';
  @Input()
  public monthLabelKey = 'calendar.month_names';
  @Input()
  public outputType: 'object' | 'string' = 'string';
  @Output()
  public apply = new EventEmitter<void>();

  protected readonly CalendarType = CalendarType;
  protected readonly SelectionLevel = SelectionLevel;
  protected readonly CalendarView = CalendarView;
  protected readonly RangeEdge = RangeEdge;
  protected readonly isStandalone: boolean;

  @ViewChild('rightMonthDisplay')
  protected rightMonthDisplay: MonthDisplayComponent;
  protected monthsBetweenCount = 0;
  protected tabsAnimation = false;

  @Output()
  private readonly edgeChange = new EventEmitter<RangeEdge>();
  @Output()
  private readonly modeChange = new EventEmitter<CalendarView>();

  @ViewChild('monthDisplay')
  private monthDisplay: MonthDisplayComponent;
  private modelTouched: () => void = noop;
  private modelChanged: (date: CalendarDateTime | CalendarRange) => void = noop;
  private preselection: CalendarDateTime | CalendarRange | null = null;
  private selection: CalendarDateTime | CalendarRange | null = null;
  private _selectedRangeEdge: RangeEdge = null;
  private _disabled = false;
  private _view = CalendarView.DATE;

  constructor(
    protected readonly calendarService: CalendarService,
    @Optional()
    parentOverlayPanelComponent: OverlayPanelComponent
  ) {
    this.isStandalone = !parentOverlayPanelComponent;
    this.updateView();
  }

  writeValue(value: any): void {
    this.selection = this.calendarService.parseInput(value);

    this.updateView();

    if (this.selection instanceof CalendarRange && this.selectedRangeEdge) {
      this.selection.nextModification = this.selectedRangeEdge;
    }

    if (!this.monthDisplay) {
      return;
    }

    this.monthDisplay?.setSelection(this.selection);
    this.rightMonthDisplay?.setSelection(this.selection);
    this.display(this.selection);
  }

  registerOnChange(fn: (date: CalendarDateTime | CalendarRange) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  ngAfterViewInit(): void {
    if (this.selection instanceof CalendarRange && this.selectedRangeEdge) {
      this.selection.nextModification = this.selectedRangeEdge;
    }

    this.monthDisplay?.setSelection(this.selection);
    this.rightMonthDisplay?.setSelection(this.selection);

    setTimeout(() => {
      this.display(this.selection);
    });
  }

  @Input()
  public set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  public set dateFormat(value: string) {
    this.calendarService.dateFormat = value;
  }

  @Input()
  public set timeFormat(value: string) {
    this.calendarService.timeFormat = value;
  }

  @Input()
  public set type(type: CalendarType) {
    this.calendarService.type = type;
    this.updateView();
  }

  @Input()
  public set range(value: boolean) {
    this.calendarService.range = value;
  }

  @Input()
  public set minDate(date: Date) {
    this.calendarService.minDate = date;
  }

  @Input()
  public set maxDate(date: Date) {
    this.calendarService.maxDate = date;
  }

  @Input()
  public set clearEnabled(value: boolean) {
    this.calendarService.clearEnabled = value;
  }

  @Input()
  public set timeStep(value: number) {
    this.calendarService.timeStep = value;
  }

  @Input()
  public set view(value: CalendarView) {
    this._view = value;

    if (this.tabsAnimation) {
      this.display(this.selection);
      this.modeChange.emit(this.view);
    }
  }

  public get view(): CalendarView {
    if (this.calendarService.type === CalendarType.DATE_ONLY) {
      return CalendarView.DATE;
    }

    if (this.calendarService.type === CalendarType.TIME_ONLY) {
      return CalendarView.TIME;
    }

    return this._view;
  }

  @Input()
  public set selectedRangeEdge(value: RangeEdge) {
    if (!(
      this.calendarService.type === CalendarType.TIME_ONLY ||
      this.view !== CalendarView.TIME ||
      value === RangeEdge.FROM && this.selection instanceof CalendarRange && this.selection.from ||
      value === RangeEdge.TO && this.selection instanceof CalendarRange && this.selection.to
    )) {
      this._selectedRangeEdge = null;
      this.edgeChange.next(null);

      return;
    }

    if (this.selection instanceof CalendarRange) {
      this.selection.nextModification = value;
    }

    this._selectedRangeEdge = value;
    this.edgeChange.next(value);
  }

  public get selectedRangeEdge(): RangeEdge {
    return this._selectedRangeEdge;
  }

  public get selectionDate(): CalendarDateTime {
    return this.selection instanceof CalendarRange ? this.selection.from : this.selection;
  }

  public get selectionDateEnd(): CalendarDateTime {
    return this.selection instanceof CalendarRange ? this.selection.to : null;
  }

  public get timeViewEnabled(): boolean {
    return !!(this.selectionDate || this.selectionDateEnd);
  }

  public get autoApply(): boolean {
    return this.isStandalone || this.calendarService.type === CalendarType.DATE_ONLY && !this.calendarService.range;
  }

  public monthDisplayChanged(month: Date, source: MonthDisplayComponent): void {
    if (!this.rightMonthDisplay) {
      return;
    }

    if (
      source === this.monthDisplay &&
      this.rightMonthDisplay.currentDisplayMonth &&
      month.getTime() >= this.rightMonthDisplay.currentDisplayMonth.getTime()
    ) {
      this.rightMonthDisplay.displayDate(DateHelper.getNextMonth(month));

      return;
    }

    if (
      source === this.rightMonthDisplay &&
      this.monthDisplay.currentDisplayMonth &&
      month.getTime() <= this.monthDisplay.currentDisplayMonth.getTime()
    ) {
      this.monthDisplay.displayDate(DateHelper.getPreviousMonth(month));

      return;
    }

    this.monthsBetweenCount = this.countMonthsBetween();
  }

  public preselectDate(date: Date | null): void {
    this.preselection = date ? this.modifySelectionDate(date) : null;
    this.monthDisplay.setPreselection(this.preselection);
    this.rightMonthDisplay?.setPreselection(this.preselection);

    if (date) {
      const modifiedEdge = this.preselection instanceof CalendarRange
        ? this.preselection.lastModification
        : RangeEdge.FROM;

      this.edgeChange.emit(modifiedEdge);
    } else {
      this.edgeChange.emit(this.selectedRangeEdge);
    }
  }

  public selectTime(dateTime: CalendarDateTime, edge: RangeEdge): void {
    this.selection = this.modifySelectionTime(dateTime, edge);
    this.updateView();
    this.emitSelection();
  }

  public selectInitialTime(edge: RangeEdge): void {
    const time = edge === RangeEdge.FROM
      ? new Date(0, 0, 1, 0, 0)
      : new Date(0, 0, 1, 23, 59);

    this.selectTime(new CalendarDateTime(time, false), edge);
  }

  public selectDate(date: Date): void {
    this.selection = this.modifySelectionDate(date);
    this.monthDisplay.setSelection(this.selection);
    this.rightMonthDisplay?.setSelection(this.selection);

    this.preselectDate(date);
    this.updateView();
    this.emitSelection();

    if (this.autoApply) {
      this.apply.emit();
    }
  }

  public emitSelectionEdge(value: RangeEdge): void {
    this.selectedRangeEdge = value;
  }

  private emitSelection(): void {
    this.modelTouched();
    this.modelChanged(this.calendarService.prepareOutput(this.selection as any, this.outputType as any));
  }

  private modifySelectionTime(dateTime: CalendarDateTime, edge: RangeEdge): CalendarDateTime | CalendarRange {
    if (!this.calendarService.range) {
      return dateTime;
    }

    if (this.selection instanceof CalendarRange) {
      return edge === RangeEdge.FROM
        ? new CalendarRange(dateTime, this.selection.to, RangeEdge.FROM)
        : new CalendarRange(this.selection.from, dateTime, RangeEdge.TO);
    }

    return this.selectedRangeEdge === RangeEdge.FROM
      ? new CalendarRange(dateTime, null, RangeEdge.FROM)
      : new CalendarRange(null, dateTime, RangeEdge.TO);
  }

  private modifySelectionDate(date: Date): CalendarDateTime | CalendarRange {
    if (!this.calendarService.range) {
      const wholeDay = this.selection instanceof CalendarDateTime ? this.selection.wholeDay : !this.calendarService.timeRequired;
      const newDateTime = this.selection instanceof CalendarDateTime ? DateHelper.mergeDateTime(date, this.selection.getValue()) : date;

      return new CalendarDateTime(newDateTime, wholeDay);
    }

    if (this.selection instanceof CalendarRange) {
      const modifiedSelection = this.selection.withDate(date, this.calendarService.timeRequired);

      return modifiedSelection;
    }

    const rangeDate = this.selectedRangeEdge === RangeEdge.FROM ? date : DateHelper.getDayEnd(date);
    const dateTime = new CalendarDateTime(rangeDate, !this.calendarService.timeRequired);

    return this.selectedRangeEdge === RangeEdge.TO
      ? new CalendarRange(null, dateTime, RangeEdge.TO)
      : new CalendarRange(dateTime, null, RangeEdge.FROM);
  }

  private display(value: CalendarDateTime | CalendarRange): void {
    // Date
    if (value instanceof CalendarDateTime) {
      this.monthDisplay?.displayDate(new Date(value.getDateFullYear(), value.getDateMonth()));
      this.rightMonthDisplay?.displayDate(DateHelper.getNextMonth(value.getDate()));

      return;
    }

    // Unknown type
    if (!(value instanceof CalendarRange)) {
      this.display(new CalendarDateTime(new Date(), false));

      return;
    }

    // Range
    if (value.from === null && value.to === null) {
      this.display(new CalendarDateTime(new Date(), false));

      return;
    }

    if (value.to === null) {
      this.monthDisplay?.displayDate(new Date(value.from.getDateFullYear(), value.from.getDateMonth()));
      this.rightMonthDisplay?.displayDate(new Date(value.from.getDateFullYear(), value.from.getDateMonth() + 1));

      return;
    }

    if (value.from === null) {
      const offset = this.calendarService.range ? 1 : 0;

      this.monthDisplay?.displayDate(new Date(value.to.getDateFullYear(), value.to.getDateMonth() - offset));
      this.rightMonthDisplay?.displayDate(new Date(value.to.getDateFullYear(), value.to.getDateMonth() + 1 - offset));

      return;
    }

    if (DateHelper.getMonth(value.from.getDate()).getTime() === DateHelper.getMonth(value.to.getDate()).getTime()) {
      this.monthDisplay?.displayDate(new Date(value.from.getDateFullYear(), value.from.getDateMonth()));
      this.rightMonthDisplay?.displayDate(DateHelper.getNextMonth(value.to.getDate()));

      return;
    }

    this.monthDisplay?.displayDate(new Date(value.from.getDateFullYear(), value.from.getDateMonth()));
    this.rightMonthDisplay?.displayDate(new Date(value.to.getDateFullYear(), value.to.getDateMonth()));
  }

  private countMonthsBetween(): number {
    if (!this.rightMonthDisplay?.currentDisplayMonth || !this.monthDisplay?.currentDisplayMonth) {
      return 0;
    }

    const yearsDiff = this.rightMonthDisplay.currentDisplayMonth.getFullYear() - this.monthDisplay.currentDisplayMonth.getFullYear();
    const monthsDiff = this.rightMonthDisplay.currentDisplayMonth.getMonth() - this.monthDisplay.currentDisplayMonth.getMonth();

    return yearsDiff * 12 + monthsDiff - 1;
  }

  private updateView(): void {
    if (!this.timeViewEnabled) {
      this.view = CalendarView.DATE;
    }
  }
}

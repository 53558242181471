<app-date-selector
  [currentDate]="currentDisplayMonth"
  [maxDate]="maxMonth"
  [minDate]="minMonth"
  [level]="SelectionLevel.MONTH"
  (dateChange)="displayDate($event)"
>
</app-date-selector>
<div class="week">
  <div
    *ngFor="let index of headerIndexes"
    class="day-name"
  >
    <app-ellipsis>{{ dayShortLabelKey + '.' + (index + firstWeekDay + 7) % 7 | translate }}</app-ellipsis>
  </div>
</div>
<div
  [class.disabled]="disabled"
  [class.preselection-visibility]="preselectionVisibility"
  class="weeks-container"
>
  <div
    *ngFor="let week of weeks; let index = index"
    [class.first-week]="index === 0"
    class="week"
  >
    <div
      *ngFor="let day of week"
      [class.day-disabled]="!day.isSelectable"
      [class.selected-day]="day.isSelected"
      [class.preselection]="day.isInPreselection"
      [class.preselection-end]="day.isLastPreselectionDay"
      [class.preselection-start]="day.isFirstPreselectionDay"
      [class.selection]="day.isInSelection"
      [class.selection-end]="day.isLastSelectionDay"
      [class.selection-start]="day.isFirstSelectionDay"
      [class.today]="day.isToday"
      (click)="selectDay(day)"
      (mouseenter)="preselectDay(day)"
      (mouseleave)="preselectDay(null)"
      class="day"
    >
      <div class="preselection-background"></div>
      <div class="selection-background"></div>
      <div class="selected-day-background"></div>
      <div class="today-mark"></div>
      <span class="day-label">{{ day.date.getDate() }}</span>
    </div>
  </div>
</div>

import {
  Component,
  ViewChild,
  ViewContainerRef,
  ElementRef,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';

import { DialogService } from '../../service/dialog.service';

@Component({
  selector: 'app-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogContainerComponent {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  private containerRef: ViewContainerRef;

  constructor(
    private dialogService: DialogService,
    private elementRef: ElementRef
  ) {
    this.dialogService.registerContainer(this);
    elementRef.nativeElement.classList.add('proget-dialog');
  }

  public getRef(): ViewContainerRef {
    return this.containerRef;
  }

  public setZIndex(zIndex: number): void {
    this.elementRef.nativeElement.style.zIndex = zIndex.toString();
  }

  @HostListener('window:keydown.escape', ['$event'])
  protected killTopDialog(event: KeyboardEvent): void {
    event.preventDefault();

    this.dialogService.killTopDialog(true);
  }
}

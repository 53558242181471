<div
  [formGroup]="form"
  class="p-grid p-align-center p-nogutter"
>
  <app-checkbox
    [binary]="true"
    [hidden]="!isOptional"
    [label]="'proget_shared.password_form_control.label' | translate"
    data-qa="password-enabled"
    formControlName="enabled"
    class="enabled-checkbox p-col-fixed"
  >
  </app-checkbox>
  <div
    [class.password-visible]="inputType === 'text'"
    class="input-col p-col"
  >
    <input
      #passwordInput
      *ngIf="!textInputVisibility"
      [attr.autocomplete]="autocomplete"
      [appAutoFocus]="control?.touched"
      [placeholder]="placeholder"
      (blur)="passwordBlurred()"
      appInput
      data-qa="password-input"
      formControlName="password"
      type="password"
      class="full-width"
    >
    <input
      #passwordInput
      *ngIf="textInputVisibility"
      [appAutoFocus]="control?.touched"
      [placeholder]="placeholder"
      (blur)="passwordBlurred()"
      appInput
      data-qa="password-input"
      formControlName="password"
      type="text"
      class="full-width"
    >
    <button
      (click)="togglePasswordVisibility()"
      data-qa="password-toggle-visibility-button"
      tabindex="-1"
      type="button"
      class="toggle-visibility-button proget-clear-button"
    >
      <i class="icon-see-icon"></i>
    </button>
  </div>
</div>

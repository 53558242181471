import { Injectable } from '@angular/core';
import {
  AlertConfiguration,
  ConfirmConfiguration,
  DialogTemplateService,
  PhraseConfirmConfiguration,
  PromptConfiguration,
} from '@proget-shared/dialog/dialog-template';
import { StringHelper } from '@proget-shared/helper';
import { TranslateService, Translation } from '@proget-shared/translate';
import { forkJoin, map, noop, Observable, of, share, switchMap } from 'rxjs';

import { TranslatedDialogOptions } from '../type/translated-dialog-options.type';

type DialogHeader = string | Translation;
type DialogMessage = string | Translation | (string | Translation)[];

@Injectable({ providedIn: 'root' })
export class TranslatedDialogService {
  constructor(private translate: TranslateService, private dialog: DialogTemplateService) {}

  public alert(
    header: DialogHeader,
    message: DialogMessage,
    options: TranslatedDialogOptions<AlertConfiguration> = {}
  ): Observable<void> {
    const okKey: string = options.configuration?.okLabel || 'button.close.label';

    return this.runDialog<void>(
      forkJoin([
        this.translateHeader(header),
        this.translateMessage(message, options.escapeMessageHtml),
        this.translate.getTranslatedText(okKey),
      ]).pipe(
        switchMap(([translatedHeader, translatedMessage, okLabel]: string[]) => this.dialog.alert(translatedMessage, {
          ...this.mergeOptions({
            header: translatedHeader,
            okLabel,
            okButtonClass: 'proget-button primary-button',
          }, options),
          returnDialogInstance: false,
        })
        )
      )
    );
  }

  public confirm(
    header: DialogHeader,
    message: DialogMessage,
    options: TranslatedDialogOptions<ConfirmConfiguration> & { iconClass?: string } = {}
  ): Observable<boolean> {
    const yesKey: string = options.configuration?.yesLabel || 'button.yes.label';
    const noKey: string = options.configuration?.noLabel || 'button.reject.label';

    return this.runDialog<boolean>(
      forkJoin([
        this.translateHeader(header),
        this.translateMessage(message, options.escapeMessageHtml),
        this.translate.getTranslatedText(yesKey),
        this.translate.getTranslatedText(noKey),
      ]).pipe(
        switchMap(([translatedHeader, translatedMessage, yesLabel, noLabel]: string[]) => this.dialog.confirm(
          options?.iconClass
            ? `<i class="confirm-dialog-icon ${options.iconClass}"></i>${translatedMessage}`
            : translatedMessage,
          {
            ...this.mergeOptions({
              header: translatedHeader,
              yesLabel,
              noLabel,
              yesButtonClass: 'proget-button primary-button',
              noButtonClass: 'proget-button secondary-button',
            }, options),
            returnDialogInstance: false,
          }
        )
        )
      )
    );
  }

  public prompt(
    header: DialogHeader,
    message: DialogMessage,
    initialValue: string,
    options: TranslatedDialogOptions<PromptConfiguration> = {}
  ): Observable<string> {
    const cancelKey = options.configuration?.cancelLabel || 'button.cancel.label';
    const okKey = options.configuration?.okLabel || 'button.submit.label';
    const inputLabelKey = options.configuration?.inputLabel || '';
    const validators = options.configuration?.validators;
    const counterPattern = options.configuration?.counterPattern;

    return this.runDialog<string>(
      forkJoin([
        this.translateHeader(header),
        this.translateMessage(message, options.escapeMessageHtml),
        this.translate.getTranslatedText(okKey),
        this.translate.getTranslatedText(cancelKey),
        this.translate.getTranslatedText(inputLabelKey),
        counterPattern ? this.translate.getTranslatedText(counterPattern) : of(void 0),
      ]).pipe(
        switchMap(
          ([
            translatedHeader,
            translatedMessage,
            okLabel,
            cancelLabel,
            inputLabel,
            translatedCounterPattern,
          ]: string[]) => this.dialog.prompt(translatedMessage, initialValue, {
            ...this.mergeOptions({
              header: translatedHeader,
              okLabel,
              cancelLabel,
              inputLabel,
              cancelButtonClass: 'proget-button secondary-button',
              okButtonClass: 'proget-button primary-button',
              validators,
              counterPattern: translatedCounterPattern,
            }, options),
            returnDialogInstance: false,
          })
        )
      )
    );
  }

  public phraseConfirm(
    header: DialogHeader,
    message: DialogMessage,
    phrase: string,
    options: TranslatedDialogOptions<PhraseConfirmConfiguration> = {}
  ): Observable<void> {
    const cancelKey = options.configuration?.cancelLabel || 'button.cancel.label';
    const okKey = options.configuration?.okLabel || 'button.confirm.label';
    const phraseMessage = options.configuration?.phraseMessage || 'proget_shared.grid.actions.phrase_prompt';
    const unifiedPhrase = (typeof phrase === 'string' && phrase.trim() ? phrase.trim() : 'Confirm').toUpperCase();

    return this.runDialog<void>(
      forkJoin([
        this.translateHeader(header),
        this.translateMessage(message),
        this.translateMessage(
          {
            key: phraseMessage,
            params: { phrase: StringHelper.escapeHtml(unifiedPhrase) },
          },
          false
        ),
        this.translate.getTranslatedText(okKey),
        this.translate.getTranslatedText(cancelKey),
      ]).pipe(
        switchMap(
          ([
            translatedHeader,
            translatedMessage,
            translatedPhraseMessage,
            okLabel,
            cancelLabel,
          ]: string[]) => this.dialog.phraseConfirm(translatedHeader, translatedMessage, unifiedPhrase, {
            ...this.mergeOptions({
              okLabel,
              cancelLabel,
              phraseMessage: translatedPhraseMessage,
              cancelButtonClass: 'proget-button secondary-button',
              okButtonClass: 'proget-button primary-button',
            }, options),
            returnDialogInstance: false,
          })
        )
      )
    );
  }

  private runDialog<T>(dialog$: Observable<T>): Observable<T> {
    const sharedDialog$: Observable<T> = dialog$.pipe(share());

    // required to run dialog without outer subscription
    sharedDialog$.subscribe({
      next: noop,
      error: noop,
    });

    return sharedDialog$;
  }

  private mergeOptions(
    configuration: AlertConfiguration | ConfirmConfiguration | PromptConfiguration | PhraseConfirmConfiguration,
    options: TranslatedDialogOptions = {}
  ): TranslatedDialogOptions {
    const mergedOptions: TranslatedDialogOptions = Object.assign({}, options);

    mergedOptions.configuration = Object.assign({}, options.configuration, configuration);

    return mergedOptions;
  }

  private translateHeader(header: DialogHeader): Observable<string> {
    return this.translate.getTranslatedText(header);
  }

  private translateMessage(message: DialogMessage, escapeHtml = true): Observable<string> {
    const messagesArray: (string | Translation)[] = message instanceof Array ? message : [message];

    return forkJoin(
      messagesArray.map(
        (messageItem: string | Translation): Observable<string | any> => this.translate.getTranslatedText(messageItem)
      )
    ).pipe(
      map((translatedMessagesArray: string[] | any): string => translatedMessagesArray.map(
        (translation) => (typeof translation === 'string'
          ? escapeHtml ? StringHelper.escapeHtml(translation) : translation
          : Object.keys(translation)
            .map((key) => (escapeHtml ? StringHelper.escapeHtml(translation[key]) : translation[key]))
            .join('<br>')
        )
      ).join('<br>'))
    );
  }
}

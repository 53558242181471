import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  ViewEncapsulation,
} from '@angular/core';
import { ConfigurableDialog, DialogService } from '@proget-shared/dialog';

import { ConfirmConfiguration } from '../../interface/confirm-configuration.interface';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmComponent implements ConfigurableDialog {
  public header = '';
  public messageHtml = '';
  public noLabel = '';
  public yesLabel = '';
  public noButtonClass = '';
  public yesButtonClass = '';
  public width: number | undefined;
  public iconClass = '';

  constructor(
    private dialog: DialogService,
    private cdr: ChangeDetectorRef,
    elementRef: ElementRef
  ) {
    elementRef.nativeElement.classList.add('proget-dialog-confirm');
  }

  onConfiguration(configuration: ConfirmConfiguration): void {
    if (configuration.hasOwnProperty('header')) {
      this.header = configuration.header;
    }

    if (configuration.hasOwnProperty('message')) {
      this.messageHtml = configuration.message;
    }

    if (configuration.hasOwnProperty('noLabel')) {
      this.noLabel = configuration.noLabel;
    }

    if (configuration.hasOwnProperty('yesLabel')) {
      this.yesLabel = configuration.yesLabel;
    }

    if (configuration.hasOwnProperty('noButtonClass') && typeof configuration.noButtonClass === 'string') {
      this.noButtonClass = configuration.noButtonClass;
    }

    if (configuration.hasOwnProperty('yesButtonClass') && typeof configuration.yesButtonClass === 'string') {
      this.yesButtonClass = configuration.yesButtonClass;
    }

    if (configuration.hasOwnProperty('iconClass') && typeof configuration.iconClass === 'string') {
      this.iconClass = configuration.iconClass;
    }

    this.width = configuration.width;
    this.cdr.markForCheck();
  }

  @HostListener('window:keydown.enter')
  protected confirm(): void {
    this.dialog.resolve(this, true);
  }

  protected deny(): void {
    this.dialog.resolve(this, false);
  }
}

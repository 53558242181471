<app-calendar
  #calendar
  [formControl]="rangeForm"
  [panelReferer]="panelReferer"
  [placeholder]="config?.placeholder | translate"
  [range]="true"
  [type]="config?.dateWithTime ? 'time-optional' : 'date-only'"
  data-qa="filter-range-calendar"
  dateFormat="yyyy-MM-dd"
  outputType="string"
>
</app-calendar>

import { DateHelper } from '@proget-shared/helper';

import { RangeEdge } from '../const/range-edge.enum';

import { CalendarDateTime } from './calendar-date-time.model';

export class CalendarRange {
  public nextModification: RangeEdge;

  constructor(
    public readonly from: CalendarDateTime,
    public readonly to: CalendarDateTime,
    public lastModification: RangeEdge
  ) {
    this.nextModification = lastModification === RangeEdge.FROM ? RangeEdge.TO : RangeEdge.FROM;
  }

  public withDate(date: Date, useTime = false): CalendarRange {
    if (this.from === null && this.to === null) {
      const rangeDate = this.nextModification === RangeEdge.FROM ? DateHelper.getDayStart(date) : DateHelper.getDayEnd(date);
      const dateTime = new CalendarDateTime(rangeDate, !useTime);

      return this.nextModification === RangeEdge.FROM
        ? new CalendarRange(dateTime, null, RangeEdge.FROM)
        : new CalendarRange(null, dateTime, RangeEdge.TO);
    }

    if (this.contains(date)) {
      if (this.nextModification === RangeEdge.FROM) {
        const dateFrom = this.from
          ? this.mergeDate(this.from, date)
          : new CalendarDateTime(date, !useTime);

        return new CalendarRange(dateFrom, this.to, RangeEdge.FROM);
      }

      const dateTo = this.to
        ? this.mergeDate(this.to, date)
        : new CalendarDateTime(DateHelper.getDayEnd(date), !useTime);

      return new CalendarRange(this.from, dateTo, RangeEdge.TO);
    }

    if (this.from && date.getTime() < this.from.getTimeStart().getTime()) {
      const dateFrom = this.mergeDate(this.from, date);

      return new CalendarRange(dateFrom, this.to, RangeEdge.FROM);
    }

    if (this.to && date.getTime() >= this.to.getTimeEnd().getTime()) {
      const dateTo = this.mergeDate(this.to, date);

      return new CalendarRange(this.from, dateTo, RangeEdge.TO);
    }
  }

  public contains(date: Date): boolean {
    if (this.from === null && this.to === null) {
      return false;
    }

    if (this.from === null) {
      return date.getTime() < this.to.getTimeEnd().getTime();
    }

    if (this.to === null) {
      return date.getTime() >= this.from.getTimeStart().getTime();
    }

    return date.getTime() >= this.from.getTimeStart().getTime() && date.getTime() < this.to.getTimeEnd().getTime();
  }

  public isValid(): boolean {
    return !(this.from && this.to && this.from.getTimeStart().getTime() > this.to.getTimeEnd().getTime());
  }

  private mergeDate(dateTime: CalendarDateTime, date: Date): CalendarDateTime {
    return new CalendarDateTime(DateHelper.mergeDateTime(date, dateTime.getValue()), dateTime.wholeDay);
  }
}

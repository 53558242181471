<i
  *ngIf="icon"
  [class]="icon"
  class="toast-icon"
></i>
<div class="toast-content">
  <span #placeholder></span>
</div>
<button
  *ngIf="closable"
  (click)="close()"
  type="button"
  class="toast-close-button"
>
  <svg width="10" height="10">
    <line x1="0" y1="0" x2="10" y2="10" class="close-button-line" />
    <line x1="10" y1="0" x2="0" y2="10" class="close-button-line" />
  </svg>
</button>

<app-dialog-header
  [header]="header"
  [iconClass]="iconClass"
>
</app-dialog-header>
<app-dialog-content>
  <form
    [formGroup]="promptForm"
    (ngSubmit)="submit()"
    class="dialog-layout"
  >
    <div [style.width.px]="width">
      <div
        *ngIf="messageHtml"
        [innerHTML]="messageHtml"
        class="dialog-message"
      >
      </div>
      <div
        [innerHTML]="phraseMessageHtml"
        class="dialog-message"
      >
      </div>
      <input
        appInput
        autocomplete="off"
        class="dialog-input"
        formControlName="promptInput"
      >
      <app-form-control-error [control]="promptForm.get('promptInput')"></app-form-control-error>
    </div>
  </form>
</app-dialog-content>
<div class="dialog-footer">
  <button
    [ngClass]="cancelButtonClass"
    (click)="cancel()"
    class="dialog-button dialog-button-cancel"
    type="button"
  >
    {{ cancelLabel }}
  </button>
  <button
    [ngClass]="okButtonClass"
    [submitButton]="promptForm"
    (click)="submit()"
    class="dialog-button dialog-button-submit"
    type="submit"
  >
    {{ okLabel }}
  </button>
</div>

<ng-template [ngIf]="scrollbar">
  <app-scrollbar
    [autoHeightDisabled]="false"
    [autoWidthDisabled]="false"
    [viewClass]="scrollbarViewClass"
    track="all"
  >
    <div class="dialog-content-scrollbar-content">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </app-scrollbar>
</ng-template>
<ng-template [ngIf]="!scrollbar">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

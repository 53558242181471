export class StorageWrapper {
  constructor(protected storage: Storage) {}

  public set(key: string, value: any): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  public get(key: string): any {
    const value = this.storage.getItem(key);

    try {
      return JSON.parse(value);
    } catch {
      this.remove(key);
    }

    return null;
  }

  public remove(key: string): void {
    this.storage.removeItem(key);
  }

  public clear(): void {
    this.storage.clear();
  }
}

import { NgModule } from '@angular/core';
import { UiHelperModule } from '@proget-shared/ui/helper';

import { FormControlLabelComponent } from './form-control-label.component';
import { RequiredClassDirective } from './required-class.directive';

@NgModule({
  imports: [UiHelperModule],
  declarations: [FormControlLabelComponent, RequiredClassDirective],
  exports: [UiHelperModule, FormControlLabelComponent],
})
export class FormControlLabelModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from '@proget-shared/form/checkbox';
import { TranslateModule } from '@proget-shared/translate';
import { OverlayPanelModule } from '@proget-shared/ui/overlay-panel';
import { SpinnerModule } from '@proget-shared/ui/spinner';

import { SelectionAllVisibleItemsComponent } from './component/selection-all-visible-items/selection-all-visible-items.component';
import { SelectionInfoComponent } from './component/selection-info/selection-info.component';
import { SelectionItemComponent } from './component/selection-item/selection-item.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    SpinnerModule,
    TranslateModule.forChild(),
    CheckboxModule,
  ],
  declarations: [SelectionAllVisibleItemsComponent, SelectionItemComponent, SelectionInfoComponent],
  exports: [SelectionAllVisibleItemsComponent, SelectionItemComponent, SelectionInfoComponent],
})
export class SelectionModule {}

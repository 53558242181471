import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { FilterConfig } from '../type/filter-config.type';

export class FilterFormControl<T = any> extends FormControl<T> {
  public readonly activate$: Observable<void>;

  private activateSubject = new Subject<void>();

  constructor(
    public config: FilterConfig,
    initialValue: T
  ) {
    super(initialValue);

    this.activate$ = this.activateSubject.asObservable();
  }

  public activate(): void {
    this.activateSubject.next();
  }
}

import { NgModule } from '@angular/core';
import { TranslateModule } from '@proget-shared/translate';

import { InputLengthCounterComponent } from './input-length-counter.component';

@NgModule({
  imports: [
    TranslateModule.forChild(),
  ],
  declarations: [InputLengthCounterComponent],
  exports: [InputLengthCounterComponent],
})
export class InputLengthCounterModule {}

<ng-template
  #template
  let-items
>
  <ul class="menu-list">
    <li
      *ngFor="let item of items"
      [attr.data-qa]="item.dataQa"
      (click)="item.command($event)"
      class="list-item"
    >
      {{ item.label }}
    </li>
  </ul>
</ng-template>

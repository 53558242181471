import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DropdownItem } from '@proget-shared/form/dropdown';
import { DateHelper } from '@proget-shared/helper';
import { Subscription } from 'rxjs';

import { CalendarService } from '../../service/calendar.service';

import { SelectionLevel } from './selection-level.enum';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateSelectorComponent implements OnDestroy {
  @Input()
  public monthLabelKey = 'calendar.month_names';

  protected readonly SelectionLevel = SelectionLevel;
  protected readonly headerControl = new FormGroup({
    day: new FormControl(1),
    month: new FormControl(0),
    year: new FormControl(0),
  });

  protected dayOptions: DropdownItem[] = [];
  protected monthOptions: DropdownItem[] = [];
  protected yearOptions: DropdownItem[] = [];

  @Output()
  private readonly dateChange = new EventEmitter<Date>();
  private readonly subscription = new Subscription();

  private _level = SelectionLevel.DAY;
  private _currentDate: Date = null;
  private _minDate: Date = null;
  private _maxDate: Date = null;

  constructor(private calendarService: CalendarService) {
    this.subscription.add(
      this.headerControl.valueChanges.subscribe({
        next: (value) => {
          this.dateChange.emit(this.roundDate(new Date(value.year, value.month, value.day)));
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set level(value: SelectionLevel) {
    this._level = value;

    this.updateOptions();
  }

  public get level(): SelectionLevel {
    return this._level;
  }

  @Input()
  public set minDate(value: Date) {
    this._minDate = value;

    this.updateOptions();
  }

  public get minDate(): Date {
    return this._minDate;
  }

  @Input()
  public set maxDate(value: Date) {
    this._maxDate = value;

    this.updateOptions();
  }

  public get maxDate(): Date {
    return this._maxDate;
  }

  @Input()
  public set currentDate(date: Date) {
    this._currentDate = DateHelper.isValidDate(date) ? date : null;

    if (this.currentDate) {
      this.headerControl.enable({ emitEvent: false });
      this.headerControl.setValue(
        { day: this.currentDate.getDate(), month: this.currentDate.getMonth(), year: this.currentDate.getFullYear() },
        { emitEvent: false }
      );
    } else {
      this.headerControl.disable({ emitEvent: false });
      this.headerControl.setValue(
        { day: null, month: null, year: null },
        { emitEvent: false }
      );
    }

    this.updateOptions();
  }

  public get currentDate(): Date {
    return this._currentDate;
  }

  public get minDateSelected(): boolean {
    if (!this.currentDate) {
      return false;
    }

    const dateTime = DateHelper.mergeDateTime(this.getSiblingDate(-1), this.currentDate);

    return !this.isDateInRange(dateTime) || this.minDate && dateTime.getTime() <= this.minDate.getTime();
  }

  public get maxDateSelected(): boolean {
    if (!this.currentDate) {
      return false;
    }

    const dateTime = DateHelper.mergeDateTime(this.getSiblingDate(1), this.currentDate);

    return !this.isDateInRange(dateTime) || this.maxDate && dateTime.getTime() >= this.maxDate.getTime();
  }

  protected emitNextDate(): void {
    this.dateChange.emit(this.getSiblingDate(1));
  }

  protected emitPreviousDate(): void {
    this.dateChange.emit(this.getSiblingDate(-1));
  }

  private getSiblingDate(param: number): Date {
    if (!DateHelper.isValidDate(this.currentDate)) {
      return null;
    }

    switch (this.level) {
      case SelectionLevel.YEAR:
        return new Date(this.currentDate.getFullYear() + param);
      case SelectionLevel.MONTH:
        return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + param);
      case SelectionLevel.DAY:
        return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() + param);
    }
  }

  private updateOptions(): void {
    if (!DateHelper.isValidDate(this.currentDate)) {
      this.dayOptions = [{ label: '-', value: null }];
      this.monthOptions = [{ label: '-', value: null }];
      this.yearOptions = [{ label: '-', value: null }];

      return;
    }

    const currentYear = this.currentDate.getFullYear();
    const daysCount = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0).getDate();

    this.dayOptions = Array.from(new Array(daysCount).keys())
      .map((index) => ({ value: index + 1, label: (index + 1).toString() }))
      .filter((item) => {
        const date = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), item.value);
        const isCurrentDay = item.value === this.currentDate.getDate();

        return isCurrentDay || this.isDateInRange(date) && this.isValidDate(date);
      });

    this.monthOptions = Array.from(new Array(12).keys())
      .map((index) => ({ value: index, label: `${this.monthLabelKey}.${index}` }))
      .filter((item) => {
        const date = new Date(this.currentDate.getFullYear(), item.value, this.currentDate.getDate());
        const isCurrentMonth = item.value === this.currentDate.getMonth();

        return isCurrentMonth || this.isDateInRange(date) && this.isValidDate(date);
      });

    this.yearOptions = Array.from(new Array(9).keys())
      .map((index) => ({ value: currentYear + index - 4, label: (currentYear + index - 4).toString() }))
      .filter((item) => {
        const date = new Date(item.value, this.currentDate.getMonth(), this.currentDate.getDate());
        const isCurrentYear = item.value === this.currentDate.getFullYear();

        return isCurrentYear || this.isDateInRange(date) && this.isValidDate(date);
      });
  }

  private roundDate(date: Date): Date {
    return DateHelper.isValidDate(date)
      ? new Date(
        date.getFullYear(),
        this.level <= SelectionLevel.MONTH ? date.getMonth() : 0,
        this.level <= SelectionLevel.DAY ? date.getDate() : 1
      )
      : null;
  }

  private isValidDate(date: Date): boolean {
    if (this.minDate && DateHelper.mergeDateTime(date, this.currentDate).getTime() <= this.minDate.getTime()) {
      return false;
    }

    if (this.maxDate && DateHelper.mergeDateTime(date, this.currentDate).getTime() >= this.maxDate.getTime()) {
      return false;
    }

    return true;
  }

  private isDateInRange(dateTime: Date): boolean {
    switch (this.level) {
      case SelectionLevel.YEAR:
        return this.calendarService.isYearInRange(dateTime);
      case SelectionLevel.MONTH:
        return this.calendarService.isMonthInRange(dateTime);
      case SelectionLevel.DAY:
        return this.calendarService.isYearInRange(dateTime);
    }
  }
}

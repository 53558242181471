import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@proget-shared/dialog';
import { CapsLockTooltipModule } from '@proget-shared/form/caps-lock-tooltip';
import { FormControlErrorModule } from '@proget-shared/form/form-control-error';
import { FormControlLabelModule } from '@proget-shared/form/form-control-label';
import { FormHelperModule } from '@proget-shared/form/helper';
import { InputModule } from '@proget-shared/form/input';
import { PasswordModule } from '@proget-shared/form/password';
import { InputLengthCounterModule } from '@proget-shared/ui/input-length-counter';

import { AlertComponent } from './component/alert/alert.component';
import { ConfirmComponent } from './component/confirm/confirm.component';
import { PhraseConfirmComponent } from './component/phrase-confirm/phrase-confirm.component';
import { PromptComponent } from './component/prompt/prompt.component';

@NgModule({
  declarations: [
    AlertComponent,
    ConfirmComponent,
    PhraseConfirmComponent,
    PromptComponent,
  ],
  imports: [
    DialogModule,
    CommonModule,
    ReactiveFormsModule,
    FormControlLabelModule,
    FormControlErrorModule,
    CapsLockTooltipModule,
    InputModule,
    InputLengthCounterModule,
    PasswordModule,
    FormHelperModule,
  ],
  exports: [
    DialogModule,
  ],
})
export class DialogTemplateModule {}

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BetaFeaturesEnabled, PermissionService } from '@proget-shared/_local';

import { EncodeHttpParamsInterceptor } from './_interceptor/encode-http-params.interceptor';
import { LanguageInterceptor } from './_interceptor/language.interceptor';
import { RequestCancelInterceptor } from './_interceptor/request-cancel.interceptor';

type ProgetSharedConfig = {
  betaFeaturesEnabled: boolean;
  permissionService: any;
};

@NgModule()
export class ProgetSharedModule {
  public static forRoot(config: Partial<ProgetSharedConfig> = {
    betaFeaturesEnabled: false,
  }): ModuleWithProviders<ProgetSharedModule> {
    return {
      ngModule: ProgetSharedModule,
      providers: [
        config?.permissionService
          ? { provide: PermissionService, useExisting: config.permissionService }
          : PermissionService,
        {
          provide: BetaFeaturesEnabled,
          useValue: config.betaFeaturesEnabled ?? false,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: EncodeHttpParamsInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestCancelInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LanguageInterceptor,
          multi: true,
        },
      ],
    };
  }

  public static forChild(): ModuleWithProviders<ProgetSharedModule> {
    return {
      ngModule: ProgetSharedModule,
    };
  }
}

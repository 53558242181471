import { HttpBackend, HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  DEFAULT_LANGUAGE,
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateDefaultParser,
  TranslateFakeCompiler,
  TranslateFakeLoader,
  TranslateLoader,
  TranslateModule as NgxTranslateModule,
  TranslateParser,
  TranslateService as NgxTranslateService,
  TranslateStore,
  USE_DEFAULT_LANG,
  USE_EXTEND,
  USE_STORE,
} from '@ngx-translate/core';

import { CustomHttpLoader } from './custom-http.loader';
import { MissingTranslationReporterService } from './service/missing-translation-reporter.service';
import { TRANSLATE_OPTIONS } from './translate-options.token';
import { TranslateOptions } from './type/translate-options.type';

function HttpLoaderFactory(httpBackend: HttpBackend): TranslateLoader {
  // https://github.com/ngx-translate/core/issues/921
  return new CustomHttpLoader(new HttpClient(httpBackend), 'assets/i18n/', `.json?date=${Date.now()}`);
}

@NgModule({
  exports: [NgxTranslateModule],
})
export class TranslateModule {
  public static forRoot(options: TranslateOptions): ModuleWithProviders<TranslateModule> {
    return {
      ngModule: TranslateModule,
      providers: [
        { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpBackend] },
        { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
        { provide: TranslateParser, useClass: TranslateDefaultParser },
        { provide: MissingTranslationHandler, useExisting: MissingTranslationReporterService },
        TranslateStore,
        { provide: USE_STORE, useValue: void 0 },
        { provide: USE_DEFAULT_LANG, useValue: void 0 },
        { provide: USE_EXTEND, useValue: void 0 },
        { provide: DEFAULT_LANGUAGE, useValue: void 0 },
        NgxTranslateService,
        {
          provide: TRANSLATE_OPTIONS,
          useValue: options,
        },
      ],
    };
  }

  public static forChild(): ModuleWithProviders<TranslateModule> {
    return {
      ngModule: TranslateModule,
      providers: [
        { provide: TranslateLoader, useClass: TranslateFakeLoader },
        { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
        { provide: TranslateParser, useClass: TranslateDefaultParser },
        { provide: MissingTranslationHandler, useExisting: MissingTranslationReporterService },
        { provide: USE_STORE, useValue: void 0 },
        { provide: USE_DEFAULT_LANG, useValue: void 0 },
        { provide: USE_EXTEND, useValue: void 0 },
        { provide: DEFAULT_LANGUAGE, useValue: void 0 },
        NgxTranslateService,
      ],
    };
  }
}

<label
  [class.radio-button-disabled]="form.disabled"
  [formGroup]="form"
  class="radio-button-group"
>
  <input
    [attr.name]="name"
    [value]="value"
    (change)="$event.stopPropagation()"
    formControlName="radio"
    type="radio"
  >
  <span class="radio-button-dot"></span>
  <span class="radio-button-label">{{ label }}</span>
</label>

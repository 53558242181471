import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { OverlayPanelComponent } from '../component/overlay-panel.component';

@Injectable({
  providedIn: 'root',
})
export class OverlayPanelService {
  public readonly close$: Observable<OverlayPanelComponent | void>;

  private readonly close = new Subject<OverlayPanelComponent | void>();

  private activePanels: OverlayPanelComponent[] = [];

  constructor() {
    this.close$ = this.close.asObservable();
  }

  public register(panel: OverlayPanelComponent): void {
    this.activePanels = this.activePanels.concat(panel);
  }

  public unregister(panel: OverlayPanelComponent): void {
    this.activePanels = this.activePanels.filter((activePanel) => activePanel !== panel);
  }

  public isAnyActive(): boolean {
    return this.activePanels.filter((panel) => !panel.destroying).length > 0;
  }

  public closeOthers(caller: OverlayPanelComponent): void {
    this.close.next(caller);
  }

  public closeAll(): void {
    this.close.next();
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { DateHelper } from '@proget-shared/helper';
import { OverlayPanelDirective } from '@proget-shared/ui/overlay-panel';
import {
  EMPTY,
  noop,
  Observable,
  of,
  Subject,
  Subscription,
  distinctUntilChanged,
  filter,
  switchMap,
  tap,
  fromEvent,
  debounceTime,
} from 'rxjs';

import { CalendarType } from '../../const/calendar-type.enum';
import { CalendarView } from '../../const/calendar-view.enum';
import { RangeEdge } from '../../const/range-edge.enum';
import { CalendarDateTime } from '../../model/calendar-date-time.model';
import { CalendarRange } from '../../model/calendar-range.model';
import { CalendarService } from '../../service/calendar.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
    CalendarService,
  ],
})
export class CalendarComponent implements ControlValueAccessor, Validator, AfterViewInit, OnDestroy {
  @Input()
  public firstWeekDay = 1;
  @Input()
  public dayShortLabelKey = 'calendar.day_names_short';
  @Input()
  public dayFullLabelKey = 'calendar.day_names';
  @Input()
  public monthLabelKey = 'calendar.month_names';
  @Input()
  public outputType: 'object' | 'string' = 'string';
  @Input()
  public selectedRangeEdge = RangeEdge.FROM;
  @Input()
  public placeholder = '';
  @Input()
  public target = 'body';
  @Input()
  public closeOnScroll = true;
  @Input()
  public panelReferer: HTMLElement;

  protected readonly RangeEdge = RangeEdge;
  protected readonly form = new FormGroup({
    input: new FormGroup({
      date: new FormControl(),
      endDate: new FormControl(),
    }),
    calendar: new FormControl(),
  });

  protected editingEdge: RangeEdge = null;
  protected calendarView = CalendarView.DATE;
  protected labelVisibility = true;

  private readonly subscription: Subscription = new Subscription();

  @ViewChild('calendarPanel', { read: OverlayPanelDirective })
  private calendarPanelDirective: OverlayPanelDirective;
  @ViewChild('dateInput')
  private dateInput: ElementRef<HTMLInputElement>;
  @ViewChild('endDateInput')
  private endDateInput: ElementRef<HTMLInputElement>;
  private modelTouched: () => void = noop;
  private modelChanged: (date: CalendarDateTime |
    string |
    { from: CalendarDateTime; to: CalendarDateTime } |
    { from: string; to: string }
  ) => void = noop;
  private calendarOpenedSubject = new Subject<boolean>();
  private calendarPanelOpened = false;
  private _disabled = false;

  constructor(
    protected readonly calendarService: CalendarService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscription.add(
      this.watchInputDate('date')
        .pipe(
          filter((date) => {
            const endDate = this.getEndDate();

            return !this.calendarService.range || !date || !endDate || date.getValue().getTime() <= endDate.getTimeEnd().getTime();
          })
        )
        .subscribe({
          next: (value) => {
            this.form.patchValue({
              calendar: this.calendarService.range ? new CalendarRange(value, this.getEndDate(), RangeEdge.FROM) : value,
            });

            if (!this.calendarPanelOpened) {
              this.emitValue();
            }
          },
        })
    );

    this.subscription.add(
      this.watchInputDate('endDate')
        .pipe(
          filter((date) => {
            if (!this.calendarService.range) {
              return;
            }

            const startDate = this.getDate();

            return !date || !startDate || date.getValue().getTime() >= startDate.getTimeStart().getTime();
          })
        )
        .subscribe({
          next: (value) => {
            this.form.patchValue({
              calendar: new CalendarRange(this.getDate(), value, RangeEdge.TO),
            });

            if (!this.calendarPanelOpened) {
              this.emitValue();
            }
          },
        })
    );

    this.subscription.add(
      this.form.get('calendar').valueChanges.subscribe({
        next: (value: CalendarDateTime | CalendarRange) => {
          this.selectedRangeEdge = this.editingEdge;

          if (value instanceof CalendarDateTime) {
            this.form.get('input').setValue({
              date: this.calendarService.stringifyDate(value),
              endDate: '',
            }, { emitEvent: false });
          } else if (value instanceof CalendarRange) {
            this.form.get('input').setValue({
              date: value.from ? this.calendarService.stringifyDate(value.from) : '',
              endDate: value.to ? this.calendarService.stringifyDate(value.to) : '',
            }, { emitEvent: false });
          } else {
            this.form.get('input').setValue({
              date: '',
              endDate: '',
            }, { emitEvent: false });
          }

          this.updateLabelVisibility();
        },
      })
    );

    this.subscription.add(
      this.calendarOpenedSubject
        .pipe(
          distinctUntilChanged(),
          tap({
            next: (opened) => {
              this.calendarPanelOpened = opened;
            },
          }),
          filter((opened) => !opened)
        )
        .subscribe({
          next: () => {
            this.emitValue();
          },
        })
    );

    this.subscription.add(
      fromEvent(window, 'resize').pipe(debounceTime(100))
        .subscribe(() => {
          this.updateLabelVisibility();
        })
    );
  }

  writeValue(value: any): void {
    this.form.get('calendar').setValue(this.calendarService.parseInput(value));
  }

  registerOnChange(fn: (date: CalendarDateTime | string) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable({ emitEvent: false }) : this.form.enable({ emitEvent: false });
    this._disabled = isDisabled;
  }

  validate(): ValidationErrors | null {
    const value = this.form.value.calendar;

    if (value instanceof CalendarRange && !value.isValid()) {
      return { invalidRange: true };
    }

    return null;
  }

  ngAfterViewInit(): void {
    this.updateLabelVisibility();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  public set dateFormat(value: string) {
    this.calendarService.dateFormat = value;
  }

  @Input()
  public set timeFormat(value: string) {
    this.calendarService.timeFormat = value;
  }

  @Input()
  public set type(type: CalendarType) {
    this.calendarService.type = type;
  }

  @Input()
  public set range(value: boolean) {
    this.calendarService.range = value;
  }

  @Input()
  public set minDate(date: Date) {
    this.calendarService.minDate = date;
  }

  @Input()
  public set maxDate(date: Date) {
    this.calendarService.maxDate = date;
  }

  @Input()
  public set clearEnabled(value: boolean) {
    this.calendarService.clearEnabled = value;
  }

  @Input()
  public set timeStep(value: number) {
    this.calendarService.timeStep = value;
  }

  public openPanel(): void {
    this.selectedRangeEdge = RangeEdge.FROM;
    this.dateInput.nativeElement.focus();
    this.calendarPanelDirective.open(this.panelReferer);
  }

  protected verifyInputDate(inputControlName: 'date' | 'endDate'): void {
    const inputControl = this.form.get(`input.${inputControlName}`);
    const currentDate = this.calendarService.range
      ? inputControlName === 'date'
        ? this.form.value.calendar instanceof CalendarRange ? this.form.value.calendar.from : null
        : this.form.value.calendar instanceof CalendarRange ? this.form.value.calendar.to : null
      : this.form.value.calendar;

    try {
      const currentDateString = this.calendarService.stringifyDate(currentDate);

      if (currentDateString !== inputControl.value) {
        inputControl.setValue(currentDateString);
      }
    } catch {
      inputControl.setValue('');
    }
  }

  protected calendarStatusChanged(opened: boolean): void {
    if (!opened) {
      this.editingEdge = null;
    }

    this.calendarOpenedSubject.next(opened);
  }

  protected markEditingRangeEdge(edge: RangeEdge): void {
    this.editingEdge = edge;

    if (edge === null) {
      // reset selection edge when time was disabled (allows to select previously selected edge)
      // TODO replace @Input selectedRangeEdge in inline-calendar with method and remove this condition
      // *overlay panel must return created instance
      this.selectedRangeEdge = edge;
    }

    this.cdr.detectChanges();
  }

  protected closePanel(): void {
    this.calendarPanelDirective.close();
  }

  private emitValue(): void {
    this.modelTouched();
    this.modelChanged(this.calendarService.prepareOutput(this.form.value.calendar, this.outputType as any));
  }

  private getDate(): CalendarDateTime | null {
    if (DateHelper.isValidDate(this.form.value.calendar)) {
      return this.form.value.calendar;
    }

    return this.form.value.calendar instanceof CalendarRange ? this.form.value.calendar.from : null;
  }

  private getEndDate(): CalendarDateTime | null {
    return this.form.value.calendar instanceof CalendarRange ? this.form.value.calendar.to : null;
  }

  private watchInputDate(inputControlName: 'date' | 'endDate'): Observable<CalendarDateTime | null> {
    return this.form.get(`input.${inputControlName}`).valueChanges.pipe(
      switchMap((dateString) => {
        if (!dateString && this.calendarService.clearEnabled) {
          return of(null);
        }

        const parsed = this.calendarService.parseDate(dateString, inputControlName === 'endDate');

        return parsed ? of(this.calendarService.applyTimeStep(parsed)) : EMPTY;
      })
    );
  }

  private updateLabelVisibility(): void {
    this.labelVisibility = true;

    if (!this.dateInput || !this.endDateInput) {
      return;
    }

    window.requestAnimationFrame(() => {
      this.labelVisibility = !(
        this.getInputOverflow(this.dateInput) || this.getInputOverflow(this.endDateInput)
      );
    });
  }

  private getInputOverflow(inputRef: ElementRef<HTMLInputElement>): boolean {
    return inputRef.nativeElement.offsetWidth < this.textLength(inputRef.nativeElement);
  }

  private textLength(inputElement: HTMLInputElement): number {
    const fixedStyles: Partial<CSSStyleDeclaration> = {
      whiteSpace: 'nowrap',
      width: 'auto',
    };

    const inputStyle = window.getComputedStyle(inputElement);
    const test = document.createElement('span');

    for (let i = 0; i < inputStyle.length; i++) {
      const style = inputStyle.item(i);

      test.style.setProperty(style, fixedStyles[style] ?? inputStyle.getPropertyValue(style));
    }

    test.innerText = inputElement.value;
    document.body.append(test);
    const testWidth = test.offsetWidth;

    test.remove();

    return testWidth;
  }
}

<div class="actions-container">
  <div class="selection-actions">
    <app-selection-info
      *ngIf="selectionInfo"
      [customSelection]="customSelection"
      [forceMenu]="forceSelectionMenu"
    ></app-selection-info>
    <span class="actions-separator"></span>
    <div class="buttons-wrapper desktop-only">
      <button
        *ngFor="let action of visibleActions"
        [appTooltip]="action.label | translate"
        [appTooltipDisabled]="!isActionEnabled(action)"
        [attr.data-qa]="getQaAttribute(action)"
        [disabled]="!isActionEnabled(action)"
        (click)="performAction(action)"
        appTooltipPosition="top"
        appTooltipClass="tooltip-nowrap"
        type="button"
        class="action-button proget-button primary-button icon-only"
      >
        <i [class]="action.icon"></i>
      </button>
    </div>
    <app-tooltip
      [hidden]="visibleActions.length <= 3"
      class="desktop-only"
    >
      <div class="icons-description">
        <div class="icons-description-header">
          {{ 'proget_shared.grid.actions.icons_description' | translate }}:
        </div>
        <ol class="icons-description-list">
          <li
            *ngFor="let action of visibleActions"
            class="icons-description-item"
          >
            <i
              [ngClass]="action.icon"
              class="item-icon"
            >
            </i>
            <span class="item-label"> - {{ action.label | translate }}</span>
          </li>
        </ol>
      </div>
    </app-tooltip>
    <div class="mobile-only">
      <select
        [formControl]="actionForm"
        class="grid-action-mobile-select"
      >
        <option
          disabled
          selected
          value=""
        >
          {{ 'proget_shared.grid.actions.select_action' | translate }}
        </option>
        <option
          *ngFor="let action of visibleActions"
          [hidden]="!isActionEnabled(action)"
          [ngValue]="action"
        >
          {{ action.label | translate }}
        </option>
      </select>
    </div>
  </div>
  <div class="side-buttons">
    <ng-content></ng-content>
  </div>
</div>

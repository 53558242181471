<div
  #container
  [class.destroy]="destroying"
  [style]="containerStyle"
  class="overlay-panel-container"
>
  <div #scrolledContent>
    <app-scrollbar
      #scrollbar
      *ngIf="scrollContent"
      [autoWidthDisabled]="false"
      [autoHeightDisabled]="false"
    >
      <div
        [style.padding.px]="padding"
        class="overlay-panel-content scrolled-content"
      >
        <ng-container
          [ngTemplateOutlet]="getTemplate()"
          [ngTemplateOutletContext]="{ $implicit: templateData }"
          [ngTemplateOutletInjector]="injector"
        ></ng-container>
      </div>
    </app-scrollbar>
  </div>
  <div #fixedContent>
    <div
      *ngIf="!scrollContent"
      [style.padding.px]="padding"
      class="overlay-panel-content"
    >
      <ng-container
        [ngTemplateOutlet]="getTemplate()"
        [ngTemplateOutletContext]="{ $implicit: templateData }"
        [ngTemplateOutletInjector]="injector"
      ></ng-container>
    </div>
  </div>
</div>

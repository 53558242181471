import { NgModule } from '@angular/core';

import { DateOnlyPipe } from './date-only/date-only.pipe';
import { DefaultPipe } from './default/default.pipe';
import { EscapeHtmlPipe } from './escape-html/escape-html.pipe';
import { FileSizePipe } from './file-size/file-size.pipe';
import { SecondsToTimePipe } from './seconds-to-time/seconds-to-time.pipe';
import { TransformCasePipe } from './transform-case/transform-case.pipe';
import { UtcDateTimePipe } from './utc-date-time/utc-date-time.pipe';

@NgModule({
  declarations: [DateOnlyPipe, DefaultPipe, EscapeHtmlPipe, FileSizePipe, TransformCasePipe, SecondsToTimePipe, UtcDateTimePipe],
  providers: [DateOnlyPipe, DefaultPipe, EscapeHtmlPipe, FileSizePipe, TransformCasePipe, SecondsToTimePipe, UtcDateTimePipe],
  exports: [DateOnlyPipe, DefaultPipe, EscapeHtmlPipe, FileSizePipe, TransformCasePipe, SecondsToTimePipe, UtcDateTimePipe],
})
export class PipeModule {}

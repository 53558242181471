import { Injectable, OnDestroy } from '@angular/core';
import { DialogService } from '@proget-shared/dialog';
import { WorkerResult, WorkerTranslations, WorkerItem, BatchWorker } from '@proget-shared/grid/grid-actions';
import { ObjectHelper } from '@proget-shared/helper';
import { sum } from '@proget-shared/helper/operators';
import {
  ResponseError,
  ResponseErrorFactory,
  ResponseErrorTemplateMapper,
} from '@proget-shared/helper/response-error';
import { TranslateService } from '@proget-shared/translate';
import { ToastService } from '@proget-shared/ui/toast';
import {
  catchError,
  combineLatest,
  delay,
  finalize,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  reduce,
  share,
  Subject,
  Subscription,
  tap,
} from 'rxjs';

import { WorkProgressDialogComponent } from '../component/work-progress-dialog/work-progress-dialog.component';
import { WorkReport } from '../interface/work-report';

@Injectable()
export class BatchWorkService implements BatchWorker, OnDestroy {
  private readonly pendingWorksSubject = new Subject<number>();
  private readonly subscription = new Subscription();

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {
    this.subscription.add(
      this.pendingWorksSubject.pipe(sum()).subscribe({
        next: (pendingWorks) => {
          document.body.classList.remove('batch-work-pending');

          if (pendingWorks > 0) {
            document.body.classList.add('batch-work-pending');
          }
        },
      })
    );
  }

  process<T extends WorkerItem>(
    items: T[],
    actionMethod: (id: string | number, item: WorkerItem) => Observable<any>,
    translations: WorkerTranslations<T>,
    errorsDialog: boolean | any = false
  ): Observable<WorkerResult> {
    const itemsCount: number = items.length;
    // TO-DO Transform stream instead
    let processCancelled = false;

    this.pendingWorksSubject.next(1);

    const firstBatch = items.slice(0, 10);
    const remainingItems = items.slice(10);
    const nextItemSubject = new Subject<T>();

    if (remainingItems.length === 0) {
      nextItemSubject.complete();
    }

    const action$: Observable<Partial<WorkReport>> = merge(
      from(firstBatch),
      nextItemSubject
    )
      .pipe(
        mergeMap((item: T) => (processCancelled
          ? of({
            id: this.readItemDisplayName(item),
            action: this.getSingleActionLabel<T>(translations.singleActionKey, item),
            result: 'message.batch_work_cancelled',
            success: false,
            item,
          })
          : actionMethod(item.getId(), item).pipe(
            delay(1), // delay for share to be updated
            map(() => ({
              id: this.readItemDisplayName(item),
              action: this.getSingleActionLabel<T>(translations.singleActionKey, item),
              result: translations.singleResultKey,
              success: true,
              item,
            })),
            catchError((errorObject) => this.handleError(errorObject, item, translations)),
            tap({
              next: (report: WorkReport) => {
                processCancelled = !!report.cancelProcess;
              },
            })
          ))
        ),
        tap({
          next: () => {
            remainingItems.length
              ? nextItemSubject.next(remainingItems.shift())
              : nextItemSubject.complete();
          },
        }),
        finalize(() => {
          this.pendingWorksSubject.next(-1);
        }),
        share()
      );

    return combineLatest([
      // combineLatest is used to wait whole stream for dialog to be closed
      this.openWorkProgress(action$, itemsCount, translations.actionTitleKey, errorsDialog),
      action$.pipe(
        reduce(
          (result, current) => this.incrementBatchWorkResult(result, current),
          { success: [], error: [], partial: false } as WorkerResult
        ),
        tap({
          next: (result) => {
            this.displayCompleteToast(result, translations);
          },
        })
      ),
    ]).pipe(map((stream: [void, WorkerResult]) => stream[1]));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getSingleActionLabel<T>(
    singleActionKey: string | ((item: T) => string),
    item: T
  ): string {
    if ('function' === typeof singleActionKey) {
      return singleActionKey(item);
    }

    return singleActionKey;
  }

  private incrementBatchWorkResult(result: WorkerResult, report: Partial<WorkReport>): WorkerResult {
    return report.success
      ? { ...result, success: result.success.concat(report.item) }
      : { ...result, error: result.error.concat(report.item) };
  }

  private handleError<T extends WorkerItem>(
    errorObject: ResponseError,
    item: T,
    translations: WorkerTranslations
  ): Observable<Partial<WorkReport>> {
    if (errorObject?.code === 0) {
      return of({
        id: this.readItemDisplayName(item),
        action: this.getSingleActionLabel<T>(translations.singleActionKey, item),
        result: '',
        success: false,
        cancelProcess: true,
        errorObject,
        item,
      });
    }

    const formErrors: string[] = Array.from(new Set(ObjectHelper.findStrings(this.getFormErrors(errorObject))));

    return of({
      id: this.readItemDisplayName(item),
      action: this.getSingleActionLabel<T>(translations.singleActionKey, item),
      result:
        translations.singleErrorKey ||
        ResponseErrorTemplateMapper.map(errorObject?.messages || formErrors),
      success: false,
      errorObject,
      item,
    });
  }

  private getFormErrors(errorObject: ResponseError): ResponseError {
    const responseErrorFactoryForm: ResponseError = ResponseErrorFactory.create(errorObject).form;

    if (responseErrorFactoryForm) {
      return responseErrorFactoryForm;
    }

    if (errorObject?.form) {
      return errorObject.form;
    }

    return {};
  }

  private openWorkProgress(
    report$: Observable<Partial<WorkReport>>,
    totalReportsCount: number,
    headerKey?: string,
    errorsDialog: boolean | any = false
  ): Observable<void> {
    return this.dialogService.custom<WorkProgressDialogComponent, void>(WorkProgressDialogComponent, {
      configuration: {
        report$,
        totalReportsCount,
        headerKey,
        errorsDialog,
      },
    });
  }

  private displayCompleteToast(result: WorkerResult, translations: WorkerTranslations): void {
    this.toastService.info(
      translations.completeHeaderKey,
      {
        key: translations.completeMessageKey,
        params: result,
      },
      {
        duration: 10000,
      }
    );
  }

  private readItemDisplayName(item: WorkerItem): string {
    if (
      typeof item.isDisplayNameTranslationRequired === 'function' &&
      item.isDisplayNameTranslationRequired()
    ) {
      return this.translateService.instant(item.getDisplayName());
    }

    return item.getDisplayName();
  }
}

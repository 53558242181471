import { Injectable, Type } from '@angular/core';
import { DialogOptions, DialogService } from '@proget-shared/dialog';
import { Observable } from 'rxjs';

import { AlertComponent } from './component/alert/alert.component';
import { ConfirmComponent } from './component/confirm/confirm.component';
import { PhraseConfirmComponent } from './component/phrase-confirm/phrase-confirm.component';
import { PromptComponent } from './component/prompt/prompt.component';
import { AlertConfiguration } from './interface/alert-configuration.interface';
import { ConfirmConfiguration } from './interface/confirm-configuration.interface';
import { PhraseConfirmConfiguration } from './interface/phrase-confirm-configuration.interface';
import { PromptConfiguration } from './interface/prompt-configuration.interface';

@Injectable({ providedIn: 'root' })
export class DialogTemplateService {

  constructor(
    private dialogService: DialogService
  ) {}

  alert(
    message: string,
    options?: Partial<DialogOptions<AlertConfiguration>> & { returnDialogInstance: true }
  ): { message$: Observable<void>; dialog: AlertComponent };
  alert(
    message: string,
    options?: Partial<DialogOptions<AlertConfiguration>> & { returnDialogInstance?: false }
  ): Observable<void>;
  alert(
    message: string,
    options?: Partial<DialogOptions<AlertConfiguration>>
  ): Observable<void> | { message$: Observable<void>; dialog: AlertComponent };
  public alert(
    message: string,
    options: Partial<DialogOptions<AlertConfiguration>> = {}
  ): Observable<void> | { message$: Observable<void>; dialog: AlertComponent } {
    const configuration = Object.assign(
      { okLabel: 'Ok' },
      options.configuration,
      { message }
    );
    const mergedOptions = Object.assign({ cancellable: true }, options, {
      configuration,
    });

    return this.custom<AlertComponent, void>(AlertComponent, mergedOptions);
  }

  confirm(
    message: string,
    options?: Partial<DialogOptions<ConfirmConfiguration>> & { returnDialogInstance: true }
  ): { message$: Observable<boolean>; dialog: ConfirmComponent };
  confirm(message: string,
    options?: Partial<DialogOptions<ConfirmConfiguration>> & { returnDialogInstance?: false }
  ): Observable<boolean>;
  confirm(
    message: string,
    options?: Partial<DialogOptions<ConfirmConfiguration>>
  ): Observable<boolean> | { message$: Observable<boolean>; dialog: ConfirmConfiguration };
  public confirm(
    message: string,
    options: Partial<DialogOptions<ConfirmConfiguration>> = {}
  ): Observable<boolean> | { message$: Observable<boolean>; dialog: ConfirmComponent } {
    const configuration = Object.assign(
      { noLabel: 'No', yesLabel: 'Yes' },
      options.configuration,
      { message }
    );
    const mergedOptions = Object.assign({ cancellable: true }, options, {
      configuration,
    });

    return this.custom<ConfirmComponent, boolean>(
      ConfirmComponent,
      mergedOptions
    );
  }

  prompt(
    message: string,
    initialValue: string,
    options?: Partial<DialogOptions<PromptConfiguration>> & { returnDialogInstance: true }
  ): { message$: Observable<string>; dialog: PromptComponent };
  prompt(
    message: string,
    initialValue: string,
    options?: Partial<DialogOptions<PromptConfiguration>> & { returnDialogInstance?: false }
  ): Observable<string>;
  prompt(
    message: string,
    initialValue: string,
    options?: Partial<DialogOptions<PromptConfiguration>>
  ): Observable<string> | { message$: Observable<string>; dialog: PromptComponent };
  public prompt(
    message: string,
    initialValue: string,
    options: Partial<DialogOptions<PromptConfiguration>> = {}
  ): Observable<string> | { message$: Observable<string>; dialog: PromptComponent } {
    const configuration = Object.assign(
      { cancelLabel: 'Cancel', okLabel: 'Ok', password: false },
      options.configuration,
      { message, initialValue }
    );
    const mergedOptions = Object.assign({ cancellable: true }, options, {
      configuration,
    });

    return this.custom<PromptComponent, string>(PromptComponent, mergedOptions);
  }

  phraseConfirm(
    header: string,
    message: string,
    phrase: string,
    options?: Partial<DialogOptions<PhraseConfirmConfiguration>> & { returnDialogInstance: true }
  ): { message$: Observable<void>; dialog: PhraseConfirmComponent };
  phraseConfirm(
    header: string,
    message: string,
    phrase: string,
    options?: Partial<DialogOptions<PhraseConfirmConfiguration>> & { returnDialogInstance?: false }
  ): Observable<void>;
  phraseConfirm(
    header: string,
    message: string,
    phrase: string,
    options?: Partial<DialogOptions<PhraseConfirmConfiguration>>
  ): Observable<void> | { message$: Observable<void>; dialog: PhraseConfirmComponent };
  public phraseConfirm(
    header: string,
    message: string,
    phrase: string,
    options: Partial<DialogOptions<PhraseConfirmConfiguration>> = {}
  ): Observable<void> | { message$: Observable<void>; dialog: PhraseConfirmComponent } {
    const configuration = Object.assign(
      { cancelLabel: 'Cancel', okLabel: 'Ok', password: false },
      options.configuration,
      { header, message, phrase }
    );
    const mergedOptions = Object.assign({ cancellable: true }, options, {
      configuration,
    });

    return this.custom<PhraseConfirmComponent, void>(PhraseConfirmComponent, mergedOptions);
  }

  custom<T, U = any>(
    dialogComponent: Type<T>,
    options?: Partial<DialogOptions> & { returnDialogInstance?: false }
  ): Observable<U>;
  custom<T, U = any>(
    dialogComponent: Type<T>,
    options?: Partial<DialogOptions> & { returnDialogInstance: true }
  ): { message$: Observable<U>; dialog: T };
  custom<T, U = any>(
    dialogComponent: Type<T>,
    options?: Partial<DialogOptions>
  ): Observable<U> | { message$: Observable<U>; dialog: T };
  public custom<T, U = any>(
    dialogComponent: Type<T>,
    options?: Partial<DialogOptions>
  ): Observable<U> | { message$: Observable<U>; dialog: T } {
    return this.dialogService.custom(dialogComponent, options);
  }
}

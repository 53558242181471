import { Directive, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl } from '@angular/forms';
import { delay, distinctUntilChanged, merge, Observable, ReplaySubject, Subscription } from 'rxjs';

@Directive({
  selector: '[appRelatedControls]',
})
export class RelatedControlsDirective implements OnInit, OnDestroy {
  public readonly controls$: Observable<AbstractControl[]>;

  @Input()
  public formControl: FormControl;
  @Input()
  public formControlName: string;

  private readonly controlsSubject = new ReplaySubject<AbstractControl[]>(1);

  private relatedControlsSubscription = new Subscription();
  private relatedControls: AbstractControl[] = [];
  private control: AbstractControl;

  constructor(
    @Optional()
    private controlContainer: ControlContainer
  ) {
    this.controls$ = this.controlsSubject.asObservable();
  }

  ngOnInit(): void {
    this.control =
      this.formControl ||
      this.controlContainer?.control.get(this.formControlName) ||
      null
    ;
  }

  ngOnDestroy(): void {
    this.relatedControlsSubscription?.unsubscribe();
  }

  @Input()
  public set appRelatedControls(controls: AbstractControl | AbstractControl[]) {
    this.relatedControls = Array.isArray(controls) ? controls : [controls];
    this.controlsSubject.next(this.relatedControls);

    this.relatedControlsSubscription.unsubscribe();
    this.relatedControlsSubscription = merge(...this.relatedControls.map((control) => control.valueChanges.pipe(
      // prevents "Maximum call stack size exceeded" when two fields are connected each other
      distinctUntilChanged(),
      // waits for related fields values and validity to be updated
      delay(0)
    )
    ))
      .subscribe({
        next: () => {
          this.control?.updateValueAndValidity({ onlySelf: true });
        },
      });
  }

  public get appRelatedControls(): AbstractControl[] {
    return this.relatedControls;
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TemplateDirective } from '@proget-shared/_common';
import { ObjectHelper } from '@proget-shared/helper';
import { distinctUntilChanged, EMPTY, merge, noop, Subscription } from 'rxjs';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true,
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  public readonly nativeControl = new FormControl(false);

  @Input()
  public falseValue: any = false;
  @Input()
  public binary = false;
  @Input()
  public label = '';
  @Input()
  public tabindex = '0';
  public customLabelTemplate: TemplateRef<any> | null = null;

  private readonly subscription = new Subscription();

  private modelChanged: (value: any) => void = noop;
  private modelTouched: () => void = noop;
  private modelValue: any;
  private userInteraction = false;
  private _animated = false;
  @HostBinding('class.checkbox-disabled')
  private _disabled = false;
  @HostBinding('class.checkbox-readonly')
  private _readonly = false;
  private _indeterminate = false;
  private _value: any = true;
  private _inputId: string | undefined;
  @ContentChildren(TemplateDirective)
  private templates: QueryList<TemplateDirective>;

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    const emptyNgControl = { control: { valueChanges: EMPTY } };

    this.subscription.add(
      merge(
        // this updates value after checkbox from group was changed
        // why? for ReactiveForms, writeValue is not called when group value was changed
        this.injector.get<any>(NgControl, emptyNgControl).control.valueChanges,
        this.nativeControl.valueChanges
      )
        .pipe(distinctUntilChanged())
        .subscribe({
          next: (value) => {
            this.writeValue(value);
          },
        })
    );

    this.customLabelTemplate = this.templates.find((template) => template.appTemplate === 'label')?.templateRef ?? null;
    this.cdr.markForCheck();
  }

  writeValue(value: any): void {
    if (this.modelValue === value) {
      return;
    }

    this.modelValue = value;
    this.updateCheckedStatus();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.updateNativeInputDisabled();
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set disabled(disabled: any) {
    this.setDisabledState(disabled !== false && disabled !== undefined);
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  public set value(value: any) {
    this._value = value;
    this.updateCheckedStatus();
  }

  public get value(): any {
    return this._value;
  }

  @Input()
  public set readonly(value: any) {
    this._readonly = value !== false && value !== void 0;
    this.updateNativeInputDisabled();
    this.cdr.markForCheck();
  }

  public get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  public set inputId(value: any) {
    this._inputId = typeof value === 'string' && value.length > 0 ? value : void 0;
  }

  public get inputId(): any {
    return this._inputId;
  }

  @Input()
  public set indeterminate(value: boolean) {
    this._indeterminate = value;
  }

  public get animated(): boolean {
    return this._animated;
  }

  @HostBinding('class.checkbox-checked')
  public get checked(): boolean {
    return this.nativeControl.value;
  }

  public get editable(): boolean {
    return !this.readonly && !this.disabled;
  }

  @HostBinding('tabindex')
  public get tabindexAttr(): string {
    return this.editable ? this.tabindex : '-1';
  }

  @HostBinding('class.checkbox-partially-checked')
  public get partiallyChecked(): boolean {
    return this.binary && this._indeterminate && !this.checked;
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.space', ['$event'])
  public toggle(event?: Event): void {
    if (!this.editable) {
      return;
    }

    if (event) {
      event.preventDefault();
      this.userInteraction = true;
    }

    this.checked ? this.uncheck() : this.check();
  }

  private check(): void {
    const newValue = this.binary
      ? this.value
      : Array.isArray(this.modelValue)
        ? this.modelValue.concat(this.value)
        : [this.value];

    this.emitValue(newValue);
  }

  private uncheck(): void {
    const newValue = this.binary
      ? this.falseValue
      : Array.isArray(this.modelValue)
        ? this.modelValue.filter((item) => !ObjectHelper.equals(item, this.value))
        : [];

    this.emitValue(newValue);
  }

  private emitValue(value: any): void {
    this.modelTouched();
    this.modelChanged(value);
  }

  private updateCheckedStatus(): void {
    const testModel = this.binary ? [this.modelValue] : this.modelValue;
    const checked = Array.isArray(testModel) &&
      testModel.some((item) => ObjectHelper.equals(item, this.value));
    const changed = this.nativeControl.value !== checked;

    this.nativeControl.setValue(checked, { emitEvent: false });
    this._animated = this.userInteraction;
    this.userInteraction = false;

    if (changed) {
      this.cdr.markForCheck();
    }
  }

  private updateNativeInputDisabled(): void {
    this.editable
      ? this.nativeControl.enable({ emitEvent: false })
      : this.nativeControl.disable({ emitEvent: false });
  }
}

import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class UserStorageService extends LocalStorageService {
  private userId = '';

  public set(key: string, value: any): void {
    super.set(this.getUserKey(key), value);
  }

  public get(key: string): any {
    return super.get(this.getUserKey(key));
  }

  public remove(key: string): void {
    return super.remove(this.getUserKey(key));
  }

  public clear(): void {
    this.checkIfUserSet();

    const storageKeysCount = this.storage.length;
    const storageKeys = [];

    for (let i = 0; i < storageKeysCount; i++) {
      storageKeys.push(this.storage.key(i));
    }

    const userKeyTest = new RegExp(`^_user\..+\.${this.userId}$`);
    const userStorageKeys = storageKeys.filter((key) => userKeyTest.test(key));

    for (const key of userStorageKeys) {
      this.storage.removeItem(key);
    }
  }

  public setUserId(userId: string): void {
    this.userId = userId;
  }

  public clearUserId(): void {
    this.userId = '';
  }

  public isUserSet(): boolean {
    return typeof this.userId === 'string' && this.userId.trim() !== '';
  }

  private getUserKey(key: string): string {
    this.checkIfUserSet();

    return `_user.${key}.${this.userId}`;
  }

  private checkIfUserSet(): void {
    if (!this.isUserSet()) {
      throw new Error('User id for UserStorageService is not defined');
    }
  }
}

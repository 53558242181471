import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

@NgModule({ imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AuthHttpClientModule {
  public static forRoot(providers: {
    authToken: Provider;
  }): ModuleWithProviders<AuthHttpClientModule> {
    return {
      ngModule: AuthHttpClientModule,
      providers: [providers.authToken],
    };
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SELECTION_MODE } from '../../enum/selection-mode.enum';
import { SelectableItem } from '../../interface/selectable-item';
import { SelectionService } from '../../service/selection.service';

@Component({
  selector: 'app-selection-item',
  templateUrl: './selection-item.component.html',
  styleUrls: ['./selection-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionItemComponent implements OnInit, OnDestroy {
  protected readonly formControl = new FormControl(false);

  private readonly componentSubscription = new Subscription();

  @Input()
  private customSelection: SelectionService;
  private _item: SelectableItem;
  private selection: SelectionService | null = null;

  constructor(@Optional() private inheritedSelection: SelectionService) {}

  ngOnInit(): void {
    this.selection = this.resolveSelection();

    if (null === this.selection) {
      return;
    }

    this.componentSubscription.add(
      this.selection.visibleItemsSelectionChange$.subscribe({
        next: () => {
          this.formControl.setValue(this.isItemSelected(), { emitEvent: false });
        },
      })
    );

    this.componentSubscription.add(
      this.selection.visibleItemsChange$.subscribe({
        next: () => {
          this.updateItemEnabled();
        },
      })
    );

    this.componentSubscription.add(
      this.selection.allSelectedItemsSelectionChange$.subscribe({
        next: () => {
          this.formControl.setValue(this.isItemSelected(), { emitEvent: false });
        },
      })
    );

    this.componentSubscription.add(
      this.formControl.valueChanges.subscribe({
        next: (checked) => {
          checked
            ? this.selection.selectItem(this.getItem())
            : this.selection.deselectItem(this.getItem());
        },
      })
    );

    this.componentSubscription.add(
      this.selection.pending$.subscribe({
        next: (pending) => {
          pending
            ? this.formControl.disable({ emitEvent: false })
            : this.formControl.enable({ emitEvent: false });
        },
      })
    );

    this.updateFormValue();
    this.updateItemEnabled();
  }

  ngOnDestroy(): void {
    this.componentSubscription.unsubscribe();
  }

  @Input()
  public set item(item: SelectableItem) {
    this._item = item;
    this.updateFormValue();
  }

  public isSingleSelectionMode(): boolean {
    if (null === this.selection) {
      return false;
    }

    return SELECTION_MODE.SINGLE === this.selection.getMode();
  }

  public isMultiSelectionMode(): boolean {
    if (null === this.selection) {
      return false;
    }

    return SELECTION_MODE.MULTI === this.selection.getMode();
  }

  public getItem(): SelectableItem {
    return this._item;
  }

  public isItemSelected(): boolean {
    return null === this.selection ? false : this.selection.isItemSelected(this.getItem());
  }

  private isItemVisible(): boolean {
    return null === this.selection ? false : this.selection.isItemVisible(this.getItem());
  }

  private updateItemEnabled(): void {
    this.isItemVisible()
      ? this.formControl.enable({ emitEvent: false })
      : this.formControl.disable({ emitEvent: false });
  }

  private updateFormValue(): void {
    this.formControl.setValue(this.isItemSelected(), { emitEvent: false });
  }

  private resolveSelection(): SelectionService | null {
    if (this.customSelection instanceof SelectionService) {
      return this.customSelection;
    }

    if (this.inheritedSelection instanceof SelectionService) {
      return this.inheritedSelection;
    }

    return null;
  }
}

import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@proget-shared/storage';
import { distinctUntilChanged, map, noop, Observable, of, ReplaySubject, Subject, skipWhile, switchMap, tap } from 'rxjs';

import { TRANSLATE_OPTIONS } from '../translate-options.token';
import { TranslateOptions } from '../type/translate-options.type';
import { Translation } from '../type/translation.type';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  public lang$: Observable<string>;

  private readonly langSubject = new ReplaySubject<string>(1);
  private readonly loadLangSubject = new Subject<string>();

  private loading = false;

  constructor(
    @Inject(DOCUMENT)
    private _document: Document,
    @Inject(TRANSLATE_OPTIONS)
    private translateOptions: TranslateOptions,
    private localStorage: LocalStorageService,
    private translate: NgxTranslateService
  ) {
    this.lang$ = this.langSubject.pipe(
      skipWhile(() => this.loading),
      distinctUntilChanged()
    );

    this.loadLangSubject.pipe(
      map((lang) => this.findLang(lang)),
      distinctUntilChanged(),
      tap({
        next: () => {
          this.loading = true;
        },
      }),
      switchMap((lang) => this.translate.use(lang).pipe(map(() => lang)))
    )
      .subscribe({
        next: (lang) => {
          this.localStorage.set(this.translateOptions.storageKey, lang);

          if (this._document.documentElement instanceof Object) {
            this._document.documentElement.lang = lang;
          }

          this.loading = false;
          this.langSubject.next(lang);
        },
        error: noop,
      });

    this.selectLang(this.getStoredLang());
  }

  public get currentLang(): string {
    return this.translate.currentLang;
  }

  public get(key: string | string[], interpolateParams?: object): Observable<string | any> {
    return this.translate.get(key, interpolateParams);
  }

  public instant(key: string | string[], interpolateParams?: object): string | any {
    return this.translate.instant(key, interpolateParams);
  }

  public selectLang(lang: string): void {
    this.loadLangSubject.next(lang);
  }

  public getTranslatedText(translation: string | Translation = ''): Observable<string | any> {
    return 'string' === typeof translation
      ? this.translateString(translation)
      : this.translateObject(translation);
  }

  private findLang(lang: string) {
    return this.translateOptions.langs.find((LANG) => LANG === lang) || 'en';
  }

  private getStoredLang(): string {
    return this.findLang(this.localStorage.get(this.translateOptions.storageKey) || this.findLang(navigator.language));
  }

  private translateString(translation: string): Observable<string> {
    return '' === translation ? of('') : this.get(translation);
  }

  private translateObject(translation: Translation): Observable<string | any> {
    return this.get(translation.key, translation.params);
  }
}

import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { TooltipPosition } from '../../const/tooltip-position.enum';
import { Rect } from '../../type/rect.type';

@Component({
  selector: 'app-tooltip-cloud',
  templateUrl: './tooltip-cloud.component.html',
  styleUrls: ['./tooltip-cloud.component.scss'],
})
export class TooltipCloudComponent implements AfterViewInit {
  @Input()
  public width: number;
  public triangleTransform = '';

  @ViewChild('tooltipContent', { read: ElementRef })
  private tooltipContentRef: ElementRef;
  private oversize = 0;
  private position = TooltipPosition.RIGHT;

  constructor(private elementRef: ElementRef) {}

  public setPosition(
    position: TooltipPosition,
    horizontal: number,
    vertical: number
  ): void {
    this.elementRef.nativeElement.classList.remove('position-left', 'position-right', 'position-bottom', 'position-top');

    this.elementRef.nativeElement.style.top = 'auto';
    this.elementRef.nativeElement.style.right = 'auto';
    this.elementRef.nativeElement.style.bottom = 'auto';
    this.elementRef.nativeElement.style.left = 'auto';

    this.position = position;

    switch (position) {
      case TooltipPosition.TOP:
        this.elementRef.nativeElement.style.left = `${horizontal}px`;
        this.elementRef.nativeElement.style.bottom = `${vertical}px`;
        break;
      case TooltipPosition.BOTTOM:
        this.elementRef.nativeElement.style.left = `${horizontal}px`;
        this.elementRef.nativeElement.style.top = `${vertical}px`;
        break;
      case TooltipPosition.LEFT:
        this.elementRef.nativeElement.style.right = `${horizontal}px`;
        this.elementRef.nativeElement.style.top = `${vertical}px`;
        break;
      default:
        this.elementRef.nativeElement.style.left = `${horizontal}px`;
        this.elementRef.nativeElement.style.top = `${vertical}px`;
        this.position = TooltipPosition.RIGHT;
        break;
    }

    this.elementRef.nativeElement.classList.add(`position-${this.position}`);
  }

  ngAfterViewInit(): void {
    window.requestAnimationFrame(() => {
      this.calculateOversize();
    });
  }

  public calculateOversize(): void {
    this.resetOversize();

    const { bottom, top, left, right }: Rect = this.tooltipContentRef.nativeElement.getBoundingClientRect();
    const MARGIN = 5;

    switch (this.position) {
      case TooltipPosition.RIGHT:
      case TooltipPosition.LEFT:
        this.oversize = Math.max(0, bottom - window.innerHeight + MARGIN) || // check bottom
          Math.min(0, top - MARGIN); // check top

        if (this.oversize) {
          const fixedTop = parseFloat(this.elementRef.nativeElement.style.top) - this.oversize;

          this.elementRef.nativeElement.style.top = `${fixedTop}px`;
          this.triangleTransform = `translateY(${this.oversize}px)`;
        }
        break;
      case TooltipPosition.TOP:
      case TooltipPosition.BOTTOM:
        this.oversize = Math.max(0, right - document.body.clientWidth + MARGIN) || // check right
          Math.min(0, left - MARGIN); // check top

        if (this.oversize) {
          const fixedLeft = parseFloat(this.elementRef.nativeElement.style.left) - this.oversize;

          this.elementRef.nativeElement.style.left = `${fixedLeft}px`;
          this.triangleTransform = `translateX(${this.oversize}px)`;
        }
        break;
    }
  }

  public resetOversize(): void {
    this.triangleTransform = '';
    this.oversize = 0;
  }
}

import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

import { MenuItem } from '../../model';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input()
  public menuItem: MenuItem;

  protected submenuPositionCorrection: number;
  protected displayTop = false;

  @ViewChild('menuItemElement', { read: ElementRef })
  private menuItemElement: ElementRef<HTMLElement>;
  @ViewChild('submenuElement', { read: ElementRef })
  private submenuElement: ElementRef<HTMLElement>;
  private _scrollOffset = 0;

  @Input()
  public set scrollOffset(offset: number) {
    this._scrollOffset = offset;
    this.updateSubmenuPosition();
  }

  public get submenuItems(): MenuItem[] {
    return this.menuItem ? this.menuItem.submenu || [] : [];
  }

  @HostListener('window:resize')
  protected updateSubmenuPosition(): void {
    if (!this.menuItemElement) {
      return;
    }

    const element: HTMLElement = this.menuItemElement.nativeElement;

    // check if there is enough space below
    const availableSpaceBelow: number = window.innerHeight - element.getBoundingClientRect().top;
    const submenuHeight: number = this.submenuElement.nativeElement.offsetHeight;

    this.displayTop = availableSpaceBelow < submenuHeight;

    // update offset
    const topPositionOffset: number = this.displayTop ? submenuHeight : element.offsetHeight;

    this.submenuPositionCorrection = -this._scrollOffset - topPositionOffset;
  }
}

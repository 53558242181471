<input
  [attr.id]="inputId"
  [formControl]="nativeControl"
  type="checkbox"
>
<div class="square">
  <div
    [class.animated]="animated"
    class="tick"
  ></div>
  <div class="half-tick"></div>
</div>
<ng-container [ngTemplateOutlet]="customLabelTemplate ? defaultLabelTemplateContainer : defaultLabelTemplate"></ng-container>

<ng-template #defaultLabelTemplateContainer>
  <label class="checkbox-label">
    <ng-container [ngTemplateOutlet]="customLabelTemplate"></ng-container>
  </label>
</ng-template>
<ng-template #defaultLabelTemplate>
  <label class="checkbox-label">{{ label }}</label>
</ng-template>

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class PermissionService {
  public permissions$: Observable<any[]> = of([]);

  constructor() {
    throw new Error('Permission service not implemented');
  }

  public hasPermission(_permission: any): Observable<boolean> {
    throw new Error('Permission service not implemented');
  }

  public hasPermissionInstant(_permission: any): boolean {
    throw new Error('Permission service not implemented');
  }
}

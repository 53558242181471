<ng-container #listContainer></ng-container>

<ng-template
  #listItemTemplate
  let-index="index"
  let-item
>
  <li
    [appOptionItem]="item"
    [class.hover]="isHovered(item)"
    [hidden]="!item.visible"
    (mousemove)="hoverItem(item)"
    class="list-item"
  >
    <ng-container
      [ngTemplateOutlet]="item.isGroup ? groupTemplate : multiple ? multiItemTemplate : singleItemTemplate"
      [ngTemplateOutletContext]="{ $implicit: item, hover: isHovered(item) }"
    ></ng-container>
  </li>
</ng-template>

<ng-template
  #groupTemplate
  let-item
>
  <div
    (mousemove)="hoverGroup(item)"
    class="group-header"
  >
    <div
      class="indent"
      [style.width.em]="item.level - 1"
    ></div>
    <div
      *ngIf="multiple && showToggleGroup"
      class="multiple-checkbox"
    >
      <app-checkbox
        [binary]="true"
        [formControl]="group.groupSelection"
        [indeterminate]="group.anyChildSelected"
      ></app-checkbox>
    </div>
    <ng-container
      [ngTemplateOutlet]="groupLabelEllipsis ? ellipsisOptionTemplate : simpleOptionTemplate"
      [ngTemplateOutletContext]="{ template: groupLabelTemplate, hover: false, context: { $implicit: item.groupLabel, index: item.index, counter: getCounter(item) } }"
    ></ng-container>
  </div>
  <ul
    #group
    [appOptionsGroup]="item"
    class="group-container"
  ></ul>
</ng-template>

<ng-template
  #singleItemTemplate
  let-hover="hover"
  let-item
>
  <div
    class="indent"
    [style.width.em]="item.level - 1"
  ></div>
  <ng-container
    [ngTemplateOutlet]="optionLabelEllipsis ? ellipsisOptionTemplate : simpleOptionTemplate"
    [ngTemplateOutletContext]="{ template: optionTemplate, hover: hover, context: { $implicit: item.source, index: item.index } }"
  ></ng-container>
</ng-template>

<ng-template
  #multiItemTemplate
  let-index="index"
  let-hover="hover"
  let-item
>
  <div
    class="indent"
    [style.width.em]="item.level - 1"
  ></div>
  <div class="multiple-checkbox">
    <app-checkbox
      [formControl]="selectionForm"
      [readonly]="true"
      [value]="item.source"
    ></app-checkbox>
  </div>
  <ng-container
    [ngTemplateOutlet]="optionLabelEllipsis ? ellipsisOptionTemplate : simpleOptionTemplate"
    [ngTemplateOutletContext]="{ template: optionTemplate, hover: hover, context: { $implicit: item.source, index: item.index } }"
  ></ng-container>
</ng-template>

<ng-template
  #ellipsisOptionTemplate
  let-context="context"
  let-hover="hover"
  let-template="template"
>
  <app-ellipsis
    [hover]="hover"
    class="dropdown-item-label"
  >
    <ng-container
      *ngTemplateOutlet="template; context: mergeObjects({ hover: hover }, context)"
    ></ng-container>
  </app-ellipsis>
</ng-template>

<ng-template
  #simpleOptionTemplate
  let-context="context"
  let-hover="hover"
  let-template="template"
>
  <div class="dropdown-item-label">
    <ng-container
      *ngTemplateOutlet="template; context: mergeObjects({ hover: hover }, context)"
    ></ng-container>
  </div>
</ng-template>

<app-calendar
  #calendar
  [clearEnabled]="showClear"
  [formControl]="formControl"
  [placeholder]="config?.placeholder | translate"
  [dateFormat]="config?.dateFormat || 'yyyy-MM-dd'"
  [panelReferer]="panelReferer"
  data-qa="filter-calendar"
  outputType="string"
  type="date-only"
>
</app-calendar>

import { Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ObjectHelper } from '@proget-shared/helper';
import { TranslateService } from '@proget-shared/translate';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-control-error',
  templateUrl: './form-control-error.component.html',
})
export class FormControlErrorComponent implements OnDestroy {
  @Input()
  public display: 'tooltip' | 'inline' = 'inline';

  protected allErrors: string[] = [];

  private readonly langSubscription: Subscription;

  private apiErrors: string[] = [];
  private apiErrorsValueSnapshot: any;
  private _control: AbstractControl;
  private controlSubscription = new Subscription();

  constructor(private translate: TranslateService) {
    this.langSubscription = translate.lang$.subscribe({
      next: () => {
        this.updateErrors();
      },
    });
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
    this.controlSubscription.unsubscribe();
  }

  /*
   * @deprecated Use [appApiErrors] directive on form control
   */
  @Input()
  public set errors(errors: any) {
    if (this.control) {
      this.apiErrorsValueSnapshot = this.control.value;
    }

    this.apiErrors = ObjectHelper.findStrings(errors);
    this.updateErrors();
  }

  @Input()
  public set control(control: AbstractControl) {
    this._control = control;

    this.controlSubscription.unsubscribe();
    this.updateErrors();

    if (this.control instanceof AbstractControl) {
      this.controlSubscription = this.control.valueChanges.subscribe({
        next: () => {
          this.updateErrors();
        },
      });
    }
  }

  public get control(): AbstractControl {
    return this._control;
  }

  private clearErrors(): void {
    this.allErrors = [];
  }

  private updateErrors(): void {
    this.clearErrors();

    if (!(this.control instanceof AbstractControl) || this.control.disabled) {
      return;
    }

    const apiErrors: string[] = this.areApiErrorsUpToDate() ? this.apiErrors : [];

    this.allErrors = [...this.getValidationErrors(), ...apiErrors].filter((value) => !!value.trim());
  }

  private getValidationErrors(): string[] {
    if (
      !(this.control instanceof AbstractControl) ||
      !this.control.errors
    ) {
      return [];
    }

    const errors = this.control.errors;

    return Object.keys(errors)
      .map((key: string) => (key === 'apiErrors'
        ? errors[key]
        : this.translate.instant(
          `form_error.${key}`,
          errors[key]
        ))
      )
      .reduce(
        (allErrors, current) => (current instanceof Array
          ? [...allErrors, ...current]
          : [...allErrors, current]),
        []
      );
  }

  private areApiErrorsUpToDate(): boolean {
    if (!this.control) {
      return true;
    }

    return this.control.value === this.apiErrorsValueSnapshot;
  }
}

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { OverlayPanelDirective } from '@proget-shared/ui/overlay-panel';
import { Subscription } from 'rxjs';

import { SelectionService } from '../../service/selection.service';

@Component({
  selector: 'app-selection-info',
  templateUrl: './selection-info.component.html',
  styleUrls: ['./selection-info.component.scss'],
})
export class SelectionInfoComponent implements OnInit, OnDestroy {
  @Input()
  public forceMenu = false;

  protected selectionPending = false;

  private readonly subscription = new Subscription();

  @Input()
  private customSelection: SelectionService;
  @ViewChild('selectionOptionsToggle', { read: OverlayPanelDirective })
  private selectionOptionsToggle: OverlayPanelDirective;
  private selection: SelectionService | null = null;

  constructor(@Optional() private inheritedSelection: SelectionService) {}

  ngOnInit(): void {
    this.selection = this.resolveSelection();

    if (null === this.selection) {
      return;
    }

    this.subscription.add(
      this.selection.pending$.subscribe({
        next: (pending) => {
          this.selectionPending = pending;
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get itemsCount(): number {
    return this.selection ? this.selection.itemsCount : 0;
  }

  public get selectionOptionsData(): { deselectEnabled: boolean; selectAllEnabled: boolean } {
    return this.selection
      ? {
          deselectEnabled: this.itemsCount > 0 && !this.selectionPending,
          selectAllEnabled: this.selection.selectAllPagesEnabled,
        }
      : {
          deselectEnabled: false,
          selectAllEnabled: false,
        };
  }

  public clearItems(): void {
    if (this.selection) {
      this.selection.deselectAllItems();
    }

    this.selectionOptionsToggle?.close();
  }

  public selectAllItems(): void {
    if (this.selection) {
      this.selection.selectAllPages();
    }

    this.selectionOptionsToggle?.close();
  }

  private resolveSelection(): SelectionService | null {
    if (this.customSelection instanceof SelectionService) {
      return this.customSelection;
    }

    if (this.inheritedSelection instanceof SelectionService) {
      return this.inheritedSelection;
    }

    return null;
  }
}

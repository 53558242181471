import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTE } from '@core/const';

import { PageNotFoundComponent } from './ui';

const routes: Routes = [
  {
    path: ROUTE.BASE,
    pathMatch: 'full',
    redirectTo: ROUTE.TENANT.BASE,
  },
  {
    path: ROUTE.AUDIT_LOG.BASE,
    loadChildren: () => import('./audit-log/audit-log.module').then((m) => m.AuditLogModule),
  },
  {
    path: ROUTE.AUTH.BASE,
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: ROUTE.KNOX.BASE,
    loadChildren: () => import('./knox/knox.module').then((m) => m.KnoxModule),
  },
  {
    path: ROUTE.LABEL.BASE,
    loadChildren: () => import('./label/label.module').then((m) => m.LabelModule),
  },
  {
    path: ROUTE.RELEASE.BASE,
    loadChildren: () => import('./release/release.module').then((m) => m.ReleaseModule),
  },
  {
    path: ROUTE.STATISTICS.BASE,
    loadChildren: () => import('./statistics/statistics.module').then((m) => m.StatisticsModule),
  },
  {
    path: ROUTE.TENANT.BASE,
    loadChildren: () => import('./tenant/tenant.module').then((m) => m.TenantModule),
  },
  {
    path: ROUTE.TERMS_OF_USE.BASE,
    loadChildren: () => import('./terms-of-use/terms-of-use.module').then((m) => m.TermsOfUseModule),
  },
  {
    path: ROUTE.USER.BASE,
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: ROUTE.ZERO_TOUCH.BASE,
    loadChildren: () => import('./zero-touch/zero-touch.module').then((m) => m.ZeroTouchModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

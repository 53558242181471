export class MenuItem {
  constructor(
    public readonly name: string,
    public readonly urlTree: string[],
    public readonly icon?: string,
    public readonly submenu?: MenuItem[]
  ) {}

  public get url(): string {
    return this.urlTree.join('/');
  }
}

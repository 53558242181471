import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-menu-template',
  templateUrl: './menu-template.component.html',
  styleUrls: ['./menu-template.component.scss'],
})
export class MenuTemplateComponent {
  @ViewChild('template')
  public template: TemplateRef<any>;
}

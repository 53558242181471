import { Component, Input } from '@angular/core';

import { GridControl } from '../../class/grid-control.class';
import { ORDER } from '../../enum/order.enum';

@Component({
  selector: 'app-grid-order',
  templateUrl: './grid-order.component.html',
  styleUrls: ['./grid-order.component.scss'],
})
export class GridOrderComponent {
  @Input()
  private gridControl: GridControl;
  @Input()
  private field: string;

  protected isAscOrder(): boolean {
    return ORDER.ASC === this.getOrder();
  }

  protected isDescOrder(): boolean {
    return ORDER.DESC === this.getOrder();
  }

  protected changeOrder(): void {
    if (this.isAscOrder()) {
      this.gridControl.setOrder(this.field, ORDER.DESC);

      return;
    }

    if (this.isDescOrder()) {
      this.gridControl.setOrder(this.field, ORDER.NONE);

      return;
    }

    this.gridControl.setOrder(this.field, ORDER.ASC);
  }

  private getOrder(): ORDER {
    if (!this.gridControl || !this.field || this.field !== this.gridControl.getOrderField()) {
      return ORDER.NONE;
    }

    return this.gridControl.getOrderDirection();
  }
}

import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Subscription } from 'rxjs';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input()
  public value: any;
  @Input()
  public label: string;
  @Input()
  public name: string;

  protected readonly form = new FormGroup({
    radio: new FormControl(),
  });

  private readonly subscription = new Subscription();

  private _inactive = false;
  private modelChanged: (value: string) => void = noop;
  private modelTouched: () => void = noop;

  ngOnInit(): void {
    this.subscription.add(
      this.form.get('radio').valueChanges.subscribe({
        next: (value) => {
          this.modelTouched();
          this.modelChanged(value);
        },
      })
    );
  }

  writeValue(value: string): void {
    this.form.get('radio').setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: string) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (this._inactive || disabled) {
      this.form.disable({ emitEvent: false });

      return;
    }

    this.form.enable({ emitEvent: false });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * @deprecated Use 'inactive' instead, this causes warnings when used with reactive forms
   */
  @Input()
  public set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Input()
  public set inactive(inactive: boolean) {
    this._inactive = inactive;
    this.setDisabledState(inactive);
  }
}

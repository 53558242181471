import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';

import { JwtDecoder } from './jwt-decoder.service';

@NgModule({
  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter: () => void 0,
      },
    }),
  ],
  providers: [JwtDecoder],
})
export class JwtDecoderModule {}

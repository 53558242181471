<app-dropdown
  #dropdown
  [autoSort]="config?.autoSort"
  [class.invalid-value]="!isValid"
  [filter]="config?.options.length > 10"
  [formControl]="formControl"
  [maxVisibleItems]="config?.maxVisibleItems"
  [options]="dropdownOptions$ | async"
  [panelReferer]="panelReferer"
  [placeholder]="config?.placeholder | translate"
  [translate]="!config?.preventTranslatingOptions"
  [showClear]="showClear"
  data-qa="filter-dropdown"
>
  <ng-template
    *ngIf="itemTemplate"
    [appEllipsisEnabled]="config?.optionLabelEllipsis"
    appTemplate="option"
    let-hover="hover"
    let-item
    let-selected="selected"
  >
    <ng-container
      *ngTemplateOutlet="itemTemplate; context: { $implicit: item, hover: hover, selected: selected }"
    ></ng-container>
  </ng-template>
</app-dropdown>

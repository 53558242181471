import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ElementSpinnerComponent } from './element-spinner/element-spinner.component';
import { SpinnerDirective } from './spinner.directive';
import { SpinnerHttpInterceptor } from './spinner.http.interceptor';

@NgModule({
  declarations: [SpinnerDirective, ElementSpinnerComponent],
  exports: [SpinnerDirective],
})
export class SpinnerModule {
  public static withInterceptor(): ModuleWithProviders<SpinnerModule> {
    return {
      ngModule: SpinnerModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SpinnerHttpInterceptor,
          multi: true,
        },
      ],
    };
  }
}

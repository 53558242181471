import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { InputComponent } from './component/input.component';
import { InputDirective } from './directive/input.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: [InputComponent, InputDirective],
  exports: [InputComponent, InputDirective],
})
export class InputModule {}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { ScrollbarComponent } from '@proget-shared/ui/scrollbar';
import {
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  fromEvent,
  map,
  merge,
  of,
  startWith,
  Subject,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

import { EllipsisService } from './ellipsis.service';

@Component({
  selector: 'app-ellipsis',
  templateUrl: './ellipsis.component.html',
  styleUrls: ['./ellipsis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EllipsisComponent implements OnInit, OnDestroy {
  @Input()
  public matchColor = false;

  protected expanded = false;

  private readonly hoverSubject = new Subject<{ status: boolean; delay: number }>();
  private readonly subscription = new Subscription();

  @ViewChild('shortenText', { static: true, read: ElementRef })
  private shortenTextElement: ElementRef;
  @ViewChild('fullTextContainer', { static: true, read: ElementRef })
  private fullTextContainer: ElementRef;
  @ViewChild('fullText', { static: true, read: ElementRef })
  private fullTextElement: ElementRef;
  private scrollSubscription: Subscription;
  private activeEllipsisSubscription: Subscription;
  private contentObserver: MutationObserver;
  private ellipsisDisabled = false;

  constructor(
    private elementRef: ElementRef,
    private ellipsisService: EllipsisService,
    @Optional()
    private scrollbarComponent: ScrollbarComponent
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      fromEvent(this.shortenTextElement.nativeElement, 'highlight')
        .subscribe(() => {
          this.hover = { status: true, delay: 0 };
        })
    );

    this.subscription.add(
      this.hoverSubject.pipe(
        distinctUntilKeyChanged('status'),
        switchMap((value) => of(value.status).pipe(delay(value.delay))),
        switchMap((hover) => (this.scrollbarComponent && hover
          ? this.scrollbarComponent.scrollbar.scrolled.pipe(
            startWith(null),
            map(() => this.isVisibleInScroll() && this.isVisibleInTable())
          )
          : of(hover))
        ),
        distinctUntilChanged()
      )
        .subscribe({
          next: (hover) => {
            hover ? this.expand() : this.collapse();
          },
        })
    );

    this.subscription.add(
      this.ellipsisService.mouseenter$
        .pipe(
          map(({ target }) => this.elementRef.nativeElement === target ||
          this.elementRef.nativeElement.contains(target)
          ),
          distinctUntilChanged(),
          filter((hover) => hover),
          tap(() => {
            this.hover = { status: true, delay: 700 };
          }),
          switchMap(() => merge(
            fromEvent(this.elementRef.nativeElement, 'mousedown'),
            fromEvent(this.elementRef.nativeElement, 'mouseleave')
          ))
        )
        .subscribe(() => {
          this.hover = { status: false, delay: 100 };
        })
    );

    this.contentObserver = new MutationObserver(() => {
      this.updateFullElementContent();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.scrollSubscription?.unsubscribe();
    this.activeEllipsisSubscription?.unsubscribe();
    this.collapse();
    this.hoverSubject.complete();
    this.contentObserver?.disconnect();
  }

  @Input()
  public set disabled(value: boolean) {
    if (!value) {
      this.hover = false;
    }

    this.ellipsisDisabled = value;
  }

  @Input()
  public set hover(value: boolean | { status: boolean; delay: number }) {
    if (this.ellipsisDisabled) {
      this.hoverSubject.next({ status: false, delay: 0 });

      return;
    }

    this.hoverSubject.next(typeof value === 'boolean' ? { status: value, delay: 0 } : value);
  }

  protected get isMultiline(): boolean {
    return /\n/.test(this.shortenTextElement.nativeElement.innerText);
  }

  private expand(): void {
    if (this.expanded) {
      return;
    }

    const shortenElement = this.shortenTextElement.nativeElement as HTMLElement;
    const fullElement = this.fullTextElement.nativeElement as HTMLElement;

    this.updateFullElementContent();

    if (!this.isMultiline && fullElement.getBoundingClientRect().width <= shortenElement.getBoundingClientRect().width) {
      this.clearFullElementContent();

      return;
    }

    document.body.append(fullElement);
    this.expanded = true;
    this.ellipsisService.setActiveEllipsis(this);

    this.updateFullElementStyle();

    this.scrollSubscription?.unsubscribe();
    this.scrollSubscription = this.scrollbarComponent?.scrollbar.scrolled.subscribe(() => {
      this.updateFullElementStyle();
    });

    this.activeEllipsisSubscription?.unsubscribe();
    this.activeEllipsisSubscription = this.ellipsisService.activeEllipsis$
      .pipe(
        filter((ellipsis) => ellipsis !== this)
      )
      .subscribe(() => {
        this.hover = false;
      });

    this.contentObserver.observe(
      this.shortenTextElement.nativeElement,
      { characterData: true, childList: true }
    );
  }

  private collapse(): void {
    if (!this.expanded) {
      return;
    }

    const fullElement = this.fullTextElement.nativeElement as HTMLElement;

    this.fullTextContainer.nativeElement.append(fullElement);
    fullElement.removeAttribute('style');
    this.expanded = false;
    this.clearFullElementContent();
    this.scrollSubscription?.unsubscribe();
    this.activeEllipsisSubscription?.unsubscribe();
    this.contentObserver.disconnect();
  }

  private updateFullElementContent(): void {
    this.fullTextElement.nativeElement.innerHTML = this.shortenTextElement.nativeElement.innerHTML;
  }

  private clearFullElementContent(): void {
    this.fullTextElement.nativeElement.innerHTML = '';
  }

  private updateFullElementStyle(): void {
    if (!this.expanded) {
      return;
    }

    const shortenElement = this.shortenTextElement.nativeElement as HTMLElement;
    const fullElement = this.fullTextElement.nativeElement as HTMLElement;
    const styles = getComputedStyle(shortenElement);
    const textBounding = shortenElement.getBoundingClientRect();

    fullElement.style.left = `${ textBounding.x + window.scrollX }px`;
    fullElement.style.top = `${ textBounding.y + window.scrollY }px`;
    fullElement.style.maxWidth = `${ window.innerWidth - textBounding.x - 10 }px`;
    fullElement.style.fontFamily = styles.fontFamily;
    fullElement.style.fontSize = styles.fontSize;
    fullElement.style.fontStyle = styles.fontStyle;
    fullElement.style.fontWeight = styles.fontWeight;
    fullElement.style.letterSpacing = styles.letterSpacing;
    fullElement.style.lineHeight = styles.lineHeight;
    fullElement.style.textTransform = styles.textTransform;
    fullElement.style.color = this.matchColor ? styles.color : '';
  }

  private isVisibleInScroll(): boolean {
    if (!this.scrollbarComponent) {
      return true;
    }

    const viewPortBounds = this.scrollbarComponent.scrollbar.viewport.nativeElement.getBoundingClientRect();
    const elementBounds = this.elementRef.nativeElement.getBoundingClientRect();

    return elementBounds.bottom > viewPortBounds.top && elementBounds.top < viewPortBounds.bottom;
  }

  private isVisibleInTable(): boolean {
    let tableElement = this.elementRef.nativeElement;

    // eslint-disable-next-line no-cond-assign
    while (tableElement = tableElement.parentElement) {
      if (tableElement.classList.contains('table-header')) {
        return true;
      }

      if (tableElement.classList.contains('proget-table')) {
        break;
      }
    }

    if (!tableElement) {
      return true;
    }

    const header = tableElement.getElementsByClassName('table-header');

    if (!header.length) {
      return true;
    }

    const tableHeaderBounds = header.item(0).getBoundingClientRect();
    const elementBounds = this.elementRef.nativeElement.getBoundingClientRect();

    return elementBounds.bottom > tableHeaderBounds.bottom;
  }
}

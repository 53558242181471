import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Provider,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { PasswordFormControl } from '@proget-shared/form/conditional-form-generator';
import { debounceTime, distinctUntilChanged, filter, map, noop, Subscription } from 'rxjs';

const PASSWORD_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PasswordComponent),
  multi: true,
};

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [PASSWORD_VALUE_ACCESSOR],
})
export class PasswordComponent implements OnInit, ControlValueAccessor, OnDestroy {
  public readonly form: FormGroup;

  @Input()
  public formControl: FormControl;
  @Input()
  public formControlName: string;
  public inputType = 'password';
  public isOptional = true;
  @Input()
  public placeholder = '';
  @Input()
  public autocomplete: false | string = false;

  private readonly subscription = new Subscription();

  @ViewChild('passwordInput')
  private passwordInputRef: ElementRef<HTMLInputElement>;
  private modelTouched: () => void = noop;
  private modelChanged: (value: string) => void = noop;
  private modelValue: string;

  constructor(
    @Optional()
    private controlContainer: ControlContainer,
    formBuilder: FormBuilder
  ) {
    this.form = formBuilder.group({
      enabled: false,
      password: '',
    });
  }

  ngOnInit(): void {
    if (this.control instanceof PasswordFormControl) {
      const enabledValue = this.form.get('enabled').value;

      this.control.enabledControl.setValue(enabledValue);
      // use form control from PasswordFormControl for checkbox
      this.form.setControl('enabled', this.control.enabledControl);
    }

    this.subscription.add(
      this.form.get('enabled').valueChanges.subscribe({
        next: (value) => {
          if (!value) {
            this.inputType = 'password';
            this.isOptional = true;
            this.form.get('password').disable({ emitEvent: false });

            return;
          }

          if (this.form.enabled) {
            this.form.get('password').enable({ emitEvent: false });
          }
        },
      })
    );

    this.subscription.add(
      this.form
        .get('enabled')
        .valueChanges.pipe(
          debounceTime(1),
          distinctUntilChanged(),
          filter((value) => value)
        )
        .subscribe({
          next: () => {
            this.control?.markAsUntouched();
            this.passwordInputRef.nativeElement.focus();
          },
        })
    );

    this.subscription.add(
      this.form.valueChanges
        .pipe(
          map(() => this.form.getRawValue()),
          map(({ enabled, password }) => (enabled || !this.isOptional ? password || '' : void 0)),
          // case for empty password: emit change when tick was clicked for optional password (force dirty state)
          filter((password) => password !== this.modelValue || password === ''),
          debounceTime(0)
        )
        .subscribe({
          next: (password) => {
            this.modelValue = password;

            this.modelChanged(password);
          },
        })
    );

    this.subscription.add(
      this.form.get('password').valueChanges
        .subscribe({
          next: () => {
            this.modelTouched();
          },
        })
    );

    this.setDisabledState(false);
  }

  writeValue(password: string): void {
    this.modelValue = password;
    this.form.get('password').setValue('string' === typeof password ? password : '', { emitEvent: false });

    if (this.isOptional) {
      const enabled = 'string' === typeof password;
      const passwordControl = this.form.get('password');
      const noEmit = { emitEvent: false };

      this.form.get('enabled').setValue(enabled, { onlySelf: true });
      enabled ? passwordControl.enable(noEmit) : passwordControl.disable(noEmit);

      if (this.control instanceof PasswordFormControl) {
        this.control.updateValidators();
      }
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.modelTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable({ emitEvent: false });

      return;
    }

    this.form.enable({ emitEvent: false });

    if (!this.form.value.enabled && this.isOptional) {
      this.form.get('password').disable({ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Input()
  public set optional(optionalInput: any) {
    const optional = !!optionalInput;

    if (optional === this.isOptional) {
      return;
    }

    this.isOptional = optional;
    this.form.get('enabled').setValue(!optional || 'string' === typeof this.modelValue, { onlySelf: true });

    if (this.form.disabled) {
      return;
    }

    if (!optional) {
      const passwordField = this.form.get('password');

      if (passwordField.disabled) {
        passwordField.enable({ emitEvent: false });
      }

      return;
    }

    this.setDisabledState(false);
  }

  public passwordBlurred(): void {
    this.modelTouched();
    this.control?.updateValueAndValidity();
  }

  public togglePasswordVisibility(): void {
    this.inputType = 'password' === this.inputType ? 'text' : 'password';
  }

  protected get control(): FormControl {
    return (
      this.formControl ||
      ((this.controlContainer?.control?.get(this.formControlName) || null) as FormControl)
    );
  }

  protected get textInputVisibility(): boolean {
    return this.inputType === 'text' || !this.autocomplete && !this.form.get('password').value;
  }
}

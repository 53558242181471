import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input()
  public displayProgress = true;

  protected currentProgress = 0;

  @Input()
  public set progress(value: number) {
    this.currentProgress = isFinite(Number(value)) ? Math.max(0, Math.min(100, value)) : 0;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnimatedHeightComponent } from './animated-height/animated-height.component';
import { AnimatedHiddenDirective } from './animated-hidden.directive';

@NgModule({
  declarations: [
    AnimatedHiddenDirective,
    AnimatedHeightComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AnimatedHiddenDirective,
    AnimatedHeightComponent,
  ],
})
export class AnimationsModule { }

export class DateParser {
  public static parse(input: string, format: string): Date {
    const allParts: string[] = ['y', 'm', 'd', 'H', 'M', 'S'];
    const inputParts: string[] = [];

    const partPattern = new RegExp(`[${allParts.join('')}]{1,}`, 'g');
    const formatPatternSource: string = format.replace(partPattern, (found) => {
      const partKey: string = found.substring(0, 1);

      if (-1 !== inputParts.indexOf(partKey)) {
        throw new Error(`Duplicated '${partKey}' key in '${format}' date format.`);
      }

      const partLength: number = 'y' === partKey ? 4 : found.length;

      inputParts.push(partKey);

      return partLength === 1 ? '(\\d{1,2})' : `(\\d{${partLength}})`;
    });

    const inputMatch: RegExpMatchArray = input.match(new RegExp(`^${formatPatternSource}$`));

    if (!inputMatch) {
      return new Date(void 0);
    }

    const partsValues: number[] = [];

    for (let i = 0; i < allParts.length; i++) {
      const partKey: string = allParts[i];
      const foundPartIndex: number = inputParts.indexOf(partKey);

      partsValues[i] = -1 === foundPartIndex ? 0 : Number(inputMatch[foundPartIndex + 1]);
    }

    return new Date(
      partsValues[0],
      partsValues[1] - 1,
      partsValues[2],
      partsValues[3],
      partsValues[4],
      partsValues[5]
    );
  }

  public static stringify(input: Date, format: string): string {
    const partsMethods: { [key: string]: Function } = {
      y: Date.prototype.getFullYear,
      m: Date.prototype.getMonth,
      d: Date.prototype.getDate,
      H: Date.prototype.getHours,
      M: Date.prototype.getMinutes,
      S: Date.prototype.getSeconds,
    };
    const partPattern = new RegExp(`[${Object.keys(partsMethods).join('')}]{1,}`, 'g');

    return format.replace(partPattern, (found) => {
      const partKey: string = found.substring(0, 1);
      const dateValue: number = partsMethods[partKey].apply(input);
      const partValue: number = 'm' === partKey ? dateValue + 1 : dateValue;

      if ('y' === partKey) {
        return partValue.toString();
      }

      const valueLength: number = Math.max(found.length, partValue.toString().length);
      const leadingZeros: string = [...Array(valueLength)].map(() => 0).join('');
      const stringLength = `${leadingZeros}${partValue}`.length;

      return `${leadingZeros}${partValue}`.substring(stringLength - valueLength);
    });
  }
}

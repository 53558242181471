import { Directive, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ObjectHelper } from '@proget-shared/helper';
import { map, merge, Subscription, switchMap } from 'rxjs';

import { RelatedControlsDirective } from './related-controls.directive';

@Directive({
  selector: '[appApiErrors]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ApiErrorsDirective,
      multi: true,
    },
  ],
})
export class ApiErrorsDirective implements OnInit, OnDestroy, Validator {
  @Input()
  public formControl: FormControl;
  @Input()
  public formControlName: string;

  private readonly relatedControlsSubscription: Subscription;

  private errors: string[] = [];
  private control: AbstractControl;
  private snapshot = '';
  private relatedControlsSnapshot = '';
  private relatedControlsChanged = false;

  constructor(
    @Optional()
    private controlContainer: ControlContainer,
    @Self()
    @Optional()
    private relatedControls?: RelatedControlsDirective
  ) {
    this.relatedControlsSubscription = relatedControls?.controls$
      .pipe(
        switchMap((controls) => merge(...controls.map((control) => control.valueChanges))),
        map(() => this.takeRelatedControlsSnapshot())
      )
      .subscribe({
        next: (snapshot) => {
          this.relatedControlsChanged = this.relatedControlsSnapshot !== snapshot;
        },
      });
  }

  ngOnInit(): void {
    this.control =
      this.formControl ||
      this.controlContainer?.control.get(this.formControlName) ||
      null
    ;
  }

  ngOnDestroy(): void {
    this.relatedControlsSubscription?.unsubscribe();
  }

  validate(): ValidationErrors | null {
    return !this.relatedControlsChanged && this.snapshot === this.stringifyControlValue(this.control) && this.errors.length > 0
      ? { apiErrors: this.errors.slice() }
      : null;
  }

  @Input()
  public set appApiErrors(errors: any) {
    this.errors = ObjectHelper.findStrings(errors).filter((error, index, all) => all.indexOf(error) === index);
    this.snapshot = this.stringifyControlValue(this.control);
    this.relatedControlsSnapshot = this.takeRelatedControlsSnapshot();
    this.relatedControlsChanged = false;

    this.control?.updateValueAndValidity({ onlySelf: true });
  }

  private takeRelatedControlsSnapshot(): string {
    return (this.relatedControls?.appRelatedControls ?? [])
      .map((control) => this.stringifyControlValue(control))
      .join(';');
  }

  private stringifyControlValue(control: AbstractControl): string {
    if (!control) {
      return '';
    }

    // TODO iterate through FormGroups and FormArrays

    const value = control.value;

    if (value instanceof File) {
      return `${value.name}|${value.lastModified.toString()}|${value.size}`;
    }

    return JSON.stringify(value);
  }
}

<div
  #tooltipContent
  [style.width.px]="width"
  class="proget-tooltip"
>
  <span
    [style.transform]="triangleTransform"
    class="triangle"
  ></span>
  <ng-content></ng-content>
</div>

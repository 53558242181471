import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiHelperModule } from '@proget-shared/ui/helper';
import { ScrollbarModule } from '@proget-shared/ui/scrollbar';

import { OverlayPanelComponent } from './component/overlay-panel.component';
import { ChildDirective } from './directive/child.directive';
import { OverlayPanelDirective } from './directive/overlay-panel.directive';

@NgModule({
  declarations: [
    OverlayPanelDirective,
    OverlayPanelComponent,
    ChildDirective,
  ],
  imports: [
    CommonModule,
    UiHelperModule,
    ScrollbarModule,
  ],
  exports: [
    OverlayPanelDirective,
    ChildDirective,
  ],
})
export class OverlayPanelModule { }

import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@core/service';
import { noop, Subscription } from 'rxjs';

@Directive({
  selector: '[appUserClass]',
})
export class UserClassDirective implements OnInit, OnDestroy {
  @Input()
  public appUserClass: string[] = [];

  private userServiceOnChangeSubscription = new Subscription();

  constructor(private elementRef: ElementRef, private userService: UserService) {}

  ngOnInit(): void {
    this.userServiceOnChangeSubscription = this.userService.onChange.subscribe({
      next: (user) => {
        this.updateClasses(user.loggedIn);
      },
      error: noop,
    });
  }

  ngOnDestroy(): void {
    this.userServiceOnChangeSubscription.unsubscribe();
  }

  private updateClasses(loggedIn: boolean): void {
    for (const className of this.appUserClass) {
      loggedIn ? this.addClass(className) : this.removeClass(className);
    }
  }

  private addClass(className: string): void {
    const classNames: string[] = this.splitClassString(className);

    for (const item of classNames) {
      this.elementRef.nativeElement.classList.add(item);
    }
  }

  private removeClass(className: string): void {
    const classNames: string[] = this.splitClassString(className);

    for (const item of classNames) {
      this.elementRef.nativeElement.classList.remove(item);
    }
  }

  private splitClassString(classString: string): string[] {
    return classString.split(' ');
  }
}

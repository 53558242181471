import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayPanelModule } from '@proget-shared/ui/overlay-panel';

import { MenuTemplateComponent } from './component/menu-template.component';
import { MenuDirective } from './directive/menu.directive';

@NgModule({
  declarations: [
    MenuTemplateComponent,
    MenuDirective,
  ],
  imports: [
    CommonModule,
    OverlayPanelModule,
  ],
  exports: [
    MenuDirective,
  ],
})
export class MenuModule { }

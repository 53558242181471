<app-grid-filters
  *ngIf="areFiltersConfigured(gridControl.filtersConfig$ | async)"
  [gridControl]="gridControl"
  class="filters"
>
</app-grid-filters>
<ng-container
  *ngTemplateOutlet="gridContent; context: { $implicit: pageItems, gridControl: gridControl }"
>
</ng-container>

import {
  ViewContainerRef,
  ComponentRef,
  ElementRef,
  Type,
} from '@angular/core';
import { delay, Observable, of, Subject } from 'rxjs';

import { DEFAULT_DIALOG_OPTIONS } from './const/default-dialog-options.const';
import { ConfigurableDialog } from './interface/configurable-dialog.interface';
import { DialogsZIndexService } from './service/dialogs-zindex.service';
import { DialogOptions } from './type/dialog-options.type';

export class DialogWrapper<T = any, U = any> {
  public fadingOut = false;
  public locked = false;

  protected placeholder: ViewContainerRef;
  protected options: DialogOptions;
  private dialogBodyRef: ComponentRef<T>;
  private responseSubject = new Subject<U>();

  constructor(
    protected elementRef: ElementRef,
    private dialogsZIndexService: DialogsZIndexService,
    private providedDialogOptions: Partial<DialogOptions>,
    private zIndexOffset = 0
  ) {
    if (
      document.activeElement &&
      'function' === typeof (document.activeElement as HTMLElement).blur
    ) {
      (document.activeElement as HTMLElement).blur();
    }

    this.zIndex = dialogsZIndexService.nextZIndex();
  }

  public get response$(): Observable<U> {
    return this.responseSubject;
  }

  public get zIndex(): number {
    return Number(this.elementRef.nativeElement.style.zIndex) - this.zIndexOffset;
  }

  public set zIndex(zIndex: number) {
    this.elementRef.nativeElement.style.zIndex = zIndex + this.zIndexOffset;
  }

  public isFloating(): boolean {
    return this.options?.floating;
  }

  public createDialogBody(
    component: Type<T>,
    options: Partial<DialogOptions> = {}
  ): void {
    this.dialogBodyRef = this.placeholder.createComponent(component);
    this.options = Object.assign(
      {},
      DEFAULT_DIALOG_OPTIONS,
      this.providedDialogOptions,
      options
    );

    const dialogBody = this.dialogBodyRef.instance as unknown;

    try {
      (dialogBody as ConfigurableDialog).onConfiguration.call(
        dialogBody,
        options.configuration || {}
      );
    // eslint-disable-next-line no-empty
    } catch {}

    const dialogElement = this.dialogBodyRef.location.nativeElement;

    if ('string' === typeof this.options.styleClass) {
      this.options.styleClass.split(' ')
        .map((classItem) => classItem.trim())
        .forEach((classItem) => {
          if (classItem) {
            dialogElement.classList.add(classItem);
          }
        });
    }
  }

  public getDialogBody(): T {
    return this.dialogBodyRef ? this.dialogBodyRef.instance : null;
  }

  public getOptions(): DialogOptions {
    return this.options;
  }

  public emitMessage(message: U): void {
    this.responseSubject.next(message);
  }

  public lock(): void {
    this.locked = true;
  }

  public unlock(): void {
    this.locked = false;
  }

  public resolveDialog(response: U): void {
    this.emitMessage(response);
    this.finalizeDialog();
  }

  public rejectDialog(reason: any = 'reject'): void {
    this.responseSubject.error(reason);
  }

  public finalizeDialog(): void {
    this.responseSubject.complete();
  }

  public fadeOut(): Observable<void> {
    this.fadingOut = true;

    return of(null).pipe(delay(this.options.animationDuration));
  }

  public bringToFront(): void {
    if (this.dialogsZIndexService.getTopZIndex() !== this.zIndex) {
      this.zIndex = this.dialogsZIndexService.nextZIndex();
    }
  }

  public setSize(_width: number, _height: number): void {
    console.warn('Only floating resizable dialog can be resized');
  }

  protected focusInput(): void {
    const visibleInput = ['textarea', 'input', 'app-dropdown', 'app-listbox']
      .map((tagName) => Array.from<HTMLElement>(this.dialogBodyRef.location.nativeElement.getElementsByTagName(tagName)))
      .flat()
      .filter((element) => !element.hasAttribute('disabled') && Number(element.getAttribute('tabindex') ?? 0) >= 0)
      .map((element) => ({ element, bounds: element.getBoundingClientRect() }))
      .filter(({ bounds }) => bounds.width > 0 && bounds.height > 0)
      .sort((a, b) => b.bounds.x * 10000 + b.bounds.y - (a.bounds.x * 10000 + a.bounds.y))
      .pop();

    if (visibleInput) {
      visibleInput.element.focus();
    }
  }

  protected setSelfClassName(className: string): void {
    this.elementRef.nativeElement.classList.add(className);
  }
}

import { QueryParams } from '@proget-shared/_common';

import { GridFilterType } from '../enum/grid-filter-type.enum';
import { Range } from '../model/range';
import { FilterConfig, RangeFilterConfig } from '../type/filter-config.type';
import { FiltersValues } from '../type/filters-values.type';

enum RangeSide {
  FROM = 'from',
  TO = 'to',
}
type RangeAlias = {
  name: string;
  side: RangeSide;
};

export class FiltersValuesSerializer {
  private readonly RANGE_KEY_PATTERN: RegExp = new RegExp(
    `^([^\\[]+)\\[(${RangeSide.FROM}|${RangeSide.TO})\\]$`
  );

  private knownAliases: { [alias: string]: RangeAlias } = {};

  public setFiltersConfig(config: FilterConfig[]): void {
    const aliases: { [alias: string]: RangeAlias } = {};

    for (const filter of config) {
      if (!filter || !this.isRangeFilter(filter) || !filter.hasOwnProperty('requestAlias')) {
        continue;
      }

      const rangeFilter: RangeFilterConfig = filter as RangeFilterConfig;

      if (rangeFilter.requestAlias.from) {
        this.addAlias(aliases, rangeFilter.requestAlias.from, rangeFilter.name, RangeSide.FROM);
      }

      if (rangeFilter.requestAlias.to) {
        this.addAlias(aliases, rangeFilter.requestAlias.to, rangeFilter.name, RangeSide.TO);
      }
    }

    this.knownAliases = aliases;
  }

  public flattenValues(filtersValues: FiltersValues): QueryParams {
    const flattenValues: any = {};

    for (const key in filtersValues) {
      if (!filtersValues.hasOwnProperty(key)) {
        continue;
      }

      if (filtersValues[key] instanceof Range) {
        const fromAlias: string = this.getAlias(key, RangeSide.FROM);

        flattenValues[fromAlias] = filtersValues[key].from;
        const toAlias: string = this.getAlias(key, RangeSide.TO);

        flattenValues[toAlias] = filtersValues[key].to;

        continue;
      }

      flattenValues[key] = filtersValues[key];
    }

    return flattenValues;
  }

  public parseValues(params: QueryParams): FiltersValues {
    const filtersValues: FiltersValues = {};

    for (const key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }

      const rangeAlias: RangeAlias = this.readRangeAlias(key);

      if (!rangeAlias) {
        filtersValues[key] = params[key];

        continue;
      }

      const value: string = params[key] as string;
      const range: Range = (filtersValues[rangeAlias.name] as Range) || new Range();

      if (RangeSide.FROM === rangeAlias.side) {
        range.from = value;
      } else if (RangeSide.TO === rangeAlias.side) {
        range.to = value;
      }

      filtersValues[rangeAlias.name] = range;
    }

    return filtersValues;
  }

  private readRangeAlias(key: string): RangeAlias {
    if (this.knownAliases.hasOwnProperty(key)) {
      return this.knownAliases[key];
    }

    const rangeMatch: RegExpMatchArray = key.match(this.RANGE_KEY_PATTERN);

    if (null !== rangeMatch) {
      return { name: rangeMatch[1], side: rangeMatch[2] as RangeSide };
    }

    return null;
  }

  private getAlias(name: string, side: RangeSide): string {
    for (const alias in this.knownAliases) {
      if (
        this.knownAliases.hasOwnProperty(alias) &&
        name === this.knownAliases[alias].name &&
        side === this.knownAliases[alias].side
      ) {
        return alias;
      }
    }

    return `${name}[${side}]`;
  }

  private addAlias(
    aliases: { [alias: string]: RangeAlias },
    newAlias: string,
    name: string,
    side: RangeSide
  ): { [alias: string]: RangeAlias } {
    if (aliases.hasOwnProperty(newAlias)) {
      throw new Error(`duplicated filter alias: ${newAlias}`);
    }

    const newAliasEntry: { [alias: string]: RangeAlias } = {};

    newAliasEntry[newAlias] = { name, side };

    return Object.assign(aliases, newAliasEntry);
  }

  private isRangeFilter(filter: FilterConfig): boolean {
    return GridFilterType.RANGE_NUMBER === filter.type || GridFilterType.RANGE_DATE === filter.type;
  }
}

import { Component } from '@angular/core';

import { Toast } from '../../interface/toast.interface';
import { ToastContainerService } from '../../service/toast-container.service';
import { BasicToastOptions } from '../../type/basic-toast-options.type';

@Component({
  selector: 'app-basic-toast',
  templateUrl: './basic-toast.component.html',
  styleUrls: ['./basic-toast.component.scss'],
})
export class BasicToastComponent implements Toast {
  public title = '';
  public details = '';
  public linkRoute: string | string[] | undefined;
  public linkLabel = '';
  public html = true;

  constructor(private toastContainerService: ToastContainerService) {}

  setOptions(options: BasicToastOptions): void {
    this.html = options.html ?? true;
  }

  protected close(): void {
    this.toastContainerService.closeByInstance(this);
  }
}

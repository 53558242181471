import { BehaviorSubject, map, MonoTypeOperatorFunction, tap, withLatestFrom } from 'rxjs';
// eslint-disable-next-line
export function sum(): MonoTypeOperatorFunction<number> {
  const sumSubject = new BehaviorSubject<number>(0);

  return (input$) => input$.pipe(
    withLatestFrom(sumSubject),
    map(([sumValue, currentValue]) => sumValue + currentValue),
    tap({
      next: (result) => {
        sumSubject.next(result);
      },
    })
  );
}
